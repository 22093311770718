import React, { useState, useEffect } from "react";

const CheckAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cash, setCash] = useState();
  const [pan, setPan] = useState("");
  const [kycStatus, setKycStatus] = useState(0);
  const [walr, setWalr] = useState();
  const [residex, setResidex] = useState();
  const [loaderCheckAuth, setLoaderCheckAuth] = useState(true);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    setLoaderCheckAuth(true);
    fetch(`https://api-scdndestates.com/api/check/`, {
      method: "POST",
      body: JSON.stringify({
        access_token: "access_token=" + getCookie("access_token"),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setIsAuthenticated(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data["authenticated"] === true) {
          setIsAuthenticated(true);
          setEmail(data["user_email"]);
          setPhone(data["user_phone"]);
          setPan(data["user_pan"]);
          setCash(data["user_cash"]);
          setWalr(data["user_walr"]);
          setResidex(data["user_residex"]);
          setKycStatus(data["total_kyc"]);
          setLoaderCheckAuth(false);
        } else {
          setIsAuthenticated(false);
          setLoaderCheckAuth(false);
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [isAuthenticated, email, phone, pan, walr, residex, kycStatus]);

  return {
    loaderCheckAuth,
    isAuthenticated,
    email,
    phone,
    pan,
    cash,
    walr,
    residex,
    kycStatus,
  };
};

export default CheckAuth;

import React, { useState } from "react";
import { RiDashboardFill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRobot } from "react-icons/fa6";
import { BsDatabaseFillGear } from "react-icons/bs";
import { RiFolderUserFill } from "react-icons/ri";
import { IoDocumentsSharp } from "react-icons/io5";
import { ImUserTie } from "react-icons/im";
import { GrMoney } from "react-icons/gr";
import { VscGraphLine } from "react-icons/vsc";
import { PiUserCircleGearBold } from "react-icons/pi";
import { BiSolidCoupon } from "react-icons/bi";
import { HiBellAlert, HiClipboardDocumentList } from "react-icons/hi2";

function SideBar({ sideBar, handleMenuItem }) {
  const [menuItem, setMenuItem] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMenuindex = (item) => {
    setMenuItem(item);
  };

  const menuItems = [
    { title: "Dashboard", icon: RiDashboardFill },
    { title: "Database Operations", icon: BsDatabaseFillGear },
    { title: "External Excel", icon: IoDocumentsSharp },
    { title: "User Management", icon: RiFolderUserFill },
    { title: "Agent Payouts", icon: ImUserTie },
    { title: "Asset-Liability", icon: HiClipboardDocumentList },
    { title: "Trading Portal", icon: VscGraphLine },
    { title: "Risk-Sensor Engine", icon: FaRobot },
    { title: "Audit Log & Alerts", icon: HiBellAlert },
  ];

  return (
    <nav
      className="admin-sidebar sticky"
      id="sidebar"
      style={{ width: sideBar ? "270px" : "90px" }}
    >
      {sideBar && (
        <div className="flex flex-col justify-center items-center admin-profile">
          {/* <img
            className="border rounded-circle"
            src=""
            alt="profile"
            height={50}
          /> */}

          <span className="fw-bold text-xl text-black">Saptarshi Das</span>
          <span className="text-gray-700 bfont-semibold text-base">Whole-Time Director</span>
        </div>
      )}

      {/* Dashboard Menu */}
      <ul className="list-unstyled py-0 px-4">
        {menuItems.map((item, index) => (
          <li
            className="sidebar-item cursor-pointer"
            key={index}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <a
              className="nav-link flex  justify-between items-center  rounded-md hover:bg-zinc-200"
              onClick={() => {
                handleMenuItem(index);
                handleMenuindex(index);
              }}
            >
              <div
                className={`flex justify-between w-full items-center px-2 py-2.5 rounded-md${
                  menuItem === index
                    ? "text-white bg-[#4E83D9] rounded-md"
                    : "text-dark"
                }`}
              >
                {sideBar && (
                  <span
                    className={`text-base ${
                      menuItem === index ? "text-[#ffffff]" : "text-[#000000]"
                    }`}
                  >
                    {item.title}
                  </span>
                )}
                <item.icon
                  size={22}
                  className="admin-sidebar-icon"
                  color={menuItem === index ? "#ffffff" : "#000000"}
                />
              </div>
            </a>
            {!sideBar && (
              <div
                className="sidebar-item-collapse"
                style={{
                  display: hoveredItem === index ? "block" : "none",
                }}
              >
                {item.title}
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default SideBar;

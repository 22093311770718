import React from "react";

const DateDisplay = ({ date }) => {
  return (
    <div className=" w-[133px] md:w-[143px] xl:w-[153px] 2xl:w-[163px] flex items-center flex-wrap justify-between h-full border rounded-lg mt-2 font-bold ">
      <div className="w-full xl:p-[7px] lg:p-[6px] m-0 flex justify-center items-center bg-[#D8E5F9] rounded-t-lg text-lg lg:text-2xl">
        {new Intl.DateTimeFormat("en-us", { year: "numeric" }).format(date)}{" "}
        &nbsp;
      </div>
      <div className="w-full flex justify-center items-center flex-col p-3">
        <p className="lg:text-2xl md:text-3xl">
          {new Intl.DateTimeFormat("en-us", { day: "2-digit" }).format(date)}{" "}
        </p>
        <p className="md:text-xl">
          {new Intl.DateTimeFormat("en-us", { month: "short" }).format(date)}
        </p>
      </div>
    </div>
  );
};

export default DateDisplay;

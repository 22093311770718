import React, { useState } from "react";
import teamsApp from "../../API/Teams.js";
import "../../pages/LegalDocument/DNT.css";
import Header from "../../components/Header/Header.jsx";
import { IoLogoLinkedin } from "react-icons/io5";

function CompanyInfo() {
  const ourTeamAPI = [
    {
      id: 1,
      image: "/images/team-icon.jpg",
      name: "Rohit Kashyap",
      profile: "Marketing Director",
      linkedin: "",
    },
    {
      id: 2,
      image: "/images/team-icon.jpg",
      name: "Sourab Singh",
      profile: "Quality & Statistics",
      linkedin: "",
    },
    {
      id: 3,
      image: "/images/team-icon.jpg",
      name: "Nitesh Kumar",
      profile: "Digital Interface Developer",
      linkedin: "",
    },
    {
      id: 4,
      image: "/images/team-icon.jpg",
      name: "Reshma Ranjan",
      profile: "Webinfra Engineer",

      linkedin: "",
    },
    {
      id: 5,
      image: "/images/team-icon.jpg",
      name: "Jyoti",
      profile: "Compliance Officer",
      linkedin: "",
    },
    {
      id: 6,
      image: "/images/team-icon.jpg",
      name: "Nitish Pradhan",
      profile: "Front-End Developer",
      linkedin: "",
    },
  ];

  return (
    <>
      <Header />
      <div className="after-header max-w-[1800px] mx-auto">
        {/* Out Team Banner */}
        <h2 className="text-3xl md:text-4xl font-bold text-center text-black mt-3">
          About SCDND Estates
        </h2>
        <p className="text-sm md:text-base mt-1 text-center w-[90%] mx-auto">
          We are not just another startup in the fintech sector we are the
          disruptors, the innovators, and the champions of financial fairness.
          Our mission is to challenge the status quo in the financial industry,
          where banks, governments and financial institutions have long thrived
          at the expense of everyday investors.
        </p>

        {/* Company Details */}
        <div className="flex flex-wrap justify-between w-[90%] mx-auto text-black mt-5 gap-3">
          {/* Left Section */}
          <div className="bg-[#F4F4F5] rounded-lg shadow-md w-full lg:w-[40%] xl:w-[30%]">
            <div
              className="w-full h-[100px] md:h-[130px] rounded-t-lg bg-cover"
              style={{
                backgroundImage: "url(/images/profile-back.jpeg)",
              }}
            ></div>
            <div className="relative p-4">
              <img
                src="/images/Rishi.jpg"
                alt=""
                className="w-[150px] absolute -top-10 left-1/2 transform -translate-x-1/2 rounded-full border-4 border-[#F4F4F5]"
              />
              <div className="mt-24">
                <p className="text-2xl font-bold text-center">SAPTARSHI DAS</p>
                <p className="text-lg font-semibold text-gray-700 text-center">
                  Whole time Director
                </p>
                <p className="mt-3">
                  <span className="font-bold">About: </span>
                  <span className="leading-relaxed">
                    Founder at SCDND Estates, Financial Engineer, Econophile,
                    Creator of Hybrid Financial Securities, Problem-solver
                    through Innovation
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="border p-3 text-black rounded-lg w-full lg:w-[50%] xl:w-[55%]">
            <h3 className="text-center text-lg md:text-2xl font-bold bg-[#0590F8] rounded-lg text-white py-1">
              Company Details
            </h3>
            <div className="mt-2 flex flex-col gap-1.5 text-sm md:text-base">
              {[
                { label: "CIN", value: "U70109WB2016PTC215755" },
                {
                  label: "Company Name",
                  value: "SCDND ESTATES PRIVATE LIMITED",
                },
                { label: "RoC", value: "RoC-Kolkata" },
                { label: "Registration Number", value: "215755" },
                {
                  label: "Company Category",
                  value: "Company limited by Shares",
                },
                { label: "Company Sub Category", value: "Non-govt company" },
                { label: "Date of Incorporation", value: "14 May 2016" },
                { label: "Age of Company", value: "7 years, 5 month, 26 days" },
              ].map((item, index) => (
                <div
                  key={index}
                  className="md:flex gap-2 font-semibold items-start md:items-center justify-between border p-2 rounded-md bg-zinc-100 w-full"
                >
                  <p className="break-words">{item.label}</p>
                  <p className="break-words">{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr className="my-10 md:my-20 w-[90%] mx-auto" />

        {/* Our teams */}
        <div className="text-black w-[90%] mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center">
            Meet our Team
          </h2>
          <p className="text-gray-700 text-center text-sm md:text-base mt-1">
            Discover the talented individuals behind our success, each dedicated
            to driving innovation and excellence.
          </p>

          <div className="flex items-center gap-2.5 mt-5 overflow-hidden relative">
            <div className="flex gap-5 animate-marquee">
              {/* Dynamically generate all cards */}
              {Array.from({ length: 20 }).flatMap((_, index) =>
                ourTeamAPI.map((member) => (
                  <div
                    key={`${member.id}-${index}`}
                    className="p-2.5 bg-zinc-100 hover:bg-[#DBEDF9] duration-300 rounded-lg min-w-[230px] hover:shadow"
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                    <div className="flex justify-between items-center mt-2">
                      <p className="text-base font-bold">{member.name}</p>
                      <a
                        href={member.linkedin}
                        target="_blank"
                        className="text-2xl text-[#0077B5] cursor-pointer"
                      >
                        <div className="group relative">
                          <button>
                            <IoLogoLinkedin />
                          </button>
                          <span className="absolute -top-14 left-[50%] -translate-x-[50%] z-20 origin-left scale-0 px-3 rounded-lg border border-gray-300 bg-white py-2 text-sm font-bold shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                            {member.name}
                          </span>
                        </div>
                      </a>
                    </div>
                    <p className="text-sm text-gray-700 font-semibold mb-1">
                      {member.profile}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <style jsx>{`
          .animate-marquee {
            display: flex;
            animation: marquee 250s linear infinite;
          }

          .animate-marquee:hover {
            animation-play-state: paused;
          }

          @keyframes marquee {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}</style>
        <hr className="my-10 md:my-20 w-[90%] mx-auto" />

        {/* Why we Exist */}
        <div className="w-[90%] mx-auto flex flex-wrap justify-between p-4 text-black bg-[#FFFFFF] rounded-lg">
          <div className="flex items-center justify-center w-full lg:w-[30%] mb-6 lg:mb-0">
            <img
              src="/images/why-we.jpg"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          </div>
          <div className="w-full lg:w-[65%]">
            <h3 className="font-bold text-xl md:text-2xl">WHY WE EXIST?</h3>
            <p className="text-base md:text-lg mt-3 ">
              <p>
                The financial world is often characterized by complexity, hidden
                fees, lack of pricing transparency and a continuous shifting of
                goal posts. It is a world where profits are prioritized over
                people, and customers find themselves on the losing end of the
                equation. We believe it is time for a change.
              </p>
              <p className="mt-3">
                We exist to bridge the gap between you and your investments,
                removing all intermediaries and middlemen. Our vision is to
                empower individuals to take control of their financial futures,
                enabling them to achieve their goals and secure a brighter
                tomorrow. We are here to ensure that your hard-earned money
                stays where it belongs—in your pocket.
              </p>
              <p className="mt-3">
                We are here to ensure that your hard-earned money stays where it
                belongs—in your pocket.
              </p>
            </p>
          </div>
        </div>

        {/* Our Vision */}
        <div className="w-[90%] mx-auto flex flex-wrap lg:flex-row-reverse justify-between p-4 text-black bg-[#FFFFFF] rounded-lg mt-10">
          <div className="flex items-center justify-center w-full lg:w-[30%] mb-6 lg:mb-0">
            <img
              src="/images/vision.png"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          </div>
          <div className="w-full lg:w-[65%]">
            <h3 className="font-bold text-xl md:text-2xl">OUR VISION</h3>
            <p className="text-base md:text-lg mt-3 leading-loose">
              The diversity of India is one of our greatest strengths, and we
              are fully devoted to utilize the brilliant human capital our
              nation provides. An inclusive and equitable environment enables us
              to deliver innovative and sustainable outcomes for our people,
              clients, shareholders, and communities.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyInfo;

import React, { useState, useEffect } from "react";
import { RiMenu2Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import UserDetails from "../../components/KYCPage/UserDetails";
import TaxDetails from "../../components/KYCPage/TaxDetails";
import Nominee from "../../components/KYCPage/Nominee";
import ClientVerification from "../../components/KYCPage/ClientVerification";
import PoolAccount from "../../components/KYCPage/PoolAccount";
import SignupMode from "../../components/KYCPage/SignupMode.jsx";
import secureLocalStorage from "react-secure-storage";
import { MdCheckBox } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { IoIosTimer } from "react-icons/io";
import { ImCross } from "react-icons/im";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";

const DrawerComponent = ({ selectedCard, handleSelectCard }) => {
  const KYCFormApi = [
    {
      Id: 1,
      title: "Personal Info",
      name: "User Details",
      content: (
        <>
          <UserDetails />
        </>
      ),
    },
    {
      Id: 2,
      title: "Personal Info",
      name: "Tax Status",
      content: (
        <>
          <TaxDetails />
        </>
      ),
    },
    {
      Id: 3,
      title: "Bank Account",
      name: "Client Verification",
      content: (
        <>
          <ClientVerification />
        </>
      ),
    },
    {
      Id: 4,
      title: "Bank Account",
      name: "Pool A/C Addition",
      content: (
        <>
          <PoolAccount />
        </>
      ),
    },
    {
      Id: 5,
      title: "Registration",
      name: "Nominee",
      content: (
        <>
          <Nominee />
        </>
      ),
    },
    // {
    //   Id: 6,
    //   title: "Registration",
    //   name: "Blockchain Key",
    //   content: (
    //     <>
    //       <BlockchainKey />
    //     </>
    //   ),
    // },
    {
      Id: 6,
      title: "Registration",
      name: "Signup Mode",
      content: (
        <>
          <SignupMode />
        </>
      ),
    },
    // {
    //   Id: 7,
    //   title: "Terms & Conditions",
    //   name: "DSC",
    //   content: (
    //     <>
    //       <DSCPapers />
    //     </>
    //   ),
    // },
  ];

  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const {} = UserData({
    isAuthenticated,
    email,
    pan,
    phone,
    formName: "",
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const [selectedCard1, setSelectedCard1] = useState(KYCFormApi[0]);

  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);

  const handleListItemClick = (card) => {
    handleSelectCard(card);
  };

  const groupedButtons = KYCFormApi.reduce((acc, card) => {
    if (!acc[card.title]) {
      acc[card.title] = [];
    }
    acc[card.title].push(card);
    return acc;
  }, {});

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    if (location.state && location.state.selectedCard1) {
      const selected = KYCFormApi.find(
        (card) => card.name === location.state.selectedCard1
      );
      if (selected) {
        setSelectedCard1(selected);
      }
    }
  }, [location.state]);

  const total = Object.values(userKycData?.["KYC_status"] || {}).reduce(
    (acc, val) => acc + (val || 0),
    0
  );

  return (
    <div>
      <div className="text-center flex justify-center">
        <button
          className=""
          type="button"
          onClick={toggleDrawer}
          aria-controls="drawer-navigation"
        >
          <RiMenu2Line size={30} />
        </button>
      </div>

      <div className="">
        <div
          id="drawer-navigation "
          className={`fixed top-20 left-0 z-40 w-72 h-screen p-4 overflow-y-scroll transition-transform  shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] ${
            isDrawerOpen ? "translate-x-0" : "-translate-x-full"
          } bg-white dark:bg-gray-800`}
          tabIndex="-1"
          aria-labelledby="drawer-navigation-label"
        >
          <div className="flex justify-between ">
            <div className="title">
              <h6 className=" flex flex-col justify-between">
                <span className="text-lg font-bold leading-9 tracking-normal   text-[#212121]">
                  KYC PROCESS
                </span>
                <span
                  className="text-md font-bold leading-4 tracking-normal   text-[#212121]"
                  style={{ color: "#5ab2ff" }}
                >
                  {total}% Completed
                </span>
              </h6>
            </div>
            <div className="close-btn">
              <button
                type="button"
                onClick={toggleDrawer}
                aria-controls="drawer-navigation"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
            </div>
          </div>

          <div className="py-4 overflow-y-scroll">
            <hr className="my-2 p-0" />
            <div className="flex flex-col justify-between">
              {Object.entries(groupedButtons).map(([title, cards], index) => (
                <>
                  <div key={title} className="kyc-btn-heading  py-3">
                    {title}
                  </div>
                  {cards.map((card) => (
                    <div
                      key={card.Id}
                      className={`${
                        selectedCard.Id === card.Id
                          ? "selected3  mb-2 rounded-xl bg-[#5ab2ff]"
                          : "kyc-btn-style mb-2 rounded-xl bg-[#f0f0f0]"
                      }`}
                      onClick={() => handleListItemClick(card)}
                    >
                      <div
                        className="flex justify-between"
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                      >
                        <div>{card.name}</div>
                        <div className="flex items-center ">
                          {(() => {
                            switch (card.name) {
                              case "User Details":
                                return userKycData?.["KYC_status"]?.["B"] ===
                                  15 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["B"] === 5 ||
                                  userKycData?.["KYC_status"]?.["B"] === 10 ? (
                                  <RiEdit2Fill size={20} color="#0051d9" />
                                ) : (
                                  <></>
                                );
                              case "Tax Status":
                                return userKycData?.["KYC_status"]?.["C"] ===
                                  20 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["C"] === 15 ||
                                  userKycData?.["KYC_status"]?.["C"] === 10 ||
                                  userKycData?.["KYC_status"]?.["C"] === 5 ? (
                                  <RiEdit2Fill size={20} color="#0051d9" />
                                ) : (
                                  <></>
                                );
                              case "Client Verification":
                                return userKycData?.["KYC_status"]?.["D"] ===
                                  10 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["D"] === 5 ? (
                                  <RiEdit2Fill size={20} color="#0051d9" />
                                ) : (
                                  <></>
                                );
                              case "Pool A/C Addition":
                                return userKycData?.["KYC_status"]?.["E"] ===
                                  15 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["E"] === 5 ? (
                                  <ImCross size={15} color="red" />
                                ) : userKycData?.["KYC_status"]?.["E"] ===
                                  10 ? (
                                  <IoIosTimer size={20} color="orange" />
                                ) : (
                                  <></>
                                );
                              case "Nominee":
                                return userKycData?.["KYC_status"]?.["F"] ===
                                  10 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["F"] === 5 ? (
                                  <RiEdit2Fill size={20} color="#0051d9" />
                                ) : (
                                  <></>
                                );
                              case "Signup Mode":
                                return userKycData?.["KYC_status"]?.["G"] ===
                                  10 ? (
                                  <MdCheckBox size={20} color="green" />
                                ) : userKycData?.["KYC_status"]?.["G"] === 5 ? (
                                  <RiEdit2Fill size={20} color="#0051d9" />
                                ) : (
                                  <></>
                                );
                              default:
                                return null;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerComponent;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./Page404.css";

function Page401() {
  const navigate = useNavigate();

  return (
    <>
      <div className="compass-container">
        <h5 className="text-4xl font-bold mb-3 text-[#5ab2ff]">
          Application Error
        </h5>
        <div className="bg-cover bg-center flex items-center justify-center">
          <img
            id="Spinner"
            src="/images/401-error.gif"
            alt=""
            className="w-[500px]"
          />
        </div>

        <div className="flex-col justify-center items-center md:mt-10 w-full">
          <h3 className="text-center text-[#212121] font-semibold text-sm md:text-base lg:text-xl  max-w-xl">
            You have pressed the Back button on your Browser or trying to access
            the URL from a saved page.
          </h3>
          <button
            className="mt-4 cursor-pointer flex w-24 md:w-36  h-8 md:h-10  items-center justify-center rounded-md bg-[#5ab2ff] px-2 py-1 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </button>
        </div>
      </div>
    </>
  );
}

export default Page401;

import React, { useEffect } from "react";
import Content from "../../components/Content/Content";
import Header from "../../components/Header/Header";
import FAQ from "./FAQ";

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <div className="after-header">
        <div className="container-fluid">
          <div className="row">
            <div className="max-w-[1800px] mx-auto">
              <Content />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;

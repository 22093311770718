import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import secureLocalStorage from "react-secure-storage";

const WALRComponentChart = () => {
  const chartRef = useRef(null);

  const processData = (data) => {
    const processedData = [];
    for (const date in data) {
      if (date !== "pan") {
        const WALR = data[date].WALR || {};
        const coverYes = WALR.CoverYes || 0;
        const coverNo = WALR.CoverNo || 0;
        processedData.push({
          date: new Date(date),
          coverYes,
          coverNo,
        });
      }
    }
    return processedData.sort((a, b) => a.date - b.date);
  };

  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const chartData = processData(couponData);

  useEffect(() => {
    const options = {
      chart: {
        type: "line",
        height: 400,
        toolbar: { show: true },
      },
      series: [
        {
          name: "CoverYes",
          data: chartData.map((item) => ({ x: item.date, y: item.coverYes })),
        },
        {
          name: "CoverNo",
          data: chartData.map((item) => ({ x: item.date, y: item.coverNo })),
        },
      ],
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (value) => {
            const date = new Date(value);
            return date
              .toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })
              .replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
              });
          },
        },
        rotate: -45,
        rotateAlways: true,
      },
      yaxis: {
        labels: {
          formatter: (value) => value,
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd",
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 5,
      },
      colors: ["#E90074", "#fdadd5"],
    };

    // Create chart instance
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    // Cleanup function to destroy the chart instance
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartData]);

  return <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>;
};

export default WALRComponentChart;

import React, { useState, useEffect } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1";
import "./CompanyInfo.css";
import KeyRatioapi from "../../API/KeyRatioApi.js";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import PopUpContent from "./PopUpContent";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header.jsx";
import { FaFilePdf } from "react-icons/fa6";

function KeyRatio() {
  const [popContent, setPopContent] = useState("");
  const [selectedCard, setSelectedCard] = useState(KeyRatioapi[0]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const { isAuthenticated } = CheckAuth();

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setSelectedDropdownValue("");
  };

  const handleDropdownChange = (e) => {
    const selectedId = e.target.value;
    const card = KeyRatioapi.find((c) => c.id === parseInt(selectedId, 10));
    if (card) {
      setSelectedCard(card);
    }
    setSelectedDropdownValue(selectedId);
  };

  return (
    <div>
      <Header />
      {!isAuthenticated ? (
        <PopUp1 />
      ) : (
        <>
          <div className="container-fluid after-header pb-5 max-w-[1800px] mx-auto">
            <div className="flex w-[90%] mx-auto">
              <h6 className="mt-4 w-full text-sm md:text-base">
                <a href="/">
                  <span className="">Home</span>
                  <span className="text-secondary"> &gt; </span>
                </a>
                <a href="#">
                  <span className="text-dark text-decoration-underline">
                    Key Ratios
                  </span>
                </a>
                <hr className="text-dark" />
              </h6>
            </div>

            <div className="flex flex-wrap gap-5 justify-start mt-4 w-[90%] mx-auto">
              <h2 className="w-full text-center mt-1 py-2 text-base lg:text-2xl font-bold text-black bg-gradient-to-r from-white via-[#DBEDF9] to-white">
                Basis of Preparation of Financial Statements
              </h2>

              <p className="text-center font-semibold text-sm">
                The financial statements have been prepared to comply with the
                Generally Accepted Accounting Principles in India (Indian GAAP),
                including the Accounting Standards notified under the relevant
                provisions of the Companies Act. 2013. The financial statements
                have been prepared under historical cost convention on an
                accrual basis. The accounting policies have been consistently
                applied by the Company and are consistent with those used in the
                previous year.
              </p>

              <div className="w-full flex flex-wrap gap-5 justify-start mt-4 ">
                {/* Left Section */}
                <div className="w-full lg:min-w-[235px] lg:w-[17%]">
                  <div className="py-4 px-4  h-fit border hover:shadow-sm rounded-lg ">
                    <div className="font-bold">
                      <h2 className="text-black">Recent Reports</h2>
                      <hr className="my-1" />
                      <div className="flex flex-wrap justify-center lg:flex-col mt-3 gap-2">
                        {KeyRatioapi.sort((a, b) => b.id - a.id)
                          .slice(0, 3)
                          .map((card, index) => (
                            <div
                              onClick={() => handleSelectCard(card)}
                              key={index}
                              className={`${
                                selectedCard.id === card.id
                                  ? "bg-[#DBEDF9]"
                                  : ""
                              } button-year border min-w-full md:min-w-[200px] lg:min-w-[150px] p-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-[#DBEDF9] duration-200 text-black`}
                            >
                              {card.startYear} - {card.endYear}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="font-bold mt-4">
                      <h2 className="text-black">More Reports</h2>
                      <hr className="my-1" />
                      <div className="select-container">
                        <select
                          name="reports"
                          id="reports"
                          onChange={handleDropdownChange}
                          value={selectedDropdownValue}
                          className="p-2 border rounded-md w-full flex items-center justify-center"
                        >
                          <option value="">Explore</option>
                          {KeyRatioapi.sort((a, b) => b.id - a.id)
                            .slice(3)
                            .map((card) => (
                              <option
                                key={card.id}
                                value={card.id}
                                className="text-black"
                              >
                                {card.startYear} - {card.endYear}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* Abbrevation */}
                  <div className="mt-4 border rounded-lg relative p-3 text-black">
                    <h5 className="font-bold absolute -top-3 px-2 -ml-2 bg-white border rounded-md py-1">
                      Abbreviations
                    </h5>
                    <div className="flex flex-col gap-2 mt-3">
                      <p>
                        <span className="font-bold">NA : </span>Not Applicable
                      </p>
                      <p>
                        <span className="font-bold">PY : </span>Previous Year
                      </p>
                      <p>
                        <span className="font-bold">PYT :</span> Profit Benefit
                        Tax
                      </p>
                      <p>
                        <span className="font-bold">EBIT: </span>Earning Before
                        Interest & Taxes
                      </p>
                      <p>
                        <span className="font-bold"> EBITDA :</span> Earnings
                        Before Interest, Taxes, Depreciation, and Amortization
                      </p>
                    </div>
                  </div>
                </div>

                {/* Right Section */}
                <div className="justify-center border rounded-lg shadow-md w-full lg:w-[68%] xl:w-[74%] p-[1%]">
                  <div className="col-12">
                    {selectedCard && (
                      <>
                        <div className="table-responsive rounded-lg">
                          <table className="table table-borderless table-sm font-size1 min-w-[550px]">
                            <thead className="">
                              <tr className="bg-[#3B82F6] text-white ">
                                <th className="fw-bold max-md:w-2/5" scope="col">
                                  Particulars/ Reasons For variance
                                </th>
                                <th className="fw-bold text-center max-md:w-1/5" scope="col">
                                  For the year ended <br />
                                  31 March {selectedCard.endYear}
                                  &nbsp;(Rs.)
                                </th>
                                <th className="fw-bold text-center max-md:w-1/5" scope="col">
                                  For the year ended <br />
                                  31 March&nbsp;
                                  {selectedCard.startYear}
                                  &nbsp;(Rs.)
                                </th>
                                <th className="fw-bold text-center max-md:w-1/5" scope="col">
                                  Variance (%)
                                </th>
                              </tr>
                            </thead>
                            <tbody className="">
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Current Ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popA && (
                                      <PopUpContent
                                        content={selectedCard.popA}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.A[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.A[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.A[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row font-bold">Current Assets</th>
                                <td className="text-center font-semibold">
                                  {selectedCard.a1[0]}
                                </td>
                                <td className="text-center font-semibold">
                                  {selectedCard.a1[1]}
                                </td>
                                <td className="text-center font-semibold">
                                  {selectedCard.a1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row font-bold">
                                  Current Liabilities
                                </th>
                                <td className="text-center font-semibold">
                                  {selectedCard.a2[0]}
                                </td>
                                <td className="text-center font-semibold">
                                  {selectedCard.a2[1]}
                                </td>
                                <td className="text-center font-semibold">
                                  {selectedCard.a2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Debt-Equity Ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popB && (
                                      <PopUpContent
                                        content={selectedCard.popB}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.B[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.B[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.B[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Total Debt</th>
                                <td className="text-center">
                                  {selectedCard.b1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.b1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.b1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Shareholders Equity</th>
                                <td className="text-center">
                                  {selectedCard.b2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.b2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.b2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Debt Service Coverage Ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popC && (
                                      <PopUpContent
                                        content={selectedCard.popC}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.C[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.C[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.C[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Earnings available for debt service
                                </th>
                                <td className="text-center">
                                  {selectedCard.c1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.c1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.c1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Debt Service</th>
                                <td className="text-center">
                                  {selectedCard.c2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.c2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.c2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Return on Equity Ratio{" "}
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popD && (
                                      <PopUpContent
                                        content={selectedCard.popD}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.D[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.D[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.D[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Total Debt</th>
                                <td className="text-center">
                                  {selectedCard.d1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.d1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.d1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Shareholders Equity</th>
                                <td className="text-center">
                                  {selectedCard.d2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.d2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.d2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Inventory turnover ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popE && (
                                      <PopUpContent
                                        content={selectedCard.popE}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.E[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.E[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.E[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Cost of Goods Sold or Sales</th>
                                <td className="text-center">
                                  {selectedCard.e1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.e1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.e1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Average Inventory</th>
                                <td className="text-center">
                                  {selectedCard.e2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.e2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.e2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Trade Receivables turnover ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popF && (
                                      <PopUpContent
                                        content={selectedCard.popF}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.F[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.F[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.F[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Net Credit sales</th>
                                <td className="text-center">
                                  {selectedCard.f1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.f1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.f1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Average Trade Debtors / Accounts receivable
                                </th>
                                <td className="text-center">
                                  {selectedCard.f2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.f2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.f2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Trade payables turnover ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popG && (
                                      <PopUpContent
                                        content={selectedCard.popG}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.G[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.G[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.G[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Net Credit Purchases</th>
                                <td className="text-center">
                                  {selectedCard.g1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.g1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.g1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Average Trade Payables</th>
                                <td className="text-center">
                                  {selectedCard.g2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.g2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.g2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Net capital turnover ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popH && (
                                      <PopUpContent
                                        content={selectedCard.popH}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.H[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.H[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.H[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Net Service</th>
                                <td className="text-center">
                                  {selectedCard.h1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.h1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.h1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Average Working Capital</th>
                                <td className="text-center">
                                  {selectedCard.h2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.h2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.h2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Net profit ratio
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popI && (
                                      <PopUpContent
                                        content={selectedCard.popI}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.I[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.I[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.I[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Net profit (PBT)</th>
                                <td className="text-center">
                                  {selectedCard.i1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.i1[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.i1[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Total Income</th>
                                <td className="text-center">
                                  {selectedCard.i2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.i2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.i2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Return on Capital employed
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popJ && (
                                      <PopUpContent
                                        content={selectedCard.popJ}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.J[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.J[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.J[2]}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Earnings Before Interest and Tax
                                </th>
                                <td className="text-center">
                                  {selectedCard.j1[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.j1[1]}
                                </td>
                                <td>{selectedCard.j1[2]}</td>
                              </tr>
                              <tr>
                                <th scope="row">Capital employed </th>
                                <td className="text-center">
                                  {selectedCard.j2[0]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.j2[1]}
                                </td>
                                <td className="text-center">
                                  {selectedCard.j2[2]}
                                </td>
                              </tr>
                              <tr className="heading2-style bg-[#E5E7EB] text-[#1E3A8A]">
                                <td className="fw-bold d-flex justify-content-between">
                                  <div className="font-size1 ">
                                    Return on investment
                                  </div>
                                  <div>
                                    {popContent !== selectedCard.popK && (
                                      <PopUpContent
                                        content={selectedCard.popK}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.K[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.K[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.K[2]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default KeyRatio;

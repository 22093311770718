import React, { useState, useEffect } from "react";
import AgentInfoApp from "../API/AgentInfoApi";
import { IoClose } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import { HiMiniCurrencyRupee } from "react-icons/hi2";
import { FaFileInvoice } from "react-icons/fa6";

function AgentsComissions() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [show, setShow] = useState(-1);
  const [show2, setShow2] = useState("");
  const [show3, setShow3] = useState(false);
  const [payDate, setPayDate] = useState("");
  const [invoice, setInvoice] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [payAmount, setPayAmount] = useState(0);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-admin-management-fee/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save management fee");
        }
        return response.json();
      })
      .then((response) => {
        setData3(response.data3);
        setData(response.data);
        setData2(response.data2);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });

    const combinedData = AgentInfoApp.map((agent) => {
      const agentCode = agent.AgentCode;

      // Find corresponding entry in onboardingFee
      const feeData = data2?.find((fee) => {
        const feeKey = Object.keys(fee)[0];
        return fee[feeKey].Code === agentCode.toString();
      });

      // total paid amount to a agent

      let totalAmount = 0;

      const feeData2 = data3
        .filter((item) => item.AgentCode === agentCode)
        .flatMap((item) =>
          Object.values(item)
            .filter((ledgerItem) => typeof ledgerItem === "object") // Ensure to filter out non-object values like AgentCode and StarRating
            .map((ledgerItem) => parseInt(ledgerItem.Pay_amount))
        );

      totalAmount = feeData2.reduce((acc, amount) => acc + amount, 0);

      let amt = 0;
      // Find all corresponding fee in AgentPaymentApp
      data2?.filter((fee) => {
        const feeKey = Object.keys(fee)[0];
        if (parseInt(fee[feeKey].Code) === agentCode) {
          amt = amt + fee[feeKey].Amount;
        }
      });

      // Construct combined data object
      return {
        AgentCode: agentCode,
        AgentName: agent.AgentName,
        Amount: amt,
        TotalPayAmount: totalAmount,
        ClientName: feeData ? Object.keys(feeData)[0] : "Direct",
      };
    });

    setCombinedData(combinedData);
  }, [dataFetched]);

  const calculateTotals = (data) => {
    const totals = {};

    data?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (key !== "pan") {
          const dateData = item[key];
          const walr = dateData.WALR ? dateData.WALR.Management : 0;
          const residex = dateData.RESIDEX ? dateData.RESIDEX.Management : 0;

          if (!totals[key]) {
            totals[key] = { walr: 0, residex: 0 };
          }

          totals[key].walr += walr;
          totals[key].residex += residex;
        }
      });
    });

    return totals;
  };
  const totals = calculateTotals(data);

  const handleClientTable = (val) => {
    setShow(val);
  };
  const handleInvoiceTable = (val) => {
    setShow2(val);
  };

  const UploadNewInvoice = () => {
    fetch(`https://api-scdndestates.com/api/upload-new-invoice/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payDate: payDate,
        invoice: invoice,
        utrNo: utrNo,
        payAmount: payAmount,
        agentCode: show2,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload invoice");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          alert("Uploaded Successfully");
          setPayDate("");
          setInvoice("");
          setUtrNo("");
          setPayAmount("");
          setShow3(false);
          setShow2("");
          setData2(response.data);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  };

  return (
    <>
      <div className="flex">
        {/* Left section */}
        <div className="col-6 p-4 pt-2 border-end">
          <h3 className="text-2xl font-bold text-black">Management Fees</h3>
          <div className="table-responsive border-dark mt-2.5 w-full px-2">
            <table className="table table-borderless table-sm w-full">
              <thead>
                <tr className="bg-[#386BC0] text-white flex justify-between px-2 sm:px-5 rounded-t-lg">
                  <th
                    scope="col"
                    className="text-xs sm:text-sm font-bold text-center w-[30%] sm:w-[20%]"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="text-xs sm:text-sm font-bold text-center w-[30%] sm:w-[20%]"
                  >
                    WALR
                  </th>
                  <th
                    scope="col"
                    className="text-xs sm:text-sm font-bold text-center w-[30%] sm:w-[20%]"
                  >
                    RESIDEX
                  </th>
                </tr>
              </thead>
              <tbody className="h-[400px] sm:h-[500px] bg-white overflow-y-scroll overflow-x-hidden rounded-b-lg flex flex-col gap-1.5 p-1">
                {Object.keys(totals).length > 0 ? (
                  Object.keys(totals).map((date, index) => (
                    <tr
                      key={index}
                      className="shadow-sm text-black font-semibold cursor-pointer hover:font-extrabold hover:bg-[#DBEAFE] flex justify-between px-2 sm:px-5 rounded-lg border hover:scale-105 duration-300"
                    >
                      <td className="text-center text-xs sm:text-sm w-[30%] sm:w-[20%]">
                        {date}
                      </td>
                      <td className="text-center text-xs sm:text-sm w-[30%] sm:w-[20%] flex items-center justify-center gap-1">
                        ₹ {totals[date].walr.toLocaleString("en-IN")}
                      </td>
                      <td className="text-center text-xs sm:text-sm w-[30%] sm:w-[20%]">
                        ₹ {totals[date].residex.toLocaleString("en-IN")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="flex justify-center items-center w-full">
                    <td
                      colSpan={3}
                      className="text-center bg-light text-xs sm:text-sm w-full flex justify-center items-center"
                    >
                      <img
                        src="./images/ghost2.gif"
                        alt="Ghost"
                        className="mr-2"
                      />
                      <span className="text-center text-primary">
                        You don't have any Management Fee.
                      </span>
                      <img
                        src="./images/ghost2.gif"
                        alt="Ghost"
                        className="ml-2"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Right Section */}
        <div className="col-6 p-4 pt-2 border-start">
          <h3 className="text-2xl font-bold text-black">Onboarding Fees</h3>
          <div className="table-responsive border-dark mt-2.5 w-full px-2">
            <table className="table table-borderless table-sm w-full">
              <thead className="w-full rounded-full">
                <tr className="font-semibold w-full bg-[#386BC0] text-white rounded-t-lg flex justify-around">
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center py-2 w-[40%]"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center py-2 w-[30%]"
                  >
                    Amount Payable
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center py-2 w-[30%]"
                  >
                    Amount Paid
                  </th>
                </tr>
              </thead>
              <tbody>
                {combinedData.length > 0 ? (
                  combinedData.map((item, index) => (
                    <tr
                      key={index}
                      className="shadow-sm text-black font-semibold rounded-lg cursor-pointer flex items-center justify-around bg-light mb-1 "
                    >
                      <td className="text-center text-sm px-2 py-2 w-[40%]">
                        {item.AgentName}
                      </td>
                      <td
                        className="text-center cursor-pointer text-sm px-2 py-2 min-w-[30%]"
                        onClick={() => handleClientTable(item.AgentCode)}
                      >
                        <button className="text-white bg-red-600 px-2 py-1 rounded min-w-[60%] hover:scale-105 duration-300">
                          ₹ {item.Amount.toLocaleString("en-In")}
                        </button>
                      </td>
                      <td className="text-center text-sm px-2 py-2 min-w-[30%] ">
                        <button
                          className=" px-2 py-1 text-white bg-green-600 rounded min-w-[60%] hover:scale-105 duration-300"
                          onClick={() => handleInvoiceTable(item.AgentCode)}
                        >
                          ₹ {item.TotalPayAmount.toLocaleString("en-In")}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-top">
                    <td
                      colSpan={3}
                      className="text-center bg-light text-sm py-4"
                    >
                      <div className="flex justify-center items-center">
                        <img
                          src="./images/ghost2.gif"
                          alt="No Data"
                          className="h-6 w-6"
                        />
                        <span className="text-center text-primary mx-2">
                          No Onboarding Fee Yet.
                        </span>
                        <img
                          src="./images/ghost2.gif"
                          alt="No Data"
                          className="h-6 w-6"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {show !== -1 && (
            <>
              <div className="demo2-container">
                <div className="bg-light rounded border-0 m-4 px-3 py-2.5 overflow-hidden pop-up-animation">
                  <div className="flex justify-end mb-2 ">
                    <IoClose
                      className="text-white bg-red-700 hover:bg-red-800 duration-300 font-bold p-0.5 rounded-full text-xl cursor-pointer"
                      onClick={() => setShow(-1)}
                    />
                  </div>
                  <table className="table-responsive table-borderless table-sm border-dark w-full min-h-[200px]">
                    <thead className="bg-[#386BC0] text-white font-semibold rounded-t-lg">
                      <tr className="flex justify-around">
                        <th
                          scope="col"
                          className="text-sm font-semibold text-center"
                        >
                          Clients
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-center"
                        >
                          Relationship Date
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-center"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="max-h-[160px] overflow-y-scroll border rounded-b-lg p-1 flex flex-col gap-1.5">
                      {data2.length > 0 ? (
                        data2
                          .filter(
                            (item) =>
                              parseInt(item[Object.keys(item)[0]].Code) === show
                          )
                          .map((entry, index) => {
                            const key = Object.keys(entry)[0];
                            const item = entry[key];
                            return (
                              <tr
                                key={index}
                                className="hover:bg-[#DBEAFE] text-black font-semibold border shadow-sm rounded-lg  cursor-pointer flex justify-around"
                              >
                                <td className="text-sm text-center break-words">
                                  {item.Name}
                                </td>
                                <td className="text-sm text-center">
                                  {item.Date}
                                </td>
                                <td className="text-sm text-center">
                                  ₹ {item.Amount.toLocaleString("en-IN")}
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr className="flex justify-center items-center">
                          <td
                            colSpan={3}
                            className="text-center bg-light text-sm flex items-center gap-2"
                          >
                            <img src="./images/ghost2.gif" alt="" />
                            <span className="text-primary">
                              No Onboarding Fee Yet.
                            </span>
                            <img src="./images/ghost2.gif" alt="" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {show2 !== "" && (
            <>
              <div className="demo2-container">
                <div
                  className="bg-light rounded border-0 m-4 p-4 overflow-hidden pop-up-animation"
                  style={{
                    width: "600px",
                  }}
                >
                  <div className="flex justify-between mb-2">
                    <button
                      className="bg-green-600 text-white font-bold text-[12px] px-2 py-1 rounded-md flex items-center gap-1"
                      onClick={() => setShow3(!show3)}
                    >
                      Add <IoMdAddCircle />
                    </button>

                    <IoClose
                      className="text-white bg-red-700 hover:bg-red-800 duration-300 font-bold p-0.5 rounded-full text-xl cursor-pointer"
                      onClick={() => setShow2("")}
                    />
                  </div>
                  <div className="table-responsive table table-hover table-borderless table-sm border-dark">
                    <div className="rounded">
                      <div className="font-semibold bg-[#386BC0] text-white flex justify-around rounded-t-lg">
                        <p
                          scope="col"
                          className="text-sm font-semibold text-center flex-1"
                        >
                          Invoice
                        </p>
                        <p
                          scope="col"
                          className="text-sm font-semibold text-center flex-1"
                        >
                          Date
                        </p>
                        <p
                          scope="col"
                          className="text-sm font-semibold text-center flex-[2] min-w-[120px]" // Adjusted width for UTR No
                        >
                          UTR No
                        </p>
                        <p
                          scope="col"
                          className="text-sm font-semibold text-center flex-1"
                        >
                          Amount
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1.5 border rounded-b-lg p-1.5 max-h-[100px] overflow-y-scroll">
                      {data3.length > 0 ? (
                        data3
                          .filter((item) => item.AgentCode === show2)
                          .flatMap((item) =>
                            Object.keys(item)
                              .filter(
                                (key) =>
                                  key !== "AgentCode" && key !== "StarRating"
                              )
                              .map((key, index) => (
                                <div
                                  key={index}
                                  className="shadow-sm flex items-center justify-around bg-[#ffffff] hover:bg-[#DBEAFE] duration-300 text-black font-semibold rounded-lg border hover:scale-100 duration-100 cursor-pointer"
                                >
                                  <p className="text-sm text-center flex-1">
                                    {key}
                                  </p>
                                  <p className="text-sm text-center flex-1">
                                    {new Date(
                                      item[key].Pay_date
                                    ).toLocaleDateString()}
                                  </p>
                                  <p className="text-sm text-center flex-[2] min-w-[120px]">
                                    {" "}
                                    {/* Adjusted width for UTR No */}
                                    {item[key].Utr_no}
                                  </p>
                                  <p className="text-sm text-center flex-1">
                                    ₹
                                    {parseInt(
                                      item[key].Pay_amount
                                    ).toLocaleString("en-IN")}
                                  </p>
                                </div>
                              ))
                          )
                      ) : (
                        <div className="border-top">
                          <div
                            colSpan={4}
                            className="text-center bg-light text-sm"
                          >
                            <div className="flex justify-center items-center">
                              <img src="./images/ghost2.gif" alt="" />
                              <span className="text-center text-primary">
                                No Onboarding Fee Yet.
                              </span>
                              <img src="./images/ghost2.gif" alt="" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {show3 && (
                    <>
                      <div className="border shadow-sm rounded-lg p-3 bg-white text-black">
                        <h3 className="font-bold flex items-center gap-1">
                          <img
                            src="/images/add-invoice.png"
                            className="w-7"
                            alt=""
                          />
                          Add New Invoice
                        </h3>
                        <hr className="my-1 py-0" />
                        <div className="row m-0 p-0">
                          <div className="col-6 m-0 p-0 row">
                            <div className="col-6 p-0 m-0 my-1">
                              <label className="text-sm">
                                Payment Date:
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-6 p-0 m-0 my-1">
                              <input
                                className="form-control col-10 text-sm"
                                type="date"
                                value={payDate}
                                onChange={(e) => setPayDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-6 m-0 p-0 ps-3 row">
                            <div className="col-6 p-0 m-0 my-1">
                              <label className="text-sm">
                                Invoice No:
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-6 p-0 m-0 my-1">
                              <input
                                className="form-control col-10 text-sm"
                                type="text"
                                value={invoice}
                                onChange={(e) => setInvoice(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-6 m-0 p-0 row">
                            <div className="col-6 p-0 m-0 my-1">
                              <label className="text-sm">
                                UTR No:
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-6 p-0 m-0 my-1">
                              <input
                                className="form-control col-10 text-sm"
                                type="text"
                                value={utrNo}
                                onChange={(e) => setUtrNo(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-6 m-0 p-0 ps-3 row">
                            <div className="col-6 p-0 m-0 my-1">
                              <label className="font-size1">
                                Paid Amount:
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-6 p-0 m-0 my-1">
                              <input
                                className="form-control col-10 font-size1"
                                type="number"
                                value={payAmount}
                                onChange={(e) => setPayAmount(e.target.value)}
                              />
                            </div>
                          </div>
                          <hr className="my-2" />
                          <div className="flex justify-end p-0">
                            <button
                              className="bg-green-600 px-2 py-1 rounded-md text-sm text-white font-semibold hover:scale-95 duration-200"
                              onClick={() => {
                                UploadNewInvoice();
                              }}
                            >
                              Upload Invoice
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AgentsComissions;

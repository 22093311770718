import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import "./PopUp.css";
import { useNavigate } from "react-router-dom";

function PopUp2() {
  const navigate = useNavigate();

  return (
    <>
      <div className="demo1-container ">
        <div className="m-1 p-1 xl:p-4 pop1-content shadow-[inset_0px_0px_7px_0px_#4299e1] w-max flex flex-col justify-center items-center pop-up-animation">
          <div className="top"><h6 className=" py-2 md:py-3 px-4 text:md  md:text-xl text-center font-bold leading-5 text-[#212121]">
              You have not completed KYC. Please enter your details in the
              Profile page for access.
            </h6></div>
        
          <div className="bot flex flex-wrap lg:flex-row  h-max mb-3">
          <div className="kyc-img my-1 lg:my-4 h-48 md:h-64 object-cover lg:h-80 w-full lg:w-2/6 bg-white flex items-center justify-center">
          <img
            src="/images/kyc-jpg.jpg"
            alt="scdnd estates"
            className="object-cover mx-16 md:mx-32 lg:mx-0 w-full h-full bg-white flex items-start justify-center"
          />
          </div>
          
          <div className="px-0 w-full lg:w-4/6 h-full">
            
            <div className="flex flex-row m-0 py-1 px-0 mt-1 md:mt-3">
              <div className="w-1/2  border-end">
                <h6 className="font-bold text-[#212121] text-center pb-1 md:pb-2">Without KYC</h6>
                <hr />
                <p className="p-1 md:p-2 text-sm font-semibold h-72 md:h-64">
                  <ul className="list-unstyled">
                    <li className="mb-2  row items-start m-0 p-0">
                      <span className="col-1 flex justify-center m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                          Learn about Decentralized Assets
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                          Post and comment on Blogs
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                        Connect as marketing Partners
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      View financial and audit information
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      Know about the Fees and Charges
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start  p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2 ">
                      Evaluate your portfolio using Risk-Sensor Engine
                      </span>
                    </li>
                  </ul>
                </p>
                <div className="px-1  md:px-5 mx-3">
                <div className="row m-0 p-0">
                  <button
                    className="rounded-md bg-[#fff] px-7 py-2.5 text-sm font-semibold text-[#212121]  hover:bg-[#f5f5f5]  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    SKIP FOR NOW
                  </button>
                </div>
              </div>
              </div>
              <div className="w-1/2">
                <h6 className="font-bold text-[#212121] text-center pb-1 md:pb-2">With KYC</h6>
                <hr />
                <p className="p-1 md:p-2 text-sm font-semibold h-72 md:h-64">
                  <ul className="list-unstyled">
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      Add and Withdraw Money
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      Order in Primary Market
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      Trade DeAs Peer-2-Peer
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      View Ledgers and Contract Notes
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0">
                      <span className="col-1 flex justify-center items-start m-0 p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2">
                      Mine and Audit PoW transactions
                      </span>
                    </li>
                    <li className="mb-2 items-start row m-0 p-0 ">
                      <span className="col-1 flex justify-center items-start  p-0">
                        <TiTick color="#14a44d" size={20} />
                      </span>
                      <span className="col-11 leading-tight m-0 py-0 px-1 md:px-2 ">
                        Verify On-chain liability and Escrow balances
                      </span>
                    </li>
                  </ul>
                </p>
                <div className="px-1  md:px-5 mx-3">
                <div className="row m-0 p-0">
                  <button
                    className="rounded-md bg-[#6cbaff] px-7 py-2.5 text-sm font-semibold text-white  hover:bg-[#4da9fa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                    onClick={() => {
                      navigate("/UserKYCForm");
                    }}
                  >
                    COMPLETE KYC
                  </button>
                </div>
              </div>
              </div>
              
              
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopUp2;

import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import Products from "../pages/Products/Products";
import Partnerships from "../pages/Partnerships/Partnerships";
import Login from "../pages/Login/Login.jsx";
import ContactUs from "../pages/ContactUs/ContactUs.jsx";
import Register from "../pages/Register/Register.jsx";
import CalculateRisk from "../pages/RiskSensor/CalculateRisk.jsx";
import RiskSensor from "../pages/RiskSensor/RiskSensor.jsx";
import PLRealStateBroker from "../pages/Partnerships/PLRealStateBroker.jsx";
import PLInsuranceAgent from "../pages/Partnerships/PLInsuranceAgent.jsx";
import PLFinancialInf from "../pages/Partnerships/PLFinancialInf.jsx";
import PLMutualFund from "../pages/Partnerships/PLMutualFund.jsx";
import PLStockBroker from "../pages/Partnerships/PLStockBroker.jsx";
import PLForeignPortfolio from "../pages/Partnerships/PLForeignPortfolio.jsx";
import WALROverview from "../pages/WALRBonds/WALROverview.jsx";
import WALRPerformance from "../pages/WALRBonds/WALRPerformance.jsx";
import RESIDEXOverview from "../pages/RESIDEXBonds/RESIDEXOverview.jsx";
import RESIDEXPerformance from "../pages/RESIDEXBonds/RESIDEXPerformance.jsx";
import TestPage from "../pages/PartnershipTest/TestPage.jsx";
import CustodianAC from "../pages/Custodian/Custodian.jsx";
import Ownership from "../pages/Ownership/Ownership.jsx";
import WALRHoldings from "../pages/Ownership/WALRHoldings.jsx";
import RESIDEXHoldings from "../pages/Ownership/RESIDEXHoldings.jsx";
import OurTeam from "./OurTeam/OurTeam.jsx";
import Pricing from "../pages/Pricing/Pricing.jsx";
import MoneyWallet from "../pages/MoneyWallet/MoneyWallet.jsx";
import PrimaryMarket from "../pages/PrimaryMarket/PrimaryMarket.jsx";
import TradingPortal from "../pages/TradingPortal/TradingPortal.jsx";
import UserKYCForm from "../pages/UserKYCForm/UserKYCForm.jsx";
import BlogsPage from "../pages/Blogs/Blogs.jsx";
import Blogs2 from "../pages/Blogs/Blogs2.jsx";
import LegalDocument from "../pages/LegalDocument/LegalDocument.jsx";
import Career from "../pages/LegalDocument/Career.jsx";
import KeyRatio from "../pages/CompanyInfo/KeyRatio.jsx";
import Shareholdings from "../pages/CompanyInfo/Shareholdings.jsx";
import AnnualReports from "../pages/CompanyInfo/AnnualReports.jsx";
import Coming from "../pages/Extras/Coming.jsx";
import Profile from "../pages/Profile/Profile.jsx";
import FinancialStatements from "../pages/CompanyInfo/FinancialStatements.jsx";
import ChangePassword from "../pages/ChangePassword/ChangePassword.jsx";
import ForgetPassword from "../pages/ChangePassword/ForgetPassword.jsx";
import PaymentPage from "../pages/MoneyWallet/PaymentPage.jsx";
import WithdrawPage from "../pages/MoneyWallet/WithdrawPage.jsx";
import StatementPage from "../pages/MoneyWallet/StatementPage.jsx";
import Page404 from "../pages/Extras/Page404.jsx";
import Page401 from "../pages/Extras/Page401.jsx";
import AdminDashboard from "../Admin/Admin.jsx";
import Error404 from "../pages/Extras/Error404.jsx";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/riskSensor" element={<RiskSensor />} />
        <Route path="/calculateRisk" element={<CalculateRisk />} />
        <Route path="/realStateBroker" element={<PLRealStateBroker />} />
        <Route path="/StockBroker" element={<PLStockBroker />} />
        <Route path="/MutualFund" element={<PLMutualFund />} />
        <Route path="/FinancialInf" element={<PLFinancialInf />} />
        <Route path="/InsuranceAgent" element={<PLInsuranceAgent />} />
        <Route path="/ForeignPortfolio" element={<PLForeignPortfolio />} />
        <Route path="/walrbonds/overview" element={<WALROverview />} />
        <Route path="/walrbonds/performance" element={<WALRPerformance />} />
        <Route path="/residexbonds/overview" element={<RESIDEXOverview />} />
        <Route
          path="/residexbonds/performance"
          element={<RESIDEXPerformance />}
        />
        <Route path="/legal_documents" element={<LegalDocument />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/OurTeam" element={<OurTeam />} />
        <Route path="/blogsPage/:title/" element={<BlogsPage />} />
        <Route path="/blogs" element={<Blogs2 />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/:title/taketest" element={<TestPage />} />
        <Route path="/CustodianAC" element={<CustodianAC />} />
        <Route path="/Ownership" element={<Ownership />} />
        <Route path="/WALR-holdings" element={<WALRHoldings />} />
        <Route path="/RESIDEX-holdings" element={<RESIDEXHoldings />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/MoneyWallet" element={<MoneyWallet />} />
        <Route path="/PrimaryMarket" element={<PrimaryMarket />} />
        <Route path="/TradingPortal" element={<Error404 />} />
        <Route path="/investment_advisory" element={<Error404 />} />
        <Route path="/fund_management" element={<Error404 />} />
        <Route path="/merchant_banking" element={<Error404 />} />
        <Route path="/about_us" element={<Error404 />} />
        <Route path="/UserKycForm" element={<UserKYCForm />} />
        <Route path="/paymentPage" element={<PaymentPage />} />
        <Route path="/withdrawPage" element={<WithdrawPage />} />
        <Route path="/statementPage" element={<StatementPage />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/keyRatios" element={<KeyRatio />} />
        <Route path="/shareholdings" element={<Shareholdings />} />
        <Route path="/annualReports" element={<AnnualReports />} />
        <Route path="/financialStatements" element={<FinancialStatements />} />
        <Route path="/accessDenied" element={<Page401 />} />
        <Route path="/userLost" element={<Page404 />} />
        <Route path="/comingsoon" element={<Coming />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Error404" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default AllRoutes;

const AnnualReportsapi = [
  {
    id: 0,
    startYear: "2019",
    endYear: "2020",
    a0: ["44,79,487.25", "32,57,174.33"],
    a1: ["39,88,539.29", "26,05,925.56"],
    B: ["4,90,947.96", "6,51,248.77"],
    b0: ["-", "-"],
    b1: ["-", "-"],
    C: ["4,90,947.96", "6,51,248.77"],
    c0: ["-26,583.00", "10,500.00"],
    c1: ["-39,257.00", "-18,273.32"],
    D: ["5,56,787.96", "6,59,022.09"],
    d0: ["-", "-"],
    E: ["5,56,787.96", "6,59,022.09"],
    F: ["-", "-"],
    f0: ["2.23", "4.39"],
    f1: ["2.23", "4.39"],
    link: "./PDFs/Directors Reports/Directors Report FY2019-20.pdf",
  },
  {
    id: 1,
    startYear: "2020",
    endYear: "2021",
    a0: ["26,22,606.08", "44,79,487.25"],
    a1: ["43,16,454.00", "39,88,539.29"],
    B: ["-16,93,847.92", "4,90,947.96"],
    b0: ["-", "-"],
    b1: ["-", "-"],
    C: ["-16,93,847.92", "4,90,947.96"],
    c0: ["-", "-26,583.00"],
    c1: ["-19,967.00", "-39,257.00"],
    D: ["-16,73,880.92", "5,56,787.96"],
    d0: ["-", "1,10,888.00"],
    E: ["-16,73,880.92", "5,56,787.96"],
    F: ["-", "-"],
    f0: ["-6.70", "2.23"],
    f1: ["-6.70", "2.23"],
    link: "./PDFs/Directors Reports/Directors Report FY2020-21.pdf",
  },
  {
    id: 2,
    startYear: "2021",
    endYear: "2022",
    a0: ["26,60,735", "26,22,606"],
    a1: ["47,46,609", "43,16,454"],
    B: ["-20,85,874", "-16,93,848"],
    b0: ["-", "-"],
    b1: ["-", "-"],
    C: ["-20,85,874", "-16,93,848"],
    c0: ["-", "-"],
    c1: ["-5,790", "-19,967"],
    D: ["-20,80,084", "-16,73,881"],
    d0: ["-", "-"],
    E: ["-20,80,084", "-16,73,881"],
    F: ["-", "-"],
    f0: ["-5.94", "-4.78"],
    f1: ["-5.94", "-4.78"],
    link: "./PDFs/Directors Reports/Directors Report FY2021-22.pdf",
  },
  {
    id: 3,
    startYear: "2022",
    endYear: "2023",
    a0: ["64,03,572", "26,60,735"],
    a1: ["54,21,057", "47,46,609"],
    B: ["9,87,429", "-20,85,874"],
    b0: ["-", "-"],
    b1: ["-", "-"],
    C: ["9,87,429", "-20,85,874"],
    c0: ["-", "-"],
    c1: ["-901", "-5,790"],
    D: ["-", "-20,80,084"],
    d0: ["-", "-"],
    E: ["9,88,333", "-20,80,084"],
    F: ["-", "-"],
    f0: ["2.82", "-5.94"],
    f1: ["2.82", "-5.94"],
    link: "./PDFs/Directors Reports/Directors Report FY2022-23.pdf",
  },
  {
    id: 4,
    startYear: "2023",
    endYear: "2024",
    a0: ["43,21,894", "64,33,988"],
    a1: ["48,89,951", "54,46,557"],
    B: ["-56,80,57", "9,87,431"],
    b0: ["-", "-"],
    b1: ["-", "-"],
    C: ["-5,68,057", "-9,87,431"],
    c0: ["-", "-"],
    c1: ["-20,886", "-901"],
    D: ["-5,47,171", "-9,88,332"],
    d0: ["-", "-"],
    E: ["-5,47,171", "9,88,332"],
    F: ["-", "-"],
    f0: ["-0.37", "2.82"],
    f1: ["-0.10", "0.18"],
    link: "./PDFs/Directors Reports/Directors Report FY2023-24.pdf",
  },
 
];
export default AnnualReportsapi;

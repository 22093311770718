import React, { useState, useEffect, useRef } from "react";
import { BsArrowBarRight } from "react-icons/bs";
import Chart from "react-apexcharts";

const CollateralChart = () => {
  const [tableContent, setTableContent] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    // Scroll to the extreme right when the component mounts
    if (chartRef.current) {
      chartRef.current.scrollLeft = chartRef.current.scrollWidth;
    }
  }, []);

  useEffect(() => {
    // Scroll to the extreme right when the component mounts
    if (chartRef.current) {
      chartRef.current.scrollLeft = chartRef.current.scrollWidth;
    }
  }, []);

  const handleClick = (event, chartContext, config) => {
    if (config.dataPointIndex !== -1) {
      const clickedLabel = options.labels[config.dataPointIndex];

      let content = null;

      switch (clickedLabel) {
        case "Nov 16":
          content = (
            <div className="w-full md:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 md:flex justify-between transition duration-300 ease-in-out">
              <div className="w-full md:w-2/3">
                <h5 className="text-lg font-bold">Case: Demonetization</h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="card-fontsize1">Gold Rises 4%</li>
                </p>
                <a
                  href="walrbonds/performance/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover md:w-1/3 h-full rounded-xl">
                <img className="rounded-lg" src="/images/demn.png" alt="" />
              </div>
            </div>
          );
          break;

        case "Oct 18":
          content = (
            <div className=" w-full lg:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 flex justify-between transition duration-300 ease-in-out">
              <div className="w-2/3">
                <h5 className="text-lg font-bold ">Case: ILFS Crisis</h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="font-medium">Gold Rises 4%</li>
                </p>

                <a
                  href="https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                  target=""
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover w-1/3 h-full rounded-xl">
                <img className="rounded-lg" src="/images/ilfs.png" alt="" />
              </div>
            </div>
          );
          break;

        case "Feb 22":
          content = (
            <div className=" w-full lg:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 flex justify-between transition duration-300 ease-in-out">
              <div className="w-2/3">
                <h5 className="text-lg font-bold ">Case: Russia-Ukrain WAR</h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="font-medium">Gold Rises 4%</li>
                </p>

                <a
                  href="https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                  target=""
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover w-1/3 h-full rounded-xl">
                <img className="rounded-lg" src="/images/rus-ukr.jpeg" alt="" />
              </div>
            </div>
          );
          break;

        case "Mar 20":
          content = (
            <div className=" w-full lg:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 flex justify-between transition duration-300 ease-in-out">
              <div className="w-2/3">
                <h5 className="text-lg font-bold ">Case: Covid-19</h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="font-medium">Gold Rises 4%</li>
                </p>

                <a
                  href="https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                  target=""
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover w-1/3 h-full rounded-xl">
                <img className="rounded-lg" src="/images/covid.png" alt="" />
              </div>
            </div>
          );
          break;

        case "May 22":
          content = (
            <div className=" w-full lg:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 flex justify-between transition duration-300 ease-in-out">
              <div className="w-2/3">
                <h5 className="text-lg font-bold ">
                  Case: US Inflationary 9 Care
                </h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="font-medium">Gold Rises 4%</li>
                </p>

                <a
                  href="https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                  target=""
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover w-1/3 h-full rounded-xl">
                <img className="rounded-lg" src="/images/us-9.jpg" alt="" />
              </div>
            </div>
          );
          break;

        case "Aug 23":
          content = (
            <div className=" w-full lg:w-1/2 h-max bg-[#dbedf9] rounded-xl p-4 flex justify-between transition duration-300 ease-in-out">
              <div className="w-2/3">
                <h5 className="text-lg font-bold ">
                  Case: Israel-Palestine War
                </h5>
                <p className="text-sm pl-2">
                  <li className="font-medium">Oil crosses 100$</li>
                  <li className="font-medium">Food prices jumped 20-30%</li>
                  <li className="font-medium">Rubel crashes by 50%</li>
                  <li className="font-medium">Gold Rises 4%</li>
                </p>

                <a
                  href="https://www.bloomberg.com/graphics/covid-vaccine-tracker-global-distribution/"
                  className="mt-2 font-semibold text-[#5ab2ff] hover:text-[#3aa0ff] flex items-center"
                  target=""
                >
                  See the news &nbsp;
                  <BsArrowBarRight size={15} />
                </a>
              </div>
              <div className="object-cover w-1/3 h-full rounded-xl">
                <img
                  className="rounded-lg"
                  src="/images/Isr-pal-war.png"
                  alt=""
                />
              </div>
            </div>
          );
          break;

        default:
          content = null;
          break;
      }

      setTableContent(content);
    }
  };

  const series = [
    {
      name: "WALR",
      data: [
        100, 109, 108, 107, 112, 110, 109, 108, 107, 112, 110, 105, 109, 108,
        107, 112, 110, 109, 108, 107, 112, 110, 100, 109, 108, 107, 112, 110,
        109, 108, 107, 112, 110, 105, 109, 108, 107, 112, 110, 109, 108, 107,
        112, 110, 100, 109, 108, 107, 112, 110, 109, 108, 100, 109, 108, 107,
        112, 110, 109, 108, 107, 112, 110, 105, 109, 108, 107, 112, 110, 109,
        108, 107, 112, 110, 100, 109, 108, 107, 112, 110, 109, 108, 107, 112,
        110,
      ],
    },
    {
      name: "RESIDEX",
      data: [
        100, 115, 114, 122, 119, 111, 109, 104, 105, 119, 115, 120, 123, 114,
        112, 108, 107, 112, 115, 120, 121, 122, 100, 115, 114, 122, 119, 111,
        109, 104, 105, 119, 115, 120, 123, 114, 112, 108, 107, 112, 115, 120,
        121, 122, 100, 115, 114, 122, 119, 111, 109, 104, 105, 119, 115, 120,
        123, 114, 112, 108, 107, 112, 115, 120, 121, 122, 100, 115, 114, 122,
        119, 111, 109, 104, 105, 119, 115, 120, 123, 114, 112, 108, 107, 112,
        115, 120, 121, 122, 100, 115, 114, 122, 119, 111, 109, 104, 105, 119,
      ],
    },
  ];

  const options = {
    legend: {
      position: "top",
    },
    colors: ["#0590F8", "#009688"],
    chart: {
      type: "line",
      id: "areachart-2",
      events: {
        mouseMove: (event, chartContext, config) => {
          handleClick(event, chartContext, config);
        },
      },
      animations: {
        enabled: false,
      },
    },

    responsive: [
      {
        breakpoint: 2300,
        options: {
          chart: {
            width: 900,
            height: 500,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            width: 900,
            height: 450,
          },
        },
      },
      {
        breakpoint: 976,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 900,
            height: 350,
          },
        },
      },
    ],
    // it will modify the y-axis according to need
    yaxis: {
      min: 80,
      max: 160,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    //
    annotations: {
      yaxis: [
        {
          y: 100,
          borderColor: "red",
          label: {
            borderColor: "red",
          },
        },
      ],
      xaxis: [
        {
          x: "Nov 16",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "Demonetization",
          },
          tooltip: {
            enabled: true,
            shared: false,
          },
        },
        {
          x: "Oct 18",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "ILFS Crisis",
          },
          tooltip: {
            enabled: true,
            shared: false,
          },
        },
        {
          x: "Mar 20",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "Covid-19",
          },
        },
        {
          x: "Feb 22",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "Russia-ukraine war",
          },
          tooltip: {
            enabled: true,
            shared: false,
          },
        },
        {
          x: "May 22",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "US Inflationary 9 care",
          },
          tooltip: {
            enabled: true,
            shared: false,
          },
        },
        {
          x: "Aug 23",
          borderColor: "orange",
          label: {
            borderColor: "orange",
            text: "Israel-Palestine war",
          },
          tooltip: {
            enabled: true,
            shared: false,
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    labels: [
      "Apr 16",
      "May 16",
      "Jun 16",
      "Jul 16",
      "Aug 16",
      "Sep 16",
      "Oct 16",
      "Nov 16",
      "Dec 16",
      "Jan 17",
      "Feb 17",
      "Mar 17",
      "Apr 17",
      "May 17",
      "Jun 17",
      "Jul 17",
      "Aug 17",
      "Sep 17",
      "Oct 17",
      "Nov 17",
      "Dec 17",
      "Jan 18",
      "Feb 18",
      "Mar 18",
      "Apr 18",
      "May 18",
      "Jun 18",
      "Jul 18",
      "Aug 18",
      "Sep 18",
      "Oct 18",
      "Nov 18",
      "Dec 18",
      "Jan 19",
      "Feb 19",
      "Mar 19",
      "Apr 19",
      "May 19",
      "Jun 19",
      "Jul 19",
      "Aug 19",
      "Sep 19",
      "Oct 19",
      "Nov 19",
      "Dec 19",
      "Jan 20",
      "Feb 20",
      "Mar 20",
      "Apr 20",
      "May 20",
      "Jun 20",
      "Jul 20",
      "Aug 20",
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 21",
      "Jun 21",
      "Jul 21",
      "Aug 21",
      "Sep 21",
      "Oct 21",
      "Nov 21",
      "Dec 21",
      "Jan 22",
      "Feb 22",
      "Mar 22",
      "Apr 22",
      "May 22",
      "Jun 22",
      "Jul 22",
      "Aug 22",
      "Sep 22",
      "Oct 22",
      "Nov 22",
      "Dec 22",
      "Jan 23",
      "Feb 23",
      "Mar 23",
      "Apr 23",
      "May 23",
      "Jun 23",
      "Jul 23",
      "Aug 23",
      "Sep 23",
      "Oct 23",
      "Nov 23",
      "Dec 23",
      "Jan 24",
      "Feb 24",
      "Mar 24",
      "Apr 24",
      "May 24",
      "Jun 24",
    ],
    xaxis: {
      tickAmount: 12,
      type: "date",
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
  };

  return (
    <div className="m-0 p-2 w-full lg:min-w-[700px] xl:max-w-[970px] border-2 rounded-xl bg-[#f5fbff] overflow-hidden">
      <div className="flex flex-col w-full px-2">
        <div id="chart" className="p-2 w-full  overflow-x-scroll" ref={chartRef}>
          <div className="flex justify-center">
            <span className="text-danger">*</span>
            <span className="font-size1">
              Hover on the Graph to see the details.
            </span>
          </div>
          <Chart
            className="w-max h-full"
            options={options}
            series={series}
            type="line"
            width="1000"
            height="400"
          />
        </div>

        {/* <hr className="m-0 p-0" /> */}
        <div className="mt-2 w-full flex-col items-center justify-center text-sm font-semibold bg-[#f5fbff]">
          <div className="w-full flex justify-center items-center transition duration-300 ease-in-out overflow-x-scroll">
            {tableContent && <>{tableContent}</>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollateralChart;

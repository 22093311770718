import React, { useState } from "react";
import "./CompanyInfo.css";
import { PiChatCircleDotsDuotone } from "react-icons/pi";

const PopUpContent = ({ content }) => {
  const [hoveredDropdown, setHoveredDropdown] = useState();
  const handleHover = () => {
    setHoveredDropdown(true);
  };

  const handleLeave = () => {
    setHoveredDropdown(false);
  };
  return (
    <>
      <PiChatCircleDotsDuotone
        className="text-primary"
        size={20}
        onMouseEnter={() => handleHover()}
        onMouseLeave={() => handleLeave()}
      />
      {hoveredDropdown && (
        <div className="bg-primary pop1 absolute">
          <p className="m-1 text-light font-size1 para-style2">{content}</p>
        </div>
      )}
    </>
  );
};

export default PopUpContent;

import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const UserData = ({ isAuthenticated, email, phone, pan, formName }) => {
  const [loader, setLoader] = useState(true);
  const [statusA, setStatusA] = useState(0);
  const [statusB, setStatusB] = useState(0);
  const [statusC, setStatusC] = useState(0);
  const [statusD, setStatusD] = useState(0);
  const [statusE, setStatusE] = useState(0);
  const [statusF, setStatusF] = useState(0);
  const [statusG, setStatusG] = useState(0);
  const [statusH, setStatusH] = useState(0);
  const [statusI, setStatusI] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const storedData = secureLocalStorage.getItem("kycData");
  const storedData1 = secureLocalStorage.getItem("blockchainData");
  const storedData2 = secureLocalStorage.getItem("ownershipData");
  const storedData3 = secureLocalStorage.getItem("tempData");
  const storedData4 = secureLocalStorage.getItem("couponData");
  const userKycData = JSON.parse(storedData);

  const decodeImageData = (imageData) => {
    try {
      if (imageData !== undefined) {
        const decodedData = atob(imageData);
        return decodedData;
      }
    } catch (error) {
      // console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/get-user-kyc-data/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: formName,
          email,
          phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (formName === "") {
            const setStatus = (letter, data) => {
              return data.data &&
                data.data["KYC_status"] &&
                data.data["KYC_status"][letter]
                ? data.data["KYC_status"][letter]
                : 0;
            };

            setStatusA(setStatus("A", data));
            setStatusB(setStatus("B", data));
            setStatusC(setStatus("C", data));
            setStatusD(setStatus("D", data));
            setStatusE(setStatus("E", data));
            setStatusF(setStatus("F", data));
            setStatusG(setStatus("G", data));
            setStatusH(setStatus("H", data));
            setStatusI(setStatus("I", data));
            if (
              storedData === null &&
              isAuthenticated &&
              data.message === "success"
            ) {
              secureLocalStorage.setItem("kycData", JSON.stringify(data.data));
              fetch(`https://api-scdndestates.com/api/completed-order/`, {
                method: "POST",
                body: JSON.stringify({
                  pan,
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Failed to fetch data");
                  }
                  return response.json();
                })
                .then((data) => {
                  if (
                    storedData1 === null ||
                    storedData2 === null ||
                    storedData3 === null ||
                    storedData4 === null
                  ) {
                    secureLocalStorage.setItem(
                      "blockchainData",
                      JSON.stringify(data.data)
                    );
                    secureLocalStorage.setItem(
                      "ownershipData",
                      JSON.stringify(data.data2)
                    );
                    secureLocalStorage.setItem(
                      "tempData",
                      JSON.stringify(data.data3)
                    );
                    secureLocalStorage.setItem(
                      "couponData",
                      JSON.stringify(data.data4)
                    );
                  }
                })
                .catch((error) => {
                  // console.error("Error: ", error);
                });
            }
            const decodedImage = decodeImageData(data.data.profile_pic_content);
            if (decodedImage) {
              const byteCharacters = decodedImage;
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: "image/jpeg" });
              setSelectedImage(
                new File([blob], "profilePic.jpg", { type: "image/jpeg" })
              );
            }
          }
          setLoader(false);
        })
        .catch((error) => {});
    } else {
      setLoader(false);
    }
  }, [isAuthenticated, email, phone, formName, loader]);
  return {
    loader,
    selectedImage,
    userKycData,
    statusA,
    statusB,
    statusC,
    statusD,
    statusE,
    statusF,
    statusG,
    statusH,
    statusI,
  };
};

export default UserData;

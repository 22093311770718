import React, { useState, useEffect, useMemo } from "react";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
import { useParams } from "react-router-dom";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header.jsx";
import ShareButtons from "./Share.jsx";
import secureLocalStorage from "react-secure-storage";
import ReactMarkdown from "react-markdown";
import PopUp1 from "../../components/PopUp1/PopUp1.jsx";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { FaPenFancy } from "react-icons/fa";

function Blogs() {
  const { email, phone, isAuthenticated } = CheckAuth();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [blogComments, setBlogComments] = useState([]);
  const [comment, setComment] = useState("");
  const { title } = useParams();
  const [sortOrder, setSortOrder] = useState("newest");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const fullName =
    userKycData &&
    userKycData["fullName"] !== undefined &&
    userKycData["fullName"] !== null &&
    userKycData["fullName"];
  const navigate = useNavigate();

  // decode Image
  const decodeImageData = (imageData) => {
    try {
      if (imageData !== undefined) {
        const decodedData = atob(imageData);
        return decodedData;
      }
    } catch (error) {
      // console.error("Error: ", error);
    }
  };

  function getDecodedImage(val) {
    if (!val) return "default-image-url";
    const decodedImage = decodeImageData(val);

    if (!decodedImage) return "default-image-url";
    if (decodedImage) {
      const byteCharacters = decodedImage;
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      return URL.createObjectURL(
        new File([blob], "profilePic.jpg", { type: "image/jpeg" })
      );
    }
  }

  // get all blogs data
  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-all-blogs-data/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setBlogPost(data.data);
        const selectedBlogIndex = data.data.findIndex(
          (blog) => blog.title === title
        );
        if (selectedBlogIndex !== -1) {
          userData(title);
          setLoader(false);
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [title]);

  const serviceapi = useMemo(() => {
    return blogPost?.map((blog2, index) => ({
      id: 0 + index,
      key: blog2?.keyword,
      title: blog2?.title,
      image: blog2?.image_url,
      date: blog2?.Timestamp,
      author: blog2?.author,
      content: blog2?.content,
    }));
  }, [blogPost]);

  const filteredBlogs = serviceapi.find(
    (blog) => blog.title.toLowerCase() === title.toLowerCase()
  );

  // pagination
  const size = serviceapi.length;
  const maxPagesToShow = 4;

  useEffect(() => {
    generatePages();
  }, [page, size]);

  // generate page numbers
  const generatePages = () => {
    let tempPages = [];
    const half = Math.floor(maxPagesToShow / 2);
    if (size <= maxPagesToShow) {
      for (let i = 1; i <= size; i++) {
        tempPages.push(i);
      }
    } else if (page < half + 1) {
      for (let i = 1; i <= maxPagesToShow - 2; i++) {
        tempPages.push(i);
      }
      tempPages.push("...", size);
    } else if (page > size - half) {
      tempPages.push(1, 2, "...");
      for (let i = size - (maxPagesToShow - 3); i <= size; i++) {
        tempPages.push(i);
      }
    } else {
      for (let i = page - half + 1; i <= page; i++) {
        tempPages.push(i);
      }
      tempPages.push("...", size - 1, size);
    }

    setPages(tempPages);
  };

  // pagenumber btn
  const handlePageClick = (pageNumber) => {
    if (pageNumber !== "...") {
      setPage(pageNumber);
      navigate(`/blogsPage/${encodeURIComponent(serviceapi[pageNumber - 1].title)}/`);
      window.scrollTo(0, 0);
    }
  };

  // prev button
  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
      navigate(`/blogsPage/${encodeURIComponent(serviceapi[page - 2].title)}/`);
      navigate(`/blogsPage/${encodeURIComponent(serviceapi[page - 2].title)}/`);
    }
  };

  // next button
  const handleNextClick = () => {
    if (page < size) {
      setPage(page + 1);
      navigate(`/blogsPage/${encodeURIComponent(serviceapi[page].title)}/`);
      navigate(`/blogsPage/${encodeURIComponent(serviceapi[page].title)}/`);
    }
  };

  // get blog comments
  const userData = (title) => {
    fetch(`https://api-scdndestates.com/api/get-blog-comments/`, {
      method: "POST",
      body: JSON.stringify({
        blog_name: title,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        const commentsArray = Array.isArray(data.data) ? data.data : [];
        setBlogComments(commentsArray);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  // submit blog comments
  const submitBlog = (value) => {
    if (isAuthenticated) {
      if (!comment.trim()) {
        alert("Comment cannot be empty");
        return;
      } else {
        setComment("");
        setBlogComments((prevComments) => [
          ...prevComments,
          {
            fullName,
            comment,
            Timestamp: new Date().toISOString(),
          },
        ]);
        fetch(`https://api-scdndestates.com/api/blog-comments/`, {
          method: "POST",
          body: JSON.stringify({
            activity: value,
            email,
            phone,
            fullName,
            comment,
            blog_name: title,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            alert(data.message);
          })
          .catch((error) => {
            // console.error("Error: ", error);
          });
      }
    } else {
      setShow(true);
    }
  };

  // convert timestamp
  const convertTimestampToDuration = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };

  // sort comments
  const handleSort = (order) => {
    setSortOrder(order);
    const sortedComments = [...blogComments];
    sortedComments.sort((a, b) => {
      const timestampA = new Date(a.Timestamp);
      const timestampB = new Date(b.Timestamp);
      if (order === "newest") {
        return timestampB - timestampA;
      } else if (order === "oldest") {
        return timestampA - timestampB;
      }
      return 0;
    });
    setBlogComments(sortedComments);
  };

  const url = window.location.href;
  const processedContent = filteredBlogs
    ? filteredBlogs.content.replace(/\\n/g, "\n")
    : "...";

  return (
    <div>
      <Header />
      {loader ? (
        <Loader2 />
      ) : (
        <>
          {show && <PopUp1 />}
          <div className="top-login-comment after-header ">
            <div className=" relative  isolate w-full flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5  sm:px-3.5 sm:before:flex-1 ">
              <div
                className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true"
              >
                <div
                  className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#aad4fc] to-[#65aaf8] opacity-30"
                  style={{
                    clipPath:
                      "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                  }}
                />
              </div>
              <div
                className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true"
              >
                <div
                  className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#aad4fc] to-[#2f89f0] opacity-30"
                  style={{
                    clipPath:
                      "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                  }}
                />
              </div>
              <div className="flex flex-wrap items-center gap-x-4 gap-y-2 justify-center w-full">
                <p className="text-xs md:text-sm text-gray-900 text-center">
                  <strong className="font-bold">SCDND Estates - </strong>
                  Evaluate your portfolio risk through our Risk Sensor Portal
                </p>
                <a
                  href="/riskSensor"
                  className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-[8px] md:text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  Evauate now <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>

          <div className="max-w-[1800px] mx-auto relative isolate  bg-white py-24 sm:py-32  pt-5 pb-0">
            <div className="w-[90%] mx-auto flex justify-between">
              {/* left */}
              <div className="w-full lg:w-[60%] ">
                <div className="lg:pr-4">
                  <div className="lg:max-w-9xl">
                    {filteredBlogs
                      ? filteredBlogs.key?.map((item) => (
                          <span
                            key={item}
                            className="me-2 cursor-pointer inline-flex items-center rounded-md  px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                          >
                            {item}
                          </span>
                        ))
                      : "..."}
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900  xl:text-5xl pb-5">
                      {title}
                    </h1>
                    <div className="blog-image block lg:hidden">
                      <img
                        className="w-full px-5 pb-3 rounded-lg object-cover object-center "
                        src={
                          filteredBlogs
                            ? getDecodedImage(filteredBlogs.image)
                            : "Loading..."
                        }
                        alt=""
                      />
                    </div>
                    <ReactMarkdown>
                      {processedContent ? processedContent : "..."}
                    </ReactMarkdown>
                    <div className="pt-5 ">
                      <div className="flex flex-wrap items-center gap-2  justify-between">
                        <span className="flex items-center gap-1 justify-starts bg-black border text-xs md:text-sm rounded-xl p-2">
                          <FaPenFancy />
                          {filteredBlogs ? filteredBlogs.author : "Loading..."}
                        </span>
                        <ShareButtons url={url} />
                      </div>
                    </div>

                    <hr className="my-5" />
                  </div>
                  <div className="flex items-center justify-between  bg-white py-3 ">
                    <div className="block sm:flex sm:flex-1 sm:items-center sm:justify-between ">
                      <div>
                        <p className="text-sm text-gray-700 mr-5">
                          Showing{" "}
                          <span className="font-medium">
                            {filteredBlogs?.id + 1}
                          </span>{" "}
                          of{" "}
                          <span className="font-medium">
                            {serviceapi ? serviceapi.length : 0}
                          </span>
                        </p>
                      </div>
                      <div>
                        <nav
                          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                          aria-label="Pagination"
                          id="pagination"
                        >
                          <button
                            onClick={handlePrevClick}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={page === 1}
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                          {pages?.map((p, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                handlePageClick(p);
                              }}
                              className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                p === page
                                  ? "text-white bg-[#9ed7fd]"
                                  : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              } focus:z-20 focus:outline-offset-0`}
                            >
                              {p}
                            </button>
                          ))}
                          <button
                            onClick={handleNextClick}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={page === size}
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex-column justify-center items-center w-[100%] overflow-hidden lg:hidden">
                    <div className="row m-0 p-0 items-center flex justify-center">
                      <p className="text-md  lg:text-xl font-bold mb-4 ml-6 ">
                        Post a Comment:
                      </p>

                      <div className="col-12 flex-column justify-content-start align-items-start">
                        <div className="relative">
                          <div className="comment-container-box flex">
                            <div className="ml-2 p-2">
                              <span className="comment-logo">
                                {fullName
                                  ? fullName.charAt(0).toUpperCase()
                                  : "U"}
                              </span>
                            </div>
                            <div className="comment-box w-full">
                              <textarea
                                id="story"
                                name="story"
                                className="ml-3 p-2 rounded-sm  border-2 border-transparent border-solid border-y-slate-600 outline-none text-md w-11/12  resize-none"
                                rows="5"
                                value={comment}
                                placeholder="Add your comment ..."
                                onChange={(e) => setComment(e.target.value)}
                              ></textarea>
                              <div className="ml-3 flex w-full justify-between py-1.5 ">
                                <div className="flex gap-2">
                                  <button
                                    type="button"
                                    className="py-2 px-4  bg-[#9ed7fd] hover:bg-[#42aaff] focus:bg-[#42aaff] text-white w-full transition ease-in-out duration-200 text-center text-base font-semibold shadow-md   rounded-lg "
                                    onClick={() => submitBlog("submitBlog")}
                                  >
                                    Post
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-4 mb-0" />
                    </div>

                    <div className="row mx-2 my-4 bg-[#eaf4fa] relative rounded-xl overflow-x-scroll max-h-[750px]">
                      <div className="comment-filter pt-2 rounded-3xl">
                        <h5 className="w-full flex justify-between items-center  m-0 p-2 top-0 align-middle text-start text-dark fw-bold mb-2 bg-[#cae2f1] rounded-xl">
                          {blogComments.length}&nbsp;Comments &nbsp;
                          <span className="ms-2">
                            <div className="btn-group dropdown shadow-0 float-right">
                              <Menu
                                as="div"
                                className="relative inline-block text-left float-right"
                              >
                                <div>
                                  <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    Filter
                                    <ChevronDownIcon
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </MenuButton>
                                </div>

                                <MenuItems
                                  transition
                                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                  <div className="py-1">
                                    <MenuItem>
                                      {({ focus }) => (
                                        <p
                                          className={`${
                                            focus
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } block px-4 py-2 text-sm`}
                                          onClick={() => handleSort("newest")}
                                        >
                                          Newest
                                        </p>
                                      )}
                                    </MenuItem>
                                    <MenuItem>
                                      {({ focus }) => (
                                        <p
                                          className={`${
                                            focus
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } block px-4 py-2 text-sm`}
                                          onClick={() => handleSort("oldest")}
                                        >
                                          Oldest
                                        </p>
                                      )}
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </Menu>
                            </div>
                          </span>
                        </h5>
                      </div>
                      {blogComments.length !== 0 ? (
                        blogComments?.map((blog) => (
                          <>
                            <div className="comment-history flex overflow-auto">
                              <div className="m-0 p-2">
                                <span className="comment-logo ">
                                  {blog.fullName
                                    ? blog.fullName.charAt(0).toUpperCase()
                                    : ""}
                                </span>
                              </div>
                              <div className="m-0 p-2 comment-font-size1">
                                <div className="">
                                  <span className="fw-bold me-3">
                                    {blog.fullName}
                                  </span>
                                  <span className="text-grey font-size2">
                                    {convertTimestampToDuration(blog.Timestamp)}
                                  </span>
                                </div>
                                <div className="para-style2">
                                  {blog.comment}
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <>
                          <div className="col-12 mb-5 d-flex justify-content-center align-items-center">
                            No Comments
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* right */}
              <div className="w-full lg:w-[38%]  max-lg:hidden">
                <div className="blog-image block ">
                  <img
                    className="w-[100%] rounded-lg object-cover object-center "
                    src={
                      filteredBlogs
                        ? getDecodedImage(filteredBlogs.image)
                        : "Loading..."
                    }
                    alt=""
                  />
                </div>

                <div className="mt-5 flex-column justify-center items-center w-[100%] overflow-hidden max-lg:hidden">
                  <div className="row m-0 p-0 items-center flex justify-center">
                    <p className="text-md  lg:text-xl font-bold mb-4 ml-6 ">
                      Post a Comment:
                    </p>

                    <div className="col-12 flex-column justify-content-start align-items-start">
                      <div className="relative">
                        <div className="comment-container-box flex">
                          <div className="ml-2 p-2">
                            <span className="comment-logo">
                              {fullName
                                ? fullName.charAt(0).toUpperCase()
                                : "U"}
                            </span>
                          </div>
                          <div className="comment-box w-full">
                            <textarea
                              id="story"
                              name="story"
                              className="ml-3 p-2 rounded-sm  border-2 border-transparent border-solid border-y-slate-600 outline-none text-md w-11/12  resize-none"
                              rows="5"
                              value={comment}
                              placeholder="Add your comment ..."
                              onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                            <div className="ml-3 flex w-full justify-between py-1.5 ">
                              <div className="flex gap-2">
                                <button
                                  type="button"
                                  className="py-2 px-4  bg-[#9ed7fd] hover:bg-[#42aaff] focus:bg-[#42aaff] text-white w-full transition ease-in-out duration-200 text-center text-base font-semibold shadow-md   rounded-lg "
                                  onClick={() => submitBlog("submitBlog")}
                                >
                                  Post
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-4 mb-0" />
                  </div>

                  <div className="row mx-2 my-4 bg-[#eaf4fa] relative rounded-xl overflow-x-scroll max-h-[750px]">
                    <div className="comment-filter pt-2 rounded-3xl">
                      <h5 className="w-full flex justify-between items-center  m-0 p-2 top-0 align-middle text-start text-dark fw-bold mb-2 bg-[#cae2f1] rounded-xl">
                        {blogComments.length}&nbsp;Comments &nbsp;
                        <span className="ms-2">
                          <div className="btn-group dropdown shadow-0 float-right">
                            <Menu
                              as="div"
                              className="relative inline-block text-left float-right"
                            >
                              <div>
                                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                  Filter
                                  <ChevronDownIcon
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </MenuButton>
                              </div>

                              <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                              >
                                <div className="py-1">
                                  <MenuItem>
                                    {({ focus }) => (
                                      <p
                                        className={`${
                                          focus
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700"
                                        } block px-4 py-2 text-sm`}
                                        onClick={() => handleSort("newest")}
                                      >
                                        Newest
                                      </p>
                                    )}
                                  </MenuItem>
                                  <MenuItem>
                                    {({ focus }) => (
                                      <p
                                        className={`${
                                          focus
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700"
                                        } block px-4 py-2 text-sm`}
                                        onClick={() => handleSort("oldest")}
                                      >
                                        Oldest
                                      </p>
                                    )}
                                  </MenuItem>
                                </div>
                              </MenuItems>
                            </Menu>
                          </div>
                        </span>
                      </h5>
                    </div>
                    {blogComments.length !== 0 ? (
                      blogComments?.map((blog) => (
                        <>
                          <div className="comment-history flex overflow-auto">
                            <div className="m-0 p-2">
                              <span className="comment-logo ">
                                {blog.fullName
                                  ? blog.fullName.charAt(0).toUpperCase()
                                  : ""}
                              </span>
                            </div>
                            <div className="m-0 p-2 comment-font-size1">
                              <div className="">
                                <span className="fw-bold me-3">
                                  {blog.fullName}
                                </span>
                                <span className="text-grey font-size2">
                                  {convertTimestampToDuration(blog.Timestamp)}
                                </span>
                              </div>
                              <div className="para-style2">{blog.comment}</div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="col-12 mb-5 d-flex justify-content-center align-items-center">
                          No Comments
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Blogs;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PopUp1 from "../../components/PopUp1/PopUp1";
import Header from "../../components/Header/Header";
import PartnershipQuiz from "./PartnershipQuiz";
import { ImCross } from "react-icons/im";
import Trello from "./Trello";
import TrelloRes from "./TrelloRes";
import { BsArrowBarRight } from "react-icons/bs";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";
import { EmailIcon } from "react-share";

function TestPage() {
  const { isAuthenticated, email, phone } = CheckAuth();
  const [pincode, setPincode] = useState("");
  const [locality, setLocality] = useState([]);
  const { title } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const isFirstQuestion = currentQuestion === 0;
  const [userAnswers, setUserAnswers] = useState({
    title: title,
    ans1: "",
    ans2: [{ pincode: "", state: "", district: "", locality: "" }],
    ans3: "",
    ans4: [],
    ans5: [],
    ans6: [],
    ans7: {
      educational_background: "",
      time_period: "",
      company_website: "",
      social_media: "",
      followers: "",
    },
  });

  const fetchPincodeData = (index, value) => {
    setPincode(value);
    if (/^\d{6}$/.test(value)) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          handleAddressChange(index, "pincode", value);
          handleAddressChange(
            index,
            "state",
            PostOffice[0]?.PostOffice[0]?.State || ""
          );
          handleAddressChange(
            index,
            "district",
            PostOffice[0]?.PostOffice[0]?.District || ""
          );
          const area = PostOffice[0]?.PostOffice.map((post) => post.Name) || [];
          setLocality(area);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleNextQuestion = () => {
    const isAnswered = (questionIndex) => {
      switch (questionIndex) {
        case 0:
          return userAnswers.ans1.trim() !== "";
        case 1:
          return (
            userAnswers.ans2[0].pincode.trim() !== "" &&
            userAnswers.ans2[0].state.trim() !== "" &&
            userAnswers.ans2[0].district.trim() !== ""
          );
        case 2:
          return userAnswers.ans3.trim() !== "";
        case 3:
          return userAnswers.ans4.length > 0;
        case 4:
          return userAnswers.ans5.length > 0;
        case 5:
          return userAnswers.ans6.length > 0;
        case 6:
          return (
            userAnswers.ans7.educational_background.trim() !== "" &&
            userAnswers.ans7.time_period.trim() !== "" &&
            userAnswers.ans7.company_website.trim() !== "" &&
            userAnswers.ans7.social_media.trim() !== "" &&
            userAnswers.ans7.followers.trim() !== ""
          );
        default:
          return true;
      }
    };

    if (isAnswered(currentQuestion)) {
      // Only move to the next question if the current question is answered
      if (currentQuestion < 6) {
        setCurrentQuestion(currentQuestion + 1);
      }
    } else {
      alert("Please answer the current question before proceeding.");
    }
  };

  const addAnotherEntry = () => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      ans2: [
        ...prevAnswers.ans2,
        { pincode: "", state: "", district: "", locality: "" },
      ],
    }));
  };

  const deleteEntry = (index) => {
    if (userAnswers.ans2.length > 1) {
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        ans2: prevAnswers.ans2.filter((_, i) => i !== index),
      }));
    }
  };

  const handleAddressChange = (index, field, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      ans2: prevAnswers.ans2.map((address, i) =>
        i === index ? { ...address, [field]: value } : address
      ),
    }));
  };

  const handleAnswerSelection = (field, value) => {
    setUserAnswers((prevAnswers) => {
      const fieldParts = field.split(".");

      if (fieldParts.length === 2) {
        return {
          ...prevAnswers,
          [fieldParts[0]]: {
            ...prevAnswers[fieldParts[0]],
            [fieldParts[1]]: value,
          },
        };
      } else {
        return {
          ...prevAnswers,
          [field]: value,
        };
      }
    });
  };

  const handleSubmission = () => {
    if (
      userAnswers.ans7.educational_background.trim() === "" &&
      userAnswers.ans7.time_period.trim() === "" &&
      userAnswers.ans7.company_website.trim() === "" &&
      userAnswers.ans7.social_media.trim() === "" &&
      userAnswers.ans7.followers.trim() === ""
    ) {
      alert("Please answer the current question before proceeding.");
    } else {
      fetch(`https://api-scdndestates.com/api/agent-test/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userAnswers, phone, email }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to submit enquiry");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Success") {
            alert("Test Completed successfully");
            window.history.back();
          } else {
            alert(response.error);
          }
        })
        .catch((error) => {
          // console.error("Error during enquiry submission:", error);
        });
    }
  };

  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const handlePriorityListChange = (newPriorityList) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      ans5: newPriorityList,
    }));
  };

  const handlePriorityListChange2 = (newPriorityList) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      ans6: newPriorityList,
    }));
  };

  return (
    <>
      <Header />
      <div className="container px-0">
        {!isAuthenticated ? (
          <PopUp1 />
        ) : (
          <>
            <div className="bg-fixed after-header flex min-h-screen flex-1 flex-col  justify-start py-12">
              <div className="flex justify-between">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col items-center max-sm:mx-4">
                  <div className="col-12 h-max flex flex-col items-center">
                    <div className="flex justify-center mb-4">
                      <div className="bg-[#ececec] text-center w-28 rounded-2xl h-12 relative font-sans text-black text-xl font-semibold group flex items-center  cursor-pointer ">
                        <div className="bg-[#69b4ff] text-white rounded-xl h-10 w-2/4 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[104px] z-10 duration-500">
                          {currentQuestion + 1}
                        </div>
                        <p className="translate-x-16  text-lg text-[#414141]">
                          of 7
                        </p>
                      </div>
                    </div>

                    <div className="mb-3 flex justify-center ">
                      <div className="text-center text-base text-[#212121] font-semibold">
                        <h5>
                          {currentQuestion === 0
                            ? "How long have you been a part of this industry?"
                            : currentQuestion === 1
                            ? "Where is your principal place of business, or what is the location of your office?"
                            : currentQuestion === 2
                            ? "What is the average turn over / revenue / Sales of your firm in the last 3 years?"
                            : currentQuestion === 3
                            ? title === "real_state_broker"
                              ? "What type of properties are you dealing in? (please arrange in order of highest to lowest turn over.)"
                              : title === "stock_broker"
                              ? "What kind of Stock-picking, trading strategies or products does your clients engage in? ( Please arrange in order of Turnover)"
                              : title === "insurance_agent"
                              ? "What kind of insurance are you predominently selling? ( Please arrange in order of Turnover)"
                              : title === "mutual_fund_distributor"
                              ? "What kind of Mutual Funds are you primarily dealing with? ( Please arrange in order of Turnover)"
                              : title === "financial_influencer"
                              ? "What kinds of asset-classes are you usually discussing with your audiences/followers?"
                              : "What are the different varieties of assets you hold on behalf of clients (i.e. included in your investment mandate)?"
                            : currentQuestion === 4
                            ? "What features of our WALR product do you think fits best with your client needs?"
                            : currentQuestion === 5
                            ? "What features of our RESIDEX product do you think fits best with your client needs?"
                            : "What experience domain expertise or network strength do you posses, that is useful for the investment industry?"}
                        </h5>
                      </div>
                    </div>

                    <div className="sm:mx-auto sm:w-full sm:max-w-sm flex justify-center">
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm max-md:w-72">
                        {currentQuestion === 0 ? (
                          <>
                            <button
                              className={`${
                                userAnswers.ans1.trim() === "0-2 Years"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection("ans1", "0-2 Years")
                              }
                            >
                              0-2 Years
                            </button>
                            <button
                              className={`${
                                userAnswers.ans1.trim() === "2-5 Years"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection("ans1", "2-5 Years")
                              }
                            >
                              2-5 Years
                            </button>
                            <button
                              className={`${
                                userAnswers.ans1.trim() === "5-10 Years"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection("ans1", "5-10 Years")
                              }
                            >
                              5-10 Years
                            </button>
                            <button
                              className={`${
                                userAnswers.ans1.trim() === "Above 10 Years"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection("ans1", "Above 10 Years")
                              }
                            >
                              Above 10 Years
                            </button>
                          </>
                        ) : currentQuestion === 1 ? (
                          <>
                            {userAnswers.ans2.map((address, index) => (
                              <div key={index} className="max-md:w-72">
                                <label
                                  className="font-semibold text-[#212121]"
                                  htmlFor={`pincode-${index}`}
                                >
                                  Pincode<span className="text-danger">*</span>
                                </label>
                                <input
                                  className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                  type="text"
                                  id={`pincode-${index}`}
                                  onChange={(e) => {
                                    fetchPincodeData(index, e.target.value);
                                    handleAddressChange(
                                      index,
                                      "pincode",
                                      e.target.value
                                    );
                                  }}
                                />

                                <label
                                  className="font-semibold text-[#212121]"
                                  htmlFor={`state-${index}`}
                                >
                                  State<span className="text-danger">*</span>
                                </label>
                                <input
                                  className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                  type="text"
                                  id={`state-${index}`}
                                  value={address.state}
                                />
                                <label
                                  className="font-semibold text-[#212121]"
                                  htmlFor={`district-${index}`}
                                >
                                  District<span className="text-danger">*</span>
                                </label>
                                <input
                                  className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                  type="text"
                                  id={`district-${index}`}
                                  value={address.district}
                                />

                                <label
                                  className="font-semibold text-[#212121] "
                                  htmlFor={`locality-${index}`}
                                >
                                  Locality
                                </label>
                                <select
                                  className="w-full text-sm py-2 px-2 mb-3 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1 "
                                  id={`locality-${index}`}
                                  value={address.locality}
                                  onChange={(e) =>
                                    handleAddressChange(
                                      index,
                                      "locality",
                                      e.target.value
                                    )
                                  }
                                >
                                  {locality.map((loc, idx) => (
                                    <option key={idx} value={loc}>
                                      {loc}
                                    </option>
                                  ))}
                                </select>

                                {userAnswers.ans2.length > 1 && (
                                  <>
                                    <button
                                      className="text-red-600 mt-2"
                                      onClick={() => deleteEntry(index)}
                                    >
                                      Delete Address
                                    </button>
                                    <hr className="mb-3" />
                                  </>
                                )}
                              </div>
                            ))}
                            <button
                              className="rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 text-[#212121] shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out"
                              onClick={addAnotherEntry}
                            >
                              Add Another Address
                            </button>
                          </>
                        ) : currentQuestion === 2 ? (
                          <>
                            <button
                              className={`${
                                userAnswers.ans3.trim() === "5 Lacs - 25 Lacs"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection(
                                  "ans3",
                                  "5 Lacs - 25 Lacs"
                                )
                              }
                            >
                              5 Lacs - 25 Lacs
                            </button>
                            <button
                              className={`${
                                userAnswers.ans3.trim() === "25 Lacs - 1 Crore"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection(
                                  "ans3",
                                  "25 Lacs - 1 Crore"
                                )
                              }
                            >
                              25 Lacs - 1 Crore
                            </button>
                            <button
                              className={`${
                                userAnswers.ans3.trim() === "1 Crore - 5 Crore"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection(
                                  "ans3",
                                  "1 Crore - 5 Crore"
                                )
                              }
                            >
                              1 Crore - 5 Crore
                            </button>
                            <button
                              className={`${
                                userAnswers.ans3.trim() === "Above 5 Crore"
                                  ? "bg-[#5ab2ff] text-[#fff]"
                                  : "text-[#212121]"
                              } flex w-full justify-center rounded-md border-2 border-[#5ab2ff] px-3 py-2.5 text-sm font-medium leading-6 shadow-sm hover:bg-[#5ab2ff] hover:text-[#fff] duration-300 ease-in-out mb-2 mt-4`}
                              onClick={() =>
                                handleAnswerSelection("ans3", "Above 5 Crore")
                              }
                            >
                              Above 5 Crore
                            </button>
                          </>
                        ) : currentQuestion === 3 ? (
                          <PartnershipQuiz
                            title={title}
                            userAnswers={userAnswers}
                            setUserAnswers={setUserAnswers}
                          />
                        ) : currentQuestion === 4 ? (
                          <>
                            <div className="text-center">
                              <a
                                href="/walrbonds/overview/"
                                className="product-btn-style"
                              >
                                Know Our Products <BsArrowBarRight size={20} />
                              </a>
                            </div>
                            <Trello
                              onPriorityListChange={handlePriorityListChange}
                            />
                          </>
                        ) : currentQuestion === 5 ? (
                          <>
                            <div className="text-center">
                              <a
                                href="/residexbonds/overview/"
                                className="product-btn-style"
                              >
                                Know Our Products <BsArrowBarRight size={20} />
                              </a>
                            </div>
                            <TrelloRes
                              onPriorityListChange={handlePriorityListChange2}
                            />
                          </>
                        ) : (
                          currentQuestion === 6 && (
                            <>
                            
                              <details className="text-start ">
                                <summary className=" w-full justify-center rounded-md px-3 py-3 text-sm font-medium leading-6  shadow-sm bg-[#5ab2ff] text-[#fff] duration-300 ease-in-out mb-2 mt-1">
                                  Staff with technical and educational
                                  background in financial markets/ Investments
                                </summary>
                                <div className=" py-2 flex items-start justify-between">
                                  <div className="  flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="mcom"
                                      name="experience"
                                      value="M.Com."
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.educational_background",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      htmlFor="mcom"
                                      className="text-[10px] md:text-sm text-[#212121]"
                                    >
                                      M.Com.
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="nisn"
                                      name="experience"
                                      value="NISN"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.educational_background",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="nisn"
                                    >
                                      NISN
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="economics"
                                      name="experience"
                                      value="Economics"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.educational_background",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="e conomics"
                                    >
                                      Economics
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="mba"
                                      name="experience"
                                      value="MBA Finance"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.educational_background",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="m ba"
                                    >
                                      MBA Finance
                                    </label>
                                  </div>
                                </div>
                              </details>
                              <details className="text-start">
                                <summary className=" w-full justify-center rounded-md px-3 py-3 text-sm font-medium leading-6  shadow-sm bg-[#5ab2ff] text-[#fff] duration-300 ease-in-out mb-2 mt-1">
                                  Network and data of investors (HNI, Corporate)
                                  looking for wealth management solutions or
                                  investment-focused
                                </summary>
                                <div className="py-2 flex items-start justify-between">
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="2years"
                                      name=""
                                      value="0-2 Years"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.time_period",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="2 years"
                                    >
                                      0-2 Years
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="5years"
                                      name=""
                                      value="0-5 Years"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.time_period",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="5 years"
                                    >
                                      0-5 Years
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="8years"
                                      name=""
                                      value="0-8 Years"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.time_period",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="8 years"
                                    >
                                      0-8 Years
                                    </label>
                                  </div>
                                  <div className=" flex items-center text-[10px] md:text-sm font-semibold ">
                                    <input
                                      type="radio"
                                      id="10years"
                                      name=""
                                      value="0-10 Years"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.time_period",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    <label
                                      className="text-[10px] md:text-sm text-[#212121]"
                                      htmlFor="1 0years"
                                    >
                                      0-10 Years
                                    </label>
                                  </div>
                                </div>
                              </details>
                              <details className="text-start">
                                <summary className="   justify-center rounded-md px-3 py-3 text-sm font-medium leading-6  shadow-sm bg-[#5ab2ff] text-[#fff] duration-300 ease-in-out mb-2 mt-1">
                                  Marketing Infrastructure
                                </summary>
                                <div className="py-2 flex flex-col items-start justify-between">
                                  <div className="flex w-full items-center text-base font-semibold text-[#212121]">
                                    <label className="text-xs md:text-sm w-64" htmlFor="">
                                      Company Website
                                    </label>
                                    <br />
                                    <input
                                      type="text"
                                      className="border rounded-lg py-1 px-2 ml-3 w-full"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.company_website",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex w-full items-center text-base font-semibold text-[#212121] mt-2">
                                    <label className="text-xs md:text-sm w-64" htmlFor="">
                                      Social media Account
                                    </label>
                                    <br />
                                    <input
                                      type="text"
                                      className="border rounded-lg py-1 px-2 ml-3 w-full"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.social_media",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" flex w-full items-center text-base font-semibold text-[#212121] mt-2">
                                    <label className="text-xs md:text-sm w-64" htmlFor="">
                                      Followers
                                    </label>
                                    <br />
                                    <input
                                      type="number"
                                      className="border rounded-lg py-1 px-2 ml-3 w-full"
                                      onChange={(e) => {
                                        handleAnswerSelection(
                                          "ans7.followers",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </details>
                            </>
                          )
                        )}
                      </div>
                    </div>

                    <div className="flex justify-center mt-5 ">
                      <div className="m-0">
                        {!isFirstQuestion && (
                          <button
                            className="relative w-32 py-2 text-center border-2 border-[#5ab2ff]  text-[#212121] text-base font-bold  overflow-hidden bg-white rounded-lg transition-all duration-400 ease-in-out shadow-md md:hover:scale-105  hover:shadow-lg active:scale-90 before:absolute before:top-0 before:-right-full before:w-full before:h-full before:bg-gradient-to-r before:from-blue-200 before:to-[#5ab2ff]   before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xs md:hover:before:right-0 md:hover:text-white mr-20"
                            onClick={() => handlePrevQuestion()}
                          >
                            PREVIOUS
                          </button>
                        )}
                      </div>

                      <div className="m-0">
                        {currentQuestion === 7 - 1 ? (
                          <button
                            className="relative w-32 py-2 text-center border-2 border-[#5ab2ff] text-[#212121] text-base font-bold  overflow-hidden bg-white rounded-lg transition-all duration-400 ease-in-out shadow-md md:hover:scale-105  hover:shadow-lg active:scale-90 before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#5ab2ff]  before:to-blue-200 before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xs md:hover:before:left-0 md:hover:text-white"
                            onClick={() => handleSubmission()}
                          >
                            SUBMIT
                          </button>
                        ) : (
                          <button
                            className="relative w-32 py-2 text-center border-2 border-[#5ab2ff] text-[#212121] text-base font-bold  overflow-hidden bg-white rounded-lg transition-all duration-400 ease-in-out shadow-md md:hover:scale-105  hover:shadow-lg active:scale-90 before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#5ab2ff]  before:to-blue-200 before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xs md:hover:before:left-0 md:hover:text-white"
                            onClick={() => handleNextQuestion()}
                          >
                            NEXT
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 ">
                    <div className="flex flex-col gap-0">
                      <div className="text-black  rounded-xl mb-1">
                        <h2
                          className="flex flex-row justify-between items-center text-xs lg:text-base font-semibold pt-5 cursor-pointer "
                          onClick={() => toggleAccordion(currentQuestion)}
                        >
                          <span
                            className="font-semibold px-2 duration-500 ease-in-out flex items-center gap-2 "
                            style={{
                              color:
                                openIndex === currentQuestion
                                  ? "#339dfa"
                                  : "#212121",
                            }}
                          >
                            <BsQuestionCircleFill className="" /> Why we ask?
                          </span>
                          <span className="duration-1000 ease-in-out">
                            {openIndex === currentQuestion ? (
                              <IoIosArrowDropup
                                size={25}
                                className="flex-shrink-0 w-3.5 h-3.5 duration-1000 ease-in-out text-[#339dfa]"
                              />
                            ) : (
                              <IoIosArrowDropdown
                                size={25}
                                className="duration-1000 ease-in-out text-[#339dfa]"
                              />
                            )}
                          </span>
                        </h2>
                        <div
                          style={{
                            maxHeight:
                              openIndex === currentQuestion ? "300px" : "0",
                          }}
                          className={`ml-3  border-l-2  border-[#339dfa]  overflow-hidden  duration-700 ease-in-out  ${
                            openIndex === currentQuestion
                              ? "max-h-screen "
                              : "max-h-0 "
                          }`}
                        >
                          <p className=" py-1 pb-3 pl-2 pr-4 text-[#212121] font-medium text-sm">
                            {currentQuestion === 0
                              ? " A firm's age is usually directly proportional to its reliability and experience, and long-surviving firms have the skill sets to form client relationships based on trust and mutual benefit. On the other hand, young fast growing firms have the competitive edge and energy to break barriers and innovate."
                              : currentQuestion === 1
                              ? "It helps us classify your potential clientele into Tier 1/2/3 cities, net-worth criteria, competing assets and asset-class preferences. and estimate potential growth rates of client assets and resources to be allocated to your network using our extensive data analytics."
                              : currentQuestion === 2
                              ? "This helps us estimate our partner's network, connections and average client relationship values using our data resources on brokerage/margins, thereby informing us how to allocate marketing resources as per their inherent strengths."
                              : currentQuestion === 3
                              ? "This helps us classify behavioral patterns of your client base and their risk appetites, along with their average ticket sizes and holding periods, thereby allowing us to design and offer financial products best suited to their needs and liking."
                              : currentQuestion === 4 || currentQuestion === 5
                              ? "This helps us understand why you think our financial products are a good fit in your typical client's portfolio, and which attribute is the most strongly appealing to them. Our marketing strategy will be custom-made based on this response to highlight the most attractive aspects (among the several advantages they enjoy over traditional assets) of Decentralized Assets."
                              : "We are interested in knowing the strengths of our Partners, so that our asset management, financial planning as well as marketing strategies are tailored to maximize their potential and resources invested, thereby building durable relationships with our Partners as well as with our clients."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TestPage;

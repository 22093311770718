import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableItem(props) {
  // props.id
  // JavaScript

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
      <button
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="flex w-full justify-center rounded-md px-3 py-3 text-sm font-medium leading-6  shadow-sm bg-[#5ab2ff] text-[#fff] duration-300 ease-in-out mb-2 mt-1"
      >
        {props.id}
      </button>
  );
}
import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

const DatePicker = ({ model, date, onChange, onClose }) => {
  const [currentDate, setCurrentDate] = useState(date);
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());

  useEffect(() => {
    setCurrentDate(date);
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());
  }, [date]);

  const changeMonth = (direction) => {
    const newDate = new Date(currentYear, currentMonth + direction, 1);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  const selectDate = (day) => {
    const newDate = new Date(currentYear, currentMonth, day);
    onChange(newDate);
  };

  const renderDays = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const lastDateOfMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const lastDateOfPrevMonth = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();

    const days = [];
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days.push(
        <td key={`prev-${i}`} className="previous-month w-full">
          {lastDateOfPrevMonth - i}
        </td>
      );
    }

    for (let i = 1; i <= lastDateOfMonth; i++) {
      days.push(
        <td
          key={`current-${i}`}
          className={`${
            i === date.getDate() &&
            currentMonth === date.getMonth() &&
            currentYear === date.getFullYear() &&
            "active"
          } w-full cursor-pointer`}
          onClick={() => selectDate(i)}
        >
          {i}
        </td>
      );
    }

    const extraDays = 7 - (days.length % 7);
    for (let i = 1; i <= extraDays; i++) {
      days.push(
        <td key={`next-${i}`} className="next-month w-full">
          {i}
        </td>
      );
    }

    return days;
  };

  return (
    <div id="date-picker-modal" className="bg-white border shadow rounded">
      <div className="flex bg-[#D8E5F9] justify-end gap-5 items-center py-2 px-4">
        <div
          id="date-picker-top-bar"
          className="flex text-base items-center justify-between font-semibold bg-white rounded min-w-[200px] "
        >
          <div
            id="date-picker-previous-month"
            className="date-picker-change-month cursor-pointer bg-zinc-100 w-5 h-full flex items-center justify-center "
            onClick={() => changeMonth(-1)}
          >
            &lsaquo;
          </div>
          <div id="date-picker-month">{`${new Intl.DateTimeFormat("en-us", {
            month: "long",
          }).format(new Date(currentYear, currentMonth))} ${currentYear}`}</div>
          <div
            id="date-picker-next-month"
            className="flex text-base items-center justify-center gap-2 font-semibold cursor-pointer bg-zinc-100 w-5"
            onClick={() => changeMonth(1)}
          >
            &rsaquo;
          </div>
        </div>
        <IoClose
          className="p-1 bg-slate-50 rounded-full text-red-600 hover:bg-red-600 hover:text-white hover:scale-90  duration-300 cursor-pointer"
          size={24}
          id="date-picker-exit"
          onClick={onClose}
        />
      </div>

      <div className="w-full flex flex-col justify-between p-2">
        <div
          id="date-picker-weekdays"
          className="bg-[#D8E5F9] flex justify-around rounded font-semibold text-center"
        >
          <div className="w-full">S</div>
          <div className="w-full">M</div>
          <div className="w-full">T</div>
          <div className="w-full">W</div>
          <div className="w-full">T</div>
          <div className="w-full">F</div>
          <div className="w-full">S</div>
        </div>
        {Array.from({ length: Math.ceil(renderDays().length / 7) }, (_, i) => (
          <div key={i} className="bg-white py-1 px-2">
            <div className="date-picker-calendar-row flex justify-around text-center">
              {renderDays().slice(i * 7, (i + 1) * 7)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;

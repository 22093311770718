import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const WalrLineChart = ({ category, graphData, graphXAxis }) => {
  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };
  const [series, setSeries] = useState([
    {
      name: category,
      data: graphData,
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: "area",
    },
    colors:
      category === "Nifty"
        ? ["#a8031c"]
        : category === "Gold"
        ? ["#ffbb00"]
        : category === "US Tresuries"
        ? ["#6f705e"]
        : ["#0066cc"],
    legend: {
      position: "top",
      fontSize: "10px",
      paddingBottom: "0px",
      marginBottom: "0px",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      tickAmount: 10,
      categories: graphXAxis,
    },
    tooltip: {
      enabled: false,
    },

    yaxis: {
      labels: {
        formatter: function (value) {
          return formatRupees(value / 1000) + "K";
        },
      },
    },
  });

  return (
    <div className="m-0 p-0 pb-2 px-2">
      <div className="display-style">
        <div id="chart" className="p-0 w-full">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            className="apexChartGr w-[350px] lg:w-[400px]"
          />
        </div>
      </div>
    </div>
  );
};

export default WalrLineChart;

import React, { useState, useEffect, useRef } from "react";
import "./Blogs.css";
import Header from "../../components/Header/Header.jsx";
import Loader2 from "../../components/Loaders/Loader2";
import ReactMarkdown from "react-markdown";

function Blogs2() {
  const [blogComments, setBlogComments] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [loader, setLoader] = useState(true);
  const [groupedBlogs, setGroupedBlogs] = useState({});
  const [displayedItems, setDisplayedItems] = useState(6);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState(-1);
  const [blogPost, setBlogPost] = useState([]);
  const [keywordStartIndex, setKeywordStartIndex] = useState(0);
  const [keywordsPerPage, setKeywordsPerPage] = useState(5);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateKeywordsPerPage = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setKeywordsPerPage(2); // Small screens
      } else if (window.matchMedia("(max-width: 1024px)").matches) {
        setKeywordsPerPage(4); // Medium screens
      } else {
        setKeywordsPerPage(5); // Large screens
      }
    };

    updateKeywordsPerPage(); // Set on initial load
    window.addEventListener("resize", updateKeywordsPerPage);

    return () => window.removeEventListener("resize", updateKeywordsPerPage);
  }, []);

  // imageDecode code
  const decodeImageData = (imageData) => {
    try {
      if (imageData !== undefined) {
        const decodedData = atob(imageData);
        return decodedData;
      }
    } catch (error) {
      // console.error("Error: ", error);
    }
  };

  //  format date
  const formattedDate = (val) => {
    const date = new Date(val);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-IN", options);
    return formattedDate;
  };

  function getDecodedImage(val) {
    const decodedImage = decodeImageData(val);
    if (decodedImage) {
      const byteCharacters = decodedImage;
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      return URL.createObjectURL(
        new File([blob], "profilePic.jpg", { type: "image/jpeg" })
      );
    }
  }

  useEffect(() => {
    // Fetch all blog comments
    fetch(`https://api-scdndestates.com/api/get-all-blogs-comments/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        const commentsArray = Array.isArray(data.data) ? data.data : [];
        setBlogComments(commentsArray);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });

    // get-all-blogs-data
    fetch(`https://api-scdndestates.com/api/get-all-blogs-data/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setBlogPost(data.data);
        // keyword
        const uniqueKeys = [
          ...new Set(data.data.flatMap((item) => item.keyword)),
        ];
        setKeyword(uniqueKeys);

        // date and blog card rendering
        const grouped = data.data.reduce((acc, blog) => {
          const date = new Date(blog.Timestamp);
          const monthYear = `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getFullYear()}`;
          if (!acc[monthYear]) acc[monthYear] = [];
          acc[monthYear].push(blog);
          return acc;
        }, {});
        setGroupedBlogs(grouped);
        setSelectedKeyword(-1);
        setSelectedKeyword(-1);
        setLoader(false);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, []);

  let serviceapi = [];

  serviceapi = [
    // Append each comment as a new entry
    ...blogPost?.map((blog, index) => ({
      id: 0 + index,
      key: blog?.keyword,
      title: blog?.title,
      image: blog?.image_url,
      date: blog?.Timestamp,
      author: blog?.author,
      content: blog?.content,
    })),
  ];

  // Truncate text function
  function truncateText(text) {
    const words = text.split(" ");
    const truncatedText = words.slice(0, 30).join(" ").replace(/\\n/g, "\n");
    return truncatedText + " ...";
  }

  // Handle load more button click
  const handleLoadMore = () => {
    setDisplayedItems((prev) => prev + 4);
  };

  // Calculate comment counts for each blog post
  const blogsWithCommentCounts = serviceapi.map((blog) => {
    const commentCount = blogComments.filter(
      (comment) => comment.blog_name === blog.title
    ).length;
    return { ...blog, commentCount };
  });

  // Sort blogs by comment count in descending order
  const mostCommented = blogsWithCommentCounts
    .sort((a, b) => b.commentCount - a.commentCount)
    .slice(0, 5)
    .map((blog) => blog.title);

  // Filter blogs based on the selected month and keyword
  const filteredBlogs = serviceapi.filter((blog) => {
    const date = new Date(blog.date);
    const monthYear = `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
    const matchesMonth = selectedMonth === "" || monthYear === selectedMonth;
    const matchesKeyword =
      selectedKeyword === -1 || blog.key.includes(selectedKeyword);
    return matchesMonth && matchesKeyword;
  });

  // Handle keyword arrow navigation
  const handleArrowClick = (direction) => {
    setKeywordStartIndex((prevIndex) => {
      const maxIndex = Math.max(0, keyword.length - keywordsPerPage);
      let newIndex = prevIndex;
      if (direction === "left") {
        newIndex = Math.max(prevIndex - keywordsPerPage, 0);
      } else if (direction === "right") {
        newIndex = Math.min(prevIndex + keywordsPerPage, maxIndex);
      }
      // Smooth scrolling with fade effect
      if (containerRef.current) {
        containerRef.current.classList.add("fade-out");
        setTimeout(() => {
          containerRef.current.scrollTo({
            left:
              newIndex * (containerRef.current.scrollWidth / keyword.length),
            behavior: "smooth",
          });
          containerRef.current.classList.remove("fade-out");
          containerRef.current.classList.add("fade-in");
          setTimeout(
            () => containerRef.current.classList.remove("fade-in"),
            300
          );
        }, 300);
      }
      return newIndex;
    });
  };

  return (
    <div>
      <Header />
      {loader ? (
        <Loader2 />
      ) : (
        <div className="after-header pb-5 max-w-[1800px] mx-auto">
          <div className="lg:mx-12 2xl:mx-32 py-2 w-[90%] mx-auto">
            <div className="blogs-title flex-col items-center mt-6">
              {/* key words */}
              <div className="flex items-center justify-between">
                <button
                  className={`arrow-button ${
                    keywordStartIndex === 0
                      ? "disabled cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  onClick={() => handleArrowClick("left")}
                  disabled={keywordStartIndex === 0}
                >
                  <img
                    className="w-6 h-6 rotate-180"
                    src="./images/nexticon.png"
                    alt=""
                  />
                </button>
                <div
                  ref={containerRef}
                  className="keywords-container flex justify-center items-center overflow-x-scroll mx-2"
                >
                  <div className="flex flex-wrap justify-center items-center gap-2 max-md:w-[250px] md:w-[700px] lg:w-[800px] xl:w-[900px]">
                    {keyword
                      .slice(
                        keywordStartIndex,
                        keywordStartIndex + keywordsPerPage
                      )
                      .map((i, index) => (
                        <span
                          key={`${index}`}
                          className={`relative cursor-pointer inline-flex items-center rounded-md max-md:px-1 md:px-2 py-1 text-xs lg:text-sm font-medium bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10 ${
                            selectedKeyword === i ? "bg-gray-300" : ""
                          }`}
                          onClick={() => {
                            setSelectedKeyword(i);
                            setDisplayedItems(4);
                            setSelectedMonth("");
                          }}
                        >
                          {i}
                        </span>
                      ))}
                  </div>
                </div>
                <button
                  className={`arrow-button ${
                    keywordStartIndex + keywordsPerPage >= keyword.length
                      ? "disabled cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  onClick={() => handleArrowClick("right")}
                  disabled={
                    keywordStartIndex + keywordsPerPage >= keyword.length
                  }
                >
                  <img
                    className="w-6 h-6 "
                    src="./images/nexticon.png"
                    alt=""
                  />
                </button>
              </div>

              {/* key words */}

              {/* Heading */}
              <div className="title">
                <h1 className="text-3xl pt-2 text-center font-bold tracking-tight text-[#5ab2ff]  md:text-5xl">
                  Insights & Innovations
                </h1>
              </div>
              {/* Heading */}
            </div>
            <div className="mt-12 blog-container2">
              {/* left section  */}
              <div className="flex flex-col sm:w-3/6 md:w-4/6 lg:w-3/4 xl:w-5/6 mb-4">
                <div className=" rounded-xl h-max grid gap-3 md:gap-4 lg:grid-cols-1 xl:grid-cols-2 md:pr-10">
                  {filteredBlogs?.slice(0, displayedItems)?.map((curElem) => (
                    <div className="flex bg[#212121] h-max" key={curElem.id}>
                      <div className="row m-0 md:m-1  bg-[#eaf4fa]  rounded-xl p-1 md:p-3  flex h-max">
                        {/* image  */}
                        <div className=" w-12/12 sm:w-5/12  rounded-lg bg-[#25628a] p-0  ">
                          <img
                            className="object-cover w-full h-full   rounded-lg border-2 border-[#ffffff] shadow-sm "
                            src={`${getDecodedImage(curElem.image)}`}
                            alt=""
                          />
                        </div>
                        {/* text content  */}
                        <div className="col-lg-7 col-md-7  h-max flex-column ">
                          <div className="blog-text h-[140px]">
                            <h6 className="blogs-title text-primary pb-2 ">
                              <span className="font-semibold">
                                {curElem.title}
                              </span>
                            </h6>
                            <p className="blogs-para text-md font-semibold tracking-tight">
                              <ReactMarkdown>
                                {truncateText(curElem.content)
                                  ? truncateText(curElem.content)
                                  : "..."}
                              </ReactMarkdown>
                            </p>
                          </div>
                          <div className="flex flex-wrap md:justify-between items-center slice ">
                            <div className="font-semibold rounded-md mt-2">
                              <a
                                href={`/blogsPage/${curElem.title}/`}
                                className="flex px-2 py-1  rounded-md items-center shadow-sm gap-2 duration-200 text-sm "
                              >
                                Read More{" "}
                              </a>
                            </div>
                          </div>
                        </div>

                        {/* comments  */}
                        <div className="block px-2 py-1 mt-2">
                          <h6 className="text-dark flex items-end font-bold justify-between  blogs-date">
                            <span className="">
                              {curElem.author} ({formattedDate(curElem.date)})
                            </span>
                            <span className="">
                              {
                                blogComments.filter(
                                  (comment) =>
                                    comment.blog_name === curElem.title
                                ).length
                              }{" "}
                              Comments
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* load more btn */}
                {displayedItems < filteredBlogs?.length && (
                  <div className="mt-10 lg:pl-6 lg:float-l flex sm:flex-row items-center justify-center md:justify-start lg:float-left gap-x-4 sm:gap-x-6">
                    <a
                      href="#"
                      className="rounded-md bg-[#6cbaff] px-7 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#4da9fa] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3698ee]"
                      onClick={() => handleLoadMore()}
                    >
                      Show More
                    </a>
                  </div>
                )}
                {/* load more btn */}
              </div>
              {/* left section  */}

              {/* right section  */}
              <div className="h-max sm:w-3/6 md:w-2/6 lg:w-1/4 xl:w-1/6 mx-1">
                {/* Most comments  */}
                <div className="my-0 bg-[#ffffff] rounded-xl box-border ">
                  <h5 className="bg-[#eaf4fa] pl-4 py-2 flex justify-start items-start font-bold rounded-xl text-dark">
                    Most Commented
                  </h5>
                  <div className="list-unstyled px-3">
                    {mostCommented.map((item, index) => (
                      <p
                        key={index}
                        className="border-bottom py-2 text-sm font-semibold leading-5"
                      >
                        <a href={`/blogsPage/${encodeURIComponent(item)}/`}>
                          {item}
                        </a>
                      </p>
                    ))}
                  </div>
                </div>
                {/* Most comments  */}

                {/* archieves section  */}
                <div className=" my-3 bg-white rounded-xl">
                  <h5 className="bg-[#eaf4fa] pl-4 py-2 flex justify-start items-start font-bold rounded-xl text-dark">
                    Archives
                  </h5>
                  <ul className="list-unstyled px-2.5 ">
                    {Object.keys(groupedBlogs).map((monthYear) => (
                      <li
                        key={monthYear}
                        className={`${
                          monthYear === selectedMonth && "text-primary"
                        } border-bottom py-2 text-sm font-semibold leading-5 cursor-pointer`}
                        onClick={() => {
                          setSelectedMonth(monthYear);
                          setDisplayedItems(4);
                          setSelectedKeyword(-1);
                        }}
                      >
                        {monthYear}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* archieves section  */}
              </div>
              {/* right section  */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Blogs2;

import Container from "react-bootstrap/Container";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState, useEffect } from "react";
import { SortableItem } from "./SortableItem";

function PartnershipQuiz({ title, userAnswers, setUserAnswers }) {
  const [languages, setLanguages] = useState(
    title === "real_state_broker"
      ? [
          "Gated Apartment Towers",
          "Builder Floor",
          "Independent House/Villas",
          "Plots(Residential & Commercial)",
          "Offices/Industrial buildings",
          "Retail Shops",
        ]
      : title === "stock_broker"
      ? [
          "Buy-Today, Sell-Tomorrow",
          "Intraday",
          "Near-term expiry future",
          "Put & Call options",
          "Buy & Hold strategies",
          "Index ETFs",
          "Commodities",
          "Corporate Bonds (Retail Segment)",
        ]
      : title === "insurance_agent"
      ? [
          "Health Insurance",
          "Term Insurance",
          "Life Insurance with Investment",
          "Cars & 2-wheelers Insurance",
          "Fire & Earthquake Insurance",
          "Annuity & Pension",
          "Market-Linked",
          "Guaranteed Returns",
        ]
      : title === "mutual_fund_distributor"
      ? [
          "Large-cap equity",
          "Midcap equity/Smallcap equity",
          "Multicap equity",
          "Balanced Funds",
          "Gilt/PSU Bond Funds",
          "Corporate Bond Funds",
          "Short duration debt Funds",
          "Arbitrage Funds",
          "Liquid money market Funds",
          "Gold Funds",
          "Thematic Funds (Pharmacy, Tech, etc.)",
        ]
      : title === "financial_influencer"
      ? [
          "Individual Stock recommendations",
          "Thematic of industry-specific Stocks",
          "Equity mutual fund schemes",
          "Tax-savings strategies",
          "Structured Debt instruments",
          "Privately placed Debent]",
        ]
      : [
          "Individual Stocks",
          "Indices and ETFs",
          "Listed Derivative (Futures & Options)",
          "Complex OTC Derivatives",
          "Distressed Debt and restructuring",
          "Structured Debt Instruments",
          "REITs/InvITs",
          "Venture Capital Investments",
          "Cryptocurrency",
        ]
  );

  // Effect to initialize ans4 in userAnswers
  useEffect(() => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      ans4: languages,
    }));
  }, [languages, setUserAnswers]);

  return (
    <>
      <div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <Container
            className="p-1 flex flex-col w-full "
            style={{ width: "100%" }}
            align="center"
          >
            <SortableContext
              items={languages}
              strategy={verticalListSortingStrategy}
            >
              {languages.map((language) => (
                <SortableItem key={language} id={language} />
              ))}
            </SortableContext>
          </Container>
        </DndContext>
      </div>
    </>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLanguages((items) => {
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, activeIndex, overIndex);

        // Update the ans4 with the new order of languages
        setUserAnswers((prevAnswers) => ({
          ...prevAnswers,
          ans4: newOrder,
        }));

        return newOrder;
      });
    }
  }
}

export default PartnershipQuiz;
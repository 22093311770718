import React from "react";
import Chart from "react-apexcharts";

const Linechart = ({ fundName, tableData, graphData, graphXAxis }) => {
  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };
  const data = [
    {
      name: tableData,
      data: graphData[0],
    },
  ];

  const data2 = [
    {
      name: tableData,
      data: graphData[fundName],
    },
  ];

  const option = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        rotate: -90,
        rotateAlways: true,
      },
      categories: graphXAxis,
      tickAmount: 12,
    },
    yaxis: {
      title: { text: "Investments (₹)" },
      labels: {
        formatter: function (value) {
          return formatRupees(value / 1000) + "K";
        },
      },
    },
    stroke: {
      dashArray: [5],
      width: 2,
    },
  };

  return (
    <React.Fragment>
      {fundName === "WALR" ? (
        <Chart
          type="line"
          className="linegraph_style2"
          series={data}
          options={option}
        ></Chart>
      ) : (
        <Chart
          type="line"
          className="linegraph_style2"
          series={data2}
          options={option}
        ></Chart>
      )}
    </React.Fragment>
  );
};

export default Linechart;

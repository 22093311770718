import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function LegalDocument() {
  const LegalDocumentsAPI = [
    {
      id: 0,
      title: "Disclosures",
      content: (
        <>
          <div className="row justify-content-center p-3 text-black">
            <h4 className="text-center fw-bold mb-2 text-2xl flex items-center justify-center gap-2">
              <span>
                <svg
                  id="fi_3437346"
                  enableBackground="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-fit"
                >
                  <g>
                    <path
                      d="m256 21.996c-135.311 0-245.002 109.691-245.002 245.002 0 61.567 22.714 117.824 60.213 160.864l79.062 60.208c32.009 15.336 67.864 23.93 105.727 23.93 135.311 0 245.002-109.691 245.002-245.002s-109.691-245.002-245.002-245.002z"
                      fill="#4fabf7"
                    ></path>
                    <path
                      d="m271.868 421.246-.616-1.126c-3.565-6.514-1.153-14.761 5.361-18.326l.75-.41c-6.824 3.735-15.463 1.208-19.198-5.615-3.735-6.823-1.208-15.462 5.615-19.197-6.824 3.735-15.462 1.208-19.198-5.616-3.735-6.824-1.208-15.462 5.615-19.198-6.824 3.735-15.463 1.208-19.198-5.616-3.735-6.823-1.208-15.462 5.616-19.198l18.427-10.087-2.809-5.131c-2.063-3.769-5.643-6.474-9.832-7.43-15.315-3.493-31.214 2.683-40.155 15.598-8.054 11.96.048-2.625-20.368 44.198-1.317 3.124-3.652 5.71-6.626 7.339l-104.041 56.431c21.854 25.084 48.729 45.675 79.062 60.208l73.496-39.865c2.015-1.103 4.256-1.729 6.551-1.83l22.343-.981c2.295-.101 4.536-.727 6.551-1.83l29.538-16.17-.95.233c-6.021 1.909-12.793-.644-15.934-6.381z"
                      fill="#fcad6d"
                    ></path>
                    <path
                      d="m315.136 413.251-73.366-134.427-51.556 11.876 80.262 147.06c6.768 12.333 22.252 16.844 34.585 10.076 12.332-6.768 16.843-22.252 10.075-34.585z"
                      fill="#3c58a0"
                    ></path>
                    <path
                      d="m236.499 323.9c-5.683-10.413.143-23.374 11.702-26.037l3.517-.81-9.949-18.23-51.555 11.877 80.262 147.06c6.637 12.095 21.657 16.66 33.867 10.447z"
                      fill="#2a428c"
                    ></path>
                    <g fill="#f9ba8f">
                      <path d="m270.328 422.088-.616-1.126c-3.566-6.514-1.153-14.761 5.361-18.327l37.94-20.768c6.514-3.566 14.761-1.153 18.327 5.361l.616 1.126c3.566 6.514 1.153 14.761-5.361 18.327l-37.94 20.768c-6.514 3.566-14.761 1.153-18.327-5.361z"></path>
                      <path d="m256.625 396.61c-3.735-6.824-1.208-15.463 5.616-19.198l45.487-24.899c6.824-3.735 15.463-1.208 19.198 5.615 3.735 6.823 1.208 15.462-5.615 19.198l-45.487 24.899c-6.825 3.736-15.464 1.208-19.199-5.615z"></path>
                      <path d="m243.043 371.797c-3.735-6.824-1.208-15.463 5.615-19.198l48.317-26.448c6.824-3.735 15.463-1.208 19.198 5.615 3.735 6.824 1.208 15.463-5.615 19.198l-48.317 26.448c-6.824 3.735-15.463 1.208-19.198-5.615z"></path>
                      <path d="m296.652 310.204c-3.735-6.824-12.374-9.351-19.198-5.616l-42.379 23.197c-6.824 3.735-9.351 12.374-5.615 19.198 3.735 6.824 12.374 9.351 19.198 5.615l42.378-23.197c6.824-3.735 9.351-12.373 5.616-19.197z"></path>
                    </g>
                    <path
                      d="m176.855 370.401c.634 8.855 4.415 22.554 21.302 25.745 11.23 2.122 22.595-12.148 22.595-12.148 14.214-19.858 14.284-34.516 13.32-40.9-.084-.553.662-.803.931-.313l3.625 6.622c1.269 2.318 2.929 4.283 4.85 5.868 4.553 3.316 10.785 3.93 16.053 1.047 4.873-2.668 7.676-7.644 7.8-12.826.143-5.96-2.534-8.567-15.095-31.772-.951-1.736-2.35-3.43-4.102-4.757-.006-.004-.011-.009-.016-.013-1.641-1.24-3.589-2.175-5.713-2.66-15.315-3.493-31.214 2.683-40.155 15.598-8.054 11.96.048-2.625-20.368 44.198-1.071 2.538-2.82 4.709-5.027 6.311z"
                      fill="#fcad6d"
                    ></path>
                    <path
                      d="m249.65 336.53 6.462 11.805c1.702 3.109 5.454 4.414 8.678 3.174 1.588-2.376 2.471-5.162 2.539-8.012.127-5.294-2.083-7.753-8.614-19.933l-6.277 3.436c-3.388 1.854-4.642 6.143-2.788 9.53z"
                      fill="#f9dfcd"
                    ></path>
                    <g>
                      <path
                        d="m203.692 390.972c-.949-1.751-.608-3.986.944-5.367 25.783-22.94 21.965-48.437 21.924-48.692-.391-2.392 1.233-4.647 3.625-5.038 2.393-.39 4.649 1.233 5.037 3.625.201 1.235 4.597 30.549-24.751 56.662-1.811 1.611-4.585 1.449-6.196-.361-.231-.259-.425-.537-.583-.829z"
                        fill="#fd995b"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m165.894 286.691-28.867-125.32c-.192-.836-.279-1.67-.284-2.493-34.074 9.651-54.932 44.453-46.895 79.345s42.02 57.063 76.881 50.834c-.355-.742-.642-1.53-.835-2.366z"
                        fill="#e9efff"
                      ></path>
                    </g>
                    <path
                      d="m96.875 256.283c13.695 24.1 41.483 37.842 69.854 32.774-.356-.742-.643-1.53-.836-2.366l-8.772-38.081c-.767-.398-1.559-.862-2.375-1.401.002 0-25.128 26.536-57.871 9.074z"
                      fill="#d3dcfb"
                    ></path>
                    <path
                      d="m327.044 74.825c-26.265 30.774-60.721 53.299-99.212 65.077.048.173.106.341.147.518l28.867 125.32c.041.177.062.353.095.53 39.765-6.248 80.602-1.067 117.684 15.116l62.1 27.1c-3.651-15.852-62.043-269.347-65.695-285.199z"
                      fill="#e9efff"
                    ></path>
                    <path
                      d="m345.767 52.888 31.183 126.042c5.084 20.55-11.351 40.188-32.462 38.606-24.947-1.87-58.439-1.193-94.759 8.394 0 0-.443 1.108-1.479 2.49l8.597 37.32c.041.177.063.353.095.53 39.765-6.248 80.602-1.067 117.684 15.116l62.1 27.1c-3.651-15.852-62.043-269.347-65.695-285.199z"
                      fill="#d3dcfb"
                    ></path>
                    <path
                      d="m467.962 130.238c-29.815 1.651-49.413-12.027-49.413-12.027 2.345 10.182 17.018 73.881 19.379 84.13 0 0 11.639-20.873 39.172-32.432 4.423-1.857 6.915-6.578 5.838-11.253l-4.803-20.852c-1.077-4.675-5.383-7.831-10.173-7.566z"
                      fill="#e9efff"
                    ></path>
                    <path
                      d="m480.113 146.393c-5.18 2.094-9.766 4.306-13.741 6.482-10.622 5.813-23.85-.125-26.568-11.925-1.196-5.191-2.378-10.323-3.368-14.62-11.312-3.54-17.887-8.12-17.887-8.12 2.345 10.182 17.018 73.881 19.379 84.13 0 0 11.639-20.873 39.172-32.432 4.424-1.857 6.915-6.578 5.838-11.253z"
                      fill="#d3dcfb"
                    ></path>
                    <g>
                      <path
                        d="m453.543 325.817c-7.266 1.674-14.512-2.859-16.186-10.125l-68.91-299.158c-1.674-7.266 2.859-14.512 10.125-16.186s14.512 2.859 16.186 10.125l68.91 299.158c1.674 7.266-2.859 14.512-10.125 16.186z"
                        fill="#ff4155"
                      ></path>
                    </g>
                    <path
                      d="m463.668 309.631-27.214-118.145c-1.061 5.531-5.267 10.193-11.108 11.539-5.991 1.38-11.964-1.13-15.293-5.869l27.304 118.535c1.674 7.266 8.92 11.799 16.186 10.125 7.266-1.673 11.799-8.92 10.125-16.185z"
                      fill="#e80054"
                    ></path>
                    <path
                      d="m216.301 131.447-72.698 16.746c-6.117 1.409-9.934 7.51-8.525 13.627l28.867 125.32c1.409 6.117 7.51 9.933 13.627 8.524l72.698-16.746c6.117-1.409 9.934-7.51 8.524-13.627l-28.867-125.32c-1.408-6.116-7.509-9.933-13.626-8.524z"
                      fill="#ff4155"
                    ></path>
                    <path
                      d="m231.318 232.702-56.921 13.112s-6.789 9.889-19.649 1.395l9.198 39.931c1.409 6.117 7.51 9.933 13.627 8.524l72.698-16.746c6.117-1.409 9.933-7.51 8.524-13.627l-9.067-39.361s-4.493 11.262-18.41 6.772z"
                      fill="#e80054"
                    ></path>
                    <g>
                      <path
                        d="m180.274 148.75c-3.415 0-6.502-2.349-7.302-5.818l-4.967-21.565c-.93-4.036 1.589-8.063 5.625-8.992 4.035-.935 8.063 1.589 8.992 5.625l4.967 21.565c.93 4.036-1.589 8.063-5.625 8.992-.566.13-1.133.193-1.69.193z"
                        fill="#bec8f7"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m164.39 128.144c-3.415 0-6.502-2.349-7.302-5.818-.93-4.036 1.589-8.063 5.625-8.992l21.152-4.872c4.037-.933 8.062 1.588 8.992 5.625.93 4.036-1.589 8.063-5.625 8.992l-21.152 4.872c-.566.131-1.132.193-1.69.193z"
                        fill="#e9efff"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m156.636 168.708c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.036-.934 8.062 1.589 8.992 5.625s-1.589 8.063-5.625 8.992l-42.304 9.744c-4.027.93-8.061-1.582-8.992-5.625z"
                        fill="#cc0053"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m162.556 194.41c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.036-.93 8.062 1.589 8.992 5.625s-1.589 8.063-5.625 8.992l-42.304 9.744c-4.026.93-8.061-1.582-8.992-5.625z"
                        fill="#cc0053"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m168.476 220.112c-.93-4.036 1.588-8.063 5.625-8.992l42.304-9.745c4.037-.93 8.062 1.589 8.992 5.625s-1.588 8.063-5.625 8.992l-42.304 9.745c-4.026.93-8.06-1.582-8.992-5.625z"
                        fill="#cc0053"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m174.397 245.814c-.93-4.036 1.588-8.063 5.625-8.992l42.304-9.745c4.037-.932 8.063 1.589 8.992 5.625.93 4.036-1.588 8.063-5.625 8.992l-42.304 9.745c-4.026.93-8.061-1.581-8.992-5.625z"
                        fill="#cc0053"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m180.317 271.515c-.93-4.036 1.589-8.063 5.625-8.992l42.304-9.744c4.034-.934 8.063 1.588 8.992 5.625.93 4.036-1.589 8.063-5.625 8.992l-42.304 9.744c-4.026.93-8.061-1.581-8.992-5.625z"
                        fill="#cc0053"
                      ></path>
                    </g>
                    <path
                      d="m223.769 448.205c-68.117-7.677-46.915-77.801-46.915-77.804-.512.372-1.042.723-1.602 1.03l-104.041 56.431c21.854 25.084 48.729 45.675 79.063 60.208z"
                      fill="#fd995b"
                    ></path>
                  </g>
                </svg>
              </span>
              <span>Disclosures</span>
            </h4>
            <h6 className="fw-bold mt-2">SCDND Estates Private Limited</h6>
            <p className="text-justify mt-2 text-sm leading-relaxed ">
              <div className="" type="1">
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  SCDND Estates Private Limited (hereinafter referred to as “the
                  Company”) is a financial technology company based in Kolkata
                  incorporated 2016. The company builds financial technology
                  platforms and provides financial technology services to its
                  clients.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  A Decentralized Asset is an actively managed portfolio of
                  stocks, exchange-traded funds, REITs, Non-convertible
                  Debentures, Private Equity, Derivatives thereof and other
                  domestic and global securities (listed or unlisted) that are
                  curated to track a predefined real-world economic statistics
                  or data-survey based index.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  SCDND Estates is registered under Companies act 2013, with its
                  Objectives to operate in the asset management sector. The
                  Company bridges the gap between investors and their money by
                  removing intermediaries and price-setters, through the use of
                  Blockchain technology and collateral-backed guarantee.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  All returns and ratios displayed on the mobile and web
                  applications of the company (hereinafter referred to as
                  “Product”) are only for informational purposes and are to help
                  investors make an informed decision. They are tools to
                  communicate factual and verifiable return information and
                  should not be seen as advertisement or promotion.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  Unless otherwise stated, the percentage returns displayed on
                  the website for calculating product performance are normalized
                  on Rs. 1 lakhs or multiples thereof.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  The content and data available on scdndestates.com, including
                  but not limited to SCDND Estates Product Performance, SCDND
                  Estates Custodian account, SCDND Estates Key ratio, SCDND
                  Estates shareholdings, SCDND Estates annual report are for
                  information and illustration purposes only. No content on our
                  website or application, including any posts, pictures or any
                  percentage returns should be considered an advertisement,
                  endorsement, solicitation, financial advice, or offer to sell
                  any products or services of the Company or of any other third
                  parties.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  Charts and performance numbers on the platform do not include
                  any back tested data. Please refer to the Product performance
                  and Risk sensor to check how returns are calculated on the
                  platform. Data used for the calculation of live returns and
                  other information is provided by exchange-approved third-party
                  data vendors and has neither been audited nor validated by the
                  Company.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  SCDND Estates are not licensed to any exchange, trading or
                  settling venue outside India.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  Prices shown on the scdndestates.com may be delayed. The
                  prices are sourced from an Regulatory authorities and ministry
                  of statistics published data, or from equivalent institutions
                  of other countries. The references are detailed on the pages
                  themselves. The Company is not responsible for the accuracy
                  and correctness of the prices.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  All information present on scdndestates.com is to help
                  investors in their decision-making process and shall not be
                  considered as a recommendation or solicitation of an
                  investment or investment strategy. Investors are responsible
                  for their investment decisions and are responsible to validate
                  all the information used to make the investment decision.
                  Investors should understand that his/her investment decision
                  is based on personal investment needs and risk tolerance, and
                  performance information available on smallcase.com is one
                  amongst many other things that should be considered while
                  making an investment decision. Past performance does not
                  guarantee future returns and the performance of SCDND Estates
                  are subject to market risk. Investments in the securities
                  market are subject to market risks and investors should read
                  all the related documents carefully before investing.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  The Company ensures that all data, images, or other material
                  sourced from non-public channels are acquired through
                  appropriate licensing agreements with the rightful owners. We
                  guarantee that no third-party trademarks or intellectual
                  property rights have been infringed upon.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  While the Company endeavours to provide a seamless user
                  experience and adhere to Know Your Customer (KYC) standards by
                  integrating third-party APIs, we do not assume responsibility
                  for the integrity, security, or reliability of these external
                  services. The Company reserves the right to modify APIs and
                  service providers without prior notification to users.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  The Company and its representatives disclaim liability for any
                  instances of data breaches, server compromises, system
                  crashes, or other malfunctions attributed to the cloud
                  computing resources utilized for hosting and database
                  purposes.
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  An investment in securities is speculative in nature and
                  involves a high degree of risk, including the risk of loss of
                  capital. Any such investment is suitable only for
                  sophisticated investors who are familiar with, fully
                  comprehend and are willing to accept high risk, and have
                  carefully read the information memorandum. No assurance is
                  given by the Company that users of this website will achieve
                  their investment objectives or will receive a return of their
                  capital
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  Registered office of SCDND Estates Private Limited – <br />
                  Flat 1/1A CLUBTOWN GREENS <br />
                   167 JESSORE ROAD <br /> KOLKATA – 700055
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  CIN: U47912DL2023PTC423725
                </p>
                <p className="flex flex-wrap items-center gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  <span>Support-Contact us at</span>
                  <a
                    href="mailto:helpdesk@scdndestates.com"
                    className="text-blue-700 font-semibold"
                  >
                    helpdesk@scdndestates.com
                  </a>
                  <span>
                    for any queries and assistance. All grievances should be
                    addressed to our grievance team at
                  </span>
                  <a
                    href="mailto:compliance@scdndesttes.com"
                    className="text-blue-700 font-semibold"
                  >
                    compliance@scdndesttes.com
                  </a>
                </p>

                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  The information and opinions contained herein have been
                  compiled or arrived at, based upon information obtained in
                  good faith from sources believed to be reliable. Such
                  information has not been independently verified and no
                  guarantee, representation of warranty, express or implied, is
                  made as to its accuracy, completeness or correctness. All such
                  information and opinions are subject to change without notice
                </p>
                <p className="flex items-start gap-1.5 mt-1.5">
                  <span className="mt-1">
                    <TbPointFilled />
                  </span>
                  The Company does not endorse or promote any of the
                  opportunities that appear on this website nor makes any
                  recommendations regarding the same to any user of the website.
                  Listing of details of various assets/securities may be
                  arranged/referred and/or owned by the Company.
                </p>
              </div>
            </p>
          </div>
        </>
      ),
    },
    {
      id: 1,
      title: "Terms & Conditions",
      content: (
        <>
          <div className="row justify-content-center p-3 text-black">
            <h4 className="text-center fw-bold mb-2 text-xl md:text-2xl flex items-center justify-center gap-2">
              <span>
                <svg
                  id="fi_14682660"
                  enableBackground="new 0 0 68 68"
                  viewBox="0 0 68 68"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-9 h-fit"
                >
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m56.1049805 19.9151611v42.2099609c0 2.0898438-1.6901855 3.7797852-3.7800293 3.7797852h-40.9899903c-2.0900879 0-3.7800293-1.6899414-3.7800293-3.7797852v-52.8601074c0-2.0900879 1.6899414-3.7900391 3.7800293-3.7900391h30.0200195z"
                                  fill="#b9b9b9"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m60.4450684 16.5350342v42.2099609c0 2.0900879-1.7001953 3.7800293-3.7900391 3.7800293h-40.9902344c-2.0898438 0-3.7797852-1.6899414-3.7797852-3.7800293v-52.8601074c0-2.0898438 1.6899414-3.7897949 3.7797852-3.7897949h30.0200195z"
                                  fill="#dbdbdb"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m60.4450684 16.5350342h-10.9799805c-2.0900879 0-3.7802734-1.6999512-3.7802734-3.7900391v-10.6499023z"
                                  fill="#304056"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path d="m22.6320934 8.2247419h-5.9760742c-.2109375 0-.3979492.0786133-.5410156.2270508-.1396475.1459961-.213378.3325195-.213378.5390625 0 .2080078.0751953.3935547.2172842.5366211.1420898.1420898.3276367.2172852.5371094.2172852h2.1977539v6.6259775c0 .2172852.0791016.4111328.2294922.5605469.1494141.1499023.3432617.2294922.5605469.2294922.215332 0 .4101563-.078125.5629883-.2260742.1567383-.1499023.2392578-.3447266.2392578-.5639648v-6.6259776h2.1860352c.2070313 0 .3935547-.0737305.5390625-.2138672.1479492-.1425781.2265625-.3291016.2265625-.5400391 0-.2094727-.0771484-.3969727-.2231445-.5429688s-.3334962-.2231445-.5424806-.2231445z"></path>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="m31.9777393 15.888279-.6499023-.6206055c.1801758-.2700195.3393555-.5522461.472168-.8388672.0576172-.1142578.0869141-.2329102.0869141-.3520508 0-.2114258-.0766602-.3964844-.2216797-.534668-.25-.2387695-.6533203-.2788086-.9536133-.081543-.1225586.0820313-.2148438.1899414-.2739258.3193359-.0795898.171875-.1533203.3203125-.2216797.4438477l-2.2851563-2.1542969c-.3364258-.3125-.581543-.5537109-.7285156-.7163086-.1333008-.1479492-.2333984-.2983398-.2978516-.4482422-.0620117-.1430664-.09375-.3154297-.09375-.512207 0-.2270508.0869141-.4213867.265625-.59375.3569336-.3447266 1.0673828-.362793 1.784668.0253906.3261719.1630859.6118164.0898438.8095703-.1176758.1230469-.1303711.1884766-.2910156.1884766-.4658203 0-.1201172-.0356445-.2368164-.1064453-.3457031-.0664063-.1025391-.1459961-.1806641-.2211914-.222168-1.050293-.7001953-2.2319336-.7089844-3.0498047-.2568359-.3798828.2099609-.6748047.4990234-.8764648.8579102-.2006836.3564453-.3022461.7529297-.3022461 1.1787109 0 .3793945.0708008.722168.2099609 1.0185547.1020508.2167969.2402344.4414063.4121094.6704102-.2753906.2133789-.5341797.4848633-.7719727.8095703-.3144531.4301758-.4741211.9501953-.4741211 1.5454102 0 .4643555.1132813.9082031.3364258 1.3193359.2260742.4179688.5854492.7607422 1.0673828 1.019043.4750977.2548828 1.0756836.3837891 1.7861328.3837891.4941406 0 .9692383-.0908203 1.4135742-.2700195.3686523-.1489258.7148438-.3452148 1.03125-.5844727l.6494141.6201172c.3085938.2851563.8222656.2709961 1.0864277-.0151367.1308594-.1430664.1972656-.3168945.1972656-.5166016 0-.1313477-.0273438-.2456055-.0810547-.340332-.0463867-.0805665-.1123047-.1586915-.1879902-.2241211zm-2.746582-.538086c-.4453125.2988281-.9248047.4443359-1.4584961.4443359-.4570313 0-.8271484-.1206055-1.1318359-.3696289-.2895508-.2358398-.4301758-.527832-.4301758-.8920898 0-.3271484.0800781-.6030273.2441406-.8427734.1342773-.1953125.2910156-.3642578.4682617-.5029297z"></path>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="m40.1021805 15.1412458c-.3339844.1748047-.6655273.3095703-.9848633.4013672-.3134766.0888672-.6821289.1342773-1.0957031.1342773-.6396484 0-1.1884766-.1318359-1.6313477-.3920898-.4394531-.2573242-.7739258-.6137695-.9941406-1.0595703-.2260742-.4545898-.340332-.9799805-.340332-1.5625s.1142578-1.1079102.340332-1.5629883c.2202148-.4448242.5546875-.8012695.9941406-1.0595703.4428711-.2597656.9916992-.3916016 1.6313477-.3916016.4135742 0 .7822266.0454102 1.0952148.1342773.3178711.0913086.6494141.2260742.9887695.402832.2739258.1386719.6464844.0810547.8569336-.1401367.1313477-.1376953.2011719-.3071289.2011719-.4907227 0-.3007813-.1362305-.534668-.390625-.6738281-.4370117-.2470703-.8842773-.4404297-1.3300781-.5742188-.4506836-.1342773-.9648438-.2021484-1.5292969-.2021484-.8383789 0-1.6030273.1860352-2.2739258.5527344-.675293.3696289-1.2128906.9067383-1.5981445 1.597168-.3833008.6865234-.5776367 1.496582-.5776367 2.4082031 0 .9121094.1943359 1.722168.5776367 2.4082031.3852539.6904297.9228516 1.22754 1.5981445 1.5971689.6708984.3666992 1.4355469.5527344 2.2739258.5527344.5644531 0 1.0786133-.0678711 1.5297852-.2021484.4433594-.1328125.890625-.3256836 1.3266602-.5727539.2573242-.140625.3935547-.3745117.3935547-.6752939 0-.1933594-.0708008-.3662109-.2055664-.5009766-.2119142-.2114257-.576172-.2695311-.8559571-.1284179z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m26.1049805 23.7249756v5.920166c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.920166c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757-.0000001 1.670166.7499999 1.670166 1.6799316z"
                                  fill="#488ff7"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 23.9317627h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m34.1994629 23.9317627h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 27.1832275h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m43.8967285 27.1832275h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m47.197998 30.4346924h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m36.5378418 30.4346924h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="m20.6804199 28.3907471c-.1328125 0-.2597656-.0527344-.3535156-.1464844l-.8305664-.8310547c-.1953125-.1953125-.1953125-.512207 0-.7070313.1953125-.1953125.5117188-.1953125.7070313 0l.4770508.4775391 2.0615234-2.0615234c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4150391c-.0937501.0937499-.2207032.1464843-.3535157.1464843z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m26.1049805 36.3250732v5.9199219c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.9199219c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757 0 1.670166.75 1.670166 1.6799316z"
                                  fill="#488ff7"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 36.5313721h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m34.1994629 36.5313721h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 39.7833252h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m43.8967285 39.7833252h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m47.197998 43.03479h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m36.5378418 43.03479h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="m20.6804199 40.9908447c-.1328125 0-.2597656-.0527344-.3535156-.1464844l-.8305664-.8310547c-.1953125-.1953125-.1953125-.512207 0-.7070313.1953125-.1953125.5117188-.1953125.7070313 0l.4770508.4775391 2.0615234-2.0615234c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4150391c-.0937501.09375-.2207032.1464843-.3535157.1464843z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  d="m26.1049805 48.9249268v5.920166c0 .9299316-.7399902 1.6799316-1.670166 1.6799316h-5.9199219c-.9299316 0-1.6799316-.75-1.6799316-1.6799316v-5.920166c0-.9299316.75-1.6799316 1.6799316-1.6799316h5.9199219c.9301757-.0000001 1.670166.7499999 1.670166 1.6799316z"
                                  fill="#488ff7"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 49.1314697h-18.2617187c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h18.2617188c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m34.1994629 49.1314697h-3.8515625c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h3.8515625c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m55.4870605 52.3834229h-7.8764648c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.8764648c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m43.8967285 52.3834229h-13.5488281c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h13.5488281c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m47.197998 55.6348877h-7.633789c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h7.6337891c.2763672 0 .5.2236328.5.5s-.2236329.5-.5000001.5z"></path>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <path d="m36.5378418 55.6348877h-6.1899414c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h6.1899414c.2763672 0 .5.2236328.5.5s-.2236328.5-.5.5z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="m20.6804199 53.5904541c-.1279297 0-.2558594-.0488281-.3535156-.1464844l-.8305664-.8305664c-.1953125-.1953125-.1953125-.5117188 0-.7070313s.5117188-.1953125.7070313 0l.4770508.4770508 2.0615234-2.0610352c.1953125-.1953125.5117188-.1953125.7070313 0s.1953125.5117188 0 .7070313l-2.4150391 2.4145508c-.0976563.0976563-.225586.1464844-.3535157.1464844z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span>Terms & Conditions</span>
            </h4>
            <p className=" text-sm mt-2 text-justify m-0">
              The users of this website understand that any use of the
              information / contents and any investments and investment related
              decisions are at the sole discretion &amp; risk of such persons.
              The information presented under newsletter and blogs is solely for
              informational purposes. 
            </p>
            <h6 className="m-0 text-base mt-4 mb-2 fw-bold">
              Terms and Conditions for Client:
            </h6>
            <hr className="px-4" />
            <p className="mt-3 text-sm  ">
              <ol type="1" className="leading-relaxed">
                <li>
                  <h6 className="m-0 text-base fw-bold">Accounts</h6>
                  To use our Services, you must provide accurate and up-to-date
                  information to open an Account. You may need to provide
                  details and documents such as PAN card details, address proof,
                  bank account details, and KYC proof. It is your responsibility
                  to keep your Account and password secure. Unauthorized use of
                  your Account due to your inability to secure your account and
                  password will be your responsibility and may result in losses
                  incurred by SCDND Estates or third-party users.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Subscription</h6>
                  Any User subscribing to, using, or accessing the services
                  available through this site, is registered as a single user of
                  the services. The applicable rate of the Subscription Fees for
                  some of the services provided herein shall be such as
                  mentioned on the site and/or as discussed with the concerned
                  users from time to time. User’s Liability for the Subscription
                  Fees, if any, shall accrue from the Date of Commencement.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Processing of Transactions
                  </h6>
                  All transactions/changes, as applied to/requested, by the user
                  shall be processed as per the applicable rules, laws and
                  regulations, business practices and as agreed with the
                  users/clients. <br /> Revision of Tax Details, Agency Code,
                  and Nominee shall be authorized exclusively through OTP
                  verification utilizing the registered mobile number. SCDND
                  Estates shall deem the User to have comprehended and consented
                  to said modifications upon completion of OTP-based
                  authentication.
                </li>{" "}
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Use of Password</h6>
                  <ol type="i">
                    <li>
                      In the event a User is given a password, the User shall
                      ensure that such password is kept in a secure manner. To
                      protect the secrecy of his user identification and/or
                      password the user shall take all such measures as may be
                      necessary and shall not reveal the same to any other
                      person(s).
                    </li>
                    <li>
                      Since a user identification is necessary to access some of
                      the services available through the site; the user shall
                      use only his own user identification while availing such
                      services. In the event of theft and/or loss of user
                      identification and/or password and/or security word, the
                      user shall notify the Company or its
                      management/representatives immediately by telephone and/or
                      concurrently provide SCDND Estates with a written notice
                      to that effect. The user shall remain solely liable for
                      use/misuse of the services by any third party until such
                      theft or loss is duly notified.
                    </li>
                    <li>
                      The password and username being made available to the user
                      shall be used only by the user /organization named as the
                      user and the authorised employees of said organization.
                      The password and username being made available to the user
                      shall be used only by the user /organization named as the
                      user and the authorised employees of said organization.
                    </li>
                    <li>
                      Password reset shall be authorized solely through OTP
                      verification via the user&#39;s registered email and
                      mobile number. The Company disclaims any liability in the
                      event of compromise of said OTPs by the User or if the
                      User loses access to the registered email or mobile number
                      through any means.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Unauthorised Access</h6>
                  As a condition for your use of this site, you will not use the
                  site for any purpose that is unlawful or prohibited by these
                  terms, conditions, and notices. You may not use the site in
                  any manner that could damage, disable, overburden, or impair
                  the network(s) connected to this site or interfere with any
                  other party’s use and enjoyment of this site. You may not
                  attempt to gain unauthorised access to this site, other
                  accounts, computer systems or networks connected to the site,
                  through hacking, password mining or any other means. You may
                  not obtain or attempt to obtain any materials or information
                  through any means not intentionally made available through the
                  site.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Discontinuation or Modification to Services
                  </h6>
                  SCDND Estates reserves the unilateral right to add to /and/or
                  change and/or delete and/or end the service (s) available
                  through the site at any time with or without notice to the
                  User. Except for paid services, a pro-rated refund shall be
                  affected for the remaining unused period.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Suspension of Service
                  </h6>
                  If any monies payable by the user to SCDND Estates are not
                  paid on the due date or if at its sole discretion any of the
                  users of this site/portal is found to be using this site for
                  illegal purposes or is observed having violated any of the
                  terms and conditions as are applicable for usage of this site,
                  SCDND Estates may without prejudice to any other rights or
                  remedies that may be available to it suspend the service (s)
                  provided to such user. Upon subsequent payment by the user of
                  such monies as demanded by SCDND Estates, SCDND Estates may at
                  its discretion and subject to such terms as it deems proper,
                  reconnect the service(s).
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">User Obligations</h6>
                  The user undertakes to:
                  <ol type="i">
                    <li>
                      be responsible for maintaining the confidentiality of your
                      account and password and for restricting access to your
                      computer to prevent unauthorised access to your account
                      and confidential information.
                    </li>
                    <li>
                      be responsible for all activities that occur under your
                      account or password.
                    </li>
                    <li>
                      take all necessary steps to ensure that the password is
                      kept confidential and secure and should inform us
                      immediately if you have any reason to believe that your
                      password has become known to anyone else, or if the
                      password is being, or is likely to be, used in an
                      unauthorised manner.
                    </li>
                    <li>
                      ensure that the details you provide us with are true,
                      correct and complete and inform the Company immediately of
                      any changes to the information that you provided when
                      registering.
                    </li>
                    <li>
                      not use the service for any illegal purposes or for the
                      violation of any law or statutory regulation, including,
                      but not restricted to indulging in; (i) any unfair,
                      manipulative or fraudulent practices or (ii) any insider
                      trading and/or communication of unpublished price
                      sensitive information.
                    </li>
                    <li>
                      not to copy, reproduce, recompile, decompile, assemble,
                      disassemble, distribute, publish, display, modify or
                      upload to, create derivative works from, transmit, or in
                      any way exploit any part of the Service, except for
                      downloading material from the Service for his /her own
                      personal, non-commercial use.
                    </li>
                    <li>agree to receive periodic alerts and newsletters.</li>
                    <li>
                      ensure that you obtain all necessary rights to the content
                      you post or share on the Website.
                    </li>
                    <li>
                      Ensure upon entering the PAN/Aadhaar Number, Date of
                      Birth, and Full Name, users acknowledge that these details
                      cannot be modified thereafter. It is the user&#39;s sole
                      responsibility to ensure the accuracy of information
                      provided in their government- issued Photo IDs. Account
                      activation on this portal is contingent upon successful
                      KYC verification.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Confidentiality</h6>
                  <ol type="i">
                    <li>
                      The Receiving Party/User shall keep confidential and not
                      disclose to any third party the Confidential Information
                      nor any part of it, unless upon prior permission in
                      writing from SCDND Estates. The Receiving Party/User
                      agrees to take all possible precautions with regard to
                      protecting the confidential information from any third
                      party.
                    </li>
                    <li>
                      Further no use, reproduction, transformation or storage of
                      the Proprietary Information shall be made by the user
                      without the prior written permission of SCDND Estates,
                      except where required to be disclosed pursuant to any
                      applicable laws or legal process issued by any court or
                      the rules of any competent regulatory body.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Non-exclusive Remedy
                  </h6>
                  Termination or expiration of this arrangement, in part or in
                  whole, shall not limit either party from pursuing other
                  remedies available to it, nor shall either party be relieved
                  of its obligation to pay all fees that are due and owing under
                  this arrangement through the effective date of termination.
                  Neither party shall be liable to the other for any damages
                  resulting solely from termination as permitted herein.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Jurisdiction</h6>
                  Any dispute, whatsoever, arising out of this arrangement shall
                  be governed by the Laws of India. The Courts of law at Kolkata
                  shall have exclusive jurisdiction over any disputes arising
                  under this arrangement.
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Exclusion of Warranties
                  </h6>
                  <ol type="i">
                    <li>
                      The Company does not provide any warranty, express or
                      implied, for the accuracy or completeness of the Platform
                      or the Services, and expressly disclaims any liability for
                      any errors or omissions;
                    </li>
                    <li>
                      The use of the Platform and the Services is at your own
                      risk and consequence. In no event shall the Company be
                      held liable for any damages or claims whatsoever in
                      connection with any inaccuracy, error, failure, omission,
                      delay or otherwise;
                    </li>
                    <li>
                      Nothing in these Terms shall exclude or limit your
                      warranty or liability for losses unless they are excluded
                      or limited by applicable law. Some jurisdictions do not
                      allow the exclusion of certain warranties or conditions or
                      the limitation or exclusion of liability for loss or
                      damage caused by negligence, breach of contract or breach
                      of implied terms, or incidental or consequential damages.
                      Accordingly, only the limitations which are lawful in your
                      jurisdiction will apply to you and our liability will be
                      limited to the maximum extent permitted by law.
                    </li>
                    <br />
                    <h6 className="fw-bold m-0 text-base">
                      We further DO NOT represent or warrant to you that:
                    </h6>
                    <li>
                      Your use of our Platform and Services will meet your
                      requirements;
                    </li>
                    <li>
                      Your use of the Platform and Services will be
                      uninterrupted, timely, secure or free from error,
                      including specifically from server downtime;
                    </li>
                    <li>
                      Any information obtained by you as a result of your use of
                      the Platform and Services will be accurate or reliable.
                    </li>
                    <li>
                      You agree that any material downloaded or otherwise
                      obtained through the use of the Services and Platform is
                      done at your own discretion and risk and we shall not be
                      liable for any damage to your computer system or other
                      device or loss of data that results from the download of
                      any such material.
                    </li>
                    <li>
                      No advice or information, whether oral or written,
                      obtained by you from us or from the use of Services shall
                      create any warranty not expressly stated in these Terms.
                    </li>
                    <li>
                      We further expressly disclaim all warranties and
                      conditions of any kind, whether express or implied,
                      including, but not limited to the implied warranties and
                      conditions of merchantability, fitness for a particular
                      purpose and non-infringement.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Restrictions</h6>
                  <ol type="i">
                    <li>
                      Access to the Platform and use of the Services shall
                      always be subject to the following restrictions, failing
                      which the Company may take any action it deems necessary
                      at its sole discretion:
                    </li>
                    <li>
                      You agree not to attempt to reverse-engineer, disseminate,
                      copy, exploit, or represent yourself as an agent or
                      reseller of the Platform and the Services, or any part
                      thereof;
                    </li>
                    <li>
                      You agree not to misrepresent yourself as or impersonate
                      any other person, or otherwise cause harm to any person;
                    </li>
                    <li>
                      You agree not to upload or transmit any vulgar, obscene,
                      abusive, defamatory, libellous, invasive, or unlawful
                      content, including content which is objectionable or
                      results in a breach of privacy of any other person;
                    </li>
                    <li>
                      You agree not to use or abuse the Platform for or in
                      relation to any illegal or unlawful purposes including
                      fraud, embezzlement, money laundering, etc.;
                    </li>
                    <li>
                      You agree not to carry out or facilitate any actions which
                      result in a detrimental effect on the Platform or the
                      Services, including DDoS attacks, data security breaches,
                      or otherwise any disruption to the servers, hardware, or
                      network of any other person or the Platform or the
                      Services;
                    </li>
                    <li>
                      You agree that you are granted a non-exclusive license for
                      the purpose of enabling you to use and access our Platform
                      and Services. Except with our prior written consent, you
                      may not assign (or grant a sub-license of) your rights to
                      use the Service or otherwise transfer any part of your
                      rights to use the Service.
                    </li>
                    <li>
                      You agree not to violate any applicable laws, whether or
                      not intended by you;
                    </li>
                    <li>
                      You represent and warrant that you possess the legal right
                      and ability to enter into these Terms and to use the
                      Platform and the Services in accordance with these Terms.
                    </li>
                    <li>
                      You agree not to upload or transmit any vulgar, obscene,
                      abusive, defamatory, libellous, pornographic, paedophilic,
                      invasive of another’s privacy including bodily privacy, or
                      unlawful content, including content which is harmful to
                      children, objectionable or results in a breach of privacy
                      of any other person, content which is insulting or
                      harassing on the basis of gender, libelous, racially or
                      ethnically objectionable, relating or encouraging money
                      laundering or gambling, or otherwise inconsistent with or
                      contrary to the laws in force in India;
                    </li>
                    <li>
                      You agree not to carry out or facilitate any actions which
                      result in a detrimental effect on the Website or the
                      Services or any related computer resource, including DDoS
                      attacks, data security breaches, or otherwise any
                      disruption to the servers, hardware, or network of any
                      other person or the Website or the Services;
                    </li>
                    <li>
                      You agree that you shall have no right to the intellectual
                      property and any proprietary information made available or
                      accessible through the Website or the Services;
                    </li>
                    <li>
                      You agree not to violate any applicable laws, whether or
                      not intended by you;
                    </li>
                    <li>
                      You represent and warrant that you possess the legal right
                      and ability to enter into these terms and to use the
                      Website and the Services in accordance with these terms
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">
                    Intellectual Property Rights
                  </h6>
                  <ol type="i">
                    <li>
                      You acknowledge and agree that the Company, Associate
                      Companies or their respective licensors, own all legal
                      rights, title and interest in and to the Services,
                      including any Intellectual Property Rights which subsist
                      in the Service.
                    </li>
                    <li>
                      Unless you receive our express authorization in writing,
                      you agree that in using the Services or accessing our
                      Platform, you shall not use any trademark, service mark,
                      trade name, the logo of any company or organization in a
                      way that is likely or intended to cause confusion about
                      the owner or authorized user of such marks, names or
                      logos.
                    </li>
                    <li>
                      You further acknowledge that our Services may contain
                      information that is designated as confidential by us and
                      that you shall not disclose such information without our
                      prior written consent.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <h6 className="m-0 text-base fw-bold">Miscellaneous</h6>
                  In order to provide the Services and access to the Platform,
                  the Company may be required to create a user profile that may
                  be linked to third-party platforms or Associate Company’s
                  platforms as may be necessary or requested by you subject to
                  verification. The registration of the user profile shall
                  require the Company to retain certain User Data for providing
                  a safe and efficient user experience;
                  <br />
                  The Company, and their respective affiliates, subsidiaries,
                  directors, employees, promoters and agents shall not be liable
                  for:
                  <ol type="i">
                    <li>
                      any direct, indirect, incidental, special, consequential
                      or exemplary loss or damage suffered by the User, however,
                      caused and shall include any loss of profit (whether
                      incurred directly or indirectly), any loss of goodwill or
                      business reputation, any loss of data suffered, cost of
                      procurement of substitute goods or Services, or other
                      intangible loss, arising out of the use of, or inability
                      to use or access of our Platform or Service or any
                      security breach or any virus, bug, unauthorised
                      intervention, technical malfunctioning or defect, whether
                      or not forceable and whether or not we have been advised
                      of the possibility of such damage.
                    </li>
                    <li>
                      Any loss or damage incurred by you owing to any temporary
                      disablement or permanent discontinuance of our Platform or
                      Services and any deletion, corruption or failure to store
                      any content or other communications maintained by your use
                      of our Services; and
                    </li>
                    <li>
                      which may be incurred by you, including but not limited to
                      loss or damage as a result of any reliance placed by you
                      on the completeness, accuracy or existence of any
                      advertising, or as a result of any relationship or
                      transaction between you and any advertiser or sponsor
                      whose advertisement appears on the Services.
                    </li>
                    <li>
                      You agree to indemnify and keep the Company, Associate
                      Company, their respective affiliates and licensors
                      indemnified against all costs, expenses, damages, losses
                      and liabilities incurred or suffered by such party related
                      to any User Content posted or transmitted by User during
                      the use of the Platform or Service or any unauthorised use
                      of the Platform or Service in contravention of this Terms.
                    </li>
                    <li>
                      The Privacy Policy and the Associate Company Terms shall
                      be read to form a part of these Terms;
                    </li>
                    <li>
                      These Terms shall be governed by and under the laws of
                      India and shall be subject to the exclusive jurisdiction
                      of Bengaluru. Any failure or delay by the Company to
                      exercise or enforce any right or provision of the Terms
                      shall not constitute a waiver of such right or provision.
                      If any part of the Terms is found by a court of competent
                      jurisdiction to be invalid, the parties agree that the
                      court should endeavour to give effect to the parties’
                      intentions as reflected in the provision, and the other
                      provisions of the Terms shall remain in full force and
                      effect. You agree that regardless of any statutory period
                      of limitation, any claim shall be filed within one (1)
                      year after the relevant cause of action arose; s
                    </li>
                    <li>
                      The Company shall be absolutely entitled to terminate your
                      use of the Platform and the Services in the event of any
                      breach of these Terms by you, or otherwise, any event
                      which gives rise to any reason for the Company to
                      discontinue the offering of the Platform or Services to
                      you, without any notice or obligations whatsoever;
                    </li>
                  </ol>
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
    },
    {
      id: 2,
      title: "Asset Management Risks",
      content: (
        <>
          <div className="row justify-content-center p-3 text-black">
            <h4 className="text-center fw-bold mb-2 text-xl md:text-2xl flex items-center justify-center gap-2">
              <span>
                <svg
                  id="fi_9539743"
                  enableBackground="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-9 h-fit"
                >
                  <g clipRule="evenodd" fillRule="evenodd">
                    <path
                      d="m385.656 83.279c-46.811-13.465-90.623-34.411-129.656-67.279-39.033 32.868-82.845 53.814-129.656 67.279 0 104.397 45.687 193.088 125.092 257.711 79.405-64.623 134.22-153.314 134.22-257.711z"
                      fill="#c90a4f"
                    ></path>
                    <circle
                      cx="256"
                      cy="150.478"
                      fill="#fcb500"
                      r="69.553"
                    ></circle>
                    <path
                      d="m224.414 152.728c-1.823-2.06-1.63-5.208.43-7.031s5.208-1.63 7.031.43l12.99 14.666 35.691-31.408c2.071-1.823 5.228-1.622 7.051.449s1.622 5.228-.449 7.051l-39.419 34.688-.01-.011c-2.06 1.813-5.201 1.619-7.021-.438z"
                      fill="#397399"
                    ></path>
                    <g>
                      <path
                        d="m125.596 448.751h99.912c1.209-26.597 3.695-49.657-2.75-66.913-21.167-46.915-51.571-74.348-101.115-88.138-15.896-5.729-28.145-31.223-28.579-38.176-7.505-58.317-10.314-80.39-10.6-82.847l-2.452-13.249c-3.101-9.644-6.858-13.443-7.351-13.848-4.529-5.627-13.735-8.773-17.54-8.158-3.028.754-5.083 1.436-7.782 5.294-4.003 4.899-3.128 21.824-3.093 23.488 2.121 41.439 4.176 63.118 4.354 71.267 2.72 56.023 8.994 77.19 12.833 92.12 4.041 11.263 11.004 20.306 20.962 29.244 9.837 8.554 34.899 28.605 37.186 31.746 7.994 10.476 6.654 53.615 6.015 58.17z"
                        fill="#efc89f"
                      ></path>
                      <path
                        d="m109.548 440.086h120.078c6.05 0 10.999 4.95 10.999 10.999v33.915c0 6.05-4.95 10.999-10.999 10.999h-120.078c-6.05 0-10.999-4.949-10.999-10.999v-33.915c0-6.049 4.949-10.999 10.999-10.999z"
                        fill="#009ac3"
                      ></path>
                    </g>
                    <g>
                      <path
                        d="m386.404 448.751c-65.997 0-99.3 0-99.912 0-1.209-26.597-3.695-49.657 2.75-66.913 21.167-46.915 51.571-74.348 101.115-88.138 15.896-5.729 28.145-31.223 28.579-38.176 7.505-58.317 10.314-80.39 10.6-82.847l2.452-13.249c3.101-9.644 6.858-13.443 7.35-13.848 4.529-5.627 13.735-8.773 17.54-8.158 3.028.754 5.082 1.436 7.782 5.294 4.003 4.899 3.128 21.824 3.094 23.488-2.122 41.439-4.176 63.118-4.354 71.267-2.721 56.023-8.994 77.19-12.833 92.12-4.041 11.263-11.004 20.306-20.962 29.244-9.836 8.554-34.899 28.605-37.186 31.746-7.994 10.476-6.654 53.615-6.015 58.17z"
                        fill="#efc89f"
                      ></path>
                      <path
                        d="m402.452 440.086h-120.078c-6.05 0-10.999 4.95-10.999 10.999v33.915c0 6.05 4.95 10.999 10.999 10.999h120.078c6.05 0 10.999-4.949 10.999-10.999v-33.915c0-6.049-4.949-10.999-10.999-10.999z"
                        fill="#009ac3"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span>Asset Management Risk</span>
            </h4>
            <h6 className="fw-bolder px-2 pt-2 text-base">General Risk</h6>
            <p className="m-0 text-sm px-2 leading-relaxed">
              Investment in Decentralized Assets is risky, and investors should
              not invest any funds in Decentralized assets unless they can
              afford to take the risk attached to such investments. Investors
              are advised to take an informed decision and to read the risk
              factors carefully before investing in this offering. For taking an
              investment decision, investors must rely on their examination of
              the Issue including the risks involved in it. <br />
              These risks are not, and are not intended to be, a complete list
              of all risks and considerations relevant to the Decentralized
              assets or Investor’s decision to purchase such securities. <br />
              By giving your consent through our Platform or Services, you agree
              to the terms of this Risk involved Policy and you expressly give
              your voluntary consent to Continue to use and disclosure of the
              Personal Information in accordance with this Business Model.
            </p>
            <h6 className="fw-bolder px-2 pt-2 text-base">RISK FACTORS</h6>
            <p className="m-0 text-sm px-2 leading-relaxed">
              An investment in this type of security involves a certain degree
              of risk. The investor should carefully consider all the
              information contained in this Financial Risk Disclosure Form,
              including the risks and uncertainties described below, before
              making an investment decision. The risk factors set forth below do
              not purport to be complete or comprehensive in terms of all the
              risks that may arise in connection with our business or any
              decision to purchase, own or dispose of the Decentralized assets.
              Additional risks and uncertainties not presently known to us or
              that we currently believe to be immaterial may also have an
              adverse impact on our business, results of operations, cash flows
              and financial condition. The market prices of the Decentralized
              assets could decline due to such risks and you may lose all or
              part of your investment. The financial and other related
              implications of the risks described in this section, have been
              disclosed to the extent quantifiable as on the date of this FORM.
              It also contains forward-looking statements that involve risks and
              uncertainties. Our results could differ materially from those
              anticipated in these forward-looking statements as a result of
              certain factors, including events described below. <br />
              Unless otherwise stated or unless context requires otherwise, the
              financial information used in this section is derived from and
              should be read in conjunction with the Annual Financial
              Statements.
            </p>
            <h6 className="fw-bolder px-2 pt-2 text-base">INTERNAL RISKS</h6>

            <p className="m-0 text-sm px-2 leading-relaxed">
              <ol type="1" className="text-sm mt-2">
                <li>
                  <span className="fw-bold">
                    We require substantial capital for our business and any
                    disruption in our sources of capital or an increase in our
                    average cost of borrowings could have an adverse effect on
                    our business, results of operations and financial condition.
                  </span>
                  <br />
                  Our business&#39;s liquidity and profitability are
                  significantly dependent on timely access to financing and
                  associated costs. Historically, we&#39;ve sourced funding from
                  various channels, including term loans, external commercial
                  borrowings, and working capital facilities. As of March 31,
                  2023, and for the Financial Years ending 2023, 2022, and 2021,
                  our Current Ratio key ratios, Debt-Equity Ratio, Debt Service
                  Coverage Ratio, Return on Capital employed ratio stood at
                  -15.57%, - 43.36%, -103.98 %, -34.90%, -56.34, 176.81%,
                  -34.90, -56.34 and 176.81% respectively. Additionally, our
                  total borrowing as of March 31, 2023, and for the Financial
                  Years ending 2023, 2022, and 2021, amounted to ₹6,32,92,250
                  crore, ₹6,42,25,000 crore and ₹6,04,90,000 crore respectively.
                  We anticipate potential future capital requirements, which may
                  vary due to economic conditions, unforeseen expenses,
                  regulatory changes, or operational delays. In instances where
                  our capital needs surpass available resources, we may seek
                  additional debt or equity capital, subjecting us to increased
                  interest costs and compliance obligations. Our ability to
                  secure funds on favourable terms and in a timely manner hinges
                  on multiple factors, including financial performance, credit
                  ratings, regulatory environments, and market liquidity.
                  Furthermore, ongoing financing requirements may impose
                  constraints on our flexibility and ability to pursue growth
                  initiatives. We cannot guarantee timely or favourable
                  financing, which may adversely affect our business, financial
                  condition, and results of operations.
                </li>
                <br />
                <li>
                  <span className="fw-bold">
                    The financing industry is becoming increasingly competitive,
                    which creates significant pricing pressures for us to retain
                    existing customers and solicit new business. Our growth will
                    depend on our ability to compete effectively in this
                    environment.
                  </span>
                  <br />
                  Our business operates in a highly competitive market and we
                  face significant competition from other players in the
                  financing industry. Many of our competitors are large
                  institutions, which may have a larger customer base, funding
                  sources, branch networks and capital base compared to us. Some
                  of our competitors may be more flexible and better positioned
                  to take advantage of market opportunities. This competition is
                  likely to further intensify as a result of securitization and
                  regulatory changes. Our future success will depend, to a large
                  extent, on our ability to respond in a timely and effective
                  manner to these competitive pressures. <br />
                  Our credit business competes based on a number of factors,
                  including cost effective sources of funding, successful
                  implementation of new technologies and securitization branches
                  to manage operational costs. Loans are becoming increasingly
                  securitization and terms such as variable (or floating) rate
                  interest options, lower processing fees and monthly reset
                  periods are becoming increasingly common in the Indian
                  financial sector, thereby increasing competition. <br />
                  Our insurance business competes for business based on various
                  factors, including product features, price, coverage offered,
                  quality of customer service, distribution network,
                  relationships with agents, bancassurance partners and other
                  intermediaries, brand recognition, size of operations,
                  financial strength and credit ratings. In addition, life
                  insurance products also compete with certain other financial
                  services products which may not be exactly comparable but
                  provide slightly different alternatives to the customers. For
                  example, in the area of savings- oriented insurance products,
                  we compete with mutual fund companies, bank fixed deposits and
                  Government small saving schemes. Some of our competitors may
                  offer higher commissions or more attractive rewards to agents
                  and other distribution intermediaries or offer similar
                  insurance products at lower pricing. If we are unable to
                  effectively respond to these various competitive pressures, it
                  could result in a decrease of market share, decrease in our
                  margins and spreads, reduced customer base, increase in
                  pricing of our products, increase in operating expenses, as
                  well as higher attrition rates among management and sales
                  staff, lower growth rates, or even losses, which could have
                  material adverse effect on our business, results of
                  operations, cash flows and financial condition.
                </li>
                <br />
                <li>
                  <span className="fw-bold">
                    We have grown in the past but there can be no assurance that
                    our growth will continue at a similar rate or that we will
                    be able to manage our rapid growth. If we are unable to
                    implement or sustain our growth strategy effectively it
                    could adversely affect our business, results of operations,
                    cash flows and financial condition.
                  </span>
                  <br />
                  Our consolidated profit for the year/ period attributable to
                  owners was ₹ 9,88,332 for the Fiscal 2023. If we are unable to
                  implement or sustain our growth strategy effectively it could
                  adversely affect our business, results of operations, cash
                  flows and financial condition.
                  <br />
                  Our growth strategy involves expanding our HNI, corporate and
                  institutional asset management, market-making of hybrid
                  securities, investment advisory, and blockchain-based trading
                  vertical. Diversifying product portfolios to meet customer
                  needs and scaling our businesses is a key component. This
                  strategy will strain management, financial, and other
                  resources, necessitating continuous improvement in
                  operational, financial, and internal controls. Expansion
                  presents challenges in financial management, human resource
                  recruitment and retention, cultural preservation, and
                  administrative infrastructure development. <br />
                  Operational risks accompany our growth strategy, particularly
                  during the transition to asset-light growth, which may
                  initially reduce our credit book but ultimately increase
                  assets under management. Rapid credit book growth exposes us
                  to various risks, including business, operational, NPA, fraud,
                  regulatory, and legal risks. Serving a large retail client
                  base expose us to additional risks across various areas,
                  including business, regulatory, legal, fraud, compliance, and
                  reputation.
                  <br />
                  The recent consolidation within the insurance or financial
                  services industries may lead to emergence of larger
                  competitors with competitive advantages. We may face
                  challenges sustaining growth due to competitive pressures or
                  other factors. Additionally, new product launches may not meet
                  market expectations, potentially affecting returns. Business
                  models may not succeed, and investments in technology and
                  customer interfaces may not yield desired results. <br />
                  Our growth relies on recruiting and retaining skilled
                  personnel, maintaining effective risk management policies,
                  offering relevant products, and providing high-quality client
                  service. Failure to train employees properly could lead to
                  increased attrition, erosion of service quality, and higher
                  costs. Rapid credit book growth without proper risk assessment
                  may increase non- performing loans, impacting asset quality
                  and financial condition. <br />
                  Rapid growth places demand on operational, credit, financial,
                  and risk controls, including preserving asset quality,
                  improving products and delivery channels, recruiting skilled
                  personnel, upgrading technology, complying with regulations,
                  and maintaining customer satisfaction. Failure to implement
                  these measures may hinder expansion and adversely affect
                  performance and financial results
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    Difficult conditions in financial markets can adversely
                    affect our business, which could materially reduce our
                    revenue and income.
                  </span>
                  <br />
                  Our financial services firm is highly susceptible to
                  fluctuations in both domestic and global financial markets, as
                  well as economic conditions in India. Any prolonged or
                  significant downturn or extreme volatility in Indian capital
                  markets could lead to decreased revenue from our products and
                  services, along with reduced net investment income and fund
                  management fees. Past trading losses in various investments,
                  including equities, derivatives, fixed income securities, and
                  commodities, pose a risk to our invested capital and could
                  negatively impact our overall business, operations, cash
                  flows, and financial condition. <br />
                  Future market and economic conditions may worsen due to
                  factors beyond our control, such as rising interest rates,
                  inflation, terrorism, political uncertainty, or adverse
                  global/domestic events affecting liquidity and investment
                  inflows. Growth potential in recent ventures like retail
                  financing, insurance, and asset management may be constrained
                  during challenging market conditions. Our capital business
                  performance, including internal liability management
                  operations, may suffer due to market and economic conditions.
                  The NBFC industry&#39;s liquidity issues since September 2018,
                  triggered by the collapse of a &#39;AAA rated&#39; entity,
                  have escalated borrowing costs, potentially exacerbating
                  future challenges. <br />
                  We cannot accurately quantify the impact of adverse market
                  conditions on our business and financial condition. Quarterly
                  operating results may fluctuate significantly due to market
                  volatility, rendering period-to-period comparisons unreliable
                  indicators of future performance.
                </li>
                <br />
                <li>
                  <span className="fw-bold">
                    High levels of defaults on our fixed-income assets and the
                    resultant non-performing assets could adversely affect our
                    business, financial condition, results of operations and
                    future financial performance.
                  </span>
                  <br />
                  Our investment business entails inherent risks associated with
                  defaults, including delays or failure to repay principal
                  and/or interest on loans and Debentures. These risks stem from
                  various factors, including external influences beyond our
                  control such as economic developments, market fluctuations,
                  regulatory changes, and customer financial difficulties.
                  Failure to manage these risks effectively may lead to
                  decreased revenue, increased expenses, elevated delinquency
                  rates, and higher provisions for loan losses. Previous
                  instances of customer default have necessitated legal actions
                  for recovery. Default incidents may increase non-performing
                  assets (NPAs) and provisions, impacting our operations and
                  profitability. Although there have been improvements in asset
                  quality, there&#39;s no guarantee of sustained trends. As our
                  loan portfolio matures, default risks may escalate. Our
                  provisioning coverage ratio and recovery rates may vary,
                  affecting our financial performance. Inadequate credit
                  appraisal and monitoring processes could further deteriorate
                  asset quality, significantly impacting our business,
                  operations, and financial health.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We may not be able to recover the full value of collateral
                    or amounts sufficient to cover the outstanding amounts due
                    under defaulted loans on a timely basis or at all, or the
                    value of collateral may decrease, which could adversely
                    affect our results of operations, cash flows and financial
                    condition.
                  </span>
                  <br />
                  In instances of default, the debtor entity typically undergoes
                  a bankruptcy resolution process pursuant to the Insolvency and
                  Bankruptcy Code (IBC). Within this framework, secured
                  creditors possess collective decision-making authority
                  regarding the disposition of the debtor company&#39;s assets,
                  either through liquidation or sale as a going concern to
                  another entity. Our company does not exert direct influence
                  over the determination of recovery amounts or the mechanism
                  for distribution.
                </li>
                <br />
                <li>
                  <span className="fw-bold">
                    Any adverse development in the real estate sector would
                    negatively affect our results of operations.
                  </span>
                  <br />A substantial portion of our credit portfolio is exposed
                  to the real estate sector, through investments in structured
                  credit, mortgage bonds, asset-backed securities collateralized
                  by mortgages both commercial and residential. <br />
                  We acknowledge that our mortgage-linked instruments are
                  directly linked to the health of India’s real estate sector.
                  Any significant downturn in property values may impair our
                  ability to recover collateral or fully recoup principal and
                  interest in case of default. Project delays could further
                  impact collateral value and our enforcement capabilities.
                  Failure to realize expected collateral value may result in
                  material adverse effects on our business, operations, cash
                  flows, and financial health. <br />
                  While the SARFAESI Act enhances creditor rights by expediting
                  property foreclosure after a 60- day notice period for
                  non-performing loans, uncertainties persist regarding full
                  collateral realization, including potential delays in
                  enforcement actions, legal proceedings, market downturns,
                  collateral perfection issues, and fraudulent borrower
                  transfers. <br />
                  Additionally, we recognize the significant impact of the Real
                  Estate Regulation and Development Act, 2016 (RERA Act) on the
                  sector. RERA Act provisions, including project registration,
                  enhanced disclosures, and penalties for non-compliance, are
                  being progressively implemented nationwide. Compliance may
                  necessitate increased resources and regulatory actions,
                  potentially impeding sector growth and adversely affecting our
                  business operations and financial position.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    SMEs to which we provide optionally convertible debentures
                    and preference shares (OCD and OCPS) may not perform as
                    expected and we may not be able to control the
                    non-performance of such businesses.
                  </span>
                  <br />
                  We extend loans to optionally convertible debentures and
                  preference shares (OCD and OCPS) and other business loan
                  portfolio. We do not manage, operate, or control these
                  businesses or entities and have no control over their
                  functions or operations. The decisions made by these
                  businesses&#39; management, majority shareholders, or
                  management teams may not align with our interests and could
                  lead to adverse outcomes. The repayment of loans depends
                  significantly on the borrower entity&#39;s management team.
                  Actions taken by our customers&#39; management may result in
                  significant losses, impacting their ability to repay our loans
                  and our financial performance.&quot;
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We are exposed to fluctuations in the market values of our
                    investment and other asset portfolio.
                  </span>
                  <br />
                  Financial market instability can harm global economic
                  activity, including in India. Credit and capital market
                  deterioration may lead to volatility in investment earnings
                  and impairments to our asset portfolio, including assets in
                  our balance sheet management unit crucial for liquidity. The
                  value of investments is subject to various uncontrollable
                  factors, such as domestic and international economic and
                  political conditions, inflation expectations, unforeseen
                  events like the COVID-19 pandemic, and RBI monetary policies.
                  Any investment value decline could adversely affect our
                  financial standing
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    If the investments made by the funds we advise or manage
                    perform poorly we will suffer a decline in our revenue and
                    earnings, we may be obligated to repay certain incentive
                    fees we have previously received to the third-party
                    investors in such funds, and our ability to raise capital
                    for future funds may be adversely affected.
                  </span>
                  <br />
                  Our revenue is generated from fees based on capital committed
                  to or drawn-down from funds, incentive fees, carried interest,
                  gains or losses on investments, and transaction fees. Poor
                  investment performance may reduce our revenues and hinder
                  future fund raising. Excess carried interest may require
                  repayment to investors.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We face the threat of fraud and cyber-attacks, such as
                    hacking, phishing, trojans and other threats, attempting to
                    exploit our network to disrupt services to customers and/or
                    theft of sensitive internal company data or customer
                    information. This may cause damage to our reputation and
                    adversely impact our business and financial results.
                  </span>
                  <br />
                  Our operational controls may not fully prevent adverse impacts
                  from cyber fraud, errors, hacking, and system failures. Our
                  mobile and internet-based customer interfaces may be
                  vulnerable to hacking or compromise by third parties, leading
                  to thefts and losses. Cyber threats include phishing, hacking,
                  data theft, and persistent network attacks, with frequencies
                  generally increasing. Such threats could materially affect our
                  business, finances, and reputation. <br />
                  Significant system breakdowns or failures could harm revenue
                  and customer satisfaction. Despite having a remote disaster
                  recovery site, its failure or delayed operation could disrupt
                  our operations and damage our reputation and finances. <br />
                  Customers might blame us for cyber incidents and terminate
                  their accounts, exposing us to regulatory scrutiny and legal
                  liability. Our use of cloud environments poses additional
                  security risks, as cloud providers&#39; failures to protect
                  our data could harm our business, finances and reputation.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We may experience difficulties in expanding our business,
                    organically and inorganically, into new regions and markets
                    in India and overseas.
                  </span>{" "}
                  <br />
                  We are actively exploring growth opportunities to expand our
                  business both domestically and internationally, including
                  through acquisitions, mergers, joint ventures, and strategic
                  investments. However, there are no guarantees regarding our
                  ability to identify suitable targets, secure financing, or
                  ensure the profitability of such ventures. Integration
                  challenges, regulatory differences, and competition in new
                  markets may pose risks to our expansion efforts. Failure to
                  effectively manage these challenges could have adverse effects
                  on our business prospects, financial performance, and overall
                  condition.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    Changes to laws and regulations across the multiple
                    jurisdictions we operate in, may materially and adversely
                    affect our business and financial performance.
                  </span>{" "}
                  <br />
                  Changes in laws, regulations, or interpretations thereof, both
                  domestically and internationally, may significantly impact our
                  business and financial performance. New regulations could
                  necessitate additional approvals, licenses, or compliance
                  measures, potentially imposing burdensome conditions on our
                  operations. Uncertainties surrounding the implementation of
                  these changes may further exacerbate adverse effects on our
                  business, including but not limited to, results of operations,
                  cash flows, and financial condition.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    Certain Directors hold Equity Shares in the Issuer and are
                    therefore interested in the Issuer’s performance in addition
                    to their remuneration and reimbursement of expenses.
                  </span>{" "}
                  <br />
                  Certain Directors have vested interests in the Issuer beyond
                  standard compensation and expense reimbursement, tied to their
                  shareholding in the Issuer. Consequently, these Directors
                  wield considerable influence, potentially determining outcomes
                  of shareholder voting, whether requiring simple or special
                  majority. Such control may enable these Directors to pursue
                  actions that diverge from the Issuer&#39;s or minority
                  shareholders&#39; best interests, creating potential conflicts
                  of interest.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We may not be able to manage the growth of our business
                    effectively or continue to grow our business at a similar
                    rate to what we have experienced in the past.
                  </span>{" "}
                  <br />
                  Despite significant revenue growth, we may encounter
                  challenges in effectively managing this expansion and
                  achieving desired profitability within anticipated timelines.
                  Failure to do so could adversely affect our business and
                  operational results. Managing relationships with a larger
                  customer base, service providers, lenders, and other
                  stakeholders will be crucial as we expand. To handle growth,
                  timely implementation and enhancement of operational systems,
                  processes, and controls are imperative. Failure to do so may
                  impact customer satisfaction, hinder recruitment efforts, and
                  impede business operations. Our growth sustainability hinges
                  on retaining key management personnel, effective risk
                  management, and managerial training to address emerging
                  challenges. External factors, including economic conditions,
                  regulatory policies, inflation, and competition, also
                  influence our growth trajectory. We cannot guarantee the
                  adequacy of our existing or future management, operational,
                  and financial systems to support future operations or foster
                  beneficial business relationships.
                </li>{" "}
                <br />
                <li>
                  <span className="fw-bold">
                    We require certain statutory and regulatory approvals and
                    licenses for conducting our business. Failure to obtain or
                    renew approvals, licenses, registrations and permits to
                    operate our business in a timely manner, or at all, may
                    adversely affect our business, financial condition, results
                    of operations and cash flows.
                  </span>
                  &nbsp;We are obligated to secure and uphold various approvals
                  and licenses from governmental and regulatory bodies,
                  including the RBI and SEBI, for our business operations.
                  However, we cannot guarantee that these approvals will not be
                  subject to suspension or revocation due to non-compliance or
                  alleged non-compliance. Additionally, there is no assurance of
                  timely approval or renewal by the relevant authorities.
                  Failure to obtain or renew such approvals may result in
                  non-compliance with applicable laws and regulatory action.
                </li>{" "}
                <br />
                <h6 className="fw-bolder pt-2 text-base">EXTERNAL RISKS</h6>
                <h6 className="fw-bolder pt-2 text-base">
                  Risks Relating to India
                </h6>
                <br />
                <li>
                  <span className="fw-bold">
                    Political, economic or other factors that are beyond our
                    control may have an adverse effect on our business and
                    results of operations.
                  </span>
                  <br />
                  The performance and growth of our business are contingent upon
                  prevailing economic, political, and market conditions, both
                  domestically and globally. Situated solely in India with all
                  assets and employees located therein, our operations are
                  inherently influenced by economic fluctuations within the
                  country. Various external factors pose risks to our business,
                  including but not limited to:
                  <ul>
                    <li>
                      Interest rate hikes, potentially limiting capital access
                      and increasing borrowing costs, thereby constraining our
                      growth and profitability.
                    </li>
                    <li>
                      Political instability arising from governmental or
                      economic policy changes, potentially impacting economic
                      conditions and leading to social unrest.
                    </li>
                    <li>
                      Changes in government or deregulation policies may
                      adversely affect economic conditions and our specific
                      business operations.
                    </li>
                    <li>
                      Civil unrest, terrorist activities, regional conflicts, or
                      natural disasters could disrupt operations.
                    </li>
                    <li>
                      Regulatory or economic developments within or affecting
                      India&#39;s financial services sectors.
                    </li>
                    <li>
                      Contagious diseases like COVID-19 and other influenza
                      strains could disrupt economic activity.
                    </li>
                  </ul>
                  Any slowdown in the Indian economy or specific sectors thereof
                  may negatively affect our business, financial condition, and
                  results of operations. Our performance is intricately linked
                  to the overall economic performance of India and the regional
                  markets in which we operate.
                </li>
                <br />
                <li>
                  <span className="fw-bold">
                    If the rate of inflation in India increases, our financial
                    condition and results of operations may be adversely
                    affected.
                  </span>{" "}
                  <br />
                  Inflation volatility poses a risk to our business,
                  particularly in India where historically high inflation has
                  led to increased interest rates and operating costs, including
                  finance costs and expenses such as salaries. This could also
                  slow down economic growth and impact credit expansion,
                  potentially hampering our business growth and profitability.
                  Difficulty in cost estimation and control may arise due to
                  inflation fluctuations, potentially leading to increased
                  operating expenses that cannot be fully passed on to
                  customers, thereby adversely affecting our financial
                  condition. Although past government measures have aimed to
                  mitigate high inflation, future trends remain uncertain, with
                  the possibility of adverse effects on our financial condition
                  and operational results.
                </li>
                <br />
              </ol>
            </p>
          </div>
        </>
      ),
    },
  ];

  const [selectedCard, setSelectedCard] = useState(LegalDocumentsAPI[0]);

  const handleSelectCard = (card) => {
    setSelectedCard(card);
  };
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1800px] mx-auto">
        <div className="flex w-[90%] mx-auto">
          <h6 className="mt-4 w-full text-sm md:text-base">
            <a href="/">
              <span className="header-link">Home</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="#">
              <span className="text-dark text-decoration-underline">
                {selectedCard.title}
              </span>
            </a>
            <hr className="text-dark mb-1" />
          </h6>
        </div>

        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap gap-4 justify-start mt-4 ">
            {/* Left Section */}
            <div className="w-full lg:min-w-[270px] lg:w-[17%]">
              <div className="py-4 px-4  h-fit border hover:shadow-sm rounded-lg ">
                <div className="font-bold">
                  <div className="flex justify-center md:justify-between items-center flex-wrap lg:flex-col gap-1.5 mx-auto">
                    {LegalDocumentsAPI.map((card, index) => (
                      <h6
                        key={index}
                        onClick={() => handleSelectCard(card)}
                        className={`${
                          selectedCard.id === card.id ? "bg-[#DFEFFA]" : ""
                        } text-sm border p-2 min-w-full md:min-w-[200px] lg:min-w-[215px] rounded-md flex items-center justify-center cursor-pointer hover:bg-[#DFEFFA] duration-200 text-black`}
                      >
                        {card.title}
                      </h6>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="justify-center border rounded-lg shadow-md w-full lg:w-[68%] xl:w-[73%] 2xl:w-[77%] p-[1%]">
              <div className="col-12">{selectedCard.content}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalDocument;

import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";

function AccordionItem() {
  const productFAQ = [
    {
      id: 0,
      ques: "Do WALR bonds have different series open for subscription simultaneously?",
      ans: "No, only the current series is available for purchase from the ‘WALR Exchange’, from the 1st to the 15th day of each corresponding quarter. However, clients can Sell/Redeem any of the other 15 earlier series of WALR outstanding (each named after its original issue date e.g Q2FY21, Q3FY21, ..... Q3FY25).",
    },
    {
      id: 1,
      ques: "Why do I need to pay an extra ‘Option Price’ per Unit of the current series of WALR, to obtain the Downside Cover?",
      ans: "The cover is an optional add-on ensures that throughout the entire 4 years tenure of 	the corresponding series, the Coupon rate on your WALR is automatically revised upwards if 	the external benchmark rises, but never falls even if the reference interest rate (published by 	the RBI 	falls. Effectively, the holder of a WALR series with the add-on Option is always entitled to the maximum interest rate for Coupon payouts, that occurs between the Issue 	date and the present date.",
    },
    {
      id: 2,
      ques: "What is the tenure of a WALR series and is the redemption amount automatically paid back to the investor?",
      ans: "Each WALR is automatically redeemed, and the entire principal credited to the Clients MONEY WALLET, on the ‘Maturity Date’ that is 4 (four) years from the Date of Issue 		(specified against all the 16 series). Consequently, a new current series is opened for subscription to investors at the start of every quarter.",
    },
    {
      id: 3,
      ques: "Are RESIDEX bonds divided into city-based indexes, each linked to the real estate prices of that particular city?",
      ans: "Yes, at any point of time, there are a total of 16 different city-based indexes outstanding to subscribers of SCDND, each named after its reference city e.g., Chennai, Vadodara, ..... Bengaluru. Unlike WALR, these set of city-based indexes do not change with time (no new cities are added/removed to the basket).",
    },
    {
      id: 4,
      ques: "What is the difference between the two Instrument Classes ‘Index’ and ‘Futures’ with respect to a city-wise RESIDEX?",
      ans: "An ‘Index’ unit is purchased/redeemed in the Auction at the reference NAV (published 	by NHB), has no maturity date, and earns 3% p.a. dividend. A ‘Futures’ unit is similar to an 	(European) at- the-money Call on the underlying Index. It is purchased at the ‘3 mth 		Premium’ price and the payout upon expiry is the Latest NAV minus previous quarter’s NAV 	of the underlying city (zero if Latest NAV < previous quarter’s Index value).",
    },
    {
      id: 5,
      ques: "What is the duration of a ‘Futures’ DeAs and can I prematurely redeem or Sell them?",
      ans: "The duration of a ‘Futures’ contract is 3 months from the date of issue I.e., at the last 	business day of the corresponding quarter. The payout value is automatically transferred to 	the registered Bank A/C of the holder upon expiry. However, a ‘Futures’ unit cannot be 		prematurely redeemed on the PRIMARY MARKET, and they are not transferrable Peer-2-Peer 	at all (therefore no buying or selling on the TRADING PORTAL).",
    },
    {
      id: 6,
      ques: "Where can I find the detailed performance history of your financial products?",
      ans: "Each series/city of WALR and RESIDEX have their historical performance shown on this 	page (with Rs. 1 lakh as the principal at start), starting from their inception to the present 	date. Furthermore, their Growth (compounded annual rate of return) and Volatility (standard deviation of their value) is also displayed next to the graph.",
    },
    {
      id: 7,
      ques: "How can I modify or cancel the Buy/Sell orders placed on the Auction Exchange?",
      ans: "Any order of WALR and RESIDEX placed during the auction session can be modified by clicking on the ‘Open Orders tab’ before the closing of trading window, i.e. 6:30 pm. Note that you can only alter the “Bid Unit” entry of an order, but cannot change the type of Security or flip from Buy to Sell. Alternatively, you can cancel the orders and start fresh. Please note that all open orders are automatically executed after the closing of the trading session if not cancelled by the User.",
    },
    {
      id: 8,
      ques: "How easily can I withdraw my money from your portal? How is my MONEY WALLET linked to my Buy/Sell transactions in the portal?",
      ans: "The Users MONEY Blockchain is directly linked to the WALR and RESIDEX Blockchains through Smart Contracts, and the funds are debited (on Buy orders) and credited (on Sell orders) automatically to their wallet balances upon execution. Subsequently, the ‘Cash’ lying in the MONEY WALLET can be withdrawn through the portal itself, to the registered Bank A/C of the User at all times through OTP verification.",
    },
    {
      id: 9,
      ques: "What is the Lock-in period, Withdrawal Penalties or Exit Load applicable in case of premature redemption of my DeAs?",
      ans: "There is NO Lock-in period for your funds, zero penalties upon premature withdrawal and Nil charges equivalent to an Exit Load upon redemption/sale of your DeAs through our portal. Furthermore, the number of redemption orders that can be placed through the PRIMARY MARKET, or withdrawal of Cash from MONEY WALLET, is unlimited and not subject to any brokerage, tax or additional processing charges etc.",
    },
    {
      id: 10,
      ques: "At what price are my principal amount in WALR and RESIDEX redeemed at if I sell them in the PRIMARY MARKET module?",
      ans: "Your WALR DeAs of any series are redeemed at face value of Rs. 1000/-, plus the residual value of ‘Option Price’ of the corresponding series (as noted on the ‘WALR Exchange tab’) if the Downside Cover was availed on the DeAs (at the time of issue). Your RESIDEX DeAs of Class ‘Index’ are redeemed at the Latest NAV (most recent data updated by NHB) of the corresponding city. Please note that the broken period Interest or Dividend (accrued from the start of the current quarter to the date of premature redemption) are NOT paid to the holders.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="max-w-[90%] mx-auto border rounded-lg p-3 relative transition-all duration-500 ease-in-out mt-20">
      <h2 className="font-bold text-lg bg-white text-black absolute -top-4 px-2 w-fit">
        Frequently Asked Questions
      </h2>
      <div className="mt-3 ">
        <div className="flex flex-col gap-0">
          {productFAQ.map((data, index) => (
            <div className="text-black" key={index}>
              <h2
                className="flex flex-row justify-between lg:ṭitems-center text-xs lg:text-base font-semibold p-3 cursor-pointer "
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex lg:items-center gap-2">
                  <span className="text-base md:text-lg mt-0.5 md:mt-0">
                    <BsQuestionCircleFill />
                  </span>
                  <span
                    className="font-semibold px-2 duration-500 ease-in-out"
                    style={{
                      color: openIndex === index ? "#339dfa" : "#212121",
                    }}
                  >
                    {data.ques}
                  </span>
                </div>
                <span className="duration-1000 ease-in-out">
                  {openIndex === index ? (
                    <IoIosArrowDropup
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  ) : (
                    <IoIosArrowDropdown
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  )}
                </span>
              </h2>
              <div
                style={{
                  maxHeight: openIndex === index ? "300px" : "0",
                }}
                className={`ml-7  border-l-2 border-[#339dfa]  overflow-hidden  duration-700 ease-in-out ${
                  openIndex === index ? "max-h-screen " : "max-h-0 "
                }`}
              >
                <p className=" py-1 pl-3 pr-4 text-[#212121] font-medium text-xs lg:text-base">
                  {data.ans}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccordionItem;

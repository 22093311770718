import React, { useEffect, useRef } from "react";
import "./Blogs.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const App = () => {
  const sectionRef = useRef(null);
  const triggerRef = useRef(null);
  const stepRefs = useRef([]);
  const stepHead = useRef([]);
  const textContainerRefHead = useRef(null);
  const textContainerRef1 = useRef(null);
  const textContainerRef2 = useRef(null);
  const textContainerRef3 = useRef(null);
  const textContainerRef3Head = useRef(null);
  const textContainerRef4 = useRef(null);
  const textContainerRef4Head = useRef(null);
  const textContainerRef5 = useRef(null);
  const textContainerRef5Head = useRef(null);
  const textContainerRef6 = useRef(null);
  const textContainerRef6Head = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const pin = (widthLength) => {
      gsap.fromTo(
        sectionRef.current,
        {
          translateX: 0,
        },
        {
          translateX: widthLength,
          ease: "none",
          duration: 1,
          scrollTrigger: {
            trigger: triggerRef.current,
            start: "top top", // Start pinning when the bottom of the trigger hits the bottom of the viewport
            end: "2000 top", // End the animation when the bottom of the trigger reaches the top of the viewport
            scrub: 1,
            pin: true,
            pinSpacing: false, // Ensures the pinned element doesn't take up extra space
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 768px) ": () => {
        pin("-650vw");
      },

      "(max-width: 765px)": () => {
        pin("-1250vw");
      },
    });

    // Create animations for each step
    stepRefs.current.forEach((step, stepHead, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 80%",
            end: "top 60%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // const fade = gsap.fromTo(
    //   stepHead.current,
    //   { opacity: 1, y: 0 },
    //   {
    //     opacity: 0,
    //     y: -100,
    //     duration: 15,
    //     ease: "power2.in",
    //     scrollTrigger: {
    //       trigger: stepHead.current,
    //       start: "top -70%",
    //       end: "top -100%",
    //       scrub: 1,
    //       markers: false,
    //       toggleActions: "play none none reverse",
    //     },
    //   }
    // );

    const fade = (headingFadeIn, headingFadeOut) => {
      gsap.fromTo(
        stepHead.current,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: -100,
          duration: 15,
          ease: "power2.in",
          scrollTrigger: {
            trigger: stepHead.current,
            start: headingFadeIn,
            end: headingFadeOut,
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        fade("top -80%", "top -100%");
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        fade("top -100%", "top -120%");
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        fade("top -110%", "top -130%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        fade("top -130%", "top -150%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        fade("top -150%", "top -170%");
      },

      "(max-width: 765px)": () => {
        fade("top -190%", "top -210%");
      },
    });

    const createAnimationsp1 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef1.current.querySelectorAll(".p1"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef1.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef1.current.querySelectorAll(".p1"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef1.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp1("top 85%", "top 70%", "top 70%", "top 50%");
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp1("top 80%", "top 65%", "top 65%", "top 45%");
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp1("top 80%", "top 60%", "top 60%", "top 40%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp1("top 75%", "top 50%", "top 50%", "top 30%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp1("top 80%", "top 50%", "top 50%", "top 30%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp1("top 75%", "top 45%", "top 45%", "top 15%");
      },
    });

    const createAnimationsp2 = (
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef2.current.querySelectorAll(".p2"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef2.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef2.current.querySelectorAll(".p2"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef2.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp2("top 65%", "top 50%", "top 50%", "top 30%");
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp2("top 60%", "top 40%", "top 40%", "top 20%");
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp2("top 55%", "top 35%", "top 35%", "top 15%");
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp2("top 50%", "top 25%", "top 25%", "top 5%");
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp2("top 50%", "top 20%", "top 20%", "top 0%");
      },

      "(max-width: 765px)": () => {
        createAnimationsp2("top 45%", "top 5%", "top 5%", "top -25%");
      },
    });

    const createAnimationsp3 = (
      startTriggerBubble,
      startTriggerHead,
      endTriggerHead,
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef3Head.current.querySelectorAll(".p3Head"),
        { x: startTriggerBubble },
        {
          x: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0,
          scrollTrigger: {
            trigger: textContainerRef3Head.current,
            start: startTriggerHead,
            end: endTriggerHead,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef3.current.querySelectorAll(".p3"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef3.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef3.current.querySelectorAll(".p3"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef3.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp3(
          "-970",
          "top 54%",
          "top 52%",
          "top 50%",
          "top 35%",
          "top 35%",
          "top 15%"
        );
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp3(
          "-970",
          "top 47%",
          "top 45%",
          "top 45%",
          "top 15%",
          "top 15%",
          "top -5%"
        );
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp3(
          "-970",
          "top 48%",
          "top 46%",
          "top 35%",
          "top 10%",
          "top 10%",
          "top -10%"
        );
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp3(
          "-970",
          "top 48%",
          "top 46%",
          "top 25%",
          "top -5%",
          "top -5%",
          "top -25%"
        );
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp3(
          "-970",
          "top 48%",
          "top 46%",
          "top 20%",
          "top -5%",
          "top -5%",
          "top -25%"
        );
      },

      "(max-width: 765px)": () => {
        createAnimationsp3(
          "-970",
          "top 48%",
          "top 46%",
          "top 10%",
          "top -30%",
          "top -30%",
          "top -70%"
        );
      },
    });

    const createAnimationsp4 = (
      startTriggerBubble,
      startTriggerHead,
      endTriggerHead,
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef4Head.current.querySelectorAll(".p4Head"),
        { x: startTriggerBubble },
        {
          x: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0,
          scrollTrigger: {
            trigger: textContainerRef3Head.current,
            start: startTriggerHead,
            end: endTriggerHead,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef4.current.querySelectorAll(".p4"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef4.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef4.current.querySelectorAll(".p4"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef4.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp4(
          "-970",
          "top 35%",
          "top 33%",
          "top 35%",
          "top 15%",
          "top 15%",
          "top -5%"
        );
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp4(
          "-970",
          "top 18%",
          "top 16%",
          "top 15%",
          "top -10%",
          "top -10%",
          "top -30%"
        );
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp4(
          "-1070",
          "top 18%",
          "top 16%",
          "top 10%",
          "top -15%",
          "top -15%",
          "top -35%"
        );
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp4(
          "-1070",
          "top 18%",
          "top 16%",
          "top 0%",
          "top -25%",
          "top -25%",
          "top -45%"
        );
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp4(
          "-1070",
          "top 18%",
          "top 16%",
          "top -5%",
          "top -35%",
          "top -35%",
          "top -55%"
        );
      },

      "(max-width: 765px)": () => {
        createAnimationsp4(
          "-1070",
          "top 18%",
          "top 16%",
          "top -30%",
          "top -70%",
          "top -70%",
          "top -110%"
        );
      },
    });

    const createAnimationsp5 = (
      startTriggerBubble,
      startTriggerHead,
      endTriggerHead,
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef5Head.current.querySelectorAll(".p5Head"),
        { x: startTriggerBubble },
        {
          x: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0,
          scrollTrigger: {
            trigger: textContainerRef3Head.current,
            start: startTriggerHead,
            end: endTriggerHead,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef5.current.querySelectorAll(".p5"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef5.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef5.current.querySelectorAll(".p5"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef5.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp5(
          "-970",
          "top 15%",
          "top 13%",
          "top 15%",
          "top -5%",
          "top -5%",
          "top -25%"
        );
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp5(
          "-970",
          "top -12%",
          "top -14%",
          "top -5%",
          "top -30%",
          "top -30%",
          "top -50%"
        );
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp5(
          "-970",
          "top -12%",
          "top -14%",
          "top -15%",
          "top -35%",
          "top -35%",
          "top -55%"
        );
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp5(
          "-970",
          "top -12%",
          "top -14%",
          "top -25%",
          "top -50%",
          "top -50%",
          "top -70%"
        );
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp5(
          "-970",
          "top -12%",
          "top -14%",
          "top -35%",
          "top -65%",
          "top -65%",
          "top -90%"
        );
      },

      "(max-width: 765px)": () => {
        createAnimationsp5(
          "-970",
          "top -12%",
          "top -14%",
          "top -70%",
          "top -120%",
          "top -120%",
          "top -160%"
        );
      },
    });

    const createAnimationsp6 = (
      startTriggerBubble,
      startTriggerHead,
      endTriggerHead,
      startTrigger,
      endTrigger,
      outStartTrigger,
      outEndTrigger
    ) => {
      gsap.fromTo(
        textContainerRef6Head.current.querySelectorAll(".p6Head"),
        { x: startTriggerBubble },
        {
          x: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0,
          scrollTrigger: {
            trigger: textContainerRef6Head.current,
            start: startTriggerHead,
            end: endTriggerHead,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef6.current.querySelectorAll(".p6"),
        { opacity: 0, y: 150 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef6.current,
            start: startTrigger,
            end: endTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );

      gsap.fromTo(
        textContainerRef6.current.querySelectorAll(".p6"),
        { y: 0 },
        {
          opacity: 0,
          y: 150,
          duration: 1,
          ease: "power2.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: textContainerRef6.current,
            start: outStartTrigger,
            end: outEndTrigger,
            scrub: 1,
            markers: false,
          },
        }
      );
    };

    ScrollTrigger.matchMedia({
      "(min-width: 1736px)": () => {
        createAnimationsp6(
          "-970",
          "top -10%",
          "top -17%",
          "top -10%",
          "top -35%",
          "top -55%",
          "top -50%"
        );
      },

      "(min-width: 1536px) and (max-width: 1736px)": () => {
        createAnimationsp6(
          "-970",
          "top -32%",
          "top -35%",
          "top -30%",
          "top -50%",
          "top -50%",
          "top -70%"
        );
      },

      "(min-width: 1280px) and (max-width: 1536px)": () => {
        createAnimationsp6(
          "-970",
          "top -12%",
          "top -14%",
          "top -35%",
          "top -60%",
          "top -60%",
          "top -80%"
        );
      },

      "(min-width: 1024px) and (max-width: 1280px)": () => {
        createAnimationsp6(
          "-970",
          "top -12%",
          "top -14%",
          "top -50%",
          "top -75%",
          "top -75%",
          "top -90%"
        );
      },

      "(min-width: 768px) and (max-width: 1024px)": () => {
        createAnimationsp6(
          "-970",
          "top -12%",
          "top -14%",
          "top -65%",
          "top -90%",
          "top -90%",
          "top -120%"
        );
      },

      "(max-width: 765px)": () => {
        createAnimationsp6(
          "-970",
          "top -12%",
          "top -14%",
          "top -110%",
          "top -165%",
          "top -165%",
          "top -200%"
        );
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section className="overflow-hidden p-0">
      <div
        ref={triggerRef}
        className="container h-[320vh] md:h-[280vh] lg:h-[260vh] xl:h-[240vh] 2xl:h-[220vh] flex w-full  px-0 overflow-x-hidden "
      >
        <div
          className="header-title w-full absolute max-md:top-20 md:top-32 flex flex-col  text-center  leading-tight  font-semibold tracking-wide text-gray-800 md:p-2   mb-1  "
          ref={(el) => (stepRefs.current[0] = el)}
        >
          <p
            ref={stepHead}
            className="font-bold leading-tight w-full  lg:leading-snug  mx-0 text-[15px]  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]"
          >
            A Roadmap to building the 
            <span className="text-sky-400 "> Technological <br /> Backbone </span> of
            our DeAs portal.
          </p>
        </div>
        <div
          ref={sectionRef}
          className="h-[95vh] md:h-[100vh] relative items-end xl:pb-10 2xl:pb-16  w-[1250vw] md:w-[650vw] max-md:-left-[1150px] max-lg:-left-[700px] max-xl:-left-96 flex max-md:scale-[40%] max-lg:scale-[65%] max-xl:scale-75 max-2xl:scale-90"
        >
          {/* section 1 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#1c5050] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#1c5050] lg:ml-10 xl:ml-20 2xl:ml-80"
            ref={(el) => (stepRefs.current[1] = el)}
          >
            <div ref={textContainerRefHead}>
              <div className="p1-head md:w-20 md:h-20 h-36 w-36 bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] border-none border-[#1c5050] z-10 overflow-hidden">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className="w-12 h-12 absolute -top-6 fill-white"
                >
                  <path d="m14.854,18.854l-2.146,2.146,2.146,2.146c.195.195.195.512,0,.707-.098.098-.226.146-.354.146s-.256-.049-.354-.146l-2.146-2.146-2.146,2.146c-.098.098-.226.146-.354.146s-.256-.049-.354-.146c-.195-.195-.195-.512,0-.707l2.146-2.146-2.146-2.146c-.195-.195-.195-.512,0-.707s.512-.195.707,0l2.146,2.146,2.146-2.146c.195-.195.512-.195.707,0s.195.512,0,.707Zm5.854,2.146l2.146-2.146c.195-.195.195-.512,0-.707s-.512-.195-.707,0l-2.146,2.146-2.146-2.146c-.195-.195-.512-.195-.707,0s-.195.512,0,.707l2.146,2.146-2.146,2.146c-.195.195-.195.512,0,.707.098.098.226.146.354.146s.256-.049.354-.146l2.146-2.146,2.146,2.146c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512,0-.707l-2.146-2.146Zm-13.854-2.854c-.195-.195-.512-.195-.707,0l-2.146,2.146-2.146-2.146c-.195-.195-.512-.195-.707,0s-.195.512,0,.707l2.146,2.146-2.146,2.146c-.195.195-.195.512,0,.707.098.098.226.146.354.146s.256-.049.354-.146l2.146-2.146,2.146,2.146c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512,0-.707l-2.146-2.146,2.146-2.146c.195-.195.195-.512,0-.707Zm11.146-6.646c0,1.93-1.57,3.5-3.5,3.5h-5c-1.93,0-3.5-1.57-3.5-3.5v-3c0-1.58,1.059-2.903,2.5-3.337v-1.663c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5v1.663c1.441.434,2.5,1.757,2.5,3.337v3Zm-8.5-6.5h5v-1.5c0-1.378-1.121-2.5-2.5-2.5s-2.5,1.122-2.5,2.5v1.5Zm7.5,3.5c0-1.378-1.121-2.5-2.5-2.5h-5c-1.379,0-2.5,1.122-2.5,2.5v3c0,1.378,1.121,2.5,2.5,2.5h5c1.379,0,2.5-1.122,2.5-2.5v-3Zm-5,.5c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Z" />
                </svg> */}
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  p-1"
                  src="./images/lock.png"
                  alt=""
                />
              </div>
              <div className="p1-head relative max-md:-top-72 md:-top-44 duration-300 ease-out text-4xl md:text-lg lg:text-xl text-[#008080] font-semibold">
                Phase 1
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#1c5050]"
              ref={textContainerRef1}
            >
            <div className="p1 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
            <div className="p1 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
            <div className="p1 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
            <div className="p1 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p1 text-3xl md:text-sm  max-md:mt-36 bg-white lg:text-base font-semibold  leading-relaxed w-96 md:w-60 mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#008080] ">1.1</span>
                Informative pages (static), UI/UX design and Frontend
                architecture.
              </p>
              <p className="p1 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.2</span>
                OTP-based User Registration, Login and database encryption.
              </p>

              <p className="p1 text-3xl md:text-sm  max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.3</span>
                PAN/Aadhaar based KYC verification with external APIs.
              </p>
              <p className="p1 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col  px-2">
                <span className="font-extrabold text-[#008080]">1.4</span>
                Secure authentication framework and Local Storage encryption.
              </p>
            </div>
          </div>
          {/* section 2 */}
          <div
            className="w-max h-[400px]  mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#923c34] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#923c34]"
            ref={(el) => (stepRefs.current[2] = el)}
          >
            <div ref={textContainerRefHead}>
            <div className="md:w-20 md:h-20 h-36 w-36 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px]  border-none  border-[#923c34] z-10">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                className="w-12 h-12 absolute -top-6 fill-white"
              >
                <path d="M24,5c0-1.65-1.35-3-3-3H3C1.35,2,0,3.35,0,5V22H2v2h2v-2H20v2h2v-2h2V5Zm-2,15H2v-3h2v-2H2v-6h2v-2H2v-2c0-.55,.45-1,1-1H21c.55,0,1,.45,1,1v15ZM13,6c-3.31,0-6,2.69-6,6s2.69,6,6,6,6-2.69,6-6-2.69-6-6-6Zm0,10c-2.21,0-4-1.79-4-4s1.79-4,4-4c1.86,0,3.41,1.28,3.86,3h-2.86v2h2.86c-.45,1.72-2,3-3.86,3Z" />
              </svg> */}
              <img
                className="md:w-20 md:h-20 h-36 w-36 absolute  p-1"
                src="./images/folder.png"
                alt=""
              />
            </div>
            <div className="relative max-md:-top-72 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#FF6F61] font-semibold ">
              Phase 2
            </div>
            </div>
            <div
              className="col flex  ml-6  text-[#923c34]"
              ref={textContainerRef2}
            >
              <div className="p2 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p2 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p2 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p2 h-[550px] md:h-52 w-0.5  bg-black absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p2 text-3xl md:text-sm max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col pl-1 pr-2">
                <span className="p-2 font-extrabold text-[#FF6F61]">2.1</span>
                User Bank A/C linking with Wallet and fraud prevention system.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.2</span>
                Integration of Escrow mechanism with collateralized securities.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-36 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.3</span>
                Creation of Transaction - Ownership - Interest Payout data
                structure.
              </p>
              <p className="p2 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 bg-white lg:text-base font-semibold leading-relaxed w-96 md:w-60 mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#FF6F61]">2.4</span>
                Auction Exchange module for purchase, revision and redemption of
                DeAs.
              </p>
            </div>
          </div>
          {/* section 3 */}
          <div
            className="w-max h-[400px] mt-[10px] group relative flex flex-col items-start pr-20  border-t-[2px] border-[#807432] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#a7a7a7] before:border-t-[2px] before:border-dashed before:border-[#ffffff] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#a7a7a7] after:border-t-[2px] after:border-[#807432]"
            ref={(el) => (stepRefs.current[3] = el)}
          >
            <div className="absolute -top-24   w-[580px] h-20  ">
              <div className="float-right h-max relative top-7 left-14 flex flex-col items-center justify-end">
                <p className="font-bold text-xl text-[#807432]">We are here</p>
                <div class="ping z-10"></div>
              </div>
            </div>

            <div ref={textContainerRef3Head}>
              <div className="p3Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px]  border-none  border-[#807432] ">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className="w-12 h-12 absolute -top-6 fill-white"
                >
                  <path d="m21,23c0,.553-.448,1-1,1s-1-.447-1-1c0-2.206-1.794-4-4-4h-6c-2.206,0-4,1.794-4,4,0,.553-.448,1-1,1s-1-.447-1-1c0-3.309,2.691-6,6-6h6c3.309,0,6,2.691,6,6Zm1-15.5v2c0,.827-.673,1.5-1.5,1.5h-.5c0,2.206-1.794,4-4,4h-8c-2.206,0-4-1.794-4-4h-.5c-.827,0-1.5-.673-1.5-1.5v-2c0-.827.673-1.5,1.5-1.5h.5c0-2.206,1.794-4,4-4h3v-1c0-.553.448-1,1-1s1,.447,1,1v1h3c2.206,0,4,1.794,4,4h.5c.827,0,1.5.673,1.5,1.5Zm-4-1.5c0-1.103-.897-2-2-2h-8c-1.103,0-2,.897-2,2v5c0,1.103.897,2,2,2h8c1.103,0,2-.897,2-2v-5Zm-8.5,1c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm5,0c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Z" />
                </svg> */}
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  "
                  src="./images/robot-head.png"
                  alt=""
                />
              </div>
              <div className="p3Head relative max-md:-top-72 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#f1db5a] font-semibold">
                Phase 3
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#807432]"
              ref={textContainerRef3}
            >
              <div className="p3 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p3 h-[550px] md:h-52 w-0.5  bg-black absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p3 h-72 md:h-24 w-0.5  bg-black absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p3 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p3 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#f1db5a]">3.1</span>
                Client Fees calculation, dynamic Invoice and Contract Note
                generation.
              </p>
              <p className="p3 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.2</span>
                Incorporation of User PAN linked Form 16As (from TRACES).
              </p>
              <p className="p3 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.3</span>
                Frontend development of the (machine-learning based) Risk-Sensor
                Engine.
              </p>
              <p className="p3 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#f1db5a]">3.4</span>
                'BoP algorithm' implementation on User portfolio and optimizing
                risk-return.
              </p>
            </div>
          </div>
          {/* section 4 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20  border-t-[2px] border-[#5b3575] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#5b3575]"
            ref={(el) => (stepRefs.current[4] = el)}
          >
            <div ref={textContainerRef4Head}>
              <div className="p4Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className="w-12 h-12 absolute -top-6 fill-white"
                >
                  <path d="M14,10c0,1.019-.308,1.964-.832,2.754l-3.168-3.168V5.101c2.282,.463,4,2.48,4,4.899Zm-6-4.899c-2.282,.463-4,2.48-4,4.899,0,2.761,2.239,5,5,5,1.019,0,1.964-.308,2.754-.832l-3.754-3.754V5.101Zm8,1.899h4v-2h-4v2Zm0,4h4v-2h-4v2Zm0,4h4v-2h-4v2Zm-3,4v2h5v2H6v-2h5v-2H0V4C0,2.346,1.346,1,3,1H21c1.654,0,3,1.346,3,3v15H13Zm-11-2H22V4c0-.551-.448-1-1-1H3c-.552,0-1,.449-1,1v13Z" />
                </svg> */}
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  "
                  src="./images/stock-market.png"
                  alt=""
                />
              </div>
              <div className="p4Head relative max-md:-top-72 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#9f48dd] font-semibold">
                Phase 4
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#5b3575]"
              ref={textContainerRef4}
            >
              <div className="p4 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p4 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p4 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p4 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p4 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#9f48dd]">4.1</span>
                Fine-tuning of AI on asset-price correlations using historical
                data.
              </p>
              <p className="p4 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.2</span>
                P-2-P Negotiated Dealing System based on price-time priority
                matching.
              </p>
              <p className="p4 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.3</span>
                Programming of lien-marking and trade-settlement mechanisms for
                DeAs.
              </p>
              <p className="p4 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#9f48dd]">4.4</span>
                Real-time (security-wise) Trade data reporting and technical
                analysis.
              </p>
            </div>
          </div>
          {/* section 5 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#234723] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#234723]"
            ref={(el) => (stepRefs.current[5] = el)}
          >
            <div ref={textContainerRef5Head}>
              <div className="p5Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#ffffff] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className="w-12 h-12 absolute -top-6 fill-white"
                >
                  <path d="m22.634,7.967c1.819-1.82,1.818-4.78,0-6.6-.881-.882-2.053-1.367-3.3-1.367s-2.419.485-3.3,1.367l-2.667,2.666c-1.576,1.577-1.781,4.007-.626,5.812l-.447.447c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l.448-.448c.744.478,1.606.741,2.512.741,1.247,0,2.419-.485,3.3-1.367l2.667-2.666Zm-6.997,1.81l2.07-2.07c.391-.391.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.079,2.079c-.409-.97-.222-2.135.567-2.924l2.667-2.666c.503-.504,1.173-.781,1.885-.781s1.382.277,1.886.781c1.04,1.039,1.039,2.731,0,3.771l-2.667,2.666c-.76.76-1.945.945-2.915.558ZM2.793,4.207c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2,2c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2-2Zm5.207-1.207V1c0-.553.448-1,1-1s1,.447,1,1v2c0,.553-.448,1-1,1s-1-.447-1-1ZM0,9c0-.553.448-1,1-1h2c.552,0,1,.447,1,1s-.448,1-1,1H1c-.552,0-1-.447-1-1Zm21.207,10.793c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2,2Zm-5.207,1.207v2c0,.553-.448,1-1,1s-1-.447-1-1v-2c0-.553.448-1,1-1s1,.447,1,1Zm8-6c0,.553-.448,1-1,1h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Zm-12.293-2.707c-.391-.391-1.023-.391-1.414,0l-.448.448c-.744-.478-1.606-.741-2.512-.741-1.247,0-2.419.485-3.3,1.367l-2.667,2.666c-1.819,1.82-1.818,4.78,0,6.6.881.882,2.053,1.367,3.3,1.367s2.419-.485,3.3-1.367l2.667-2.666c1.576-1.577,1.781-4.007.626-5.812l.447-.447c.391-.391.391-1.023,0-1.414Zm-2.488,6.26l-2.667,2.666c-1.007,1.007-2.763,1.008-3.771,0-1.04-1.039-1.039-2.731,0-3.771l2.667-2.666c.503-.504,1.173-.781,1.885-.781.364,0,.715.074,1.04.212l-2.081,2.081c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l2.079-2.079c.409.97.222,2.135-.567,2.924Z" />
                </svg> */}
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  "
                  src="./images/cheque.png"
                  alt=""
                />
              </div>
              <div className="p5Head relative max-md:-top-72 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#228B22] font-semibold">
                Phase 5
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#234723]"
              ref={textContainerRef5}
            >
              <div className="p5 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p5 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p5 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p5 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p5 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#228B22]">5.1</span>
                Conversion of MONEY Wallet to PoW Blockchain, with native Token
                as INR stablecoin.
              </p>
              <p className="p5 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.2</span>
                Conversion of WALR database to PoW Blockchain, with native Token
                as current WALR Series.
              </p>
              <p className="p5 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.3</span>
                RESIDEX database to PoW Blockchain, with Token as equal-weighted
                average of 16 cities.
              </p>
              <p className="p5 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold  leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#228B22]">5.4</span>
                Algorithms for periodical adjusting of mining difficulty based
                on network hash-rate.
              </p>
            </div>
          </div>

          {/* section 6 */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start pr-20 border-t-[2px] border-[#52262f] before:content-[''] before:absolute before:top-[-14px] before:left-0 before:right-0 before:h-[12px] before:bg-[#ffffff] before:border-t-[2px] before:border-dashed before:border-[#a7a7a7] after:content-[''] after:absolute after:top-[-28px] after:left-0 after:right-0 after:h-[14px] after:bg-[#ffffff] after:border-t-[2px] after:border-[#52262f]"
            ref={(el) => (stepRefs.current[6] = el)}
          >
            <div ref={textContainerRef6Head}>
              <div className="p6Head md:w-20 md:h-20 h-36 w-36  z-10 text-[#f8f8f8] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className="w-12 h-12 absolute -top-6 fill-white"
                >
                  <path d="M8.224,8.021h6.078c.553,0,1-.448,1-1s-.447-1-1-1h-6.078c-.553,0-1,.448-1,1s.447,1,1,1Z" />
                  <path d="M15.302,11.061c0-.552-.447-1-1-1h-6.078c-.553,0-1,.448-1,1s.447,1,1,1h6.078c.553,0,1-.448,1-1Z" />
                  <path d="M8.224,14.1c-.553,0-1,.448-1,1s.447,1,1,1h3.229c.553,0,1-.448,1-1s-.447-1-1-1h-3.229Z" />
                  <path d="M22.041,15.953c0-1.568-.509-2.64-1.516-3.21,.026-.54,.042-1.103,.042-1.682,0-.711-.022-1.394-.06-2.043-.211-3.635-.903-6.14-.933-6.245-.092-.328-.344-.586-.669-.687-.145-.044-3.581-1.086-7.643-1.086S3.766,2.042,3.621,2.086c-.327,.1-.58,.36-.671,.689-.04,.146-.991,3.634-.991,8.285,0,4.69,.951,8.143,.992,8.287,.092,.328,.344,.586,.669,.687,.145,.044,3.582,1.086,7.643,1.086,.358,0,.711-.008,1.059-.023,.552-.023,.979-.49,.956-1.042-.022-.552-.499-.962-1.042-.957-.318,.014-.644,.021-.973,.021-2.855,0-5.437-.574-6.518-.852-.255-1.113-.786-3.844-.786-7.208,0-3.34,.533-6.088,.787-7.209,1.084-.278,3.668-.852,6.517-.852s5.438,.574,6.519,.852c.204,.89,.586,2.814,.729,5.281,.035,.612,.056,1.257,.056,1.928,0,.41-.008,.812-.022,1.202-.064-.002-.129-.003-.196-.003-2.45,0-3.692,1.243-3.692,3.694,0,1.221,.308,2.142,.921,2.759v3.083c0,.513,.312,.958,.794,1.132,.483,.176,1.007,.033,1.336-.362l.485-.583c.105-.128,.207-.128,.312,0l.486,.583c.301,.351,.817,.556,1.335,.362,.481-.18,.794-.619,.794-1.132v-3.083c.612-.617,.921-1.538,.921-2.759Zm-3.692-1.694c1.249,0,1.692,.256,1.692,1.694,0,1.458-.499,1.693-1.692,1.693-1.249,0-1.692-.256-1.692-1.693,0-1.458,.499-1.694,1.692-1.694Z" />
                </svg> */}
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute  "
                  src="./images/data-network.png"
                  alt=""
                />
              </div>
              <div className="p6Head relative max-md:-top-72 md:-top-44 text-4xl md:text-lg lg:text-xl text-[#DC143C] font-semibold">
                Phase 6
              </div>
            </div>
            <div
              className="col flex ml-6 text-[#52262f]"
              ref={textContainerRef6}
            >
              <div className="p6 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[40px] md:left-[40px]"></div>
              <div className="p6 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[450px] md:left-[300px]"></div>
              <div className="p6 h-72 md:h-24 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0 max-md:left-[850px] md:left-[560px]"></div>
              <div className="p6 h-[550px] md:h-52 w-0.5  border-[1px] border-dashed border-gray-500 absolute top-0  max-md:left-[1260px] md:left-[820px]"></div>

              <p className="p6 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col pl-1 pr-2">
                <span className="font-extrabold text-[#DC143C]">6.1</span>
                Smart Contract integration of Auction Exchange & Peer-2-Peer
                Trading with WALR Blockchain.
              </p>
              <p className="p6 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.2</span>
                Smart Contract integration of Auction Exchange & Peer-2-Peer
                Trading with RESIDEX Blockchain.
              </p>
              <p className="p6 text-3xl md:text-sm lg:text-base max-md:mt-36 font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.3</span>
                Smart Contract integration of MONEY stablecoin Blockchain with
                WALR and RESIDEX.
              </p>
              <p className="p6 text-3xl md:text-sm max-md:mt-[420px] md:mt-32 lg:text-base font-semibold leading-relaxed w-96 md:w-60 bg-white mr-5 flex flex-col px-2">
                <span className="font-extrabold text-[#DC143C]">6.4</span>
                Reconfigure all portal modules to retrieve & render Client data
                from the three distributed ledgers.
              </p>
            </div>
          </div>

          {/* End Point  */}
          <div
            className="w-max h-[400px] mt-[10px] group flex flex-col items-start "
            ref={(el) => (stepRefs.current[7] = el)}
          >
            <div ref={textContainerRef6Head}>
              <div className=" md:w-20 md:h-20 h-36 w-36  z-10 text-[#f8f8f8] bg-[#ffffff] flex items-center justify-center duration-300 ease-out relative max-md:bottom-20 md:bottom-[60px] rounded-full border-none  border-gray-700 ">
                <img
                  className="md:w-20 md:h-20 h-36 w-36 absolute -rotate-12 "
                  src="./images/finish-flag.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    //   </div>
    // </div>
  );
};

export default App;

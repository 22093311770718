import React, { useState, useEffect } from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

const QuarterDisplay = ({ model, index, date, onChange, onClose }) => {
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());


  useEffect(() => {
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());
  }, [date]);

  const changeYear = (direction) => {
    setCurrentYear(currentYear + direction);
  };

  const selectQuarter = (quarter) => {
    let newDate = new Date();
    let startMonth, endMonth, day;
    switch (quarter) {
      case 1:
        startMonth = 3; // April
        endMonth = 5; // June
        break;
      case 2:
        startMonth = 6; // July
        endMonth = 8; // September
        break;
      case 3:
        startMonth = 9; // October
        endMonth = 11; // December
        break;
      case 4:
        startMonth = 0; // January
        endMonth = 2; // March
        break;
      default:
        return;
    }
    if (quarter === 4) {
      day = 1;
      if (index === 1) {
        newDate = new Date(currentYear + 1, endMonth + 1, 0);
      } else {
        newDate = new Date(currentYear + 1, startMonth, day);
      }
    } else {
      day = 1;
      if (index === 1) {
        newDate = new Date(currentYear, endMonth + 1, 0);
      } else {
        newDate = new Date(currentYear, startMonth, day);
      }
    }
    onChange(newDate);
  };

  return (
    <div
      id="date-picker-modal"
      className="border shadow rounded-md p-2.5 flex flex-col bg-white min-w-64 text-black"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center">
          <span
            className="bg-[#3B71CA] text-xl text-white rounded-l-md p-1 cursor-pointer"
            onClick={() => changeYear(-1)}
          >
            <IoMdArrowDropleft />
          </span>
          <div className="px-3 border font-semibold w-full text-center text-lg">
            {currentYear} - {currentYear + 1}
          </div>
          <span
            className="bg-[#3B71CA] text-xl text-white rounded-r-md p-1 cursor-pointer"
            onClick={() => changeYear(1)}
          >
            <IoMdArrowDropright />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <div
            id="date-picker-exit"
            onClick={onClose}
            className="bg-[#3B71CA] text-xl text-white border rounded-circle px-2 cursor-pointer"
          >
            &times;
          </div>
        </div>
      </div>
      <hr className="mt-2" />
      {/* Quarter Section */}
      <div className="mt-3">
        <div className="flex justify-between gap-2">
          <div
            className="text-2xl font-semibold rounded-md p-2 border-1 hover:border-[#0C2A75] hover:text-[#0C2A75] hover:bg-[#D8E5F9] duration-200 cursor-pointer w-full"
            onClick={() => selectQuarter(1)}
          >
            <h2 className="text-center font-bold">Q1</h2>
            <p className="text-sm mt-2 text-center">Apr-Jun</p>
          </div>
          <div
            className="text-2xl font-semibold rounded-md p-2 border-1 hover:border-[#0C2A75] hover:text-[#0C2A75] hover:bg-[#D8E5F9] duration-200 cursor-pointer w-full"
            onClick={() => selectQuarter(2)}
          >
            <h2 className="text-center font-bold">Q2</h2>
            <p className="text-sm mt-2 text-center">Jul-Sep</p>
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-2">
          <div
            className="text-2xl font-semibold rounded-md p-2 border-1 hover:border-[#0C2A75] hover:text-[#0C2A75] hover:bg-[#D8E5F9] duration-200 cursor-pointer w-full"
            onClick={() => selectQuarter(3)}
          >
            <h2 className="text-center font-bold">Q3</h2>
            <p className="text-sm mt-2 text-center">Oct-Dec</p>
          </div>
          <div
            className="text-2xl font-semibold rounded-md p-2 border-1 hover:border-[#0C2A75] hover:text-[#0C2A75] hover:bg-[#D8E5F9] duration-200 cursor-pointer w-full"
            onClick={() => selectQuarter(4)}
          >
            <h2 className="text-center font-bold">Q4</h2>
            <p className="text-sm mt-2 text-center">Jan-Mar</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuarterDisplay;

import React, { useState, useEffect, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import { IoClose } from "react-icons/io5";

const EventScheduler = ({ adminCalender }) => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBlocker, setEditedBlocker] = useState("Blocker text here...");
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const rowsPerPage = 5;

  useEffect(() => {
    const eventsOnDate = adminCalender
      .filter(
        (event) =>
          !event.National_holidays &&
          event.title !== "Next Coupon Payment Dates"
      )
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
    setEvents(eventsOnDate);

    // Calculate default page based on today's date or upcoming event
    const today = new Date();
    const firstUpcomingEventIndex = eventsOnDate.findIndex(
      (event) => new Date(event.event_date) >= today
    );
    if (firstUpcomingEventIndex !== -1) {
      const defaultPage = Math.ceil(
        (firstUpcomingEventIndex + 1) / rowsPerPage
      );
      setCurrentPage(defaultPage);
    } else {
      setCurrentPage(1);
    }
  }, [adminCalender]);

  const handleDeleteEvent = (val) => {
    fetch(`https://api-scdndestates.com/api/delete-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        val,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          window.location.reload();
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handleEvent = (title, key, value) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.title === title ? { ...event, [key.toLowerCase()]: value } : event
      )
    );
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        key,
        value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {})
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(events.length / rowsPerPage)) {
      setCurrentPage(page);
    }
  };

  function formattedDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    const formattedDate = date?.toLocaleDateString("en-IN", options);
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }
    return `${day}${suffix} ${formattedDate}`;
  }

  const renderRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return (
      events.length > 0 &&
      events.slice(start, end).map((event, index) => (
        <tr
          key={index}
          className={`w-full border-b border-gray-200 hover:bg-slate-100 transition duration-200 cursor-pointer text-[#313131] font-semibold text-sm ${
            isAnimating ? "" : "table-fade-in"
          }`}
        >
          <td className="p-2 w-[15%]">
            <div className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed">
              {formattedDate(event.event_date)}
            </div>
          </td>
          <td
            className="p-2 w-[30%]"
            onClick={() =>
              handleRowClick(
                event.desc,
                event.blocker,
                event.rating,
                event.title
              )
            }
          >
            <div className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded ring-2 ring-[#cbefff] focus:outline-none">
              {event.title}
            </div>
          </td>
          <td className="p-2 w-[30%]">
            <div
              type="text"
              className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none cursor-not-allowed"
            >
              {event.assignee}
            </div>
          </td>
          <td className="p-2 w-[7%]">
            <div className="flex w-full ">
              <div className="w-4/12 flex items-center justify-center">
                <div
                  className={`${
                    event.priority === "P0"
                      ? "bg-yellow-500"
                      : event.priority === "P1"
                      ? "bg-red-500"
                      : event.priority === "P2"
                      ? "bg-blue-500"
                      : event.priority === "P3"
                      ? "bg-green-500"
                      : event.priority === "P4"
                      ? "bg-purple-500"
                      : "bg-orange-500"
                  } w-3 h-3 rounded-full `}
                ></div>
              </div>
              <select
                value={event.priority || "P0"}
                onChange={(e) =>
                  handleEvent(event.title, "Priority", e.target.value)
                }
                className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
              >
                <option>P0</option>
                <option>P1</option>
                <option>P2</option>
                <option>P3</option>
                <option>P4</option>
                <option>P5</option>
              </select>
            </div>
          </td>
          <td className="p-2 w-[13%]">
            <select
              value={event.status || "Not Started"}
              onChange={(e) =>
                handleEvent(event.title, "Status", e.target.value)
              }
              className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
            >
              <option>Not Started</option>
              <option>In Progress</option>
              <option>Requires Changes</option>
              <option>Pending</option>
              <option>Postponed</option>
              <option>Completed</option>
            </select>
          </td>
          <td className="p-2 w-[5%] ">
            <div className="flex items-center justify-center">
              <IoClose
                className="p-1 bg-slate-50 rounded-full text-red-600 hover:bg-red-600 hover:text-white hover:scale-90  duration-300 cursor-pointer"
                size={24}
                onClick={() => handleDeleteEvent(event.title)}
              />
            </div>
          </td>
        </tr>
      ))
    );
  };

  const handleRowClick = (desc, blocker, rating, title) => {
    setSelectedDesc(desc);
    setEditedBlocker(blocker);
    setSelectedRate(rating);
    setSelectedTitle(title);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="w-full mx-auto shadow-[0px_1px_4px_1px_#00000024] bg-sky-600 rounded-lg overflow-hidden">
      {/* Title and Pagination Control */}
      <div className="flex justify-between items-center py-2.5 px-3">
        <h1 className="text-xl font-bold text-[#ffffff]">Events Scheduled</h1>
        <div className="flex space-x-4 items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Previous
          </button>
          <span className="text-[#ffffff] text-sm">
            <span className="font-semibold">Page {currentPage}</span> of{" "}
            {Math.ceil(events.length / rowsPerPage)}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(events.length / rowsPerPage)}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === Math.ceil(events.length / rowsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* Event Table */}
      <table className="w-full bg-white border border-gray-300 shadow-md rounded-md">
        <thead>
          <tr className="bg-sky-100 text-[#212121] text-sm uppercase leading-normal">
            <th className="p-2.5 text-left">Date</th>
            <th className="p-2.5 text-left">Task</th>
            <th className="p-2.5 text-left">Assignee</th>
            <th className="p-2.5 text-left">Priority</th>
            <th className="p-2.5 text-left">Status</th>
            <th className="p-2.5 text-left">Delete</th>
          </tr>
        </thead>
        <tbody className={`${isAnimating ? "" : "table-fade-in"}`}>
          {renderRows()}
        </tbody>
      </table>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 ">
          <div className="bg-[#0284C7]  rounded-lg overflow-hidden shadow-lg  w-[90%] max-w-md pop-up-animation">
            <div className="flex justify-between px-3 py-2.5">
              {" "}
              <h2 className="text-xl font-semibold text-white">
                Event Details
              </h2>
              <IoClose
                className="p-1 bg-white rounded-full text-red-600 hover:bg-red-100 hover:scale-95 transition-transform duration-300 cursor-pointer"
                size={24}
                onClick={closePopup}
              />
            </div>
            <div className="p-6 bg-white">
              <div className="mb-2">
                <div className="flex justify-between items-center mb-1">
                  <span className="font-semibold">Description:</span>
                  {/* <button className="text-blue-500 hover:underline">Edit</button> */}
                </div>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  defaultValue={selectedDesc || "Description text here..."}
                  rows="3"
                  readOnly
                />
              </div>
              <div>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  value={editedBlocker} // Show the editing state value
                  onChange={(e) => setEditedBlocker(e.target.value)} // Update the editedBlocker state
                  rows="3"
                  readOnly={!isEditing}
                />
              </div>
              <div className="flex justify-end mt-1">
                {isEditing ? (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(false);
                      handleEvent(selectedTitle, "Blocker", editedBlocker);
                      setShowPopup(false);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(true);
                      setEditedBlocker("");
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div>
                <div className="flex justify-between items-center mb-1">
                  <span className="font-semibold">Ratings:</span>
                </div>

                <select
                  value={selectedRate || "NE"}
                  onChange={(e) => {
                    handleEvent(selectedTitle, "Rating", e.target.value);
                    setSelectedRate(e.target.value);
                  }}
                  className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option value="EE">EE</option>
                  <option value="ME">ME</option>
                  <option value="NE">NE</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventScheduler;

import React from "react";
import { MdOutlineBlock } from "react-icons/md";
import Header from "../../components/Header/Header.jsx";

function Career() {
  return (
    <>
      <Header />
      <div className="container-fluid after-header">
        <div className="row">
          <h6 className="m-4">
            <a href="/">
              <span className="header-link">Home</span>
              <span className="text-secondary"> &gt; </span>
            </a>
            <a href="#">
              <span className="text-dark text-decoration-underline">
                Career
              </span>
            </a>
            <hr className="text-dark mb-0" />
          </h6>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-9 col-sm-12 p-3">
            <p className="text-secondary text-center mx-4 px-3">
              SCDND unites brilliant minds to tackle finance's toughest
              challenges. Join us in scaling new heights of innovation. If you
              believe in pushing boundaries, we invite you to ascend with our
              team. Together, we're redefining what's possible in finance. Join
              the climb at SCDND, where brilliance meets ambition.
            </p>
            <hr />
            <div className="d-flex flex-column align-items-center">
              <MdOutlineBlock size={70} className="text-secondary" />
              <h5 className="fw-bold text-secondary">
                There are no job openings currently.
              </h5>
            </div>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-sm-12 p-3">
            <h3 className="fw-bold text-center">Career at SCDND</h3>
            <p className="text-secondary para-style2 text-dark mx-4 px-3">
              A career at SCDND Estates means you’ll have the opportunity to
              contribute towards a disruptive change in a long-standing
              traditional industry, with tremendous potential of growth and a
              positive impact on society in general.
            </p>
            <p className="text-secondary para-style2 text-dark mx-4 px-3">
              Our Company firmly believes that India of 21st century deserves a
              revolutionary financial system that shifts power away from the
              hereditary/entitlement order to a more talent based one, and we
              will be pioneers in that field.
            </p>
            <p className="text-secondary para-style2 text-dark mx-4 px-3">
              Working with our Company will enable the applicant to learn a wide
              range of skills indispensable to the most niche parts of the
              investment industry, deal with influential clients first-hand and
              network with industry leaders, interact with the founders of both
              established and start-up firms and understand how businesses
              across a wide-range of sectors interact with each other.
            </p>
            <p className="text-secondary para-style2 text-dark mx-4 px-3">
              Our Company anticipates that your journey with us will ensure that
              you become the very best in the world in your own
              discipline/specialization and therefore an asset to the corporate
              world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;

import React, { useState, useEffect, useRef } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1.jsx";
import { useNavigate } from "react-router-dom";
import PieChart from "./PieChart.jsx";
import "./Ownership.css";
import { PiHandCoinsFill } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaPercentage } from "react-icons/fa";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import UserData from "../../components/ServiceFunctions/UserData.jsx";
import Loader2 from "../../components/Loaders/Loader2.jsx";
import KycPopUp from "../../components/PopUp1/PopUp2.jsx";
import Header from "../../components/Header/Header.jsx";
import WALRComponentChart from "./WALRComponentChart.jsx";
import RESIDEXComponentChart from "./RESIDEXComponentChart.jsx";
import secureLocalStorage from "react-secure-storage";
import { TbPointFilled } from "react-icons/tb";
import { IoMdInformationCircle } from "react-icons/io";

function Ownership() {
  const { isAuthenticated, email, phone, pan, walr, residex, kycStatus } =
    CheckAuth();
  const { loader } = UserData({
    isAuthenticated,
    email,
    phone,
    formName: "",
  });
  const chartRef = useRef(null);

  const Coupons = () => {
    investedValue = sum;
    investedValueResidex = sumResidex;
    fetch(`https://api-scdndestates.com/api/Coupon-Statements/`, {
      method: "POST",
      body: JSON.stringify({
        // pan,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  useEffect(() => {
    Coupons();
    if (chartRef.current) {
      chartRef.current.scrollLeft = chartRef.current.scrollWidth;
    }
  }, []);

  const ownershipDetails = () => {
    fetch(`https://api-scdndestates.com/api/ownership-data/`, {
      method: "POST",
      body: JSON.stringify({
        pan,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  useEffect(() => {
    if (pan) {
      ownershipDetails();
    }
  }, [pan]);

  function parseDate(dateStr) {
    return new Date(dateStr.replace(/(\d{2}) (\w{3}) (\d{2})/, "$2 $1, $3"));
  }

  const navigate = useNavigate();
  const [showBox, setShowBox] = useState("WALR");
  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const storedData1 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData1);
  const storedData3 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData3);
  // Wow
  const pieData = [
    { name: "WALR", value: walr, color: "#E90074" },
    {
      name: "RESIDEX",
      value: residex,
      color: "#071952",
    },
    { name: "Long Term Options", value: 0, color: "#FF9A00" },
  ];

  const handleShowFunction = (name) => {
    setShowBox(name);
  };

  var averageRatio = 0;
  var averageRatioResidex = 0;
  const relationshipDate = kycData?.["Relationship_start"];

  function calculateAverageRatio(array1, array2) {
    if (array1.length !== array2.length) {
      return 0;
    }

    let sumRatios = 0;
    const numPairs = array1.length;

    for (let i = 0; i < numPairs; i++) {
      const wKey = `W${i + 1}`;
      const xKey = `X${i + 1}`;

      const wValue = array1[i][wKey];
      const xValue = array2[i][xKey];

      const ratio = wValue / xValue;
      sumRatios += ratio;
    }

    const averageRatio = sumRatios / numPairs;
    return averageRatio;
  }

  function calculateAverageRatioResidex(array3, array4, totalEntriesProcessed) {
    if (array3.length !== array4.length || array3.length === 0) {
      return 0;
    }

    const numPairs = array3.length;

    // Extract the first and last values
    const firstZKey = `Z1`;
    const firstYKey = `Y1`;
    const lastZKey = `Z${numPairs}`;
    const lastYKey = `Y${numPairs}`;

    const firstZValue = array3[0][firstZKey];
    const firstYValue = array4[0][firstYKey];
    const lastZValue = array3[numPairs - 1][lastZKey];
    const lastYValue = array4[numPairs - 1][lastYKey];

    // Calculate the first and last ratios
    const firstRatio = firstZValue / firstYValue;
    const lastRatio = lastZValue / lastYValue;

    // Calculate the difference in ratios
    const ratioDifference = lastRatio - firstRatio;

    // Calculate the final value
    const averageRatioResidex = ratioDifference / totalEntriesProcessed;

    return averageRatioResidex;
  }

  var investedValue = 0;
  var totalInterest = 0;
  var investedValueResidex = 0;
  var totalInterestResidex = 0;

  // Create a new sorted object
  const sortedCouponData = { pan: couponData?.pan };
  // Check if couponData is valid
  if (couponData) {
    // Extract the date keys and sort them
    const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
    const updatedDateKeys = dateKeys.map((key) => {
      const date = new Date(key);
      date.setDate(date.getDate() - 7);
      const options = { day: "2-digit", month: "short", year: "2-digit" };
      const formattedDate = date.toLocaleDateString("en-IN", options);
      sortedCouponData[formattedDate] = couponData[key];
      return formattedDate; // You may need to adjust the format as required
    });

    updatedDateKeys.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    });

    const array1 = [];
    const array2 = [];
    var i = 1;
    Object.entries(sortedCouponData).forEach(([key, value], index) => {
      if (key !== "pan") {
        let totalBuy = 0;
        let totalSell = 0;
        let totalSum = 0;
        let total = 0;
        if (value && value.WALR) {
          total += value.WALR.CoverYes || 0;
          total += value.WALR.CoverNo || 0;
        }
        totalInterest += total;
        if (total !== 0) {
          array1.push({ [`W${index}`]: total });
        }
        const startDate = new Date(relationshipDate);
        const endDate = parseDate(key);

        function isDateInRange(date, startDate, endDate) {
          return date >= startDate && date <= endDate;
        }
        blockchainData.filter((item, index) => {
          const itemDate = new Date(item.Timestamp);
          if (
            item.Buyer === couponData.pan &&
            item["Security"].slice(0, 4) === "WALR" &&
            isDateInRange(itemDate, startDate, endDate)
          ) {
            totalBuy += item["Units"];
          }
        });

        blockchainData.filter((item, index) => {
          const itemDate = new Date(item.Timestamp);
          if (
            item.Seller === couponData.pan &&
            item["Security"].slice(0, 4) === "WALR" &&
            isDateInRange(itemDate, startDate, endDate)
          ) {
            totalSell += item["Units"];
          }
        });
        totalSum = totalBuy - totalSell;
        if (totalSum !== 0) {
          array2.push({ [`X${index}`]: totalSum * 1000 });
        }
      }
    });
    var sum = 0;
    blockchainData.filter((item, index) => {
      if (item.Buyer === pan && item["Security"].slice(0, 4) === "WALR") {
        sum += item["Units"] * item["Price_per_unit"];
      }
    });
    blockchainData.filter((item, index) => {
      if (item.Seller === pan && item["Security"].slice(0, 4) === "WALR") {
        sum -= item["Units"] * item["Price_per_unit"];
      }
    });
    investedValue = sum;

    averageRatio = calculateAverageRatio(array1, array2).toFixed(2) * 4 * 100;
  }

  if (couponData) {
    // Extract the date keys and sort them
    const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
    const updatedDateKeys = dateKeys.map((key) => {
      const date = new Date(key);
      date.setDate(date.getDate() - 7);
      const options = { day: "2-digit", month: "short", year: "2-digit" };
      const formattedDate = date.toLocaleDateString("en-IN", options);
      sortedCouponData[formattedDate] = couponData[key];
      return formattedDate; // You may need to adjust the format as required
    });

    updatedDateKeys.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    });

    const array3 = [];
    const array4 = [];
    let totalEntriesProcessed = 0;
    var i = 1;
    Object.entries(sortedCouponData).forEach(([key, value], index) => {
      if (key !== "pan") {
        let totalBuy = 0;
        let totalSell = 0;
        let totalSum = 0;
        let total = 0;
        if (value && value.RESIDEX) {
          total += value.RESIDEX.Index || 0;
        }
        totalInterestResidex += total;
        if (total !== 0) {
          array3.push({ [`Z${index}`]: total });
        }
        const startDate = new Date(relationshipDate);
        const endDate = parseDate(key);

        function isDateInRange(date, startDate, endDate) {
          return date >= startDate && date <= endDate;
        }
        blockchainData.filter((item, index) => {
          const itemDate = new Date(item.Timestamp);
          if (
            item.Buyer === couponData.pan &&
            item["Security"].slice(0, 7) === "RESIDEX" &&
            isDateInRange(itemDate, startDate, endDate)
          ) {
            totalBuy += item["Units"];
            totalEntriesProcessed++;
          }
        });

        blockchainData.filter((item, index) => {
          const itemDate = new Date(item.Timestamp);
          if (
            item.Seller === couponData.pan &&
            item["Security"].slice(0, 7) === "RESIDEX" &&
            isDateInRange(itemDate, startDate, endDate)
          ) {
            totalSell += item["Units"];
            totalEntriesProcessed++;
          }
        });
        totalSum = totalBuy - totalSell;
        if (totalSum !== 0) {
          array4.push({ [`Y${index}`]: (totalSum * 0.03) / 4 });
        }
      }
    });
    var sumResidex = 0;
    blockchainData.filter((item, index) => {
      if (item.Buyer === pan && item["Security"].slice(0, 7) === "RESIDEX") {
        sumResidex += item["Units"] * item["Price_per_unit"];
      }
    });
    blockchainData.filter((item, index) => {
      if (item.Seller === pan && item["Security"].slice(0, 7) === "RESIDEX") {
        sumResidex -= item["Units"] * item["Price_per_unit"];
      }
    });
    investedValueResidex = sumResidex;

    averageRatioResidex = calculateAverageRatioResidex(
      array3,
      array4,
      totalEntriesProcessed
    ).toFixed(2);
  }

  const handleNavigateFunction = () => {
    if (showBox === "WALR") navigate("/WALR-holdings");
    else if (showBox === "RESIDEX") navigate("/RESIDEX-holdings");
    else navigate("/");
  };
  return (
    <>
      <Header />
      <div className="after-header">
        {loader ? (
          <Loader2 />
        ) : !isAuthenticated ? (
          <PopUp1 />
        ) : kycStatus < 85 ? (
          <KycPopUp />
        ) : (
          <>
            <div className="container-fluid m-0 p-0 mt-4 max-w-[1800px] mx-auto ">
              <div className="bg-[#EDEDED] p-3 rounded-lg w-[90%] mx-auto">
                <div className="row m-0 ">
                  {/* Left Section */}
                  <div className="col-md-12 col-lg-6 col-sm-12 border shadow-md rounded-lg bg-white">
                    <div className="flex flex-col justify-center items-center relative">
                      <PieChart
                        data={pieData}
                        showFunction={handleShowFunction}
                        navigateFunction={handleNavigateFunction}
                      />
                    </div>
                  </div>

                  {/* Right Section */}
                  <div className="col-md-12 col-lg-6 col-sm-12 p-0.5 lg:pl-2">
                    {/* Top container */}

                    <div className="flex flex-wrap gap-1 justify-between text-black">
                      <div className="border rounded-lg p-3 w-full lg:w-[32.5%] shadow-md slice-btn bg-white">
                        <div className="flex flex-wrap justify-between items-center">
                          <div
                            className={`${
                              showBox === "WALR"
                                ? "bg-[#E90074]"
                                : showBox === "RESIDEX"
                                ? "bg-[#071952]"
                                : "bg-[#FF9A00]"
                            } p-2.5 text-black rounded-md w-fit lg:w-full xl:w-fit h-fit xl:p-4`}
                          >
                            <PiHandCoinsFill
                              color={"#fff"}
                              className="text-3xl lg:text-lg xl:text-3xl mx-auto"
                            />
                          </div>
                          <h2 className="text-sm 2xl:text-xl lg:mx-auto xl:mx-0 max-xl:text-center xl:text-end font-semibold my-1.5 xl:my-3">
                            Invested <br />
                            Value
                          </h2>
                        </div>
                        <hr />
                        <p className="font-bold mt-2  text-sm 2xl:text-xl  p-1.5 rounded-lg ">
                          {showBox === "WALR" ? (
                            <>
                              &#x20B9;&nbsp;
                              {parseInt(investedValue).toLocaleString("en-IN")}
                            </>
                          ) : showBox === "RESIDEX" ? (
                            <>
                              &#x20B9;&nbsp;
                              {parseInt(investedValueResidex).toLocaleString("en-IN")}
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>

                      <div className="border rounded-lg p-3 w-full lg:w-[32.5%] shadow-md slice-btn bg-white">
                        <div className="flex flex-wrap justify-between items-center">
                          <div
                            className={`${
                              showBox === "WALR"
                                ? "bg-[#E90074]"
                                : showBox === "RESIDEX"
                                ? "bg-[#071952]"
                                : "bg-[#FF9A00]"
                            } p-2.5 text-black rounded-md w-fit lg:w-full xl:w-fit h-fit xl:p-4`}
                          >
                            <FaPercentage
                              color={"#ffffff"}
                              className="text-3xl lg:text-lg xl:text-3xl mx-auto"
                            />
                          </div>
                          <h2 className="text-sm 2xl:text-xl lg:mx-auto xl:mx-0 max-xl:text-center xl:text-end font-semibold my-1.5 xl:my-3">
                            {showBox === "WALR" ? (
                              <>
                                Total <br />
                                Interest
                              </>
                            ) : showBox === "RESIDEX" ? (
                              <>
                                Total <br />
                                Dividends
                              </>
                            ) : (
                              <></>
                            )}
                          </h2>
                        </div>
                        <hr />
                        <p className="font-bold mt-2  text-sm 2xl:text-xl bg-[#DBEDF9] p-1.5 rounded-lg">
                          {showBox === "WALR" ? (
                            <>
                              &#x20B9;&nbsp;
                              {parseInt(totalInterest).toLocaleString("en-IN")}
                            </>
                          ) : showBox === "RESIDEX" ? (
                            <>
                              &#x20B9;&nbsp;
                              {parseInt(totalInterestResidex).toLocaleString("en-IN")}
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>

                      <div className="border rounded-lg p-3 w-full lg:w-[32.5%] shadow-md slice-btn bg-white">
                        <div className="flex flex-wrap justify-between items-center">
                          <div
                            className={`${
                              showBox === "WALR"
                                ? "bg-[#E90074]"
                                : showBox === "RESIDEX"
                                ? "bg-[#071952]"
                                : "bg-[#FF9A00]"
                            } p-2.5 text-black rounded-md w-fit lg:w-full xl:w-fit h-fit xl:p-4`}
                          >
                            <FaMoneyBillTrendUp
                              color={"#fff"}
                              className="text-3xl lg:text-lg xl:text-3xl mx-auto"
                            />
                          </div>
                          <h2 className="text-sm 2xl:text-xl lg:mx-auto xl:mx-0 max-xl:text-center xl:text-end font-semibold my-1.5 xl:my-3">
                            {showBox === "WALR" ? (
                              <>
                                Compound <br /> Growth
                              </>
                            ) : showBox === "RESIDEX" ? (
                              <>
                                Annualized <br />
                                Gain
                              </>
                            ) : (
                              <></>
                            )}
                          </h2>
                        </div>
                        <hr />
                        <p className="font-bold mt-2 text-sm 2xl:text-xl bg-[#DBEDF9] p-1.5 rounded-lg">
                          {showBox === "WALR" ? (
                            <>
                              {(isNaN(averageRatio) ? 0 : averageRatio).toFixed(
                                2
                              )}
                              %
                            </>
                          ) : showBox === "RESIDEX" ? (
                            <>
                              &nbsp;
                              {averageRatioResidex?.toLocaleString("en-IN")} %
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </div>

                    {/* Chart */}
                    <div
                      className="overflow-x-scroll overflow-y-hidden lg:overflow-y-scroll 2xl:overflow-y-hidden mt-2 bg-white border shadow-md rounded-lg "
                      ref={chartRef}
                    >
                      <div className=" pt-5 min-w-[600px] lg:max-h-[300px] 2xl:max-h-full rounded-lg">
                        <p className="font-semibold -mt-5 pl-4 text-black">
                          Quarterly Payouts
                        </p>
                        {showBox === "WALR" ? (
                          <>
                            <WALRComponentChart />
                          </>
                        ) : showBox === "RESIDEX" ? (
                          <>
                            <RESIDEXComponentChart />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <hr className="mt-10 mb-4" /> */}

                  <div className="col-12 border rounded-lg p-3 text-black shadow-md bg-white m-0 mt-2">
                    <div className="text-sm 2xl:text-base py-2 font-semibold flex flex-col gap-2">
                      <p className="mb-1 text-justify flex gap-2 md:gap-1">
                        <span className="mt-0.5 lg:mt-1">
                          <TbPointFilled />
                        </span>

                        <span>
                          Your total holdings of decentralized assets is
                          detailed (in percentage terms) in the pie-chart.
                          Please click on the individual components to see the
                          respective details.
                        </span>
                      </p>
                      <p className="mb-1 text-justify flex md:items-center gap-2 md:gap-1">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>
                          All WALR or Residex transactions, either through the
                          <a
                            href="/"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Primary Market
                          </a>
                          or
                          <a
                            href="/"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Trading Portal
                          </a>
                          , are automatically updated in your Ownership records
                          in T+1.
                        </span>
                      </p>
                      <p className="mb-1 text-justify flex md:items-center gap-2 md:gap-1">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>
                          You can access our
                          <a
                            href="/"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Primary Market
                          </a>
                          portal through the button 'Transact with SCDND' and
                          place Purchase/Redemption orders using funds in your
                          <a
                            href="/moneyWallet"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Money Wallet.
                          </a>
                        </span>
                      </p>
                      <p className="mb-1 text-justify flex md:items-center gap-2 md:gap-1">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>
                          You can login to our
                          <a
                            href="/"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Trading Portal
                          </a>
                          through the button 'Trade Peer-2-Peer' and place
                          Buy/Sell orders (subject to available liquidity).
                        </span>
                      </p>
                      <p className="mb-1 text-justify flex md:items-center gap-2 md:gap-1">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>
                          The actual Coupons paid on WALR and Residex to your
                          linked Bank Accounts is subject to TDS as per
                          applicable rates under Income Tax Act. 1961.
                        </span>
                      </p>
                      <p className="mb-1 text-justify flex md:items-center gap-2 md:gap-1">
                        <span className="mt-1">
                          <TbPointFilled />
                        </span>
                        <span>
                          Any TDS collected and paid are reflected in your
                          Profile by issuing Form 16A, under the
                          <a
                            href="/pricing"
                            className="text-blue-500 hover:underline mx-1"
                          >
                            Fees & Taxes
                          </a>
                          page.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Ownership;

import React from "react";
import { BsArrowBarRight } from "react-icons/bs";
import "./WALRBonds.css";
import Header from "../../components/Header/Header";

const cardData = [
  {
    image: "/images/blockchain.gif",
    heading: "Decentralized Fixed Income",
    content:
      "Decentralized Fixed Income Security, where all ownership and transaction records (of each holder) are stored, broadcast, and verified in a public Proof-of-Work (PoW) Blockchain, leading to complete transparency and immutability.",
  },
  {
    image: "/images/statistics.gif",
    heading: "Coupon benchmarked Externally",
    content:
      "Coupon benchmarked Externally i.e., linked to the Weighted Average Lending Rate of all outstanding loans by scheduled commercial banks, published monthly on RBI website. Even RBI itself do not decide this rate, they simply conduct the all-India survey.",
  },
  {
    image: "/images/settings.gif",
    heading: "Prices not Controlled",
    content:
      "Prices not Controlled i.e., by SCDND or by any centralized authority/operator. It is decided transparently and independently by the peer-to-peer transactions of the Clients themselves.",
  },
  {
    image: "/images/protection.gif",
    heading: "Client-funds are Secured",
    content:
      "Client-funds are Secured, with collateral consisting of the most liquid and internationalized assets i.e., Gold, Nifty 50 and Cash.",
  },
  {
    image: "/images/safe-box.gif",
    heading: "Collateral held in Escrow",
    content:
      "Collateral held in Escrow, in a segregated Custodian A/C with Coverage Ration always above 105%, maintained (and reported on SCDND) website in real-time.",
  },
  {
    image: "/images/cost-effectiveness.gif",
    heading: "Coupon is reset Higher",
    content:
      "Coupon is reset Higher, if the benchmark is revised higher (in a rising rate environment). But there is downside protection provided to Clients when the RBI published benchmark falls.",
  },
  {
    image: "/images/bill.gif",
    heading: "Management Fee is 0.5%",
    content:
      "Management Fee is 0.5%, which is far less compared to existing Debt mutual funds/Alternate funds available to investors.",
  },
  {
    image: "/images/protection-risk.gif",
    heading: "Zero Liquidity-risk",
    content:
      "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
  },
];

function WALROverview() {
  return (
    <>
      <Header />

      <div className="after-header mb-3 max-w-[1800px] mx-auto">
        <div className=" relative  w-[90%] mx-auto isolate  flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5  sm:px-3.5 sm:before:flex-1 ">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#aad4fc] to-[#65aaf8] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#aad4fc] to-[#2f89f0] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 justify-center w-full">
            <p className="text-[8px] text-xs  text-gray-900 md:text-sm text-center font-semibold">
              Check out our guide videos on the benefits of Decentralised Assets{" "}
              <span aria-hidden="true">&rarr;</span>
            </p>
            <a
              href="/walrbonds/performance/"
              className="flex-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              <svg
                id="fi_5968852"
                enableBackground="new 0 0 512 512"
                className="w-7 h-7"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="XMLID_184_">
                  <path
                    id="XMLID_182_"
                    d="m501.301 131.964c-5.888-22.172-23.237-39.633-45.266-45.56-39.928-10.768-200.035-10.768-200.035-10.768s-160.107 0-200.035 10.768c-22.029 5.926-39.378 23.388-45.266 45.56-10.699 40.188-10.699 124.036-10.699 124.036s0 83.848 10.699 124.036c5.888 22.172 23.237 39.633 45.266 45.56 39.928 10.768 200.035 10.768 200.035 10.768s160.107 0 200.035-10.768c22.029-5.927 39.378-23.388 45.266-45.56 10.699-40.188 10.699-124.036 10.699-124.036s0-83.848-10.699-124.036z"
                    fill="#f00"
                  ></path>
                  <path
                    id="XMLID_1355_"
                    d="m203.636 332.128 133.818-76.126-133.818-76.13z"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>

        <div className="flex flex-wrap gap-5  pt-3 w-[90%] mx-auto">
          <div className="flex flex-wrap -mx-2">
            {cardData.map((card, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-[48%] p-2">
                <div className="flex p-4 h-full group cursor-pointer hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg">
                  <div className="w-1/6 flex items-start justify-center">
                    <img
                      className="w-16 h-16 sm:w-20 sm:h-20 object-cover"
                      src={card.image}
                      alt={card.heading}
                    />
                  </div>
                  <div className="w-5/6 pl-4">
                    <a
                      href=""
                      className="transition duration-300 text-base sm:text-lg 2xl:text-xl text-black font-bold group-hover:text-[#5ab2ff]"
                    >
                      {card.heading}
                      <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#5ab2ff]"></span>
                    </a>
                    <p className="text-sm sm:text-base font-medium mt-2">
                      {card.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default WALROverview;

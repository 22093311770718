import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ResidexLineChart = ({ category, graphData, graphXAxis }) => {
  const [series, setSeries] = useState([
    {
      name: category,
      data: graphData,
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: "area",
    },
    colors: category === "US Tresuries" ? ["#6f705e"] : ["#0066cc"],
    legend: {
      position: "top",
      fontSize: "10px",
      paddingBottom: "0px",
      marginBottom: "0px",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      tickAmount: 10,
      categories: graphXAxis,
    },
    tooltip: {
      enabled: false,
    },
  });

  return (
    <div className="m-0 p-0 pb-2 px-2">
      <div className="display-style">
        <div id="chart" className="p-0 w-full">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            className="apexChartGr w-[350px] lg:w-[400px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ResidexLineChart;

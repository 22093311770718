import React, { useState, useEffect } from "react";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import BidBox from "../../components/PrimaryMarketComponents/BidBox";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import LineGraph from "./LineGraph";
import "./ExchangeTable.css";
import { BsGraphUpArrow } from "react-icons/bs";
import Loader2 from "../Loaders/Loader2";
import secureLocalStorage from "react-secure-storage";

function ExchangeTable({ head, tabHead1, tabHead2, tabHead3, tabHead4 }) {
  const { isAuthenticated, pan } = CheckAuth();
  const [walrTable, setWalrTable] = useState([]);
  const [residexTable, setResidexTable] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [product, setProduct] = useState("");
  const [cover, setCover] = useState("");
  const [option, setOption] = useState(0);
  const [btn, setBtn] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(
    (head === "WALR Exchange" ? walrTable : residexTable).length / itemsPerPage
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTransactions = (
    head === "WALR Exchange" ? walrTable : residexTable
  ).slice(startIndex, endIndex);
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState("2013-11");
  const [toDate, setToDate] = useState("");
  const [graphData, setGraphData] = useState({});
  const [graphXAxis, setGraphXAxis] = useState({});
  const [loader, setLoader] = useState(true);
  const [lastKey, setLastKey] = useState("");
  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const storedData1 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData1);
  const storedData3 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData3);

  function getHoldingPeriod(cities, productName) {
    var Days = [];
    var residex_units_calculated = [];
    const sortedCouponData = { pan: couponData?.pan };
    if (couponData) {
      const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
      const updatedDateKeys = dateKeys.map((key) => {
        const date = new Date(key);
        const options = { day: "2-digit", month: "short", year: "2-digit" };
        const formattedDate = date.toLocaleDateString("en-IN", options);
        sortedCouponData[formattedDate] = couponData[key];
        return formattedDate;
      });
      updatedDateKeys.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      const relationshipDate = kycData?.["Relationship_start"];
      Object.entries(sortedCouponData).forEach(([key, value], index) => {
        if (key !== "pan") {
          let totalBuy = 0;
          let totalSell = 0;
          let totalSum = 0;
          let total = 0;
          if (value && value.RESIDEX) {
            total += value.RESIDEX.Index || 0;
            total += value.RESIDEX.Futures || 0;
          }
          const startDate = new Date(relationshipDate);
          const endDate = new Date(
            key.replace(/(\d{2}) (\w{3}) (\d{2})/, "$2 $1, $3")
          );
          const today = new Date();
          //  calculating days
          const timeDifference = today.getTime() - endDate.getTime();
          const daysDifference =
            timeDifference > 0
              ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
              : 0;

          Days.push({ [`T${index}`]: daysDifference / 91 });

          //
          function isDateInRange(date, startDate, endDate) {
            const dateStart = new Date(date.setHours(0, 0, 0, 0));
            const start = new Date(startDate.setHours(0, 0, 0, 0));
            const end = new Date(endDate.setHours(0, 0, 0, 0));

            return dateStart >= start && dateStart <= end;
          }

          blockchainData.filter((item, index) => {
            const itemDate = new Date(item.Timestamp);
            if (
              item.Buyer === couponData.pan &&
              item["Security"] === "RESIDEX City " + productName &&
              isDateInRange(itemDate, startDate, endDate)
            ) {
              totalBuy += item["Units"];
            }
          });

          blockchainData.filter((item, index) => {
            const itemDate = new Date(item.Timestamp);
            if (
              item.Seller === couponData.pan &&
              item["Security"] === "RESIDEX City " + productName &&
              isDateInRange(itemDate, startDate, endDate)
            ) {
              totalSell += item["Units"];
            }
          });
          totalSum = totalBuy - totalSell;
          if (totalSum !== 0) {
            residex_units_calculated.push({ [`X${index}`]: totalSum });
          }
        }
      });
    }

    let sumProd = 0;
    let sum = 0;
    if (Days.length > 0 && Days.length === residex_units_calculated.length) {
      for (let i = 0; i < Days.length; i++) {
        const dayValue = Object.values(Days[i])[0];
        const residexValue = Object.values(residex_units_calculated[i])[0];
        sumProd += dayValue * residexValue;
        sum += residexValue;
      }
      return (sumProd / sum).toFixed(2);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (pan && isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/primary-product-box/`, {
        method: "POST",
        body: JSON.stringify({
          pan,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setWalrTable(Array.isArray(data.data) ? data.data : []);
          setResidexTable(Array.isArray(data.data2) ? data.data2 : []);
          const lastSeries = data.data[0];
          setLastKey(Object.keys(lastSeries)[0]);
          setLoader(false);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  }, [pan, isAuthenticated]);

  function calculateXaxis(fromDate, toDate) {
    if (head === "WALR Exchange") {
      const months = [];
      const startDate = new Date(fromDate + "-01");
      const endDate = new Date(toDate + "-01");
      let currentDate = startDate;

      while (currentDate <= endDate) {
        const monthName = currentDate.toLocaleString("default", {
          month: "short",
        });
        const year = currentDate.getFullYear().toString().slice(-2);
        months.push(`${monthName}-${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      return months;
    } else {
      const months = [];
      const startDate = new Date(fromDate + "-01");
      const endDate = new Date(toDate + "-01");
      let currentDate = startDate;

      while (currentDate <= endDate) {
        const monthName = currentDate.toLocaleString("default", {
          month: "short",
        });
        const year = currentDate.getFullYear().toString().slice(-2);
        months.push(`${monthName}-${year}`);
        currentDate.setMonth(currentDate.getMonth() + 3);
      }
      return months;
    }
  }

  const fetchGraphData = (fundName, val) => {
    fetch(
      `https://api-scdndestates.com/api/primary-market-product-graph/?fund_name=${fundName}&product=${val}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setGraphData(data["data"]);
        setFromDate(data["start"]);
        setToDate(data["end"]);
        setGraphXAxis(calculateXaxis(data["start"], data["end"]));
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const fetchTableData = (fundName, val) => {
    fetch(
      `https://api-scdndestates.com/api/primary-market-product-table/?fund_name=${fundName}&product=${val}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch tabledata");
        }
        return response.json();
      })
      .then((response) => {
        setTableData(response["result"]);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const handleSelectRow = (security_name, index, val) => {
    if (index === selectedRow) {
      setSelectedRow(0);
    } else {
      fetchTableData(security_name, val);
      fetchGraphData(security_name, val);
      setSelectedRow(index);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <div className="lg:flex gap-4 py-2 table-media lg:pl-5 ">
        <div className="w-full lg:w-[65%] border p-3 shadow-3 rounded-xl lg:mb-0">
          <div className="flex items-center justify-between flex-wrap gap-2 m-0 p-0 ">
            <span className="font-bold text-black text-lg 2xl:text-xl">
              {head}
            </span>
            <div className="flex flex-wrap items-center justify-between ">
              <div className="btn-group dropdown shadow-0">
                <div
                  className="text-dark me-4 cursor-pointer-style "
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsFilterLeft size={25} />
                </div>

                <div className="dropdown-menu border rounded">
                  <ul className="list-unstyled m-0 p-0 flex flex-col items-start text-sm lg:text-xs 2xl:text-base ">
                    <li className="sort-btn w-full -ml-2">All Series</li>
                    <li className="sort-btn -ml-2 w-full">P2P Series</li>
                    <li className="sort-btn -ml-2 w-full">Primary Series</li>
                  </ul>
                </div>
              </div>

              <div className="flex items-center justify-between">
                {currentPage > 1 ? (
                  <>
                    <div
                      className="btn btn-sm p-0 btn-secondary"
                      onClick={() => handlePrevPage()}
                    >
                      <BiSkipPrevious size={25} color="#000" />
                    </div>
                    <div className="mx-3 my-0">{currentPage - 1}</div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <BiSkipPrevious size={25} color="#fff" />
                    </div>
                    <div className="mx-3 my-0 text-light">{currentPage}</div>
                  </>
                )}
                <div
                  className="mx-1 my-0 px-2 py-0 rounded text-sm flex items-center"
                  style={{ backgroundColor: "#d8e5f9" }}
                >
                  {currentPage}
                </div>

                {currentPage < totalPages ? (
                  <>
                    <div className="mx-3 my-0">{currentPage + 1}</div>

                    <div
                      className="btn p-0 btn-sm btn-secondary"
                      onClick={() => handleNextPage()}
                    >
                      <BiSkipNext size={25} color="#000" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mx-3 my-0 text-light">{currentPage}</div>

                    <div className="">
                      <BiSkipNext size={25} color="#fff" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr className="m-0 p-0 my-2" />
          <div className="row m-0 p-0 overflow-scroll">
            {loader ? (
              <Loader2 />
            ) : (
              <table className="table-responsive table min-w-[990px] table-hover table-borderless table-sm border-dark">
                <thead className="">
                  <tr
                    className="text-dark"
                    style={{ backgroundColor: "#cbdcf7bd" }}
                  >
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    ></th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    ></th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] text-center fw-bolder justify-center flex min-w-20"
                    >
                      Security Name
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      {tabHead1}
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      Inventory (units)
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      Next Coupon Date
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      {tabHead2}
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      {tabHead3}
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder text-center"
                    >
                      {tabHead4}
                    </th>
                    <th
                      scope="col"
                      className="text-xs 2xl:text-[13px] fw-bolder justify-center flex"
                    >
                      Benchmark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {head === "WALR Exchange" ? (
                    <>
                      {currentTransactions.map((item, index) =>
                        Object.entries(item).map(([key, value]) => (
                          <>
                            <tr key={index} className="border-top ">
                              <td className="text-center text-xs 2xl:text-[13px]">
                                <button
                                  className="btn-success text-light btn px-2 py-0"
                                  disabled={key !== lastKey}
                                  onClick={() => {
                                    const cover =
                                      value?.["Option Price"] > 0
                                        ? "yes"
                                        : "no";
                                    setProduct(key);
                                    setCover(cover);
                                    setOption(value?.["Option Price"]);
                                    setBtn(1);
                                  }}
                                >
                                  BUY
                                </button>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px]">
                                <button
                                  className="btn-danger text-light btn px-2 py-0"
                                  disabled={key === lastKey}
                                  onClick={() => {
                                    const cover =
                                      value?.["Option Price"] > 0
                                        ? "yes"
                                        : "no";
                                    setProduct(key);
                                    setCover(cover);
                                    setOption(value?.["Option Price"]);
                                    setBtn(0);
                                  }}
                                >
                                  SELL
                                </button>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                WALR Series
                                <br />
                                {key}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Maturity Date"]}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Inventory"]}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Next Coupon Date"]}
                              </td>
                              <td
                                className="text-center text-xs 2xl:text-[13px] font-semibold cursor-pointer text-primary"
                                onClick={() =>
                                  handleSelectRow(key, index + 1, "WALR")
                                }
                              >
                                {value?.["Coupon post reset"]
                                  ? value["Coupon post reset"].toFixed(2)
                                  : (0).toFixed(2)}
                                <div className="bg-[#D8E5F9] rounded-full flex items-center gap-2 text-black px-2.5 py-0.5 mt-2.5 hover:scale-95 duration-200">
                                  <BsGraphUpArrow />
                                  <span>Performance</span>
                                </div>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Option Price"]
                                  ? value["Option Price"].toFixed(2)
                                  : (0).toFixed(2)}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Coupon at Issue"]
                                  ? value["Coupon at Issue"].toFixed(2)
                                  : (0).toFixed(2)}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold text-blue-600">
                                <span>
                                  <a
                                    href="https://website.rbi.org.in/web/rbi"
                                    target="blank"
                                  >
                                    website.rbi.org.in
                                  </a>
                                </span>
                              </td>
                            </tr>
                            {selectedRow === index + 1 && (
                              <tr key={index} className="border-bottom">
                                <td colSpan={9} className="text-center">
                                  <div className="flex justify-start">
                                    <LineGraph
                                      fundName="WALR"
                                      graphData={graphData}
                                      tableData={key}
                                      graphXAxis={graphXAxis}
                                    />
                                    <div>
                                      <h5 className="p-2 border rounded text-center m-1 bg-light text-dark">
                                        Growth <hr />
                                        <span className="text-success fw-bold">
                                          <FaArrowTrendUp color="green" />
                                          &nbsp;
                                          {tableData && tableData.length >= 0
                                            ? tableData[0]?.[0]
                                            : "N/A"}
                                        </span>
                                      </h5>
                                      <h5 className="p-2 border rounded text-center m-1 bg-light text-dark">
                                        Risk <hr />
                                        <span className="text-danger fw-bold">
                                          <FaArrowTrendDown color="red" />
                                          &nbsp;
                                          {tableData && tableData.length >= 0
                                            ? tableData[0]?.[1]
                                            : "N/A"}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))
                      )}
                    </>
                  ) : (
                    <>
                      {currentTransactions.map((item, index) =>
                        Object.entries(item).map(([key, value]) => (
                          <>
                            <tr key={index} className="border-top">
                              <td className="text-center text-xs 2xl:text-[13px]">
                                <button
                                  className="btn-success btn px-2 py-0"
                                  onClick={() => {
                                    setProduct(key);
                                    setBtn(2);
                                  }}
                                >
                                  BUY
                                </button>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px]">
                                <button
                                  className="btn-danger btn px-2 py-0"
                                  onClick={() => {
                                    setProduct(key);
                                    setBtn(2);
                                  }}
                                >
                                  SELL
                                </button>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                RESIDEX City
                                <br />
                                {key}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {getHoldingPeriod(residexTable, key)}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Inventory"]}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Next Coupon Date"]}
                              </td>
                              <td
                                className="text-center text-xs 2xl:text-[13px] font-semibold cursor-pointer text-primary"
                                onClick={() =>
                                  handleSelectRow(
                                    key,
                                    index + 1,
                                    "RESIDEX Data"
                                  )
                                }
                              >
                                {value?.["CAGR"].toFixed(2)}
                                <div className="bg-[#D8E5F9] rounded-full flex items-center gap-2 text-black px-2.5 py-0.5 mt-2.5 hover:scale-95 duration-200">
                                  <BsGraphUpArrow />
                                  <span>Performance</span>
                                </div>
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["3 mth Premium"]}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold">
                                {value?.["Price"]}
                              </td>
                              <td className="text-center text-xs 2xl:text-[13px] font-semibold text-blue-600">
                                <span>
                                  <a
                                    href="https://residex.nhbonline.org.in/"
                                    target="blank"
                                  >
                                    residex.nhbonline.org.in
                                  </a>
                                </span>
                              </td>
                            </tr>
                            {selectedRow === index + 1 && (
                              <tr key={index} className="border-bottom">
                                <td colSpan={9} className="text-center">
                                  <div className="flex justify-start">
                                    <LineGraph
                                      fundName={key}
                                      graphData={graphData}
                                      tableData={key}
                                      graphXAxis={graphXAxis}
                                    />
                                    <div>
                                      <h5 className="p-2 border rounded text-center m-1 bg-light text-dark">
                                        Growth <hr />
                                        <span className="text-success fw-bold">
                                          <FaArrowTrendUp color="green" />
                                          &nbsp;
                                          {tableData && tableData.length >= 0
                                            ? tableData[0]?.[0]
                                            : "N/A"}
                                        </span>
                                      </h5>
                                      <h5 className="p-2 border rounded text-center m-1 bg-light text-dark">
                                        Risk <hr />
                                        <span className="text-danger fw-bold">
                                          <FaArrowTrendDown color="red" />
                                          &nbsp;
                                          {tableData && tableData.length >= 0
                                            ? tableData[0]?.[1]
                                            : "N/A"}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {/* Bid Box */}
        <div className="w-full lg:max-w-[38%] xl:max-w-[400px] max-lg:mt-5">
          <BidBox
            Action2={"New Order"}
            timestamp={""}
            orderId={""}
            oldUnit={0}
            product={product}
            cover={cover}
            option={option}
            btn={btn}
            head={head}
            onClose2={() => {}}
            bidStatus={""}
          />
        </div>
      </div>
    </>
  );
}

export default ExchangeTable;

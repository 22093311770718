import React, { useState } from "react";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import { IoCalendarSharp } from "react-icons/io5";
import "./CalComponent.css";
import moment from "moment";
import DatePicker from "./DatePicker.jsx";
import { useNavigate } from "react-router-dom";
import DateDisplay from "./DateDisplay.jsx";
import Loader from "../Loaders/Loader2";
import { FaAngleDown } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa";

function ContractNotes() {
  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const [loader, setLoader] = useState(false);
  const [contractNotes, setContractNotes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [dates, setDates] = useState([
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 31)),
  ]);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [showModal, setShowModal] = useState(-1);
  const navigate = useNavigate();

  const handleDateClick = (index) => {
    setCurrentDateIndex(index);
    setShowModal(index);
  };

  const calculateDateRange = (days) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);
    setDates([startDate, endDate]);
  };

  const handleDateChange = (newDate) => {
    const newDates = [...dates];
    newDates[currentDateIndex] = newDate;
    setDates(newDates);
    setShowModal(-1);
  };

  const handleSubmit = () => {
    ContractNoteList(
      moment(dates[0]).format("YYYY-MM-DD"),
      moment(dates[1]).format("YYYY-MM-DD")
    );
    setShow(true);
  };

  const ContractNoteList = (fromDate, toDate) => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (isAuthenticated) {
      setLoader(true);
      fetch(`https://api-scdndestates.com/api/find-contract-notes/`, {
        method: "POST",
        body: JSON.stringify({
          pan: pan,
          startDate: fromDate,
          endDate: toDate,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          const sortedorders =
            data.data !== null &&
            data.data.sort(
              (a, b) => new Date(b.Timestamp) - new Date(a.Timestamp)
            );
          const mergeData = (data) => {
            const merged = {};
            if (Array.isArray(data)) {
              data.forEach((entry) => {
                const date = new Date(entry.Timestamp).toLocaleDateString(
                  "en-GB",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                );
                const key = `${date}`;

                if (!merged[key]) {
                  merged[key] = {
                    Date: date,
                    TotalBuy: 0,
                    TotalSell: 0,
                  };
                }
                if (entry["Price"] != 0) {
                  if (entry["Units"] < 0) {
                    merged[key].TotalBuy +=
                      entry["Units"] *
                      (entry?.["Option Price"]
                        ? entry["Price"] + entry["Option Price"]
                        : entry.Price);
                  } else if (entry["Units"] > 0) {
                    merged[key].TotalSell +=
                      entry["Units"] *
                      (entry?.["Option Price"]
                        ? entry["Price"] + entry["Option Price"]
                        : entry.Price);
                  }
                } else {
                  if (entry["Units"] < 0) {
                    merged[key].TotalBuy +=
                      entry["Units"] * entry["3 mth Premium"] * 1000;
                  } else if (entry["Units"] > 0) {
                    merged[key].TotalSell +=
                      entry["Units"] * entry["3 mth Premium"] * 1000;
                  }
                }
              });

              return Object.values(merged);
            } else {
              return [];
            }
          };
          const result = mergeData(data.data);
          setContractNotes(data.data);
          setTableData(result);
          setLoader(false);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handleDownload = (date) => {
    const filteredNotes = contractNotes.filter((note) => {
      const noteDate = new Date(note.Timestamp);
      const inputDate = new Date(date);
      return noteDate.toDateString() === inputDate.toDateString();
    });

    fetch(`https://api-scdndestates.com/api/download-contract-notes/`, {
      method: "POST",
      body: JSON.stringify({
        pan: pan,
        ContractNotes: filteredNotes,
        email: email,
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        // Return response blob instead of parsing as JSON
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = date + ".pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };
  const [contractNotesCustodian, setContractNotesCustodian] = useState(-1);

  const [selectedRange, setSelectedRange] = useState("");

  const handleClick = (range, callback) => {
    setSelectedRange(range);
    callback();
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="py-2 flex w-full lg:w-fit flex-wrap gap-4 pl-0 pr-0 lg:pl-3 text-black">
            <div className="border lg:ml-2 p-3 shadow-3 rounded-lg w-full md:w-fit">
              {/* Select a Date Range */}
              <div
                id="date-picker-container"
                className="w-fit lg:w-[300px] xl:w-fit "
              >
                <div id="date-picker-title">
                  <h4 className="pb-3 text-lg 2xl:text-xl font-bold">
                    Select a date range :
                  </h4>
                </div>
                {/* Quick Access */}
                <div className="flex flex-wrap gap-2 text-xs font-semibold">
                  <p
                    className={`${
                      selectedRange === "7 Days"
                        ? "bg-[#D8E5F9]"
                        : "bg-zinc-200"
                    } hover:bg-[#D8E5F9] rounded-full px-3 py-1 cursor-pointer duration-200`}
                    onClick={() =>
                      handleClick("7 Days", () => calculateDateRange(7))
                    }
                  >
                    7 Days
                  </p>
                  <p
                    className={`${
                      selectedRange === "15 Days"
                        ? "bg-[#D8E5F9]"
                        : "bg-zinc-200"
                    } hover:bg-[#D8E5F9] rounded-full px-3 py-1 cursor-pointer duration-200`}
                    onClick={() =>
                      handleClick("15 Days", () => calculateDateRange(15))
                    }
                  >
                    15 Days
                  </p>
                  <p
                    className={`${
                      selectedRange === "Last Month"
                        ? "bg-[#D8E5F9]"
                        : "bg-zinc-200"
                    } hover:bg-[#D8E5F9] rounded-full px-3 py-1 cursor-pointer duration-200`}
                    onClick={() =>
                      handleClick("Last Month", () => {
                        const startDate = new Date();
                        startDate.setMonth(startDate.getMonth() - 1);
                        setDates([startDate, new Date()]);
                      })
                    }
                  >
                    Last Month
                  </p>
                  <p
                    className={`${
                      selectedRange === "Last Quarter"
                        ? "bg-[#D8E5F9]"
                        : "bg-zinc-200"
                    } hover:bg-[#D8E5F9] rounded-full px-3 py-1 cursor-pointer duration-200`}
                    onClick={() =>
                      handleClick("Last Quarter", () => {
                        const startDate = new Date();
                        startDate.setMonth(startDate.getMonth() - 3);
                        setDates([startDate, new Date()]);
                      })
                    }
                  >
                    Last Quarter
                  </p>
                </div>

                <hr className="my-2" />

                {/* Select Date */}
                <div
                  id="date-picker-dates"
                  className="flex justify-between relative xl:py-3"
                >
                  {dates.map((date, index) => (
                    <>
                      <div
                        key={index}
                        className={`flex border px-3 py-2 items-center rounded-lg cursor-pointer gap-1 text-sm md:text-base xl:text-lg hover:bg-[#D8E5F9] duration-200`}
                        onClick={() => handleDateClick(index)}
                      >
                        <span className="font-semibold w-[70px] md:w-[80px] xl:w-[90px] 2xl:w-[100px]">
                          {index === 0 ? "Start Date" : "End Date"}
                        </span>
                        <span className="ml-2 ">
                          <IoCalendarSharp size={18} />
                        </span>
                      </div>

                      <span
                        className="absolute"
                        style={{
                          top: "60px",
                          ...(index === 0 ? { left: "0px" } : { right: "0px" }),
                        }}
                      >
                        {showModal === index && (
                          <DatePicker
                            model={showModal}
                            date={dates[currentDateIndex]}
                            onChange={handleDateChange}
                            onClose={() => setShowModal(-1)}
                          />
                        )}
                      </span>
                    </>
                  ))}
                </div>

                {/* Selected Date */}
                <div
                  id="date-picker-display-container"
                  className="flex justify-between mt-2"
                >
                  {dates.map((date, index) => (
                    <DateDisplay
                      key={index}
                      date={date}
                      label={`${index === 0 ? "Start " : "End "}Date`}
                    />
                  ))}
                </div>

                {/* Submit Button */}
                <div
                  id="date-picker-display-container"
                  className="flex justify-center mt-4"
                >
                  <button
                    className="bg-[#386BC0] px-4 py-1.5 rounded text-white font-semibold text-sm "
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {/* Contract Notes */}
            <div className="border rounded-lg w-full md:w-fit shadow-3 overflow-hidden">
              <div className="bg-[#3B71CA] px-2 py-2 rounded-t">
                <h2 className="font-bold text-white text-base md:text-lg">
                  Contract Notes
                </h2>
              </div>
              {/* Table div */}

              <div className="max-h-[370px] overflow-scroll  mt-2 lg:max-w-[320px] xl:min-w-[490px]">
                {Array.isArray(tableData) && tableData.length > 0 ? (
                  tableData.map((data, index) => (
                    <div key={index} className="flex flex-col mt-1 px-3 py-1">
                      <div className="flex items-center gap-1">
                        <div
                          onClick={() => {
                            if (contractNotesCustodian === index)
                              setContractNotesCustodian(-1);
                            else setContractNotesCustodian(index);
                          }}
                          className="flex w-full z-2 relative justify-between items-center bg-white px-3 py-2.5 md:gap-[50px] lg:gap-[60px] xl:gap-[150px] rounded-lg hover:bg-[#396cb7] duration-200 cursor-pointer border font-bold"
                        >
                          <p>{data["Date"] ? data["Date"] : ""}.pdf</p>
                          <span
                            className={`${
                              contractNotesCustodian === index
                                ? "rotate-180"
                                : ""
                            } flex items-center gap-2 duration-300`}
                          >
                            <FaAngleDown
                              onClick={() => {
                                if (contractNotesCustodian === index)
                                  setContractNotesCustodian(-1);
                                else setContractNotesCustodian(index);
                              }}
                            />
                          </span>
                        </div>
                        <span
                          onClick={() =>
                            handleDownload(data["Date"] ? data["Date"] : "")
                          }
                          className="flex items-center justify-center px-2 py-[12.5px] bg-white border rounded-lg cursor-pointer"
                        >
                          <FaFilePdf className="text-red-700" />
                        </span>
                      </div>

                      {/* Custodian Data */}
                      <div
                        className={`mr-[36px] flex relative flex-col justify-between px-3 gap-2 border-x-2 py-2 border-b font-semibold rounded-b-lg transition-transform duration-300 ease-in-out accordionItem ${
                          contractNotesCustodian === index
                            ? "is-expanded animateIn"
                            : "animateOut"
                        }`}
                      >
                        <div className="flex justify-between">
                          <p>Total Buy:</p>
                          <p className="font-bold">
                            &nbsp; ₹{" "}
                            {data["TotalBuy"]
                              ? Math.abs(data["TotalBuy"]).toLocaleString(
                                  "en-IN"
                                )
                              : 0}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <p>Total Sell:</p>
                          <p className="font-bold">
                            &nbsp; ₹{" "}
                            {data["TotalSell"]
                              ? Math.abs(data["TotalSell"]).toLocaleString(
                                  "en-IN"
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center px-2  md:min-w-[250px] lg:min-w-[400px] overflow-hidden ">
                    <img
                      className="max-md:scale-[60%] md:scale-75"
                      src="./images/ghost2.gif"
                      alt=""
                    />
                    <span className="text-center text-xs md:text-sm   font-semibold">
                      {" "}
                      You don't have any Transactions.
                    </span>
                    <img
                      className="max-md:scale-[60%] md:scale-75"
                      src="./images/ghost2.gif"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ContractNotes;

import "./ContactUs.css";
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

function ContactUs() {
  gsap.registerPlugin(ScrollTrigger);
  const stepRefs = useRef([]);

  useEffect(() => {
    stepRefs.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, x: -200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 80%",
            end: "top 50%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="w-full flex justify-center ">
      <div className="h-max mt-10 lg:mt-14 bg-white w-[90%] md:w-[87%]">
        <div
          className="header-title flex flex-col  text-center   xl:text-left leading-tight  font-semibold tracking-wide text-gray-800 p-2   mb-1 "
          ref={(el) => (stepRefs.current[0] = el)}
        >
          <div className="font-bold  mt-1 mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            Our promise in your journey to switch
          </div>
          <div className="font-bold  mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            <span className="text-sky-400">beyond Mutual Funds</span>
          </div>
        </div>

        <div
          className="w-full flex justify-center items-start mt-7 md:mt-14 gap-1 md:gap-3 px-2 md:px-0"
          ref={(el) => (stepRefs.current[1] = el)}
        >
          <div className="w-52 lg:w-60 justify-center ">
            <div className="flex items-center ">
              <div className="max-w-20 w-full">
                <img src="./images/edit.png" className="w-16" alt="" />
              </div>
              <div className="w-full h-0.5 rounded-3xl bg-green-900 mr-2"></div>
            </div>
            <div>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg md:leading-6 font-bold mt-1">
                Register
              </p>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg md:leading-6 font-bold">
                using OTP
              </p>
            </div>
          </div>

          <div className="w-52 lg:w-60 justify-center ">
            <div className="flex items-center ">
              <div className="max-w-20 w-full">
                <img src="./images/share.png" className="w-16" alt="" />
              </div>
              <div className="w-full h-0.5 rounded-3xl bg-green-900 mr-2"></div>
            </div>
            <div>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg md:leading-6 font-bold mt-1">
                PAN +
              </p>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg md:leading-6 font-bold ">
                Penny-drop
              </p>
            </div>
          </div>

          <div className="w-52 lg:w-60 justify-center ">
            <div className="flex items-center ">
              <div className="max-w-20 w-full">
                <img src="./images/networking.png" className="w-16" alt="" />
              </div>
              <div className="w-full h-0.5 rounded-3xl bg-green-900 mr-2"></div>
            </div>
            <div>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg  font-bold mt-1 md:leading-6">
                Install Opensource
              </p>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg  font-bold md:leading-6 ">
                Blockchain
              </p>
            </div>
          </div>

          <div className="w-max justify-center ">
            <div className="flex items-center ">
              <div className="max-w-20 w-full">
                <img src="./images/Ready-to-go.png" className="w-16" alt="" />
              </div>
            </div>
            <div>
              <p className="text-sky-400 text-xs md:text-base lg:text-lg  font-bold mt-1 md:leading-6">
                Ready to Trade
              </p>
            </div>
          </div>
        </div>

        <div
          className="w-full flex justify-center mt-10"
          ref={(el) => (stepRefs.current[2] = el)}
        >
          <div className=" flex flex-wrap justify-center max-sm:py-3 max-sm:px-0 md:p-3 xl:p-5 max-sm:gap-5 md:gap-20 rounded-xl">
            <div class="home-container shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024] overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card overflow-hidden">
                <div class="overflow-hidden img-content h-4/6">
                  <div className="content-img  after:bg-[#e8d8ff] after:border after:border-[#4bc9ff33] border-2 border-[#ceaeff]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="./images/empowerment.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                  <p className="text-[#b996ee] group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Empowerment
                  </p>
                  <p className="text-[#8a6fb1] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We empower you to take direct control of the value of your
                    assets ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-lg lg:text-2xl font-bold text-[#a983e2]">
                    Empowerment
                  </p>
                  <p className="font-semibold text-sm text-[#745b97]">
                    We empower you to take direct control of the value of your
                    assets. Whether you are saving for a home or retirement (and
                    everything in between), we provide the tools and
                    infrastructure to remove all unknowns and contingencies in
                    your path.
                  </p>
                </div>
              </div>
            </div>

            <div class="home-container shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024]  duration-300 ease-in-out overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card overflow-hidden">
                <div class="   overflow-hidden img-content h-4/6">
                  <div className="content-img  after:bg-[#bbf5c6] after:border after:border-[#4bc9ff33] border-2 border-[#79AC78]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="./images/scales.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                  <p className="text-[#67b965] group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Fairness
                  </p>
                  <p className="text-[#40813f] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We believe that everyone deserves a fair shot at financial
                    success ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-lg lg:text-2xl font-bold text-[#4c9e4b]">
                    Fairness
                  </p>
                  <p className="font-semibold text-sm text-[#336e32]">
                    We believe that everyone deserves a fair shot at financial
                    success. Our platform and services are designed to level the
                    playing field, offering you the same opportunities that were
                    once reserved for the financial elite.
                  </p>
                </div>
              </div>
            </div>

            <div class="home-container shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024] overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card overflow-hidden">
                <div class="   overflow-hidden img-content h-4/6">
                  <div className="content-img  after:bg-[#FFF8C9] after:border after:border-[#4bc9ff33] border-2 border-[#fff196]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="./images/lightbulb.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                  <p className="text-[#e6d35a] group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Innovation
                  </p>
                  <p className="text-[#a79943] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We leverage cutting-edge AI and Distributed Ledger
                    technology to ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-2xl font-bold text-[#d8c54b]">Innovation</p>
                  <p className="font-semibold text-sm text-[#817732]">
                    We leverage cutting-edge AI and Distributed Ledger
                    technology to create innovative financial solutions that is
                    aimed at removing all centralized price-setters and
                    record-keepers, whose own interests always take precedence
                    over your money.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 md:mt-10 flex  items-center justify-center ">
          <a
            class=" text-sm lg:text-base group items-center inline-flex w-max text-center  font-semibold text-sky-400 hover:text-sky-500  duration-300 bg-white/5  rounded-3xl hover:-translate-y-1 "
            href="/UserKycForm"
          >
            Start your KYC
            <div class="arrow-move ml-1">
              <svg
                className=" fill-sky-400 group-hover:fill-sky-500"
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="30"
                height="30"
              >
                <path d="M18,12h0a2,2,0,0,0-.59-1.4l-4.29-4.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L15,11H5a1,1,0,0,0,0,2H15l-3.29,3.29a1,1,0,0,0,1.41,1.42l4.29-4.3A2,2,0,0,0,18,12Z" />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

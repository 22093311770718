import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const PieChart = ({ data, showFunction, navigateFunction }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svgElement = d3.select(svgRef.current);

    const renderChart = () => {
      svgElement.selectAll("*").remove();

      const pieData = data.sort((a, b) => b.value - a.value);

      const containerWidth = svgElement.node().parentNode.offsetWidth;
      const containerHeight = containerWidth * 0.7; 
      const thickness = containerWidth * 0.1; 
      const radius = Math.min(containerWidth, containerHeight) / 2.35;

      const colorArray = pieData.map((d) => d.color);
      const color = d3.scaleOrdinal().range(colorArray);

      const svg = svgElement
        .append("svg")
        .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
        .attr("width", containerWidth)
        .attr("height", containerHeight);

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${containerWidth / 2.8}, ${containerHeight / 2})`
        );

      const arc = d3
        .arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius);

      const arcHover = d3
        .arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius + 15);

      const pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null);

    // Add default value text in the center
    const centerText = g
      .append("text")
      .attr("class", "center-text")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em");

      const paths = g
        .selectAll("path")
        .data(pie(pieData))
        .enter()
        .append("g")
        .each(function (d) {
          const group = d3.select(this);

          group
            .append("text")
            .text(`${parseInt(d.data.value).toLocaleString("en-IN")}`)
            .attr("class", "data-text data-text__value")
            .attr("text-anchor", "middle")
            .attr("dy", `${radius * 0.01}rem`);

          const textGroup = group
            .append("g")
            .attr("class", "details-group")
            .style("cursor", "pointer");

          textGroup
            .append("text")
            .text("See Details")
            .attr("class", "data-text data-text__name")
            .attr("text-anchor", "middle")
            .on("click", function (event, d) {
              navigateFunction();
            });

          textGroup
            .append("path")
            .attr("d", "M5,0 L10,5 L5,10") // Right arrow path // Adjust position
            .attr("class", "data-text arrow-path")
            .style("stroke", "#3b71ca")
            .style("fill", "none")
            .style("stroke-width", 3)
            .on("click", function (event, d) {
              navigateFunction();
            });
        });

      paths
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => color(d.data.name))
        .attr("class", "data-path")
        .on("mouseover", function (event, d) {
          showFunction(d.data.name);
          const _thisPath = this;
          const parentNode = _thisPath.parentNode;

          d3.selectAll(".data-text").classed("data-text--show", false);
          d3.selectAll(".data-path").transition().duration(250).attr("d", arc);

          d3.select(_thisPath).transition().duration(250).attr("d", arcHover);

          d3.select(parentNode)
            .select(".data-text__value")
            .classed("data-text--show", true);
            
          d3.select(parentNode)
            .select(".data-text")
            .classed("data-text--show", true);
        });

      const legendRectSize = containerWidth * 0.02;
      const legendSpacing = containerWidth * 0.01;

      const legend = svg
        .selectAll(".legend")
        .data(color.domain())
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          const itemHeight = legendRectSize + legendSpacing;
          const horz = containerWidth - legendRectSize - legendSpacing * 25;
          const vert = i * itemHeight + legendSpacing * 30;
          return `translate(${horz}, ${vert})`;
        });

      legend
        .append("circle")
        .attr("r", legendRectSize / 2)
        .style("fill", color);

      legend
        .append("text")
        .attr("x", legendRectSize + legendSpacing)
        .attr("y", legendRectSize - legendSpacing)
        .attr("class", "legend-text")
        .text((d) => d)
        .style("font-size", `${legendRectSize}px`);
    };

    renderChart();

    // Redraw chart on window resize
    window.addEventListener("resize", renderChart);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", renderChart);
    };
  }, [data]);

  return <div ref={svgRef}></div>;
};

export default PieChart;

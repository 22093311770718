import React, { useState, useEffect } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Shareholdingsapi from "../../API/ShareholdingsApi.js";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header.jsx";
import { IoIosArrowDown } from "react-icons/io";
function Shareholdings() {
  const [selectedCard, setSelectedCard] = useState(Shareholdingsapi[0]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const { isAuthenticated } = CheckAuth();

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setSelectedDropdownValue("");
  };

  const handleDropdownChange = (e) => {
    const selectedId = e.target.value;
    const card = Shareholdingsapi.find(
      (c) => c.id === parseInt(selectedId, 10)
    );
    if (card) {
      setSelectedCard(card);
    }
    setSelectedDropdownValue(selectedId);
  };

  const [openSection, setOpenSection] = useState("Indian"); // Default open section

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  return (
    <div>
      <Header />
      {!isAuthenticated ? (
        <PopUp1 />
      ) : (
        <>
          <div className="container-fluid after-header pb-5 max-w-[1800px] mx-auto">
            <div className="flex flex-col w-[90%] mx-auto">
              <h6 className="mt-4 w-full text-sm md:text-base">
                <a href="/">
                  <span className="header-link">Home</span>
                  <span className="text-secondary"> &gt; </span>
                </a>
                <a href="#">
                  <span className="text-dark text-decoration-underline">
                    Shareholdings
                  </span>
                </a>
                <hr className="text-dark mb-0" />
              </h6>
              <h2 className="w-full text-center mt-4 mb-2 py-2 text-base lg:text-2xl font-bold text-black bg-gradient-to-r from-white via-[#DBEDF9] to-white">
                Shareholdings
              </h2>
              <h6 className="text-center font-semibold text-sm">
                SCDND ESTATES PRIVATE LIMITED
              </h6>
              <p className="text-center font-semibold text-sm">
                (Equity Share Capital Breakup as percentage of Total Equity)
              </p>
            </div>

            <div className="flex flex-wrap gap-5 w-[90%] mx-auto justify-start mt-5">
              {/* Left Section */}
              <div className="py-4 px-4 w-full lg:min-w-[235px] lg:w-[17%] h-fit border hover:shadow-sm rounded-lg">
                <div className="font-bold">
                  <h2 className="text-black">Recent Reports</h2>
                  <hr className="my-1" />
                  <div className="flex flex-wrap justify-center lg:flex-col mt-3 gap-2">
                    {Shareholdingsapi.sort((a, b) => b.id - a.id)
                      .slice(0, 3)
                      .map((card, index) => (
                        <div
                          onClick={() => handleSelectCard(card)}
                          key={index}
                          className={`${
                            selectedCard.id === card.id ? "bg-[#DBEDF9]" : ""
                          } button-year border min-w-full md:min-w-[200px] lg:min-w-[150px] p-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-[#DBEDF9] duration-200 text-black`}
                        >
                          {card.startYear} - {card.endYear}
                        </div>
                      ))}
                  </div>
                </div>

                <div className="font-bold mt-4">
                  <h2 className="text-black">More Reports</h2>
                  <hr className="my-1" />
                  <div className="select-container">
                    <select
                      name="reports"
                      id="reports"
                      onChange={handleDropdownChange}
                      value={selectedDropdownValue}
                      className="p-2 border rounded-md flex items-center justify-center w-full"
                    >
                      <option value="">Explore</option>
                      {Shareholdingsapi.sort((a, b) => b.id - a.id)
                        .slice(3)
                        .map((card) => (
                          <option
                            key={card.id}
                            value={card.id}
                            className="text-black"
                          >
                            {card.startYear} - {card.endYear}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="justify-center border rounded-lg shadow-md  w-full lg:w-[68%] xl:w-[74%] p-[1%]">
                <div className="row justify-content-center">
                  <div className="">
                    {selectedCard && (
                      <>
                        <div className="table-responsive rounded-lg">
                          <table className="table table-border table-sm font-size1">
                            <thead className="bg-[#3B82F6] text-white">
                              <tr>
                                <th className="fw-bold" scope="col" rowSpan={2}>
                                  Category of Shareholders
                                </th>
                                <th
                                  className="fw-bold text-center"
                                  colSpan={4}
                                  scope="col"
                                >
                                  No. of Shares held at the beginning of the
                                  year
                                </th>
                                <th
                                  className="fw-bold text-center"
                                  colSpan={4}
                                  scope="col"
                                >
                                  No. of Shares held at the beginning of the
                                  year
                                </th>
                                <th
                                  className="fw-bold text-center"
                                  rowSpan={2}
                                  scope="col"
                                >
                                  % Change during the year
                                </th>
                              </tr>
                              <tr className="border-t-1 border-[#E5E7EB] rounded-lg">
                                <th className="fw-bold text-center" scope="col">
                                  Demat
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  Physical
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  Total
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  % of Total Shares
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  Demat
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  Physical
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  Total
                                </th>
                                <th className="fw-bold text-center" scope="col">
                                  % of Total Shares
                                </th>
                              </tr>
                            </thead>

                            <tbody className="text-black">
                              <tr className="heading2-style">
                                <td className="fw-bold">A. Promoters</td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                              </tr>

                              <tr
                                className="heading2-style cursor-pointer bg-[#E5E7EB] text-[#1E3A8A] relative"
                                onClick={() => toggleSection("Indian")}
                              >
                                <td className="fw-bold">(1) Indian</td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold"></td>
                                <span
                                  className={`absolute top-1 right-2 text-base font-bold duration-300 ${
                                    openSection === "Indian" ? "rotate-180" : ""
                                  }`}
                                >
                                  <IoIosArrowDown />
                                </span>
                              </tr>
                              {openSection === "Indian" && (
                                <>
                                  <tr className="font-bold">
                                    <td>Individual/HUF</td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[0]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[1]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[2]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[3]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[4]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[5]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[6]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[7]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1_a[8]}
                                    </td>
                                  </tr>
                                  <tr className="font-bold">
                                    <td>Central Govt.</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr className="font-bold">
                                    <td>State Govt(s)</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr className="font-bold">
                                    <td>Bodies Corp.</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr className="font-bold">
                                    <td>Banks / FI</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr className="font-bold">
                                    <td>Any Other</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">
                                      Sub-total (A)(1):
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[0]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[1]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[2]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[3]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[4]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[5]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[6]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[7]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_1[8]}
                                    </td>
                                  </tr>
                                </>
                              )}

                              <tr
                                className="heading2-style cursor-pointer bg-[#E5E7EB] text-[#1E3A8A] relative"
                                onClick={() => toggleSection("Foreign")}
                              >
                                <td className="fw-bold">(2) Foreign</td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <span
                                  className={`absolute top-1 right-2 text-base font-bold duration-300 ${
                                    openSection === "Foreign"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                >
                                  <IoIosArrowDown />
                                </span>
                              </tr>
                              {openSection === "Foreign" && (
                                <>
                                  <tr>
                                    <td className="font-bold">
                                      NRIs - Individuals{" "}
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Other – Individuals
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Bodies Corp.</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Banks / FI</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Banks / FI</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Sub-total (A)(2):
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[0]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[1]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[2]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[3]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[4]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[5]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[6]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[7]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.promoters_2[8]}
                                    </td>
                                  </tr>
                                </>
                              )}

                              <tr>
                                <td className="fw-bold">
                                  Total shareholding of Promoter (A) =
                                  (A)(1)+(A)(2)
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[2]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[3]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[4]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[5]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[6]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[7]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.promoters_total[8]}
                                </td>
                              </tr>

                              <tr className="heading2-style">
                                <td className="fw-bold">
                                  B. Public Shareholding
                                </td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                              </tr>

                              <tr
                                className="heading2-style cursor-pointer bg-[#E5E7EB] text-[#1E3A8A] relative"
                                onClick={() => toggleSection("Institutions")}
                              >
                                <td className="fw-bold">(1) Institutions</td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <span
                                  className={`absolute top-1 right-2 text-base font-bold duration-300 ${
                                    openSection === "Institutions"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                >
                                  <IoIosArrowDown />
                                </span>
                              </tr>
                              {openSection === "Institutions" && (
                                <>
                                  <tr>
                                    <td className="font-bold">Mutual Funds</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Banks / FI</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Central Govt.</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">State Govt(s)</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Venture Capital Funds
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Insurance Companies
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">FIIs</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Foreign Venture Capital Funds
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Others (Specify)
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">
                                      Sub-total (B)(1):
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[0]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[1]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[2]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[3]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[4]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[5]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[6]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[7]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_1[8]}
                                    </td>
                                  </tr>
                                </>
                              )}

                              <tr
                                className="heading2-style cursor-pointer bg-[#E5E7EB] text-[#1E3A8A] relative"
                                onClick={() =>
                                  toggleSection("Non-Institutions")
                                }
                              >
                                <td className="fw-bold">
                                  (2) Non-Institutions
                                </td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <span
                                  className={`absolute top-1 right-2 text-base font-bold duration-300 ${
                                    openSection === "Non-Institutions"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                >
                                  <IoIosArrowDown />
                                </span>
                              </tr>

                              {openSection === "Non-Institutions" && (
                                <>
                                  <tr>
                                    <td className="font-bold">Bodies Corp.</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">i) Indian</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">ii) Overseas </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">Individuals</td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      i) Individual shareholders holding <br />{" "}
                                      nominal share capital upto Rs. 1 lakh
                                    </td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[0]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[1]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[2]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[3]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[4]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[5]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[6]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[7]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_i[8]}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      ii) Individual shareholders holding <br />
                                      nominal share capital in excess of Rs 1
                                      lakh
                                    </td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[0]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[1]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[2]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[3]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[4]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[5]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[6]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[7]}</td>
                                    <td className="fw-bold text-center">{selectedCard.public_2_b_ii[8]}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-bold">
                                      Others (Specify)
                                    </td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                    <td className="fw-bold text-center"></td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">
                                      Sub-total (B)(2):
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[0]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[1]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[2]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[3]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[4]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[5]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[6]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[7]}
                                    </td>
                                    <td className="fw-bold text-center">
                                      {selectedCard.public_2[8]}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                </>
                              )}

                              <tr>
                                <td className="fw-bold">
                                  Total Public Shareholding (B)=(B)(1)+ (B)(2)
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[2]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[3]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[4]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[5]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[6]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[7]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.public_total[8]}
                                </td>
                              </tr>

                              <tr className="heading2-style">
                                <td className="fw-bold">
                                  C. Shares held by Custodian for GDRs & ADRs
                                </td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                                <td className="fw-bold text-center"></td>
                              </tr>
                              <tr className="fw-bold">
                                <td>Grand Total (A+B+C)</td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[0]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[1]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[2]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[3]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[4]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[5]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[6]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[7]}
                                </td>
                                <td className="fw-bold text-center">
                                  {selectedCard.grand_total[8]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Shareholdings;

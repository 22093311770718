import React, { useState } from "react";
import "./Trello.css";
import TrelloBoard from "../../components/TrelloBoard/TrelloBoard";

const initialLists = [
  {
    title: "RESIDEX",
    status: "todo",
  },

  {
    title: "Drop your Priorities",
    status: "done",
  },
];

const initialData = {
  todo: [
    {
      id: "qwe1",
      title: "RESIDEX",
      status: "todo",
      order: 1,
      label: "Decentralized REITs/InvITs",
    },
    {
      id: "qwe2",
      title: "RESIDEX",
      status: "todo",
      order: 2,
      label: "NAV equal to Housing Price Index",
    },
    {
      id: "qwe3",
      title: "RESIDEX",
      status: "todo",
      order: 3,
      label: "Prices not Controlled",
    },
    {
      id: "qwe4",
      title: "RESIDEX",
      status: "todo",
      order: 4,
      label: "Client-funds are Secured",
    },
    {
      id: "qwe5",
      title: "RESIDEX",
      status: "todo",
      order: 5,
      label: "Dividend fixed at 3%",
    },
    {
      id: "qwe6",
      title: "RESIDEX",
      status: "todo",
      order: 6,
      label: "Removes Information Asymmetry",
    },
    {
      id: "qwe7",
      title: "RESIDEX",
      status: "todo",
      order: 7,
      label: "Zero Maintenance & Tenancy burden",
    },
    {
      id: "qwe8",
      title: "RESIDEX",
      status: "todo",
      order: 8,
      label: "Management Fee is 0.5%",
    },
    {
      id: "qwe9",
      title: "RESIDEX",
      status: "todo",
      order: 9,
      label: "Zero Stamp-duty, Brokerage, Property Taxes",
    },
    {
      id: "qwe10",
      title: "RESIDEX",
      status: "todo",
      order: 10,
      label: "Illiquidity of real-estate removed",
    },
  ],
  done: [],
};

export default function Trello({ onPriorityListChange }) {
  const [lists] = useState(initialLists);
  const [data, setData] = useState(initialData);

  const cardChangeHandler = (cardInfo, newStatus, targetCardId) => {
    const { id, status: oldStatus } = cardInfo;

    let dropCard = data[oldStatus].find((el) => el.id === id);
    let targetCard =
      targetCardId !== ""
        ? data[newStatus].find((el) => el.id === targetCardId)
        : null;
    let newListOrderValueMax = data[newStatus]
      .map((item) => item.order)
      .reduce((maxValue, a) => Math.max(maxValue, a), 0);

    if (oldStatus === newStatus) {
      let temp = data[oldStatus]
        .map((item) => {
          if (item.id === dropCard.id)
            return {
              ...dropCard,
              order: targetCard
                ? targetCard.order - 1
                : newListOrderValueMax + 1,
            };
          return item;
        })
        .sort((a, b) => a.order - b.order)
        .map((item, i) => ({ ...item, order: i + 1 }));
      setData((d) => ({ ...d, [oldStatus]: temp }));
      return;
    }

    let tempGaveList = data[oldStatus]
      .filter((item) => item.id !== id)
      .sort((a, b) => a.order - b.order)
      .map((item, i) => ({ ...item, order: i + 1 }));

    let tempRecievedList = [
      ...data[newStatus],
      {
        ...dropCard,
        order: targetCard ? targetCard.order - 1 : newListOrderValueMax + 1,
      },
    ]
      .sort((a, b) => a.order - b.order)
      .map((item, i) => ({ ...item, order: i + 1 }));

    setData((d) => ({
      ...d,
      [oldStatus]: tempGaveList,
      [newStatus]: tempRecievedList,
    }));

    if (newStatus === "done") {
      const newPriorityList = tempRecievedList.map((item) => item.label);
      onPriorityListChange(newPriorityList);
    }
  };

  return (
    <div className="trello_page">
      <div className="app-sidebar"></div>
      <div className="app-content-area">
        <main className="app-board">
          <section className="board-body mx-auto">
            <div className="flex flex-col gap-y-5 text-lg text-center font-semibold">
              {lists.map((l) => (
                <TrelloBoard
                  data={data[l.status]}
                  key={l.status}
                  title={l.title}
                  status={l.status}
                  onChange={cardChangeHandler}
                />
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
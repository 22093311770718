import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar.jsx";
import SideBar from "./SideBar.jsx";
import DatabaseOperations from "./DatabaseOperations.jsx";
import ManageDocuments from "./ManageDocument.jsx";
import "./admin.css";
import Dashboard from "./dashboard.jsx";
import AssetLiabilityMatch from "./AssetLiabilityMatch.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";
import UserDocuments from "./UserDocuments.jsx";
import AgentsComissions from "./AgentsComissions.jsx";

function Admin() {
  const { pan, isAuthenticated } = CheckAuth();
  const [menuItem, setMenuItem] = useState(0);
  const [sideBar, setSideBar] = useState(true);
  const navigate = useNavigate();


  const handleCloseSideBar = () => {
    setSideBar(!sideBar);
  };

  const handleMenuItem = (item) => {
    if (item === 6) {
      navigate("/TradingPortal");
    }
    setMenuItem(item);
  };

  return (
    <>
      <div className="bg-[#EEEFEF]">
        <Navbar handleSidebar={handleCloseSideBar} />
        <div className="container-fluid p-0 m-0 flex">
          <SideBar
            className=""
            sideBar={sideBar}
            handleMenuItem={handleMenuItem}
          />
          <div className="after-admin-header overflow-hidden">
            {menuItem === 0 ? (
              <Dashboard />
            ) : menuItem === 1 ? (
              <DatabaseOperations />
            ) : menuItem === 2 ? (
              <ManageDocuments />
            ) : menuItem === 3 ? (
              <UserDocuments />
            ) : menuItem === 4 ? (
              <AgentsComissions />
            ) : menuItem === 5 ? (
              <AssetLiabilityMatch />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
import React from "react";
import Chart from "react-apexcharts";

const Linechart = ({ fundName, tableData, graphData, graphXAxis }) => {
  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };
  
  const data = [
    {
      name: tableData[0]?.[2],
      data: graphData[0],
    },
    {
      name: tableData[1]?.[2],
      data: graphData[1],
    },
    {
      name: tableData[2]?.[2],
      data: graphData[2],
    },
    {
      name: tableData[3]?.[2],
      data: graphData[3],
    },
    {
      name: tableData[4]?.[2],
      data: graphData[4],
    },
  ];
  const data2 = [
    {
      name: "Residex Bond",
      data: graphData["House Price"],
    },
    {
      name: "Case-Shiller US",
      data: graphData["Case-Shiller US"],
    },
    {
      name: "China Residential",
      data: graphData["China Residential"],
    },
    {
      name: "Global REITs",
      data: graphData["Global REITs"],
    },
    {
      name: "Nifty Realty",
      data: graphData["Nifty Realty"],
    },
  ];

  const option = {
    chart: {
      toolbar: {
        tools: {
          download: false,
        },
      },
    },
    xaxis: {
      categories: graphXAxis,
      tickAmount: 18,
    },
    yaxis: {
      title: { text: "Investments (₹)" },
      labels: {
        formatter: function (value) {
          return formatRupees(value / 1000) + "K";
        },
      },
    },
    stroke: {
      dashArray: [5, 0, 0, 0, 0],
      width: 2,
    },
  };

  return (
    <React.Fragment>
      <div className="mt-3 mb-3">
        {fundName === "WALR" ? (
          <Chart
            type="line"
            series={data}
            height={410}
            options={option}
          ></Chart>
        ) : (
          <Chart type="line" series={data2} options={option} height={410}></Chart>
        )}
      </div>
    </React.Fragment>
  );
};

export default Linechart;
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header";
import ConfirmPopUp from "../../components/PopUp1/ConfirmPopUp";
import secureLocalStorage from "react-secure-storage";
import "./fontFamily.css";
function PaymentPage() {
  const { email, phone, isAuthenticated, pan } = CheckAuth();
  const [x, setX] = useState("");
  const [a, setA] = useState(true);
  const [show, setShow] = useState(false);
  const [bankWebsite, setBankWebsite] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [isDropdownVisible1, setDropdownVisible1] = useState(false);
  const location = useLocation();
  const { paymentData } = location.state;
  const { topUpAmount, balance } = paymentData;
  const amount = parseFloat(topUpAmount);
  const amount2 = parseFloat(balance);
  const storedData = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData);

  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const balanceAmount = amount2.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const total = amount + amount2;
  const totalAmount = total.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const bankWebsites = {
    // Public Sector Banks
    "State Bank of India": "https://www.onlinesbi.sbi/",
    "Bank of Baroda": "https://www.bankofbaroda.in/",
    "Bank of India": "https://bankofindia.co.in/",
    "Bank of Maharashtra": "https://bankofmaharashtra.in/",
    "Canara Bank": "https://www.canarabank.com/",
    "Central Bank of India": "https://www.centralbankofindia.co.in/en",
    "Indian Bank": "https://www.indianbank.in/",
    "Indian Overseas Bank": "https://www.iob.in/",
    "Punjab & Sind Bank": "https://punjabandsindbank.co.in/",
    "Punjab National Bank": "https://www.pnbindia.in/",
    "UCO Bank": "https://www.ucobank.com/hi/",
    "Union Bank of India": "https://www.unionbankonline.co.in/",

    // Private Sector Banks
    "Axis Bank": "https://www.axisbank.com/",
    "Bandhan Bank": "https://www.bandhanbank.com/",
    "Catholic Syrian Bank": "https://www.csb.co.in/",
    "City Union Bank": "https://www.cityunionbank.com/",
    "DCB Bank": "https://www.dcbbank.com/",
    "Dhanlaxmi Bank": "https://www.dhanbank.com/",
    "Federal Bank": "https://www.federalbank.co.in/",
    "HDFC Bank": "https://www.hdfcbank.com/",
    "ICICI Bank": "https://www.icicibank.com/",
    "IDBI Bank": "https://www.idbibank.in/",
    "IDFC FIRST Bank": "https://www.idfcfirstbank.com/",
    "IndusInd Bank": "https://www.indusind.com/",
    "Jammu & Kashmir Bank": "https://www.jkbank.com/",
    "Karnataka Bank": "https://karnatakabank.com/",
    "Karur Vysya Bank": "https://www.kvb.co.in/",
    "Kotak Mahindra Bank": "https://www.kotak.com/",
    "RBL Bank": "https://www.rblbank.com/",
    "South Indian Bank": "https://www.southindianbank.com/",
    "YES Bank": "https://www.yesbank.in/",
    "Nainital bank Ltd.": "https://www.nainitalbank.co.in/english/home.aspx",
    "Tamilnad Mercantile Bank Ltd.": "https://tmb.in/",
  };

  const handleBankSelection = (bankName) => {
    setBankWebsite(bankWebsites[bankName]);
  };

  const handleHover1 = () => {
    setDropdownVisible1(true);
  };

  const handleLeave1 = () => {
    setDropdownVisible1(false);
  };
  const navigate = useNavigate();

  const money_order = (email, phone) => {
    if (isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/money-order-details/`, {
        method: "POST",
        body: JSON.stringify({
          balance: total,
          email: email,
          phone: phone,
          amount: amount,
          transactionId: transactionId,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          if (response["path"] !== undefined) {
            if (response["path"] === "Money") {
              navigate("/moneyWallet");
            }
          }
        })
        .catch((error) => {
          return null;
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (x === "Bank") {
      if (transactionId === "" || transactionId.replace(" ", "") === "") {
        alert(
          "Please enter the UTR No. or Transaction ID generated by your Bank on it's payment confirmation receipt."
        );
        return;
      }
    }
    if (x === "UPI") {
      if (!/^\d{12}$/.test(transactionId.toUpperCase())) {
        alert(
          "The Transaction ID must be a 12-digit number found on your UPI app on the 'payment successful' page, or under Transaction History/Mini Statement."
        );
        return;
      }
    }

    if (!isAuthenticated) {
      navigate("/userLost");
    } else if (x === "") {
      alert("Please select a payment method");
    } else if (transactionId === "") {
      alert("Transaction ID / UTR Number is required");
    } else {
      setShow(true);
    }
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const containerRef = useRef(null);

  const upiId = "scdnd.estates@icici";

  const copyUPIToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(upiId)
        .then(() => {
          alert("UPI ID copied to clipboard!");
        })
        .catch((err) => console.error("Failed to copy UPI ID", err));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = upiId;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);

      try {
        document.execCommand("copy");
        alert("UPI ID copied to clipboard!");
      } catch (err) {
        alert("Failed to copy UPI ID");
      }

      document.body.removeChild(textArea);
    }
  };

  const redirectToUPIApp = () => {
    const upiURL = `upi://pay?pa=${upiId}&am=${topUpAmount}&cu=INR`;
    window.location.href = upiURL; // Redirect to UPI app
  };

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = "/images/qr.png";
    link.download = "qr.png";
    link.click();
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    if (showOverlay) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOverlay]);

  return (
    <>
      {/* <Header /> */}
      {show === true ? (
        <ConfirmPopUp
          x={x}
          topUp={amount}
          balance={amount2}
          activity={"Add Funds"}
          transactionId={
            x === "Bank" ? transactionId.toUpperCase() : "UPI/" + transactionId
          }
        />
      ) : (
        <div className="container max-w-[1550px] mt-5 overflow-hidden">
          {/* All links section */}
          <div className="py-2">
            {/* All Links */}
            <div className="col-12">
              <h6 className="font-size1 m-0 p-0 mx-2">
                <a href="/">
                  <span className="header-link text-sm">Home</span>
                  <span className="text-secondary text-sm"> &gt; </span>
                </a>
                <a href="/moneyWallet">
                  <span className="header-link text-sm">Money Wallet</span>
                  <span className="text-secondary text-sm"> &gt; </span>
                </a>
                <a href="#">
                  <span className="text-sm hover:underline hover:text-black text-gray-500">
                    Add Money
                  </span>
                </a>
              </h6>
            </div>
          </div>

          <div className="bg-zinc-100 rounded-xl flex max-lg:flex-col-reverse flex-wrap justify-between overflow-hidden">
            <div className="w-full lg:w-2/5">
              {/* Left Container */}
              <div className="p-4 m-4 bg-light rounded-xl shadow-md min-h-[665px] lg:min-h-[622px] bg-white overflow-hidden">
                <h5 className="font-bold text-xl">Order Summary</h5>
                <hr className="text-gray-600" />
                <h6 className="flex justify-between text-dark my-2">
                  <span className="text-sm">Present Balance</span>
                  <span className="font-semibold text-sm">{balanceAmount}</span>
                </h6>
                <h6 className="flex justify-between text-dark my-2">
                  <span className="text-sm">Top Up Amount</span>
                  <span className="text-sm font-semibold">
                    {formattedAmount}
                  </span>
                </h6>
                <hr className="text-gray-600" />
                <h6 className="flex justify-between text-dark my-2">
                  <span className="text-base font-semibold">Final Balance</span>
                  <span className="font-bold text-base">{totalAmount}</span>
                </h6>
                <h6 className="font-bold text-xl mt-2 pt-3">
                  Select Payment Mode
                </h6>
                <div className="form-check text-sm flex items-center mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="UPI"
                    id="UPI"
                    value="UPI"
                    onChange={(e) => setX(e.target.value)}
                    checked={x === "UPI"}
                  />
                  <label className="form-check-label" htmlFor="UPI">
                    Pay via UPI
                  </label>
                </div>
                <div className="form-check text-sm flex items-center mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Bank"
                    id="Bank"
                    value="Bank"
                    onChange={(e) => {
                      setX(e.target.value);
                      handleBankSelection(
                        kycData === null || kycData["bank"] === null
                          ? ""
                          : kycData["bank"]
                      );
                    }}
                    checked={x === "Bank"}
                  />
                  <label className="form-check-label" htmlFor="Bank">
                    Internet Banking
                  </label>
                </div>
                {x === "UPI" && (
                  // QR Code Image
                  <div className="justify-content-center items-center px-3 flex flex-col my-1 h-80">
                    <>
                      <div className="relative flex flex-col w-full lg:w-[50%] mt-2">
                        <p className="font-semibold text-[#212121]">
                          Scan to Pay <span className="text-danger">*</span>
                        </p>
                        <div
                          ref={containerRef}
                          className="relative group overflow-hidden text-gray-700 bg-white border-2 rounded-xl h-max p-2"
                        >
                          {/* Image */}
                          <img
                            src="/images/qr.png"
                            alt="card"
                            className={`object-cover w-full h-full transition-all duration-500 ease-in-out ${
                              showOverlay ? "blur-sm" : ""
                            }`}
                            onClick={() => setShowOverlay(!showOverlay)}
                          />

                          {/* Overlay with UPI and buttons */}
                          {showOverlay && (
                            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 opacity-100 transition-opacity duration-500 ease-in-out">
                              {/* UPI ID */}
                              <p className="text-sky-300 mb-4 text-center text-sm font-medium">
                                UPI ID: <br />
                                <span className="text-white font-semibold flex items-center gap-2 text-base">
                                  scdnd.estates@icici
                                  <img
                                    onClick={copyUPIToClipboard}
                                    className="w-6 h-6 hover:scale-95 cursor-pointer duration-200 ease-in-out"
                                    src="./images/file.png"
                                    alt="copy-icon"
                                  />
                                </span>
                              </p>

                              {/* Save QR Code Button */}
                              <button
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = "/images/qr.png";
                                  link.download = "ScdndEstates-qrcode.png";
                                  link.click();
                                }}
                                className="mb-2 w-44 px-4 py-1 duration-200 ease-in-out font-semibold text-white hover:bg-gray-600 border-2 border-sky-300 rounded-lg"
                              >
                                Save QR Code
                              </button>

                              <button
                                onClick={redirectToUPIApp}
                                className="lg:hidden mb-2 w-44 px-4 py-1 duration-200 ease-in-out font-semibold text-white hover:bg-sky-500 border-2 border-sky-300 bg-sky-300 rounded-lg"
                              >
                                Pay Now
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                )}
                {x === "Bank" && (
                  // Beneficiary Account Details
                  <div className="flex flex-col py-4 overflow-hidden h-80">
                    <p className="font-semibold text-black">
                      Beneficiary Account Details:
                    </p>
                    <div className="border p-1 rounded mt-2">
                      <div className="flex justify-between items-center p-2">
                        {a ? (
                          <span>Virtual Account No : SCDND</span>
                        ) : (
                          <span>Account No.: 00300200001066</span>
                        )}
                        <span
                          onMouseEnter={handleHover1}
                          onMouseLeave={handleLeave1}
                        >
                          <span className="relative group text-sky-400 cursor-pointer">
                            <FaQuestionCircle className="ms-3 text-dark relative" />
                            <span className="absolute -left-12 top-6 mb-2 transform -translate-x-1/2 translate-y-2 opac-0 group-hover:opacity-100 group-hover:translate-y-0 bg-sky-100 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] text-[#414141] rounded-lg py-1 px-2 transition-all duration-300 ease-in-out font-semibold text-[13px] w-44 text-center pointer-events-none">
                              Having an issue with adding the beneficiary
                              account?&nbsp;
                              <span
                                className="cursor-pointer text-decoration-underline"
                                onClick={() => setA(!a)}
                              >
                                Click here
                              </span>
                            </span>
                          </span>
                          {/* <FaQuestionCircle className="ms-3 text-dark relative" />
                          {isDropdownVisible1 && (
                            <div className="bg-dark pop2  ">
                              <p className="p-2 text-light mb-0">
                                Having an issue with adding the beneficiary
                                account?&nbsp;
                                <span
                                  className="cursor-pointer text-decoration-underline"
                                  onClick={() => setA(!a)}
                                >
                                  Click here
                                </span>
                              </p>
                            </div>
                          )} */}
                        </span>
                      </div>
                      <div className="flex justify-between items-center p-2">
                        <span>Bank name : Bank of Baroda</span>
                      </div>
                      <div className="flex justify-between items-center p-2">
                        <span>IFSC Code : BARB0PATIPU</span>
                      </div>
                    </div>
                    {/* Payment Link */}
                    <div className="mt-4 text-center bg-sky-500 hover:bg-sky-700 rounded w-fit mx-auto">
                      <a
                        href={bankWebsite}
                        target="blank"
                        className=" text-white text-sm px-3 py-1"
                      >
                        Pay via{" "}
                        {kycData === null || kycData["bank"] == null
                          ? ""
                          : kycData["bank"]}{" "}
                        Application
                      </a>
                    </div>
                    {/* User A/c Number */}
                    <p className="text-center text-base font-semibold mt-1 text-black">
                      Account No :{" "}
                      {kycData === null || kycData["account"] == null
                        ? ""
                        : kycData["account"]}
                    </p>
                  </div>
                )}
                <div className="input-group mt-2 ">
                  {x === "Bank" ? (
                    <>
                      <input
                        className="form-control text-sm"
                        type="text"
                        id="utrNum"
                        name="utrNum"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        placeholder="Enter UTR No. or Transaction ID"
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <>
                      <span className="input-group-text text-sm">UPI/</span>
                      <input
                        className="form-control text-sm"
                        type="text"
                        id="utrNum"
                        name="utrNum"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        placeholder="Enter UTR No. or Transaction ID"
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Right Container */}
            <div className="w-full  lg:w-3/5 m-0 p-4 max-lg:pb-0">
              {/* Right Image */}
              <img
                src="./images/topUp.webp"
                className="rounded-xl shadow-sm w-full h-full object-cover"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentPage;

const AgentInfoApp = [
  {
    id: 0,
    AgentName: "Aditya Malhotra",
    AgentCode: 298078,
    Address: "Sweet Kunj, Chocolate Vihar, Uttar Pradesh-201890",
    email: "aditya02@gmail.com",
    mobile: "6299716789",
  },
  {
    id: 1,
    AgentName: "Anjali Shinde",
    AgentCode: 299043,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299712451",
  },
  {
    id: 2,
    AgentName: "Rohit Kashyap",
    AgentCode: 0,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299657686",
  },
];
export default AgentInfoApp;

import React, { useEffect, useRef, useMemo } from "react";
import secureLocalStorage from "react-secure-storage";
import * as d3 from "d3";

const PieChart = ({ setActivePieChartSection }) => {
  const svgRef = useRef();

  const getAggregatedData = () => {
    const riskSensorData =
      JSON.parse(secureLocalStorage.getItem("riskSensor")) || {};
    const aggregatedData = {};

    Object.keys(riskSensorData).forEach((key) => {
      if (
        typeof riskSensorData[key] === "object" &&
        !Array.isArray(riskSensorData[key])
      ) {
        const categoryTotal = Object.values(riskSensorData[key]).reduce(
          (acc, value) => acc + (value || 0),
          0
        );
        if (categoryTotal > 0) {
          aggregatedData[key] = categoryTotal;
        }
      }
    });

    return aggregatedData;
  };

  const transformedData = useMemo(() => {
    const aggregatedData = getAggregatedData();
    const total = Object.values(aggregatedData).reduce(
      (acc, value) => acc + value,
      0
    );
    const colors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#C9CBCF",
    ];
    const names = Object.keys(aggregatedData);

    return names.map((name, index) => ({
      name,
      value: aggregatedData[name],
      percentage: ((aggregatedData[name] / total) * 100).toFixed(2),
      color: colors[index % colors.length],
    }));
  }, [secureLocalStorage.getItem("riskSensor")]);

  const totalAmount = useMemo(() => {
    return Object.values(getAggregatedData()).reduce(
      (acc, value) => acc + value,
      0
    );
  }, [secureLocalStorage.getItem("riskSensor")]);

  const getMargin = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      return { top: 30, right: 500, bottom: 30, left: 500 };
    } else if (screenWidth <= 1024) {
      return { top: 40, right: 550, bottom: 40, left: 550 };
    } else {
      return { top: 50, right: 780, bottom: 50, left: 780 };
    }
  };

  const getFontSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      return {
        totalAmount: 100,
        label: 60,
        value: 40,
      };
    } else if (screenWidth <= 1024) {
      return {
        totalAmount: 60,
        label: 18,
        value: 20,
      };
    } else {
      return {
        totalAmount: 70,
        label: 22,
        value: 25,
      };
    }
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const renderChart = () => {
      svg.selectAll("*").remove();

      const viewBox = svg.attr("viewBox");
      const regexViewBox = /\d+ \d+ (\d+) (\d+)/;
      const [, viewBoxWidth, viewBoxHeight] = viewBox
        .match(regexViewBox)
        .map((item) => Number.parseInt(item, 10));

      const margin = getMargin();
      const fontSize = getFontSize(); // Get the font sizes based on screen size
      const width = viewBoxWidth - (margin.left + margin.right);
      const height = viewBoxHeight - (margin.top + margin.bottom);
      const radius = Math.min(width, height) / 2;
      var strokeWidth = 40;

      const group = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);
      const groupDefault = group
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);
      groupDefault
        .append("circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", radius)
        .attr("transform", "rotate(-90)")
        .attr("fill", "none")
        .attr("stroke", "hsla(0, 0%, 0%, 0.08)")
        .attr("stroke-width", strokeWidth)
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", radius * Math.PI * 2)
        .attr("stroke-dashoffset", radius * Math.PI * 2);

      const pie = d3
        .pie()
        .sort(null)
        .padAngle(0.12)
        .value((d) => d.value);

      const arc = d3.arc().innerRadius(radius).outerRadius(radius);

      const arcHover = d3
        .arc()
        .innerRadius(radius)
        .outerRadius(radius + 30);

      const groupArcs = group
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const groupsArcs = groupArcs
        .selectAll("g")
        .data(pie(transformedData))
        .enter()
        .append("g");

      groupsArcs
        .append("path")
        .attr("d", arc)
        .attr("fill", "none")
        .attr("stroke", (d) => d.data.color)
        .attr("stroke-width", strokeWidth * 0.8)
        .attr("stroke-linecap", "round")
        .attr("stroke-linejoin", "round")
        .attr("stroke-dasharray", function () {
          return this.getTotalLength();
        })
        .attr("stroke-dashoffset", function () {
          return this.getTotalLength();
        })
        .on("click", (event, d) => {
          const index = transformedData.findIndex(
            (data) => data.name === d.data.name
          );
          setActivePieChartSection(d.data.name);
        })
        .on("mouseover", function (event, d) {
          d3.select(this).transition().duration(200).attr("stroke-width", 60);
        })
        .on("mouseout", function (event, d) {
          d3.select(this).transition().duration(200).attr("stroke-width", 40);
        });

      groupsArcs
        .append("line")
        .attr("x1", 0)
        .attr("x2", (d) => {
          const [x] = arc.centroid(d);
          return x > 0 ? "150" : "-150";
        })
        .attr("y1", 0)
        .attr("y2", 0)
        .attr("stroke", ({ data: d }) => d.color)
        .attr("stroke-width", 8)
        .attr("transform", (d) => {
          const [x, y] = arc.centroid(d);
          const offset = x > 0 ? 5 : -5;
          return `translate(${x + offset}, ${y + offset})`;
        });

      groupsArcs
        .append("text")
        .attr("x", 0)
        .attr("y", 0)
        .attr("font-size", fontSize.label) // Dynamic font size for labels
        .attr("font-weight", "bold")
        .attr("text-anchor", (d) => {
          const [x] = arc.centroid(d);
          return x > 0 ? "start" : "end";
        })
        .attr("transform", (d) => {
          const [x, y] = arc.centroid(d);
          const offset = x > 0 ? 170 : -170;
          return `translate(${x + offset}, ${y})`;
        })
        .html(
          ({ data: d }) => `
        <tspan x="0">${d.name}:</tspan><tspan x="0" dy="55" font-size="${fontSize.value}">${d.percentage}% / ₹ ${d.value}</tspan>
      `
        )
        .style("fill", "#414141")
        .style("opacity", 0)
        .style("visibility", "hidden");

      groupDefault
        .append("text")
        .attr("x", 0)
        .attr("y", -50)
        .attr("text-anchor", "middle")
        .attr("font-size", fontSize.label) // Dynamic font size for "Total Amount" label
        .attr("font-weight", "bold")
        .text("Total Amount");
      groupDefault
        .append("text")
        .attr("x", 0)
        .attr("y", 50)
        .attr("text-anchor", "middle")
        .attr("font-size", fontSize.totalAmount) // Dynamic font size for total amount
        .attr("font-weight", "bold")
        .text(`₹ ${totalAmount}`);

      d3.select(groupDefault.node())
        .transition()
        .ease(d3.easeLinear)
        .duration(2000)
        .attr("stroke-dashoffset", "0")
        .on("end", () => {
          const paths = document.querySelectorAll("svg g g path");
          paths.forEach((path) => {
            const length = path.getTotalLength();
            path.setAttribute("stroke-dasharray", length);
            path.setAttribute("stroke-dashoffset", length);
          });

          const duration = 10;
          d3.selectAll("svg g g path")
            .transition()
            .ease(d3.easeLinear)
            .delay((d, i) => i * duration + duration / 100)
            .duration(duration * 100)
            .attr("stroke-dashoffset", 0);

          d3.selectAll("svg g g line")
            .transition()
            .ease(d3.easeLinear)
            .delay((d, i) => i * duration + duration / 100)
            .duration(duration * 10)
            .attr("stroke-dashoffset", 0);

          d3.selectAll("svg g g text")
            .transition()
            .ease(d3.easeLinear)
            .delay((d, i) => i * duration + duration / 100)
            .duration(duration * 10)
            .style("opacity", 1)
            .style("visibility", "visible");
        });
    };

    renderChart();

    window.addEventListener("resize", renderChart);

    return () => {
      window.removeEventListener("resize", renderChart);
    };
  }, [transformedData, totalAmount]);

  return <svg ref={svgRef} viewBox="0 0 2200 2200" className="w-[1800px]"></svg>;
};

export default PieChart;
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Loader from "../../components/Loaders/Loader2";

function WalrChart() {
  const [citiesDataYes, setCitiesDataYes] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesDataNo, setCitiesDataNo] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableContent, setTableContent] = useState(null);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/client-liabilities-data/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setCities(data.data3);
        const citiesDataYes1 = [];
        const citiesDataNo1 = [];
        const categories = cities.map((item, index) => Object.keys(item));
        categories.forEach((quarter) => {
          const val = data.data2?.RESIDEX[quarter];
          if (val) {
            citiesDataYes1.push(val?.Index * 1000 || 0);
            citiesDataNo1.push(val?.Futures * 1000 || 0);
          } else {
            citiesDataYes1.push(0);
            citiesDataNo1.push(0);
          }
          setLoader(false);
        });
        setCategory(categories);
        setCitiesDataYes(citiesDataYes1);
        setCitiesDataNo(citiesDataNo1);
        setDataFetched(true);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [dataFetched]);

  function getNextPayment(cities, productName, cover, units) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    var nextPayment = 0;
    if (cover === "Index") {
      nextPayment = productData ? productData[p]["Price"] * 0.03 * units : 0;
    } else {
      nextPayment = 0;
    }

    nextPayment = parseInt(nextPayment);
    return nextPayment;
  }

  function getCAGR(cities, productName) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["CAGR"] : "";
    return couponIssue;
  }

  function getLatestNav(cities, productName) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Price"] : "";
    return couponIssue;
  }

  function get3MTHPremium(cities, productName, cover) {
    const p = productName;
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    var _3mthPremium = productData ? productData[p]["3 mth Premium"] : "";
    if (cover === "Futures") return _3mthPremium * 1000;
    else {
      _3mthPremium = 0;
    }
    return _3mthPremium;
  }

  const formatRupees = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace("₹", "");
  };

  const handleBarClick = (
    event,
    chartContext,
    { dataPointIndex, seriesIndex }
  ) => {
    const seriesName = chartData.options.xaxis.categories[dataPointIndex]?.[0];
    const category = chartData?.series[seriesIndex]?.name;
    const value = chartData?.series[seriesIndex]?.data[dataPointIndex];
    setTableContent(
      <>
        <div className="flex items-center justify-between flex-wrap p-2">
          <h5 className="fw-bold text-sm">
            RESIDEX @NHB :
            <span>
              <a
                className="text-[#008FFB] text-sm "
                href="https://residex.nhbonline.org.in/2017-18/NHB_Residex.aspx"
                target="blank"
              >
                &nbsp;residex.nhbonline.org.in
              </a>
            </span>
          </h5>
          <div className="flex items-center gap-2">
            <span className="font-bold text-sm">Quantity:</span>
            <h5 className="font-bold border px-2 py-1 text-sm text-[#008FFB] rounded-md">
              {value / 1000} units
            </h5>
          </div>
        </div>
        <hr className="mt-2 mb-2" />

        {/* RESIDEX Data */}
        <div className="flex flex-col justify-between">
          <div className="lg:flex items-end justify-between">
            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Next Payment <br />
                <span className="font-size1">( ₹ )</span>
              </h6>
              <div className="border-4 border-[#4c3fbb] m-2 d-flex align-items-center justify-content-center info-style-custodian rounded-circle">
                <h3 className="text-[#4c3fbb] fw-bold text-xl">
                  {getNextPayment(
                    cities,
                    seriesName,
                    category,
                    value / 1000
                  ).toLocaleString("en-IN")}
                </h3>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                CAGR from inception <br />
                <span className="font-size1">( % )</span>
              </h6>
              <div className="border-4 border-[#635DAD] m-2 d-flex align-items-center justify-content-center info-style-custodian rounded-circle">
                <h3 className="text-[#655DAD] fw-bold text-3xl">
                  {getCAGR(cities, seriesName) === ""
                    ? 0
                    : parseFloat(getCAGR(cities, seriesName)).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>

          <div className="lg:flex items-end justify-between">
            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                3 month Premium
                <br />
                <span className="font-size1">( ₹ )</span>
              </h6>
              <div className="border-4 border-[#0D828D] m-2 d-flex align-items-center justify-content-center info-style-custodian rounded-circle">
                <h3 className="text-[#0D828D] fw-bold text-xl">
                  {get3MTHPremium(cities, seriesName, category) === ""
                    ? 0
                    : get3MTHPremium(cities, seriesName, category)}
                </h3>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 flex lg:flex-col text-sm xl:text-base justify-between items-center px-2 mb-2 lg:mb-0">
              <h6 className="fw-bold text-center text-sm">
                Latest NAV <br />
                <span className="font-size1">( ₹ )</span>
              </h6>
              <div className="border-4 border-[#776fcd] m-2 d-flex align-items-center justify-content-center info-style-custodian rounded-circle">
                <h3 className="text-[#776fcd] fw-bold text-3xl">
                  {getLatestNav(cities, seriesName) === ""
                    ? 0
                    : parseFloat(getLatestNav(cities, seriesName)).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const defaultDataPointIndex = 0;
    const defaultCitiesIndex = 0;
    handleBarClick(null, null, {
      dataPointIndex: defaultDataPointIndex,
      seriesIndex: defaultCitiesIndex,
    });
  }, []);

  const chartData = {
    series: [
      { name: "Index", data: citiesDataYes },
      { name: "Futures", data: citiesDataNo, color: "#8d80ec" },
    ],
    options: {
      chart: {
        type: "bar",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleBarClick(event, chartContext, config);
          },
        },
        stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      fill: {
        type: ["gradient", "pattern"],
        gradient: {
          type: "vertical",
          stops: [0, 50, 100],
          colorStops: [
            { offset: 0, color: "#40A2D8" },
            { offset: 50, color: "#80C4E9" },
            { offset: 100, color: "#40A2D8" },
          ],
        },
        pattern: {
          style: "circles",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          dataLabels: {
            position: "top",
            total: {
              enabled: false,
            },
          },
        },
      },
      dataLabels: {
        position: "top",
        enabled: false,
      },
      xaxis: {
        categories: category,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: { text: "Amount (₹)" },
        labels: {
          formatter: function (value) {
            return formatRupees(value / 1000) + "K";
          },
        }, // Set the maximum value for the y-axis
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <>
      {loader && <Loader />}
      <div className="mt-16 w-full">
        <div className="lg:flex  justify-between items-center">
          <div
            id="bar"
            className="p-1 border-2  overflow-x-scroll rounded-xl w-full lg:w-[78%] mr-3"
          >
            <Chart
              className="min-w-[500px]"
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height="400"
            />
          </div>
          <div className="flex justify-between items-center pt-3 lg:-mt-3">
            <div className="p-1 w-full lg:w-[360px] 2xl:w-[410px] border-2 rounded-xl">
              {tableContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalrChart;

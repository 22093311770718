const KeyRatioapi = [
  {
    id: 0,
    startYear: "2019",
    endYear: "2020",
    A: ["28.288", "106.723", "-73.49"],
    a1: ["₹1,00,78,129", "₹34,68,507", ""],
    a2: ["₹3,54,900", "₹32,500", ""],
    popA: "",
    B: ["0.458", "0.000", ""],
    b1: ["₹20,00,000", "₹0", ""],
    b2: ["₹43,65,852", "₹27,02,902", ""],
    popB: "Reserve & Surplus position improved over last year and Debt reduced",
    C: ["0.404", "3.838", "-89.48"],
    c1: ["₹48,435", "₹4,60,431", ""],
    c2: ["₹1,19,972", "₹1,19,972", ""],
    popC: "PY was EBITDA negative due to a large bad debt write-off vide IBC",
    D: ["0.158", "0.177", "-10.96"],
    d1: ["₹5,56,788", "₹6,59,022", ""],
    d2: ["₹35,34,377", "₹37,24,842", ""],
    popD: "Present year generated profits, last year registered a large loss",
    E: ["N.A.", "N.A.", "N.A."],
    e1: ["", "", ""],
    e2: ["", "", ""],
    popE: "",
    F: ["N.A.", "N.A.", "N.A."],
    f1: ["", "", ""],
    f2: ["", "", ""],
    popF: "",
    G: ["N.A.", "N.A.", "N.A."],
    g1: ["", "", ""],
    g2: ["", "", ""],
    popG: "",
    H: ["0.681", "1.741", "-60.90"],
    h1: ["₹44,79,487", "₹32,57,174", ""],
    h2: ["₹65,79,618", "₹18,70,656", ""],
    popH: "Revenue rose due to high mark-to-market gains",
    I: ["0.124", "0.202", "-38.57"],
    i1: ["₹5,56,788", "₹6,59,022", ""],
    i2: ["₹44,79,487", "₹32,57,174", ""],
    popI: "Revenue increased over PY which produced a net profit",
    J: ["0.008", "0.012", "-30.26"],
    j1: ["₹5,02,656", "₹6,54,766", ""],
    j2: ["₹6,06,55,852", "₹5,51,02,903", ""],
    popJ: "Present year is EBIT positive due to favourable currency fluctuations",
    K: ["N.A.", "N.A.", "N.A."],
    popK: "",
  },
  {
    id: 1,
    startYear: "2020",
    endYear: "2021",
    A: ["30.926", "28.288", "9.33"],
    a1: ["₹1,15,97,354", "₹1,00,78,129", ""],
    a2: ["₹3,75,000", "₹3,54,900", ""],
    popA: "",
    B: ["1.679", "0.458", "266.66"],
    b1: ["₹62,00,000", "₹20,00,000", ""],
    b2: ["₹36,91,971", "₹43,65,852", ""],
    popB: "Reserve & Surplus position improved over last year and Debt reduced",
    C: ["2.474", "0.404", "512.79"],
    c1: ["₹10,49,630", "₹48,435", ""],
    c2: ["₹4,24,275", "₹1,19,972", ""],
    popC: "PY was EBITDA negative due to a large bad debt write-off vide IBC",
    D: ["-0.415", "0.158", "-363.73"],
    d1: ["-₹16,73,880", "₹5,56,788", ""],
    d2: ["₹40,28,912", "₹35,34,377.17", ""],
    popD: "Present year generated profits, last year registered a large loss",
    E: ["N.A.", "N.A.", "N.A."],
    e1: ["", "", ""],
    e2: ["", "", ""],
    popE: "",
    F: ["N.A.", "N.A.", "N.A."],
    f1: ["", "", ""],
    f2: ["", "", ""],
    popF: "",
    G: ["N.A.", "N.A.", "N.A."],
    g1: ["", "", ""],
    g2: ["", "", ""],
    popG: "",
    H: ["0.236", "0.681", "-65.32"],
    h1: ["₹24,72,606", "₹44,79,487", ""],
    h2: ["₹1,04,72,792", "₹65,79,618", ""],
    popH: "Revenue rose due to high mark-to-market gains",
    I: ["-0.638", "0.124", "-613.49"],
    i1: ["-₹16,73,880", "₹5,56,788", ""],
    i2: ["₹26,22,606", "₹44,79,487", ""],
    popI: "Revenue increased over PY which produced a net profit",
    J: ["-0.020", "0.008", "-338.70"],
    j1: ["-₹12,69,572", "₹5,02,656", ""],
    j2: ["₹6,41,81,971", "₹6,06,55,852", ""],
    popJ: "Present year is EBIT positive due to favourable currency fluctuations",
    K: ["N.A.", "N.A.", "N.A."],
    popK: "",
  },
  {
    id: 2,
    startYear: "2021",
    endYear: "2022",
    A: ["20.133", "30.926", "-34.90"],
    a1: ["₹1,95,29,141", "₹1,15,97,354", ""],
    a2: ["₹9,70,000", "₹3,75,000", ""],
    popA: "",
    B: ["4.026", "1.679", ""],
    b1: ["₹64,90,000", "₹62,00,000", ""],
    b2: ["₹16,11,888", "₹36,91,971", "-56.34"],
    popB: "No Debt of the company.",
    C: ["-2.515", "2.474", ""],
    c1: ["-₹13,87,434", "₹10,49,630", ""],
    c2: ["₹5,51,557", "₹4,24,275", ""],
    popC: "No Debt of the company.",
    D: ["-0.784", "-0.415", "88.79"],
    d1: ["-₹20,80,083", "-₹16,73,880", ""],
    d2: ["₹26,51,930", "₹40,28,912", ""],
    popD: "Loss have increased.",
    E: ["N.A.", "N.A.", "N.A."],
    e1: ["", "", ""],
    e2: ["", "", ""],
    popE: "Avg Inventory reduced and Turnover increased.",
    F: ["N.A.", "N.A.", "N.A."],
    f1: ["", "", ""],
    f2: ["", "", ""],
    popF: "Avg Receivables reduced and Turnover increased.",
    G: ["N.A.", "N.A.", "N.A."],
    g1: ["", "", ""],
    g2: ["", "", ""],
    popG: "Avg Payable increased and Turnover increased.",
    H: ["0.179", "0.236", "47.65"],
    h1: ["₹26,60,736", "₹24,72,606", ""],
    h2: ["₹1,48,90,748", "₹1,04,72,792", ""],
    popH: "Turnover have increased.",
    I: ["-78.395", "-0.638", "22.83"],
    i1: ["-₹20,85,873", "-₹16,73,880", ""],
    i2: ["₹26,60,735", "₹26,22,606", ""],
    popI: "Net Loss have increased compared to loss of P.Y",
    J: ["-0.023", "-0.020", "176.81"],
    j1: ["-₹15,34,316", "-₹12,69,572", ""],
    j2: ["₹6,58,36,888", "₹6,41,81,971", ""],
    popJ: "Net Loss have increased compared to loss of P.Y",
    K: ["N.A.", "N.A.", "N.A."],
    popK: "",
  },
  {
    id: 3,
    startYear: "2022",
    endYear: "2023",
    A: ["16.998", "20.133", "-15.57"],
    a1: ["₹1,89,51,942", "₹1,95,29,141", ""],
    a2: ["₹11,14,984", "₹9,70,000", ""],
    popA: "",
    B: ["2.281", "4.026", "-43.36"],
    b1: ["₹59,30,000", "₹64,90,000", ""],
    b2: ["₹26,00,221", "₹16,11,888", ""],
    popB: "Reserve & Surplus position improved over last year and Debt reduced",
    C: ["1.735", "-2.515", "-168.98"],
    c1: ["₹26,17,549", "-₹13,87,434", ""],
    c2: ["₹15,08,463", "₹5,51,557", ""],
    popC: "PY was EBITDA negative due to a large bad debt write-off vide IBC",
    D: ["0.469", "-0.784", "-159.83"],
    d1: ["₹9,88,333", "-₹20,80,083", ""],
    d2: ["₹21,06,055", "₹26,51,930", ""],
    popD: "Present year generated profits, last year registered a large loss",
    E: ["N.A.", "N.A.", "N.A."],
    e1: ["", "", ""],
    e2: ["", "", ""],
    popE: "",
    F: ["N.A.", "N.A.", "N.A."],
    f1: ["", "", ""],
    f2: ["", "", ""],
    popF: "",
    G: ["N.A.", "N.A.", "N.A."],
    g1: ["", "", ""],
    g2: ["", "", ""],
    popG: "",
    H: ["0.352", "0.179", "97.08"],
    h1: ["₹64,08,489", "₹26,60,736", ""],
    h2: ["₹1,81,98,049", "₹1,48,90,748", ""],
    popH: "Revenue rose due to high mark-to-market gains",
    I: ["0.154", "-78.395", "-100.20"],
    i1: ["₹9,87,429", "-₹20,85,873", ""],
    i2: ["₹64,08,489", "₹26,60,735", ""],
    popI: "Revenue increased over PY which produced a net profit",
    J: ["0.038", "-0.023", "-262.53"],
    j1: ["₹24,95,895", "-₹15,34,316", ""],
    j2: ["₹6,58,92,471", "₹6,58,36,888", ""],
    popJ: "Present year is EBIT positive due to favourable currency fluctuations",
    K: ["N.A.", "N.A.", "N.A."],
    popK: "",
  },
  {
    id: 4,
    startYear: "2023",
    endYear: "2024",
    A: ["4.945", "3.751", "31.83"],
    a1: ["₹1,41,421", "₹1,57,063", ""],
    a2: ["₹28,600", "₹41,872", ""],
    popA: "",
    B: ["0.505", "1.706", "-70.40"],
    b1: ["₹65,800", "₹59,300", ""],
    b2: ["₹1,30,286", "₹34,757", ""],
    popB: "Reserve & Surplus position improved over last year and Debt reduced",
    C: ["-2.351", "-0.426", "451.74"],
    c1: ["-₹14,333", "-₹6,427", ""],
    c2: ["₹6,097", "₹15,085", ""],
    popC: "PY was EBITDA negative due to a large bad debt write-off vide IBC",
    D: ["-0.066", "0.389", "-117.07"],
    d1: ["-₹5,472", "₹9,883", ""],
    d2: ["₹82,522", "₹25,438", ""],
    popD: "Present year generated profits, last year registered a large loss",
    E: ["N.A.", "N.A.", "N.A."],
    e1: ["", "", ""],
    e2: ["", "", ""],
    popE: "",
    F: ["N.A.", "N.A.", "N.A."],
    f1: ["", "", ""],
    f2: ["", "", ""],
    popF: "",
    G: ["N.A.", "N.A.", "N.A."],
    g1: ["", "", ""],
    g2: ["", "", ""],
    popG: "",
    H: ["0.379", "0.447", "-15.10"],
    h1: ["₹43,219", "₹64,340", ""],
    h2: ["₹1,14,005.87", "₹1,44,095", ""],
    popH: "Revenue rose due to high mark-to-market gains",
    I: ["-0.132", "0.154", "-185.79"],
    i1: ["-₹5.681", "₹9,874", ""],
    i2: ["₹42,974", "₹64,085", ""],
    popI: "Revenue increased over PY which produced a net profit",
    J: ["0.003", "1.008", "-99.68"],
    j1: ["₹417", "-₹24,950", ""],
    j2: ["₹1,30,286", "₹24,757", ""],
    popJ: "Present year is EBIT positive due to favourable currency fluctuations",
    K: ["N.A.", "N.A.", "N.A."],
    popK: "",
  },
];
export default KeyRatioapi;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loader1 from "../Loaders/Loader1";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./KYCPage.css";

function Pool_Account() {
  const { isAuthenticated, email, pan, phone } = CheckAuth();
  const { loader, statusE } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });

  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);
  const [utrNum, setUtrNum] = useState("");
  const [testAmount, setTestAmount] = useState("");
  const [isDropdownVisible1, setDropdownVisible1] = useState(false);
  const [x, setx] = useState("");
  const [status, setStatus] = useState(-1);
  const [a, setA] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [bankWebsite, setBankWebsite] = useState("");
  const kycData = JSON.parse(storedData);

  const navigate = useNavigate();

  const bankWebsites = {
    // Public Sector Banks
    "State Bank of India": "https://www.onlinesbi.sbi/",
    "Bank of Baroda": "https://www.bankofbaroda.in/",
    "Bank of India": "https://bankofindia.co.in/",
    "Bank of Maharashtra": "https://bankofmaharashtra.in/",
    "Canara Bank": "https://www.canarabank.com/",
    "Central Bank of India": "https://www.centralbankofindia.co.in/en",
    "Indian Bank": "https://www.indianbank.in/",
    "Indian Overseas Bank": "https://www.iob.in/",
    "Punjab & Sind Bank": "https://punjabandsindbank.co.in/",
    "Punjab National Bank": "https://www.pnbindia.in/",
    "UCO Bank": "https://www.ucobank.com/hi/",
    "Union Bank of India": "https://www.unionbankonline.co.in/",

    // Private Sector Banks
    "Axis Bank": "https://www.axisbank.com/",
    "Bandhan Bank": "https://www.bandhanbank.com/",
    "Catholic Syrian Bank": "https://www.csb.co.in/",
    "City Union Bank": "https://www.cityunionbank.com/",
    "DCB Bank": "https://www.dcbbank.com/",
    "Dhanlaxmi Bank": "https://www.dhanbank.com/",
    "Federal Bank": "https://www.federalbank.co.in/",
    "HDFC Bank": "https://www.hdfcbank.com/",
    "ICICI Bank": "https://www.icicibank.com/",
    "IDBI Bank": "https://www.idbibank.in/",
    "IDFC FIRST Bank": "https://www.idfcfirstbank.com/",
    "IndusInd Bank": "https://www.indusind.com/",
    "Jammu & Kashmir Bank": "https://www.jkbank.com/",
    "Karnataka Bank": "https://karnatakabank.com/",
    "Karur Vysya Bank": "https://www.kvb.co.in/",
    "Kotak Mahindra Bank": "https://www.kotak.com/",
    "RBL Bank": "https://www.rblbank.com/",
    "South Indian Bank": "https://www.southindianbank.com/",
    "YES Bank": "https://www.yesbank.in/",
    "Nainital bank Ltd.": "https://www.nainitalbank.co.in/english/home.aspx",
    "Tamilnad Mercantile Bank Ltd.": "https://tmb.in/",
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const containerRef = useRef(null);

  const upiId = "scdnd.estates@icici";

  const copyUPIToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(upiId)
        .then(() => {
          alert("UPI ID copied to clipboard!");
        })
        .catch((err) => console.error("Failed to copy UPI ID", err));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = upiId;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);

      try {
        document.execCommand("copy");
        alert("UPI ID copied to clipboard!");
      } catch (err) {
        alert("Failed to copy UPI ID");
      }

      document.body.removeChild(textArea);
    }
  };

  const redirectToUPIApp = () => {
    const upiURL = `upi://pay?pa=${upiId}&am=${testAmount}&cu=INR`;
    window.location.href = upiURL; // Redirect to UPI app
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    if (showOverlay) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOverlay]);

  const handleHover1 = () => {
    setDropdownVisible1(true);
  };

  const handleLeave1 = () => {
    setDropdownVisible1(false);
  };

  const handleX = (e) => {
    setx(e.target.value);
    const randomNum = (Math.random() * (2 - 1) + 1).toFixed(2);
    setTestAmount(randomNum);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setLoader2(true);
    if (!utrNum.trim()) {
      alert("Please enter UTR No. or Transaction ID");
      return;
    }

    if (x === "Internet Banking") {
      if (
        !/^NEFT-\w+$/.test(utrNum.toUpperCase()) &&
        !/^RTGS-\w+$/.test(utrNum.toUpperCase()) &&
        !/^IMPS\/\w+$/.test(utrNum.toUpperCase())
      ) {
        alert(
          "The UTR No. or Transaction ID must begin with RTGS- or NEFT- or IMPS/, depending on your mode of payment. Please see your Bank A/C statement under the 'Description' column."
        );
        setLoader2(false);
        return; // Exit the function if validation fails
      }
    } else if (x === "UPI Payment") {
      if (!/^UPI\/\d{12}$/.test("UPI/" + utrNum.toUpperCase())) {
        alert(
          "The Transaction ID must be a 12-digit number found on your UPI app on the 'payment successful' page, or under Transaction History/Mini Statement."
        );
        setLoader2(false);
        return; // Exit the function if validation fails
      }
    }
    if (!isAuthenticated) {
      alert("Please login");
      return;
    }
    fetch(`https://api-scdndestates.com/api/user-kyc/`, {
      method: "POST",
      body: JSON.stringify({
        form_name: "poolAccount",
        testAmount: testAmount,
        payType: x,
        email: email,
        phone: phone,
        utrNum: x === "UPI Payment" ? "UPI/" + utrNum : utrNum.toUpperCase(),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((response) => {
        alert(response["error"]);
        if (response["path"] !== undefined) {
          secureLocalStorage.setItem("kycData", JSON.stringify(response.data));
          if (response["path"] === "Edit") {
            navigate("/profile");
          } else {
            navigate("/UserKycForm", {
              state: { selectedCard: response["path"] },
            });
          }
        }

        setLoader2(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setx(userKycData?.payType);
    setTestAmount(userKycData?.testAmount);
    setUtrNum(userKycData?.utrNum);
    if (userKycData?.["KYC_status"]["E"] === 10) {
      setStatus(0);
    } else if (userKycData?.["KYC_status"]["E"] === 15) {
      setStatus(1);
    } else if (userKycData?.["KYC_status"]["E"] === 0) {
      setStatus(2);
    } else if (userKycData?.["KYC_status"]["E"] === 5) {
      setx("");
      setTestAmount("");
      setUtrNum("");
      setStatus(3);
    } else {
      setStatus(-1);
    }
    setBankWebsite(
      bankWebsites[
        kycData === null || kycData["bank"] == null ? "" : kycData["bank"]
      ]
    );
  }, [statusE]);

  return (
    <>
      {loader ? (
        <Loader1 />
      ) : (
        <>
          <form action="" onSubmit={handleRegister} className="font-size1">
            {status === 0 ? (
              <>
                <div className="border rounded p-3 mt-3">
                  <div className="p-3 flex-col  items-center justify-center">
                    <div className="loader ">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-center text-lg font-bold mt-4">
                        Please wait while your entered Bank details is being
                        verified. It usually takes around 12-24 hours.
                      </h2>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="w-full  mt-2 flex justify-center items-center text-sm font-medium">
                      <input
                        type="checkbox"
                        name=""
                        id="check1"
                        className="me-1 checkbox-style"
                        required
                        value="yes"
                        checked={a}
                        onChange={(e) => setA(!a)}
                      />
                      <label htmlFor="check1 ">
                        Why am i having a delay in receiving confirmation of
                        Bank Account linking?
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  {a && (
                    <div className="row m-0 p-0">
                      <div className="w-full px-5 mt-2 flex justify-center items-center text-center">
                        <p className="font-size1 para-style2 text-center">
                          NEFT transactions roughly take one-two hours to be
                          settled. If you initiated Penny Drop by IMPS/UPI,
                          please check from the end of your Bank to ensure there
                          is server failure or error. It is common for
                          settlement to be delayed by a few hours. Please check
                          your Profile in some time.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : status === 1 ? (
              <>
                <div className="border rounded-xl  p-3 mt-3">
                  <div className="p-3 flex flex-col items-center justify-center ">
                    <div className="text-center">
                      <div className="checkmark">
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="checkmark__check"
                          height="36"
                          viewBox="0 0 48 36"
                          width="48"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
                        </svg>
                        <svg
                          className="checkmark__background"
                          height="115"
                          viewBox="0 0 120 115"
                          width="120"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
                        </svg>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-center text-lg font-bold mt-4">
                        Your Bank Account has been successfully linked to your
                        Money Wallet.
                      </h2>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {status === 3 ? (
                  <>
                    <div className="border rounded p-3 mt-3">
                      <div className="mx-4 mt-2">
                        <div className="text-center flex justify-center">
                          <div className="Container">
                            <div className="MainGraphic">
                              <ImCross className="Hummingbird" size={40} />

                              <svg
                                className="Cog"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                              >
                                <path d="M29.18 19.07c-1.678-2.908-.668-6.634 2.256-8.328L28.29 5.295c-.897.527-1.942.83-3.057.83-3.36 0-6.085-2.743-6.085-6.126h-6.29c.01 1.043-.25 2.102-.81 3.07-1.68 2.907-5.41 3.896-8.34 2.21L.566 10.727c.905.515 1.69 1.268 2.246 2.234 1.677 2.904.673 6.624-2.24 8.32l3.145 5.447c.895-.522 1.935-.82 3.044-.82 3.35 0 6.066 2.725 6.083 6.092h6.29c-.004-1.035.258-2.08.81-3.04 1.676-2.902 5.4-3.893 8.325-2.218l3.145-5.447c-.9-.515-1.678-1.266-2.232-2.226zM16 22.48c-3.578 0-6.48-2.902-6.48-6.48S12.423 9.52 16 9.52c3.578 0 6.48 2.902 6.48 6.48s-2.902 6.48-6.48 6.48z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <h2 className="text-center text-lg font-bold mt-4">
                          Your Bank Account could not linked due to mismatch of
                          Debit A/C or Test Amount. Please Try Again.
                        </h2>
                        <div className="flex justify-center align-center pt-2">
                          <button
                            className="flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  btn-danger"
                            onClick={() => setStatus(-1)}
                          >
                            Try Again
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border rounded p-3 mt-3">
                      <div className="row m-0 p-0">
                        <p className="p-2 pb-0 text-secondary">
                          <ul className="list-unstyled para-style2 font-size1">
                            <li className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                              <FaLongArrowAltRight className="mt-1 mr-2" />
                              Please add the following details to your Bank
                              Beneficiary List.
                            </li>
                            <li className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                              <FaLongArrowAltRight className="mt-1 mr-2" />
                              All additions and redemptions from Money Wallet by
                              User will be to/from this Pool A/C.
                            </li>
                            <li className="mb-1  flex items-start  text-sm text-[#616161] font-semibold">
                              <FaLongArrowAltRight className="mt-1 mr-2" />
                              NEFT, RTGS and UPI/IMPS transactions are accepted
                              modes of payment.
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="row m-0 p-0 mb-2">
                        <div className="w-full lg:w-[49%] mt-2">
                          <label
                            className="font-semibold text-[#212121] mr-2"
                            htmlFor="types"
                          >
                            Please select mode of Penny Drop
                          </label>
                          <select
                            className="text-sm py-2 px-2 rounded-lg border-none cursor-pointer font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] m-0  mt-1"
                            name="PennyDropType "
                            id="PennyDropType"
                            value={x}
                            onChange={handleX}
                            disabled={
                              status === 1 || status === 0 || status === 0
                            }
                          >
                            <option value="">Select from dropdown</option>
                            <option value="Internet Banking">
                              Internet Banking
                            </option>
                            <option value="UPI Payment">UPI Payment</option>
                          </select>
                        </div>
                        {x === "UPI Payment" && (
                          <>
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121] flex "
                                htmlFor="Phone"
                              >
                                Test amount to be paid to Pool account
                                <span className="text-danger">*</span>
                                <span
                                  onMouseEnter={handleHover1}
                                  onMouseLeave={handleLeave1}
                                >
                                  <span className="relative group text-sky-400 cursor-pointer">
                                    <FaQuestionCircle className="ms-3 text-dark relative" />
                                    <span className="absolute -left-12 top-6 mb-2 transform -translate-x-1/2 translate-y-2 opac-0 group-hover:opacity-100 group-hover:translate-y-0 bg-sky-100 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] text-[#414141] rounded-lg py-1 px-2 transition-all duration-300 ease-in-out font-semibold text-[13px] w-44 text-center pointer-events-none">
                                      The exact Test Amount will be refunded to
                                      you upon successfully verification and
                                      linking to your Money Wallet.&nbsp;
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <div className=" border   ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden">
                                <span className="text-sm select-border font-semibold m-0 cursor-pointer px-3">
                                  ₹
                                </span>
                                <input
                                  className="form-control border-none text-sm font-semibold overflow-hidden  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-none py-2"
                                  id="firstname"
                                  name="firstname"
                                  value={testAmount}
                                  disabled
                                />
                                <span className="text-sm select-border font-semibold m-0 cursor-pointer px-3">
                                  /-
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row m-0 p-0 mb-2">
                        {x === "UPI Payment" && (
                          <>
                            <div className="relative flex flex-col w-full lg:w-[30%] mt-2">
                              <p className="font-semibold text-[#212121]">
                                Scan to Pay{" "}
                                <span className="text-danger">*</span>
                              </p>
                              <div
                                ref={containerRef}
                                className="relative group overflow-hidden text-gray-700 bg-white border-2 rounded-xl h-max p-2"
                              >
                                {/* Image */}
                                <img
                                  src="/images/qr.png"
                                  alt="card-image"
                                  className={`object-cover w-full h-full transition-all duration-500 ease-in-out ${
                                    showOverlay ? "blur-sm" : ""
                                  }`}
                                  onClick={() => setShowOverlay(!showOverlay)}
                                />

                                {/* Overlay with UPI and buttons */}
                                {showOverlay && (
                                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 opacity-100 transition-opacity duration-500 ease-in-out">
                                    {/* UPI ID */}
                                    <p className="text-sky-300 mb-4 text-center text-sm font-medium">
                                      UPI ID: <br />
                                      <span className="text-white font-semibold flex items-center gap-2 text-base">
                                        scdnd.estates@icici
                                        <img
                                          onClick={copyUPIToClipboard}
                                          className="w-6 h-6 hover:scale-95 cursor-pointer duration-200 ease-in-out"
                                          src="./images/file.png"
                                          alt="copy-icon"
                                        />
                                      </span>
                                    </p>

                                    {/* Save QR Code Button */}
                                    <button
                                      onClick={() => {
                                        const link =
                                          document.createElement("a");
                                        link.href = "/images/qr.png";
                                        link.download =
                                          "ScdndEstates-qrcode.png";
                                        link.click();
                                      }}
                                      className="mb-2 w-44 px-4 py-1 duration-200 ease-in-out font-semibold text-white hover:bg-gray-600 border-2 border-sky-300 rounded-lg"
                                    >
                                      Save QR Code
                                    </button>

                                    <button
                                      onClick={redirectToUPIApp}
                                      className="lg:hidden mb-2 w-44 px-4 py-1 duration-200 ease-in-out font-semibold text-white hover:bg-sky-500 border-2 border-sky-300 bg-sky-300 rounded-lg"
                                    >
                                      Pay Now
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {x === "Internet Banking" && (
                        <>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="email"
                              >
                                Virtual Account No. / Account No.
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                type="text"
                                id="accountNo"
                                name="accountNo"
                                value="scdnd / 00300200001066"
                                disabled
                              />
                            </div>
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="types"
                              >
                                Account Type
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                type="text"
                                id="account"
                                name="account"
                                value="Current Account"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="email"
                              >
                                IFSC Code<span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                type="text"
                                id="ifsc"
                                name="ifsc"
                                value="BARB0PATIPU"
                                disabled
                              />
                            </div>
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="email"
                              >
                                Bank name<span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                type="text"
                                name="BankName"
                                id="BankName"
                                value="Bank of Baroda"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="email"
                              >
                                Branch<span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]s"
                                type="text"
                                name="branchName"
                                id="branchName"
                                value="PATIPUKUR BRANCH, 847 LAKE TOWN, BLOCK-A, KOLKATA-700089"
                                disabled
                              />
                            </div>
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="email"
                              >
                                Account holder's name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]s"
                                type="text"
                                name="branchName"
                                id="branchName"
                                value="SCDND ESTATES PVT. LTD."
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121] flex"
                                htmlFor="Phone"
                              >
                                Test amount to be paid to Pool account
                                <span className="text-danger">*</span>
                                <span
                                  onMouseEnter={handleHover1}
                                  onMouseLeave={handleLeave1}
                                >
                                  {/* <FaQuestionCircle className="ms-3 text-dark font-size1" />
                                  {isDropdownVisible1 && (
                                    <div className="bg-dark pop2">
                                      <p className="p-1 text-light mb-0 font-size1">
                                        The exact Test Amount will be refunded
                                        to you upon successfully verification
                                        and linking to your Money Wallet
                                      </p>
                                    </div>
                                  )} */}
                                  <span className="relative group text-sky-400 cursor-pointer">
                                    <FaQuestionCircle className="ms-3 text-dark relative" />
                                    <span className="absolute -left-12 top-6 mb-2 transform -translate-x-1/2 translate-y-2 opac-0 group-hover:opacity-100 group-hover:translate-y-0 bg-sky-100 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] text-[#414141] rounded-lg py-1 px-2 transition-all duration-300 ease-in-out font-semibold text-[13px] w-44 text-center pointer-events-none">
                                      The exact Test Amount will be refunded to
                                      you upon successfully verification and
                                      linking to your Money Wallet.&nbsp;
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <div className="border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden bg-[#eeeeee] ">
                                <span className="text-sm select-border font-semibold m-0 cursor-pointer px-3 ">
                                  ₹
                                </span>
                                <input
                                  className="form-control py-2 border-none text-sm font-semibold overflow-hidden ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-none"
                                  id="firstname"
                                  name="firstname"
                                  value={testAmount}
                                  disabled={status === 1 || status === 0}
                                />
                                <span className="text-sm select-border font-semibold m-0 cursor-pointer px-3">
                                  /-
                                </span>
                              </div>
                            </div>
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="utrno"
                              >
                                Enter UTR No. or Transaction ID
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="mt-1 form-control text-sm py-2 px-2 rounded-lg border-none font-semibold overflow-hidden border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                                type="text"
                                name="utrno"
                                id="utrno"
                                value={utrNum}
                                onChange={(e) => setUtrNum(e.target.value)}
                                required
                                disabled={status === 1 || status === 0}
                              />
                            </div>
                            {/* Payment Link */}

                            <div className="mt-3 py-2 text-center bg-sky-500 hover:bg-sky-700 rounded w-fit mx-auto">
                              <a
                                href={bankWebsite}
                                target="blank"
                                className=" text-white text-xs md:text-sm px-3 py-1 flex items-center justify-center font-semibold gap-2"
                              >
                                Pay via{" "}
                                {kycData === null || kycData["bank"] == null
                                  ? ""
                                  : kycData["bank"]}{" "}
                                Application
                                <img
                                  className="w-4 h-4"
                                  src="./images/share (1).png"
                                  alt=""
                                />
                              </a>
                            </div>
                            {/* User A/c Number */}
                            <p className="text-center  text-sm md:text-base font-semibold mt-1 text-black mb-3">
                              Account No :{" "}
                              {kycData === null || kycData["account"] == null
                                ? ""
                                : kycData["account"]}
                            </p>
                            <div className="w-full lg:w-[49%] mt-2">
                              <h6 className="text-danger text-sm font-bold">
                                Transaction Failed?
                              </h6>
                              <div>
                                <button
                                  className="flex w-[120px] justify-center rounded-md bg-[#717171] hover:bg-[#212121] px-2 py-1 text-sm font-semibold leading-6 text-white shadow-sm  cursor-pointer mt-2 "
                                  type="submit"
                                >
                                  REINITIATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {x === "UPI Payment" && (
                        <>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <label
                                className="font-semibold text-[#212121]"
                                htmlFor="transactionId"
                              >
                                Enter UTR No. or Transaction ID
                                <span className="text-danger">*</span>
                              </label>
                              <div className="bg-[#eeeeee] border  ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-1 rounded-lg cursor-pointer flex justify-between items-center overflow-hidden ">
                                <span className="text-sm select-border font-semibold m-0 cursor-pointer px-3">
                                  UPI/
                                </span>
                                <input
                                  className="form-control text-sm py-2 px-2 rounded-none border-none font-semibold"
                                  type="text"
                                  name="utrno"
                                  id="utrno"
                                  value={utrNum}
                                  onChange={(e) => setUtrNum(e.target.value)}
                                  required
                                  disabled={status === 1 || status === 0}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 p-0 mb-2">
                            <div className="w-full lg:w-[49%] mt-2">
                              <h6 className="text-danger text-sm font-bold">
                                Transaction Failed?
                              </h6>
                              <div>
                                <button
                                  className="flex w-[120px] justify-center rounded-md bg-[#717171] hover:bg-[#212121] px-2 py-1 text-sm font-semibold leading-6 text-white shadow-sm  cursor-pointer mt-2 "
                                  type="submit"
                                >
                                  REINITIATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex justify-center py-3 ">
                      <button
                        className={` ${
                          loader2 || status === 1 || status === 0
                            ? "bg-[#717171] cursor-not-allowed"
                            : "cursor-pointer  bg-[#5ab2ff] hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        } flex h-10 w-[120px] items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm `}
                        type="submit"
                        disabled={loader2 || status === 1 || status === 0}
                      >
                        {loader2 ? (
                          <img
                            className="userkyc-submit-btn"
                            src="/images/btn-transparent.gif"
                            alt=""
                            height={30}
                          />
                        ) : (
                          <>Save</>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </form>
        </>
      )}
    </>
  );
}

export default Pool_Account;

const PartnershipApi = [
  {
    id: 1,
    heading: "REAL ESTATE BROKER",
    image: "/images/REbroker.jpg",
    route: "/realStateBroker",
    info: "In today's rapidly changing real estate landscape, traditional methods of buying and selling properties are being disrupted by innovative technologies. Real estate agents, who have spent years building their market value and nurturing relationships with investors and retail clients, are facing unprecedented challenges. The digital revolution is reshaping the industry, and many are left wondering how to adapt and thrive in this new era.",
  },
  {
    id: 2,
    heading: "STOCK BROKER",
    image: "/images/Stockbroker.jpg",
    route: "/StockBroker",
    info: "The world of stock brokerage is undergoing a seismic shift, thanks to relentless technological innovation. Traditional stock brokers, who have long served as intermediaries between investors and the stock market, are facing unprecedented challenges. The emergence of digital platforms has not only disrupted their commission model but also shifted the dynamics of customer engagement. How can stock brokers adapt and thrive in this new era?",
  },

  {
    id: 3,
    heading: "MUTUAL FUND DISTRIBUTOR",
    image: "/images/Mutualfunds.jpg",
    route: "/MutualFund",
    info: "Mutual fund distributors have long been the trusted intermediaries connecting investors to the world of financial opportunities. However, the landscape is changing rapidly, with new online players disrupting the market by offering lower margins to customers. The influx of big players has made it increasingly challenging for mutual fund distributors to survive. So, how can they adapt and thrive in this new era",
  },

  {
    id: 4,
    heading: "FINANCIAL INFLUENCER",
    image: "/images/Influence.jpg",
    route: "/FinancialInf",
    info: "Financial influencers play a vital role in educating and guiding their audiences through the complex world of finance. However, the landscape is ever-evolving, and influencers face their own set of challenges in keeping their content fresh and engaging. How can financial influencers continue to inspire and educate their followers while staying at the forefront of innovation?",
  },
  {
    id: 5,
    heading: "INSURANCE AGENT",
    image: "/images/InfAgent.jpg",
    route: "/InsuranceAgent",
    info: "Insurance agents have been the trusted advisors helping individuals safeguard their financial futures for decades. However, the insurance landscape is evolving, presenting agents with unique challenges. Online platforms have disrupted the industry by offering similar insurance products at lower commissions, reducing margins and increasing competition. The intricacies of insurance policies, multiple sum assured options, and lengthy claim processes can make selling insurance a daunting task. How can insurance agents adapt and continue to serve their clients effectively?",
  },
  {
    id: 6,
    heading: "FOREIGN PORTFOLIO",
    image: "/images/VentureCap.jpg",
    route: "/ForeignPortfolio",
    info: "FPIs and FII from capital surplus countries are being more favourable towards a giant emerging economy like India as an investment destination in the recent past, but our own capital market infrastructure",
  },
];

export default PartnershipApi;

const Shareholdingsapi = [
  {
    id: 0,
    startYear: "2019",
    endYear: "2020",
    promoters_1_a: [
      "-",
      "1,50,000",
      "1,50,000",
      "100",
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "NIL",
    ],
    promoters_1: [
      "-",
      "1,50,000",
      "1,50,000",
      "100",
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "NIL",
    ],
    promoters_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    promoters_total: [
      "-",
      "1,50,000",
      "1,50,000",
      "100",
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "NIL",
    ],
    public_1: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_i: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_ii: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_total: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    grand_total: [
      "-",
      "1,50,000",
      "1,50,000",
      "100",
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "NIL",
    ],
  },
  {
    id: 1,
    startYear: "2020",
    endYear: "2021",
    promoters_1_a: [
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "NIL",
    ],
    promoters_1: [
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "NIL",
    ],
    promoters_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    promoters_total: [
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "NIL",
    ],
    public_1: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_i: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_ii: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_total: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    grand_total: [
      "-",
      "2,50,000",
      "2,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "NIL",
    ],
  },
  {
    id: 2,
    startYear: "2021",
    endYear: "2022",
    promoters_1_a: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    promoters_1: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    promoters_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    promoters_total: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    public_1: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_i: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_ii: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_total: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    grand_total: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
  },
  {
    id: 3,
    startYear: "2022",
    endYear: "2023",
    promoters_1_a: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    promoters_1: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    promoters_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    promoters_total: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
    public_1: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_i: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_ii: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_total: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    grand_total: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
    ],
  },
  {
    id: 4,
    startYear: "2023",
    endYear: "2024",
    promoters_1_a: [
      "-",
      "2,95,508",
      "2,95,508",
      "84.43",
      "-",
      "3,65,508",
      "3,65,508",
      "25.03",
      "60.33",
    ],
    promoters_1: [
      "-",
      "2,95,508",
      "2,95,508",
      "84.43",
      "-",
      "3,65,508",
      "3,65,508",
      "25.03",
      "60.33",
    ],
    promoters_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    promoters_total: [
      "-",
      "2,95,508",
      "2,95,508",
      "84.43",
      "-",
      "3,65,508",
      "3,65,508",
      "25.03",
      "60.33",
    ],
    public_1: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    public_2_b_i: ["-", "6,636", "6,636", "1.90", "-", "6,636", "6,636", "0.45", "1.45"],
    public_2_b_ii: ["-", "47,856", "47,856", "13.67", "-", "10,87,856", "10,87,856", "74.51", "59.33"],
    public_total: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
    grand_total: [
      "-",
      "3,50,000",
      "3,50,000",
      "100",
      "-",
      "14,60,000",
      "1,46,00,000",
      "100",
      "121.66",
    ],
  },
];
export default Shareholdingsapi;
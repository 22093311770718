import React, { useEffect, useState, useRef } from "react";
import "./Footer.css";
import { FaCircle } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";

function Footer() {
  const inactiveTimeRef = useRef(0);
  const intervalRef = useRef(null);

  // logout function
  const logout = () => {
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://api-scdndestates.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            alert(response.error);
            window.location.href = "/userLost";
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      alert("refresh token not provided or connection failure");
      window.location.href = "/accessDenied";
    }
  };
  // logout function

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const isTokenExpired = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expiry = payload.exp * 1000; // Convert to milliseconds
      return Date.now() > expiry;
    } catch (error) {
      // console.error("Failed to parse token:", error);
      return true; // Consider token expired if we can't parse it
    }
  };

  const fetchProtectedData = () => {
    const accessToken = getCookie("access_token");
    const refreshToken = getCookie("refresh_token");

    if (!accessToken || !refreshToken) {
      return;
    }
    if (isTokenExpired(accessToken) === false) {
      fetch(`https://api-scdndestates.com/api/refresh_access_token/`, {
        method: "POST",
        body: JSON.stringify({
          refresh_token: refreshToken,
          access_token: accessToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch protected data");
          }
          return response.json();
        })
        .then((data) => {
          setTokens(data.new_access_token);
        })
        .catch((error) => {
          // console.error("Error fetching protected data:", error);
        });
    }
  };

  const setTokens = (accessToken) => {
    const access_token_expires = new Date();

    // Set access token to expire after 2 minutes
    access_token_expires.setTime(
      access_token_expires.getTime() + 2 * 60 * 1000
    );

    document.cookie = `access_token=${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  // refreshing access token

  // refreshing access token
  const checkAndRefreshToken = () => {
    console.warn("checkAndRefreshToken initiated...");
    const storedTokenTime = secureLocalStorage.getItem("access_token_time");
    const currentTime = new Date();
    let timeDifference = 0;

    if (storedTokenTime) {
      timeDifference = currentTime - new Date(storedTokenTime) + 2 * 60 * 1000;
      console.log("timeDifference: ", timeDifference);
      if (timeDifference >= 1 * 60 * 1000 && timeDifference < 2 * 60 * 1000) {
        console.log("refresh_access_token_api_calling: ", timeDifference);
        fetchProtectedData();
      } else if (timeDifference >= 2 * 60 * 1000) {
        console.log("logout initiated...");
        logout();
      }
    }
  };

  useEffect(() => {
    console.warn("useEffect initiated...");
    const events = ["click", "scroll", "keydown", "touchstart", "touchmove"];

    const handleActivity = () => {
      inactiveTimeRef.current = 0;
    };

    // Reset inactivity time on user activity
    events.forEach((event) => window.addEventListener(event, handleActivity));

    // Set up the interval to check for inactivity and refresh the token
    intervalRef.current = setInterval(() => {
      checkAndRefreshToken();
      inactiveTimeRef.current += 3;

      if (inactiveTimeRef.current >= 7 * 60) {
        console.log("idle logout initiated...");
        logout();
      }
    }, 3000); // Check every 3 seconds

    // Cleanup function
    return () => {
      clearInterval(intervalRef.current);
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
    };
  }, []);

  return (
    <div className="before-footer">
      <div className="footer flex-col min-[2000px]:items-center">
        <div className="footer-title flex-col align-center max-w-[2000px]">
          <div className="topsecc flex-column justify-evenly w-fit">
            <div className="logo-left  showw">
              <img className="footerLogo" src="/images/logo02.png" alt="" />
              <div className="logo-p text-white">
                <p className="footerLeftText">CIN: U70109WB2016PTC215755</p>
                <p className="footerLeftText">SEBI AIF-1 Reg. No:</p>
                <p className="footerLeftText">RBI NBFC (ICC)</p>
              </div>
            </div>
            <p className="footer-title flex items-center  ">
              Reinventing the Financial System
            </p>
          </div>
          <div className="bottom-line flex justify-center align-center showw"></div>
        </div>
        <div className="content-container flex  justify-evenly max-w-[2000px]">
          <div className="logo-left hidee">
            <img className="footerLogo" src="/images/logo02.png" alt="" />
            <div className="logo-p">
              <p className="footerLeftText">CIN: U70109WB2016PTC215755</p>
              <p className="footerLeftText">SEBI AIF-1 Reg. No:</p>
              <p className="footerLeftText">RBI NBFC (ICC)</p>
            </div>
          </div>
          <div className="footerLine hidee"></div>
          <div className="container1  flex-col">
            <p className="container-head flex items-center ">
              <svg
                className="c1-icon mt-1"
                width="38"
                height="39"
                viewBox="0 0 38 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.75 12.9831C5.75 11.462 5.75212 10.4218 5.85702 9.64163C5.95805 8.89019 6.13762 8.53132 6.3846 8.28434C6.63158 8.03737 6.99045 7.85779 7.74189 7.75676C8.52209 7.65187 9.56228 7.64974 11.0833 7.64974H26.9167C28.4377 7.64974 29.4779 7.65187 30.2581 7.75676C31.0096 7.85779 31.3684 8.03737 31.6154 8.28434L32.3205 7.57924L31.6154 8.28434C31.8624 8.53132 32.042 8.89019 32.143 9.64163C32.2479 10.4218 32.25 11.462 32.25 12.9831V26.2331H5.75V12.9831Z"
                  stroke="white"
                  strokeWidth="2"
                />
                <path
                  d="M5.80556 26.2331C4.6703 26.2331 3.75 27.1534 3.75 28.2886C3.75 30.0068 5.14289 31.3997 6.86111 31.3997H31.1389C32.8571 31.3997 34.25 30.0068 34.25 28.2886C34.25 27.1534 33.3297 26.2331 32.1944 26.2331H5.80556Z"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
              Support
            </p>
            <ul className="ul">
              <li className="li ">
                <span className="flex gap-2 items-center bulltet-points">
                  <FaCircle className="text-xs md:text-[8px] sm:text-[6px] " />
                  Corporate Office
                </span>
                <p className="li-p">
                  C-267 Ground Floor 5th Cross Street Swarn Nagri Greater Noida
                  Uttar Pradesh- 201310
                </p>
              </li>
              <li className="li">
                <span className="flex gap-2 items-center bulltet-points">
                  <FaCircle className="text-xs  md:text-[8px] sm:text-[6px]" />
                  Registered Office
                </span>
                <p className="li-p">
                  Flat 1/1A Clubtown Greens 167 Jessore Road Kolkata- 700055
                </p>
              </li>
            </ul>
          </div>
          <div className="container1 flex-col ">
            <p className="container-head flex items-center">
              <svg
                className="c1-icon mt-1"
                width="38"
                height="39"
                viewBox="0 0 38 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.2351 32.691C30.5134 30.6709 28.9231 28.886 26.711 27.6129C24.4988 26.3398 21.7884 25.6497 19 25.6497C16.2116 25.6497 13.5012 26.3398 11.289 27.6129C9.07686 28.886 7.48662 30.6709 6.76494 32.691"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <circle
                  cx="19"
                  cy="12.9831"
                  r="6.33333"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              About Us
            </p>
            <ul className="ul">
              <li className="li">
                <a href="/OurTeam" className="li flex gap-2 items-center">
                  <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points-new" />
                  <span className="head-a bulltet-points">Our Team </span>
                </a>
              </li>
              <li className="li">
                <a href="/Career" className="li flex gap-2 items-center">
                  <FaCircle className="text-xs  md:text-[8px] sm:text-[6px] bulltet-points bulltet-points-new" />
                  <span className="head-a bulltet-points ">Career </span>
                </a>
              </li>

              <li className="li ">
                <span className="flex gap-2 items-center bulltet-points">
                  <FaCircle className="  md:text-[8px] sm:text-[6px]" />
                  Pricing{" "}
                </span>
                <div className="hyperlinks flex-col justify-center ">
                  <a
                    className="li-a flex items-center "
                    href="/investment_advisory"
                  >
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Investment Advisory
                  </a>
                  <a className="li-a flex items-center" href="/fund_management">
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Fund Management
                  </a>
                  <a
                    className="li-a flex items-center"
                    href="/merchant_banking"
                  >
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Merchant Banking
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="container1 flex-col ">
            <p className="container-head flex items-center">
              <svg
                className="c1-icon mt-1"
                width="38"
                height="39"
                viewBox="0 0 38 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5417 8.23307C26.7654 8.23307 27.8773 8.23307 28.676 8.76675C29.0217 8.99779 29.3186 9.29467 29.5496 9.64044C30.0833 10.4391 30.0833 11.551 30.0833 13.7747V28.8164C30.0833 31.802 30.0833 33.2947 29.1558 34.2222C28.2283 35.1497 26.7356 35.1497 23.75 35.1497H14.25C11.2644 35.1497 9.77165 35.1497 8.84415 34.2222C7.91666 33.2947 7.91666 31.802 7.91666 28.8164V13.7747C7.91666 11.551 7.91666 10.4391 8.45034 9.64044C8.68137 9.29467 8.97825 8.99779 9.32402 8.76675C10.1227 8.23307 11.2346 8.23307 13.4583 8.23307"
                  stroke="white"
                />
                <path
                  d="M14.25 8.23307C14.25 6.48417 15.6678 5.06641 17.4167 5.06641H20.5833C22.3322 5.06641 23.75 6.48417 23.75 8.23307C23.75 9.98197 22.3322 11.3997 20.5833 11.3997H17.4167C15.6678 11.3997 14.25 9.98197 14.25 8.23307Z"
                  stroke="white"
                />
                <path
                  d="M14.25 19.3164L23.75 19.3164"
                  stroke="white"
                  strokeLinecap="round"
                />
                <path
                  d="M14.25 25.6497L20.5833 25.6497"
                  stroke="white"
                  strokeLinecap="round"
                />
              </svg>
              Compliance
            </p>
            <ul className="ul">
              <li className="li">
                <a
                  href="/legal_documents"
                  className="li flex gap-2 items-center"
                >
                  <FaCircle className="text-xs md:text-[8px] sm:text-[6px] bulltet-points-new" />
                  <span className="head-a bulltet-points">Legal</span>
                </a>
              </li>
              <li className="li ">
                <span className="flex gap-2 items-center bulltet-points">
                  <FaCircle className="text-xs md:text-[8px] sm:text-[6px]" />
                  Company Info
                </span>
                <div className="hyperlinks flex-col justify-center ">
                  <a
                    className="li-a flex items-center "
                    href="/finaancialStatements"
                  >
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Financial Statements
                  </a>
                  <a className="li-a flex items-center" href="/annualReports">
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Annual Report
                  </a>
                  <a className="li-a flex items-center" href="/shareholdings">
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Shareholdings
                  </a>
                  <a className="li-a flex items-center" href="/keyRatios">
                    <svg
                      className="a-icon mt-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.04166 4.95834V3.45834C2.04166 2.35377 2.93709 1.45834 4.04166 1.45834H9.95832C11.0629 1.45834 11.9583 2.35377 11.9583 3.45834V9.95834C11.9583 11.0629 11.0629 11.9583 9.95832 11.9583H8.45832"
                        stroke="white"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8.45834 5.54166V5.04166H8.95834V5.54166H8.45834ZM5.89523 8.81188L5.54168 9.16543L4.83457 8.45832L5.18812 8.10477L5.89523 8.81188ZM7.95834 8.74999V5.54166H8.95834V8.74999H7.95834ZM8.45834 6.04166H5.25001V5.04166H8.45834V6.04166ZM8.8119 5.89521L5.89523 8.81188L5.18812 8.10477L8.10479 5.1881L8.8119 5.89521Z"
                        fill="white"
                      />
                      <rect
                        x="2.25"
                        y="8.66666"
                        width="3.08333"
                        height="3.08333"
                        rx="1.5"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Key Ratios
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom flex justify-between align-center max-w-[2000px]">
          <div className="f-left-bottom flex-col justify-center">
            <p className="f-bottom-text ">
              Copyright © 2024 SCDND. All rights reserved.
            </p>
            {/* <p className="f-bottom-text flex align-center">
          Copyright © 2023 SCDND. All rights reserved. All rights reserved.
          </p> */}
          </div>
          <div className="right-bottom flex justify-end align-center gap-2">
            <button className="i-button">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-6 h-6 text-gray-800 dark:text-white"
              >
                <path
                  clipRule="evenodd"
                  d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                  fillRule="evenodd"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
            <button className="i-button">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-6 h-6 text-gray-800 dark:text-white"
              >
                <path
                  clipRule="evenodd"
                  d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                  fillRule="evenodd"
                  fill="currentColor"
                ></path>
                <path
                  d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>

            <button className="i-button">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-6 h-6 text-gray-800 dark:text-white"
              >
                <path
                  clipRule="evenodd"
                  d="M22 5.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.343 8.343 0 0 1-2.605.981A4.13 4.13 0 0 0 15.85 4a4.068 4.068 0 0 0-4.1 4.038c0 .31.035.618.105.919A11.705 11.705 0 0 1 3.4 4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 6.1 13.635a4.192 4.192 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 2 18.184 11.732 11.732 0 0 0 8.291 20 11.502 11.502 0 0 0 19.964 8.5c0-.177 0-.349-.012-.523A8.143 8.143 0 0 0 22 5.892Z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>

            <button className="i-button">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-6 h-6 text-gray-800 dark:text-white"
              >
                <path
                  clipRule="evenodd"
                  d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>

            <button className="i-button">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-6 h-6 text-gray-800 dark:text-white"
              >
                <path
                  clipRule="evenodd"
                  d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

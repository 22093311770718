import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import "./ConfirmPopUp.css";
import  secureLocalStorage  from  "react-secure-storage";

const ConfirmPopUp = ({ x, topUp, balance, activity, transactionId }) => {
  const {isAuthenticated, pan } = CheckAuth();
  // const { isAuthenticated, email, phone,fullName } = CheckAuth();
  const [loaderComplete, setLoaderComplete] = useState(false);
  const storedData = secureLocalStorage.getItem("tempData");
  const tempData = JSON.parse(storedData);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (pan, isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/transaction-details/`, {
        method: "POST",
        body: JSON.stringify({
          x: x,
          activity: activity,
          pan: pan,
          amount: topUp,
          transactionId: transactionId,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          secureLocalStorage.setItem("tempData", JSON.stringify(response.data));
          if (
            response["error"] === "Transaction ID / UTR Number is required" ||
            response["error"] === "Please select a payment method"
          ) {
            alert(response.error);
            navigate(-1);
          } else {
            if (response["path"] !== undefined) {
              if (response["path"] === "Money") {
                const loader = document.querySelector(".loader2");
                if (loader) {
                  loader.classList.add("complete2");
                  setLoaderComplete(true);
                  // if(activity === "Add Funds"){
                  //   fetch(`https://api-scdndestates.com/api/add-money-wallet/`, {
                  //     method: "POST",
                  //     body: JSON.stringify({
                  //       amount: topUp,
                  //       phone: "91" + phone,
                  //     }),
                  //     headers: {
                  //       "Content-type": "application/json; charset=UTF-8",
                  //     },
                  //   })
                  //     .then((response) => {
                  //       if (!response.ok) {
                  //         throw new Error("Failed to send otp");
                  //       }
                  //       return response.json();
                  //     })
                  //     .then((response) => {
                  //       if (response.error === "Phone number not found") {
                  //         alert("Phone number not found");
                  //       } 
                  //       var dataObject = JSON.parse(response.msg91_response);
                  //       var dataArray = [dataObject];
                  //     })
                  //     .catch((error) => {
                  //       // console.error("Error: ", error);
                  //     });
                  // }
                }
              }
            }
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  }, [pan, isAuthenticated]);

  return (
    <>
      <div className="frame2">
        <div className={`loader2 ${loaderComplete ? "complete2" : ""}`}>
          <div className="line2"></div>
          <div className="line2"></div>
          <span className="tick2 "></span>
        </div>
        {loaderComplete && (
          <h6 className="confirm-text px-4 max-w-lg lg:max-w-xl text-sm md:text-base font-semibold text-[#313131]">
            {activity === "Add Funds" ? (
              <>
                Your request to add INR{" "}
                {topUp.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                from your Bank A/C has been successfully processed. Total
                balance is INR{" "}
                {(balance + topUp).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                . Funds will be available in your Money Wallet for Orders in 1
                business day.
              </>
            ) : (
              <>
                Your request to withdraw INR{" "}
                {topUp.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                to your Bank A/C has been successfully processed. Total balance
                is INR{" "}
                {balance.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                . Funds will be credited in the next working day.
              </>
            )}
          </h6>
        )}
        {loaderComplete && (
          <button
            className="confirm-btn btn btn-light"
            onClick={() => navigate("/moneyWallet")}
          >
            Go Back
          </button>
        )}
      </div>
    </>
  );
};

export default ConfirmPopUp;
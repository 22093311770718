import React, { useState, useEffect } from "react";
import "./Partnerships.css";
import PartnershipApi from "../../API/PartnershipApi";
import FAQ from "./FAQ.jsx";
import Header from "../../components/Header/Header";

function Partnerships() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Header />
      <div className="container-fluid  after-header max-w-[1800px] mx-auto">
        <div>
          <h2 className="text-center w-[90%] mx-auto font-bold text-lg md:text-3xl md:my-4 mb-4 text-[#5ab2ff]">
            Earn by helping SCDND create customized investment solutions <br /> to perfectly fit your client's desires
          </h2>
        </div>
        <div className="flex flex-wrap  gap-y-[70px] container-fluid  h-max min-h-[600px] w-[90%] mx-auto mt-2  pt-3 xl:p-3 rounded-2xl justify-center md:gap-[50px] lg:gap-[60px] 2xl:gap-[150px]">
          {PartnershipApi.map((curElem) => {
            const { id, heading, info, image, route } = curElem;
            return (
              <>
                <div
                  to={route}
                  className=" flex flex-wrap justify-center"
                >
                  <div className="group  flex flex-col justify-start items-start gap-2  w-[280px] h-44 md:h-44 md:w-[280px] lg:w-[300px] xl:w-[325px] 2xl:w-[340px] lg:h-56 duration-500 relative rounded-lg p-3 bg-[#79ccff] hover:-translate-y-2 hover:shadow-xl shadow-blue-500">
                    <div
                      className="absolute duration-700 shadow-md group-hover:-translate-y-4 group-hover:-translate-x-4 -bottom-10 -right-6 w-1/2 h-1/2 rounded-lg bg-white object-cover overflow-hidden flex justify-center items-center border-3 border-white
                      "
                      alt="image here"
                    >
                      <img src={image} alt="" />
                    </div>

                    <div className="">
                      <h2 className="text-base lg:text-lg 2xl:text-xl font-bold mb-2 text-white">
                        {heading}
                      </h2>
                      <p className="text-gray-100 text-sm lg:text-base font-medium line-clamp-3">
                        {info}
                      </p>
                    </div>

                    <a
                      className="relative hover:bg-[#3993e3] bg-[#4aa9fd] text-white mt-2 rounded p-2 px-6 lg:top-7 "
                      href={route}
                    >
                      <button className="text-white">Explore</button>
                    </a>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <FAQ />
      </div>
    </>
  );
}

export default Partnerships;

import React, { useEffect } from "react";
import Header from "../../components/Header/Header.jsx";
import Welcome from "../../components/Welcome/Welcome.jsx";
import BusinessModel1 from "../../components/BusinessModel/BusinessModel1.jsx";
import CircularNavigation from "./CircularNavigation";
import AboutUs from "../../components/AboutUs/AboutUs.jsx";
import ProgressiveDisclosureTable from "../../components/WhatIs/ProgressiveDisclosureTable.jsx"
import Blogs from "../../components/Blogs/Blogs.jsx";
import ContactUs from "../../components/ContactUs/ContactUs.jsx";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header />
      <Welcome />
      <div className="" style={{ position: "relative" }}>
        <CircularNavigation  />
        <ContactUs />
        <Blogs />
        <ProgressiveDisclosureTable />
      </div>
    </>
  );
}

export default Home;

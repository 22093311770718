/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RiImageEditFill } from "react-icons/ri";
import { MdSecurityUpdateGood } from "react-icons/md";
import "./Profile.css";
import PopUp3 from "../../components/PopUp1/PopUp3";
import Loader2 from "../../components/Loaders/Loader2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import Header from "../../components/Header/Header.jsx";
import secureLocalStorage from "react-secure-storage";

function Profile() {
  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const { loader, selectedImage, userKycData } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const fullName =
    userKycData &&
    userKycData["fullName"] !== undefined &&
    userKycData["fullName"] !== null &&
    userKycData["fullName"];
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", "", ""]);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState(300);
  const [userOtp, setUserOtp] = useState();
  const [otp, setOtp] = useState();
  const [formName, setFormName] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const timerRef = useRef(null);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const sendEditPhoneOtp = (phone, fullName, form_name) => {
    if(isAuthenticated){
    fetch(`https://api-scdndestates.com/api/edit-profile/send-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        fullName: fullName,
        phone: "91" + phone,
        form_name: form_name,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send otp");
        }
        return response.json();
      })
      .then((response) => {
        if (response.error === "Not an Individual") {
          alert(
            "You can not edit the nominee page, as your pan is not an individual type"
          );
        } else {
          setOtp(response.otp);
          setShow1(true);
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
    }
  };

  const handleSubmit = () => {
    if (!isAuthenticated) {
      navigate("/login");
      return; // Prevent further execution if not authenticated
    }
    if(isAuthenticated){
    if (userOtp === otp) {
      fetch(`https://api-scdndestates.com/api/user-kyc-edit/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: formName,
          email: email,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          secureLocalStorage.setItem("kycData", JSON.stringify(response.data));
          alert(response["error"]);
          if (response["path"] !== undefined) {
            navigate("/UserKycForm", {
              state: { selectedCard: response["path"] },
            });
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    } else {
      setOtpStatus(true);
    }
  }
  };

  const handleEdits = (form_name) => {
    setFormName(form_name);
    if (
      form_name === "nominee" ||
      form_name === "signupMode" ||
      form_name === "taxDetails" ||
      form_name === "taxDetails2"
    ) {
      sendEditPhoneOtp(phone, fullName, form_name);
      if (form_name === "nominee") {
        setName("Nominee Details");
      } else if (form_name === "signupMode") {
        setName("Agency Code");
      } else {
        setName("Tax Details");
      }
      setTimer(90);
      handleTimer();
    } else {
      fetch(`https://api-scdndestates.com/api/user-kyc-edit/`, {
        method: "POST",
        body: JSON.stringify({
          form_name: form_name,
          email: email,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          secureLocalStorage.setItem("kycData", JSON.stringify(response.data));
          if (response["path"] !== undefined) {
            navigate("/UserKycForm", {
              state: { selectedCard: response["path"] },
            });
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  useEffect(() => {
    function OTPInput() {
      const inputs = document.querySelectorAll("#otp > *[id]");

      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("input", function (event) {
          const value = event.target.value;

          if (/^\d$/.test(value)) {
            if (i !== inputs.length - 1) {
              inputs[i + 1].focus();
            }
          } else if (value === "") {
            // Backspace is pressed
            if (i !== 0) {
              inputs[i - 1].focus();
            }
          }
        });
      }
    }

    OTPInput();
  }, []);

  const navigateSection = (pathName) => {
    navigate("/userkycform", { state: { selectedCard: pathName } });
  };

  const handleOtpInputChange = (e, index) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = e.target.value;
    setOtpInputs(newOtpInputs);
    const isCompleteOtp = newOtpInputs.every((input) => input !== "");
    if (e.target.value && index < otpInputs.length - 1) {
      const nextIndex = index + 1;
      document.getElementById(`otp-${nextIndex}`).focus();
    } else if (e.target.value === "" && index > 0) {
      const prevIndex = index - 1;
      document.getElementById(`otp-${prevIndex}`).focus();
    }

    if (isCompleteOtp) {
      setUserOtp(newOtpInputs.join(""));
    }
  };

  return (
    <>
      <Header />
      {loader && <Loader2 />}
      {show1 && (
        <div className="demo1-container ">
          <div className="pop2-content rounded-xl pop-up-animation">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="circle fromMiddle "
                data-animation="magnify"
                data-remove="3000"
                onClick={() => {
                  setShow1(false);
                  setOtpStatus(false);
                  setOtpInputs(["", "", "", "", ""]);
                }}
              >
                <span></span>
              </button>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center p-4">
              <MdSecurityUpdateGood
                size={100}
                color="#fff"
                className="otp-icon"
              />
              <h6 className="text-center mt-4 font-semibold" style={{ color: "#3b71ca" }}>
                Please consent to editing your {name} by OTP verification.
              </h6>
              <h6 className="text-sm font-medium pb-2 mt-2">
                A code has been sent to xxxxxx{phone?.slice(-4)}
              </h6>
              {otpStatus && (
                <>
                  <h6 className="font-size1 text-danger">
                    OTP entered is incorrect.
                  </h6>
                </>
              )}
              <h6 className="font-size1 text-primary">{formatTime(timer)}</h6>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2"
              >
                {otpInputs?.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    className="m-2 text-center form-control rounded"
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpInputChange(e, index)}
                  />
                ))}
              </div>
              <div className="mt-4"></div>
              <button className="btn btn-sm btn-primary w-[110px]" onClick={handleSubmit}>
                Verify OTP
              </button>
              {timer === 0 ? (
                <button
                  className="btn mt-2 btn-sm btn-primary w-[110px]"
                  onClick={() => {
                    const form_name =
                      name === "Nominee Details"
                        ? "nominee"
                        : name === "Agency Code"
                        ? "signupMode"
                        : name === "Tax Details" && "taxDetails";
                    sendEditPhoneOtp(phone, fullName, form_name);
                    setTimer(90);
                    handleTimer();
                  }}
                >
                  Resend OTP
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
      {show && <PopUp3 onClose={() => setShow(false)} />}
      <div className="p-4 md:p-16 max-w-[1800px] mx-auto ">
        <div className=" w-[90%] mx-auto p-2 md:p-8 mt-36 rounded-xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]  ">
          <div className="flex  relative ">
            <div
              className="cursor-pointer profile-img-container w-36 h-36 bg-indigo-100 mx-auto rounded-full shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500 border overflow-hidden  "
              onClick={() => {
                setShow(true);
              }}
            >
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : "./images/profile-user.png"
                }
                alt="Profile Image"
                className="profile-img object-cover"
              />
              <span className="pencil-icon">
                <RiImageEditFill />
              </span>
            </div>
          </div>

          <div className="mt-20 text-center border-b pb-8">
            <h1 className="text-lg md:text-2xl font-bold text-[#212121] tracking-wide">
              {userKycData === null || userKycData["fullName"] == null
                ? ""
                : userKycData["fullName"]}
            </h1>
            <p className="flex justify-center items-center text-sm md:text-base font-semibold text-[#212121] tracking-wide mt-2">
              <svg
                height={15}
                width={15}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
              </svg>
              &nbsp;&nbsp;{email}
            </p>
            <p className="flex justify-center items-center text-sm md:text-base font-semibold text-[#212121] tracking-wide mt-1">
              <svg
                height={15}
                width={15}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              &nbsp;&nbsp;{phone}
            </p>
          </div>
          <div className="bottom-sec flex flex-wrap w-full   pt-4">
            <div className="bottom-left  w-full flex flex-wrap justify-between ">
              {/* Address*/}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon flex items-center">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.21799 4.09202C4 4.51984 4 5.0799 4 6.2V6.81385L21.9895 5.17845C21.9711 4.68953 21.9206 4.36404 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H7.2C6.0799 3 5.51984 3 5.09202 3.21799C4.71569 3.40973 4.40973 3.71569 4.21799 4.09202ZM22 7.18574L17.1352 7.62799L19.6414 20.9947C20.2329 20.9818 20.6039 20.937 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V7.18574ZM17.6076 21L15.1344 7.80988L4 8.8221V17.8C4 18.9201 4 19.4802 4.21799 19.908C4.40973 20.2843 4.71569 20.5903 5.09202 20.782C5.51984 21 6.0799 21 7.2 21H17.6076ZM13.5 15.0294C13.5 17.1878 11.3603 18.7041 10.4269 19.2628C10.1623 19.4211 9.83768 19.4211 9.57313 19.2628C8.63974 18.7041 6.5 17.1878 6.5 15.0294C6.5 12.9118 8.19587 11.5 10 11.5C11.8667 11.5 13.5 12.9118 13.5 15.0294Z"
                      />
                      <circle cx="10" cy="15" r="1" />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3 text-gray-500 ">
                      Address
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null ||
                      userKycData["addressLine1"] === null
                        ? ""
                        : userKycData["area"] + ", " + userKycData["state"]}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (userKycData?.["KYC_status"]?.["B"] === 15) {
                        handleEdits("userDetails");
                      } else if (
                        userKycData?.["KYC_status"]?.["B"] === 5 ||
                        userKycData?.["KYC_status"]?.["B"] === 10
                      ) {
                        navigateSection("User Details");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["B"] === 15
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["B"] === 5 ||
                            userKycData?.["KYC_status"]?.["B"] === 10
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Agency Code          */}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon flex items-center">
                    <svg
                      className="h-10 w-10 flex bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15 17.75L16.8 16.4C18.8144 14.8892 20 12.5181 20 10V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V10C4 12.5181 5.18555 14.8892 7.2 16.4L9 17.75C10.7778 19.0833 13.2222 19.0833 15 17.75ZM10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10ZM13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10ZM15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3 text-gray-500 ">
                      Agency Code
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null ||
                      userKycData["agencyCode"] === null
                        ? ""
                        : userKycData["agencyCode"]}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (userKycData?.["KYC_status"]?.["G"] === 10) {
                        handleEdits("signupMode");
                      } else if (userKycData?.["KYC_status"]?.["G"] === 5) {
                        navigateSection("Signup Mode");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["G"] === 10
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["G"] === 5
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        // onClick={() => {
                        //   if (userKycData?.["KYC_status"]?.["G"] === 10) {
                        //     handleEdits("signupMode");
                        //   } else if (userKycData?.["KYC_status"]?.["G"] === 5) {
                        //     navigateSection("Signup Mode");
                        //   }
                        // }}
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Bank                 */}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.27446 10.1262C5 10.7229 5 11.4018 5 12.7595V16.9999C5 18.8856 5 19.8284 5.58579 20.4142C6.11733 20.9457 6.94285 20.9949 8.5 20.9995V16C8.5 14.8954 9.39543 14 10.5 14H13.5C14.6046 14 15.5 14.8954 15.5 16V20.9995C17.0572 20.9949 17.8827 20.9457 18.4142 20.4142C19 19.8284 19 18.8856 19 16.9999V12.7595C19 11.4018 19 10.7229 18.7255 10.1262C18.4511 9.52943 17.9356 9.08763 16.9047 8.20401L15.9047 7.34687C14.0414 5.74974 13.1098 4.95117 12 4.95117C10.8902 4.95117 9.95857 5.74974 8.09525 7.34687L7.09525 8.20401C6.06437 9.08763 5.54892 9.52943 5.27446 10.1262ZM13.5 20.9999V16H10.5V20.9999H13.5Z"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3 text-gray-500 ">
                      Bank
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null || userKycData["bank"] === null
                        ? ""
                        : userKycData["bank"]}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (userKycData?.["KYC_status"]?.["D"] === 10) {
                        handleEdits("clientVerification");
                      } else if (userKycData?.["KYC_status"]?.["D"] === 5) {
                        navigateSection("Client Verification");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["D"] === 10
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["D"] === 5
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Preferences           */}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="17.5"
                        cy="17.5"
                        r="2.5"
                        stroke="#1e40af"
                        strokeWidth="2"
                      />
                      <path
                        d="M21 21L19.5 19.5"
                        stroke="#1e40af"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.17157C20 4.34315 20 6.22876 20 10V13.1689C19.2646 12.7435 18.4107 12.5 17.5 12.5C14.7386 12.5 12.5 14.7386 12.5 17.5C12.5 19.4428 13.6081 21.1269 15.2268 21.9546C14.337 22 13.2765 22 12 22C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10ZM8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H12C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5H8ZM8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11H14C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9L8 9ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13H8Z"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3 text-gray-500 ">
                      Preferences
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      Null
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-[49%]   border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4H13ZM11 4V5H13V4H11Z" />
                      <path d="M13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20H13ZM11 20V15H13V20H11Z" />
                      <path d="M12 9V11" stroke="#1e40af" strokeWidth="2" />
                      <path d="M16 12.6C16 12.0399 16 11.7599 15.891 11.546C15.7951 11.3578 15.6422 11.2049 15.454 11.109C15.2401 11 14.9601 11 14.4 11H6.66274C6.41815 11 6.29586 11 6.18077 11.0276C6.07873 11.0521 5.98119 11.0925 5.89172 11.1474C5.7908 11.2092 5.70432 11.2957 5.53137 11.4686L5.13137 11.8686C4.73535 12.2646 4.53735 12.4627 4.46316 12.691C4.3979 12.8918 4.3979 13.1082 4.46316 13.309C4.53735 13.5373 4.73535 13.7354 5.13137 14.1314L5.53137 14.5314C5.70432 14.7043 5.7908 14.7908 5.89172 14.8526C5.98119 14.9075 6.07873 14.9479 6.18077 14.9724C6.29586 15 6.41815 15 6.66274 15H14.4C14.9601 15 15.2401 15 15.454 14.891C15.6422 14.7951 15.7951 14.6422 15.891 14.454C16 14.2401 16 13.9601 16 13.4V12.6Z" />
                      <path d="M7 6.6C7 6.03995 7 5.75992 7.10899 5.54601C7.20487 5.35785 7.35785 5.20487 7.54601 5.10899C7.75992 5 8.03995 5 8.6 5H16.3373C16.5818 5 16.7041 5 16.8192 5.02763C16.9213 5.05213 17.0188 5.09253 17.1083 5.14736C17.2092 5.2092 17.2957 5.29568 17.4686 5.46863L17.8686 5.86863C18.2646 6.26465 18.4627 6.46265 18.5368 6.69098C18.6021 6.89183 18.6021 7.10817 18.5368 7.30902C18.4627 7.53735 18.2646 7.73535 17.8686 8.13137L17.4686 8.53137C17.2957 8.70432 17.2092 8.7908 17.1083 8.85264C17.0188 8.90747 16.9213 8.94787 16.8192 8.97237C16.7041 9 16.5818 9 16.3373 9H8.6C8.03995 9 7.75992 9 7.54601 8.89101C7.35785 8.79513 7.20487 8.64215 7.10899 8.45399C7 8.24008 7 7.96005 7 7.4V6.6Z" />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3  text-gray-500 ">
                      Tax Resident
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null ||
                      userKycData["Tax_resident"] === null ? (
                        ""
                      ) : (
                        <>
                          {userKycData["Tax_resident"] === "yes" ? (
                            "Indian"
                          ) : (
                            <>
                              {userKycData["tax Resident"] === "Us/Canada"
                                ? "US/Canada"
                                : userKycData["tax Resident"] === "other" &&
                                  "Other than US/Canada"}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 10 ||
                        userKycData?.["KYC_status"]?.["C"] === 5
                      ) {
                        handleEdits("taxDetails");
                      } else if (userKycData?.["KYC_status"]?.["C"] === 15) {
                        navigateSection("Tax Details");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 10 ||
                        userKycData?.["KYC_status"]?.["C"] === 5
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["C"] === 15
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Income Level           */}

              <div className="w-full lg:w-[49%]  border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z" />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3  text-gray-500 ">
                      Income Level
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null ||
                      userKycData["incomelevel"] === null
                        ? ""
                        : userKycData["incomelevel"]}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 5 ||
                        userKycData?.["KYC_status"]?.["C"] === 15
                      ) {
                        handleEdits("taxDetails2");
                      } else if (userKycData?.["KYC_status"]?.["C"] === 10) {
                        navigateSection("Tax Details");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 5 ||
                        userKycData?.["KYC_status"]?.["C"] === 15
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["C"] === 10
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Demographics           */}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.87868 3.87868C2 4.75736 2 6.17157 2 9V15C2 17.8284 2 19.2426 2.87868 20.1213C3.75736 21 5.17157 21 8 21H16C18.8284 21 20.2426 21 21.1213 20.1213C22 19.2426 22 17.8284 22 15V9C22 6.17157 22 4.75736 21.1213 3.87868C20.2426 3 18.8284 3 16 3H8C5.17157 3 3.75736 3 2.87868 3.87868ZM16 8C16.5523 8 17 8.44772 17 9V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V9C15 8.44772 15.4477 8 16 8ZM9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11V17C7 17.5523 7.44772 18 8 18C8.55229 18 9 17.5523 9 17V11ZM13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13Z"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3  text-gray-500 ">
                      Demographics
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null ||
                      userKycData["incomelevel"] === null
                        ? ""
                        : userKycData["qualification"] +
                          ", " +
                          userKycData["occupation"]}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 10 ||
                        userKycData?.["KYC_status"]?.["C"] === 15
                      ) {
                        handleEdits("taxDetails3");
                      } else if (userKycData?.["KYC_status"]?.["C"] === 5) {
                        navigateSection("Tax Details");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["C"] === 20 ||
                        userKycData?.["KYC_status"]?.["C"] === 10 ||
                        userKycData?.["KYC_status"]?.["C"] === 15
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["C"] === 5
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Nominees        */}

              <div className="w-full lg:w-[49%] border ease-in duration-200 hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] mt-2 p-2 rounded-xl cursor-pointer flex justify-between items-center ">
                <div className="flex items-center min-w-0 gap-x-4 ">
                  <div className="icon">
                    <svg
                      className="h-10 w-10 flex-none  bg-gray-50 fill-blue-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="9" r="4" fill="fill-blue-300" />
                      <circle cx="17" cy="9" r="3" fill="fill-blue-300" />
                      <circle cx="7" cy="9" r="3" fill="fill-blue-300" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5685 18H19.895C20.4867 18 20.9403 17.4901 20.7966 16.9162C20.4284 15.4458 19.448 13 17 13C16.114 13 15.4201 13.3205 14.8781 13.7991C16.3858 14.7773 17.1654 16.4902 17.5685 18Z"
                        fill="fill-blue-300"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.12197 13.7991C8.57989 13.3205 7.88609 13 7 13C4.55208 13 3.57166 15.4458 3.20343 16.9162C3.05971 17.4901 3.51335 18 4.10498 18H6.43155C6.83464 16.4902 7.61422 14.7773 9.12197 13.7991Z"
                        fill="fill-blue-300"
                      />
                      <path
                        d="M12 14C15.7087 14 16.6665 17.301 16.9139 19.0061C16.9932 19.5526 16.5523 20 16 20H8C7.44772 20 7.00684 19.5526 7.08614 19.0061C7.33351 17.301 8.29134 14 12 14Z"
                        fill="fill-blue-300"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-3  text-gray-500 ">
                      Nominees
                    </p>
                    <p className="mt-1 font-bold truncate text-sm leading-2 text-[#212121]">
                      {userKycData === null || userKycData["nominees"] === null
                        ? ""
                        : userKycData["nominees"]?.map((nominee, index) => (
                            <span key={index}>
                              {nominee.name + " (" + nominee.relation + "), "}
                            </span>
                          ))}
                    </p>
                  </div>
                </div>
                <div className="edit px-2">
                  <button
                    className="flex items-center text-sm font-semibold  bg-red-100 focus:animate-pulse"
                    onClick={() => {
                      if (userKycData?.["KYC_status"]?.["F"] === 10) {
                        handleEdits("nominee");
                      } else if (userKycData?.["KYC_status"]?.["F"] === 5) {
                        navigateSection("Nominee");
                      }
                    }}
                  >
                    <svg
                      className={`${
                        userKycData?.["KYC_status"]?.["F"] === 10
                          ? "fill-[#212121]"
                          : userKycData?.["KYC_status"]?.["F"] === 5
                          ? "fill-blue-500"
                          : ""
                      } h-5 w-5 flex-none  bg-gray-50 hover:fill-blue-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import "./Page404.css";
import secureLocalStorage from "react-secure-storage";

function Page404() {
  const { pan, email, phone } = CheckAuth();
  const [angle, setAngle] = useState(0);
  const [loader2, setLoader2] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const box = document.getElementById("Spinner");
    if (!box) return; // Add this check

    const boxCenter = [
      box.offsetLeft + box.offsetWidth / 2,
      box.offsetTop + box.offsetHeight / 2,
    ];

    const handleMouseMove = (e) => {
      const newAngle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI);
      setAngle(newAngle - 45);
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      {loader2 ? (
        <Loader2 />
      ) : (
        <>
          <div className="compass-container">
            <p className="text-center text-base md:text-lg font-semibold text-[#212121]">Authentication Error</p>
            <h3 className="compass-text text-7xl md:text-[6rem] mb-3 text-[#5ab2ff]">401</h3>
            <div
              className="compass bg-cover bg-center flex items-center justify-center w-3/4 h-2/5 md:w-[360px] md:h-[360px]"
              style={{
                backgroundImage: "url(./images/compass-back.png)",
              }}
            >
              <img
                id="Spinner"
                src="/images/compass-front.png"
                alt=""
                style={{ transform: `rotate(${angle}deg)` }}
                className="h-[180px] md:h-[190px] lg:h-[240px] 2xl:h-[250px]"
              />
            </div>

            <div className="flex-col justify-center items-center md:mt-10 w-full">
              <h3 className="text-center text-[#212121] font-semibold text-sm md:text-base lg:text-lg w-[90%] max-w-xl">
                Data integrity has been compromised! You have been Logged Out
                for protection. Please check your device and WiFi security
                before Loggin In again
              </h3>
              <button
                className="mt-4 cursor-pointer flex w-24 md:w-36  h-8 md:h-10  items-center justify-center rounded-md bg-[#5ab2ff] px-2 py-1 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Home
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Page404;

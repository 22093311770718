import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaBell } from "react-icons/fa";
import Loader2 from "../components/Loaders/Loader2.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

function Navbar({ handleSidebar }) {
  const { email, phone, isAuthenticated } = CheckAuth();
  const [sideBar, setSideBar] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (email === "" || phone === "") {
      setLoader2(true);
    } else if (email === "scdnd.estates@gmail.com" && phone === "9163213191") {
      setLoader2(false);
    } else {
      navigate("/");
    }
  }, [email, phone]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // logout function
  const logout = () => {
    setLoader2(true);
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://api-scdndestates.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            alert(response.error);
            window.location.href = "/userLost";
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setLoader2(false);
      alert("refresh token not provided or connection failure");
      window.location.href = "/accessDenied";
    }
  };
  // logout function

  return (
    <div>
      {loader2 ? (
        <Loader2 />
      ) : (
        <nav className="admin-navbar col-lg-12 col-12 p-0 d-flex">
          <div
            className="text-center admin-navbar-brand-wrapper d-flex align-items-center justify-content-center"
            style={{ width: sideBar === true ? "300px" : "110px" }}
          >
            <a href="/">
              <img
                src="/images/logo01.png"
                alt="logo"
                className="admin-navbar-brand-logo-mini"
              />
            </a>
          </div>
          <div className="d-flex justify-content-between px-4 w-100">
            <div className="align-self-center">
              <FaBars
                color="#8f8d8d"
                size={20}
                onClick={() => {
                  setSideBar(!sideBar);
                  handleSidebar();
                }}
              />
            </div>
            <ul className="d-flex justify-content-end align-items-center list-unstyled p-0 m-0">
              <button
                onClick={logout}
                className="text-sm font-bold p-2 border-2 rounded-md border-black"
              >
                Logout
              </button>
              <li className="nav-item">
                <div className="dropdown">
                  <div
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-link dropdown-toggle"
                  >
                    <FaBell color="#000" />
                    <span className="count-symbol"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </div>
  );
}

export default Navbar;

import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";

function AccordionItem() {
  const productFAQ = [
    {
      id: 0,
      ques: "Am I charged the One-time Onboarding Fee on the total MONEY WALLET balance or Top-up I’ve made over the first year? Is my ‘Initial Investment’ the net of my Buy and Sale of DeAs through your portal?",
      ans: "There is zero Onboarding Fee liable on your MONEY WALLT balance irrespective of how many times you Top-up or Withdraw. The slab rate is calculated on the ‘Initial Investment’ amount which is total sum of the DeAs you purchased from PRIMARY MARKET only. The amount of DeAs sold or redeemed is not considered for this purpose. Please also note that any DeAs bought through Peer-2-Peer trading module is NOT liable for Onboarding Fee.",
    },
    {
      id: 1,
      ques: "Similar to other debt instruments rated by various credit rating agencies, what certifications does your financial products hold?",
      ans: "Traditional debt securities are ‘centralized’ in the sense of being a promise made to the holders by one firm. The credit rating agencies need to certify the financial health of these firms as the timely repayment of principal and interest, depends on the ability of the issuing firm to fulfil it. On the contrary, a DeAs is free from all counterparty risk as all liabilities are backed up (collateralized) by liquid and internationalized assets (held in Escrow). Therefore, the financial health of SCDND Estates has no consequence on the safety and timely repayment of the WALR and RESIDEX issued by it, and by its very nature of being ‘decentralized’ no credit rating by a third party is necessary for DeAs.",
    },
    {
      id: 2,
      ques: "What is the grievance redressal process against the Agents or relationship managers in cases of poor services or non-fulfilment of commitments?",
      ans: "All KYC complaint Users can contact their designated Agents (for assisted signup) or relationship managers (direct signup) directly at their Email and Mobile No., listed under ‘Contact’, as the first step for submitting their grievance. If unsatisfied, please escalate the matter by describing your complaint on the ‘Onboard Fee tab’ and submitting along with a Star rating. The internal compliance team will resolve it directly within 24 hours. Moreover, Users can always change their Agent from the ‘Edit Profile’ page.",
    },
    {
      id: 3,
      ques: "How am I charged for assessing my portfolio using the Risk-Sensor Engine, and the related investment advisory services?",
      ans: "Users are charged on a subscription-based model, and NOT as a percentage of the total value of assets they evaluate and reorganize, for using our AI-based Investment Advisor. Please refer to the ‘Pricing page’ on the Footer for details of the subscription rates, validity and offers.",
    },
    {
      id: 4,
      ques: "Can anyone participate in the mining process and adding of new blocks to the hyper ledger and earn the rewards?",
      ans: "All KYC complaint nodes can mine new blocks and audit the chain in equal standing with each other and SCDND itself. You don’t need to hold any DeAs for mining as the Blockchain is based on Proof-of-Work (PoW) consensus mechanism. All rewards are paid in the form of newly minted DeAs of the corresponding Hyperledger.",
    },
    {
      id: 5,
      ques: "How do the Gas Charges paid by the network participants accrue to me if I audit the Blockchain?",
      ans: "All nodes have equal standing to audit new blocks and SCDND itself enjoys no special privilege. The processing priority of transactions on the chain are sorted by the Transaction fees paid by each network participant, which are directly credited (in INR) to the MONEY WALLET of the Users who Audit the newly minted block through Smart Contracts.",
    },
    {
      id: 6,
      ques: "How is the rate of Tax Deduction at Source (TDS) determined against the Coupons payable by SCDND, and how can I verify the credits against my PAN?",
      ans: "TDS rates are determined based on the Income Tax Act. 1961. If you are eligible for TDS exemption, please select “80G/H” in your User KYC information, and ensure your Tax Residency status is appropriately reported your ‘Edit Profile’ page. All TDS deducted are credited at the start of the next quarter to the User’s PAN, and Form 16A issued by SCDND can be viewed/downloaded from the ‘Form 16A(TDS) tab’ by selecting the date range.",
    },
    
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="border rounded-lg p-3 relative transition-all duration-500 ease-in-out mt-20">
      <h2 className="font-bold text-lg bg-white text-black absolute -top-4 px-2 w-fit">
        Frequently Asked Questions
      </h2>
      <div className="mt-3 ">
        <div className="flex flex-col gap-0">
          {productFAQ.map((data, index) => (
            <div className="text-black" key={index}>
              <h2
                className="flex flex-row justify-between lg:ṭitems-center text-xs lg:text-base font-semibold p-3 cursor-pointer "
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex lg:items-center gap-2">
                  <span className="text-base md:text-lg mt-0.5 md:mt-0">
                    <BsQuestionCircleFill />
                  </span>
                  <span
                    className="font-semibold px-2 duration-500 ease-in-out"
                    style={{
                      color: openIndex === index ? "#339dfa" : "#212121",
                    }}
                  >
                    {data.ques}
                  </span>
                </div>
                <span className="duration-1000 ease-in-out">
                  {openIndex === index ? (
                    <IoIosArrowDropup
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  ) : (
                    <IoIosArrowDropdown
                      // size={25}
                      className="duration-1000 ease-in-out text-[#339dfa] text-lg md:text-lg lg:text-2xl"
                    />
                  )}
                </span>
              </h2>
              <div
                style={{
                  maxHeight: openIndex === index ? "300px" : "0",
                }}
                className={`ml-7  border-l-2 border-[#339dfa]  overflow-hidden  duration-700 ease-in-out ${
                  openIndex === index ? "max-h-screen " : "max-h-0 "
                }`}
              >
                <p className=" py-1 pl-3 pr-4 text-[#212121] font-medium text-xs lg:text-base">
                  {data.ans}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccordionItem;

import React, { useState, useEffect } from "react";
// import { TiTick } from "react-icons/ti";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
// import Header from "../../components/Header/Header";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import { FaFacebookF, FaGoogle, FaLinkedin } from "react-icons/fa";
import "./Register.css";

function Register(props) {
  const { isAuthenticated } = CheckAuth();
  const [email, setEmail] = useState("");
  const [postEmail, setPostEmail] = useState("");
  const [a, setA] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [pass, setPass] = useState("");
  const [confirmpass, setConfirmpass] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [responseFromApi, setResponseFromApi] = useState("");
  const [generatedEmailOtp, setGeneratedEmailOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(0);
  const [phoneVerified, setPhoneVerified] = useState(0);
  const [generatedPhoneOtp, setGeneratedPhoneOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [loader, setLoader] = useState(false);
  const [phoneOtpMsg, setPhoneOtpMsg] = useState("");
  const [resendTimer, setResendTimer] = useState(90);
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);

  const navigate = useNavigate();

  const handleResendTimer = () => {
    setGeneratedEmailOtp("");
    setResendTimer(90);

    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const handleResendPhoneTimer = () => {
    setGeneratedPhoneOtp("");
    setResendPhoneTimer(90);

    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const verifyEmailOtp = (e) => {
    e.preventDefault();
    if (generatedEmailOtp === emailOtp) {
      setEmailVerified(1);
    } else {
      alert("Email is not verified. Please enter correct otp!");
      setEmailVerified(2);
    }
  };

  const verifyPhoneOtp = (e) => {
    e.preventDefault();
    fetch(`https://api-scdndestates.com/api/register/verify-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        phone: "91" + phone,
        otp: phoneOtp,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send otp");
        }
        return response.json();
      })
      .then((response) => {
        var dataObject = JSON.parse(response.msg91_response);
        var dataArray = [dataObject];
        if (dataArray[0].message === "OTP verified success") {
          setPhoneVerified(1);
        } else {
          alert("Phone is not verified. Please enter correct otp!");
          setPhoneVerified(2);
        }
        // Handle the received data here
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const sendEmailOtp = (e) => {
    e.preventDefault();
    fetch(`https://api-scdndestates.com/api/register/send-email-otp/`, {
      method: "POST",
      body: JSON.stringify({
        email: email + "@" + postEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send otp");
        }
        return response.json();
      })
      .then((response) => {
        handleResendTimer();
        setGeneratedEmailOtp(response.data);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const sendPhoneOtp = (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      alert("Phone number must be a 10 digit number.");
    } else {
      fetch(`https://api-scdndestates.com/api/register/send-phone-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: "91" + phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          var dataObject = JSON.parse(response.msg91_response);
          var dataArray = [dataObject];
          handleResendPhoneTimer();
          setGeneratedPhoneOtp(dataArray[0].type);
          // Handle the received data here
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const handleRegister = (e) => {
    setLoader(true);
    e.preventDefault();
    if (pass !== confirmpass) {
      alert("Passwords do not match, please re-renter your password.");
      setLoader(false);
    } else if (emailVerified !== 1) {
      alert("Your email is not verified, Please verify your email.");
      setLoader(false);
    } else if (phoneVerified !== 1) {
      alert("Your phone is not verified, Please verify your phone number.");
      setLoader(false);
    } else if (a !== true) {
      alert("Please Check your password characters again.");
      setLoader(false);
    } else {
      fetch(`https://api-scdndestates.com/api/register/`, {
        method: "POST",
        body: JSON.stringify({
          email: email + "@" + postEmail,
          password: pass,
          fullName: fullName,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          setResponseFromApi(response);
          alert(response.data);
          setLoader(false);
          navigate("/login");
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  if (isAuthenticated) {
    navigate("/");
  }

  useEffect(() => {}, [phoneVerified]);

  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value) => {
    setPass(value);

    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&])[A-Za-z\d!@#$%^&]{8,}$/;

    if (strongPasswordRegex.test(value)) {
      if (value.length >= 8) {
        setErrorMessage(
          <span className="text-success text-sm font-semibold">Strong Password</span>
        );
        setA(true);
      } else {
        setErrorMessage(
          <span className="text-warning text-sm font-semibold">Medium Password</span>
        );
        setA(true);
      }
    } else {
      setErrorMessage(
        <span className="text-danger font-size1">
          Password must have at least 8 characters, including at least 1
          lowercase character, 1 uppercase character, 1 number, and 1 special
          character from (1@#$%^&*)
        </span>
      );
      setA(false);
    }
  };

  const handlephone = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length < 11) {
      if (/^\d*$/.test(inputValue)) {
        setPhone(inputValue);
      }
    }
  };

  return (
    <>
      {loader && <Loader2 />}
      <div className="bg-fixed flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 md:mt-4">
        <div className="w-full mx-auto">
          <a href="/">
            <img
              className="mx-auto h-16 w-auto"
              src="./images/logo01.png"
              alt="Your Company"
            />
          </a>
          <h2 className="mt-8 md:mt-10 text-center text-xl md:text-2xl font-bold leading-9 tracking-tight text-[#212121]">
            Welcome to SCDND Estates
          </h2>
          <h2 className="md:mt-2 text-center text-lg md:text-xl font-bold leading-9 tracking-tight text-[#212121]">
            Get Started
          </h2>
        </div>

        <div className="mt-8 md:mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6" onSubmit={handleRegister}>
            <div className=" justify-center">
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-semibold leading-4 text-[#212121]"
                >
                  Enter Your Full Name
                </label>
                <div className="mt-2">
                  <input
                    value={fullName}
                    name="fullName"
                    className="px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 "
                    onChange={(e) => setFullName(e.target.value)}
                    id="fullName"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
              </div>
              <div className="mt-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Enter Your Email Id
                  </label>
                  <div className="text-sm ">
                    {emailVerified === 1 ? (
                      <></>
                    ) : (
                      <>
                        {resendTimer === 0 || resendTimer === 90 ? (
                          <button
                            className="focus:animate-pulse font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                            onClick={sendEmailOtp}
                          >
                            {emailVerified !== 2 ? <>Send OTP</> : <>Resend</>}
                          </button>
                        ) : (
                          <div className="font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]">
                            Resend in {resendTimer}s
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className=" flex items-center mt-2">
                  {emailVerified === 1 || generatedEmailOtp !== "" ? (
                    <>
                      <div className="input-group m-0 p-0 row ">
                        <input
                          value={email}
                          className="px-2 block col-7 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          placeholder="youremail"
                          id="email"
                          name="email"
                          disabled
                        />
                        <span className="input-group-text col-1 font-size1 py-1 px-2">
                          @
                        </span>
                        <input
                          value={postEmail}
                          className="px-2 block col-4 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                          onChange={(e) => setPostEmail(e.target.value)}
                          type="text"
                          placeholder="gmail.com"
                          id="postEmail"
                          name="postEmail"
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" input-group m-0 p-0 row">
                        <input
                          value={email}
                          className="px-2 block col-7 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          placeholder="youremail"
                          id="email"
                          name="email"
                          required
                        />
                        <span className="px-2 block col-1 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6">
                          @
                        </span>
                        <input
                          value={postEmail}
                          className="px-2 block col-4 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                          onChange={(e) => setPostEmail(e.target.value)}
                          type="text"
                          placeholder="gmail.com"
                          id="postEmail"
                          name="postEmail"
                          required
                        />
                      </div>
                    </>
                  )}
                </div>
                {generatedEmailOtp !== "" && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="col-10 ">
                        <input
                          value={emailOtp}
                          onChange={(e) => setEmailOtp(e.target.value)}
                          type="text"
                          className={`px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 ${
                            emailVerified === 2
                              ? "border-danger border-2"
                              : "border-dark"
                          }`}
                          placeholder="Enter OTP"
                          id="emailOtp"
                          name="emailOtp"
                          required
                          disabled={emailVerified === 1}
                        />
                      </div>
                      <div className=" ">
                        {emailVerified === 0 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyEmailOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                        {emailVerified === 1 && (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        )}
                        {emailVerified === 2 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyEmailOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Enter Your Phone Number
                  </label>
                  <div className="text-sm ">
                    {phoneVerified === 1 ? (
                      <></>
                    ) : (
                      <>
                        {resendPhoneTimer === 0 || resendPhoneTimer === 90 ? (
                          <button
                            className="focus:animate-pulse font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                            onClick={sendPhoneOtp}
                          >
                            {phoneVerified !== 2 ? <>Send OTP</> : <>Resend</>}
                          </button>
                        ) : (
                          <div className="font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]">
                            Resend in {resendPhoneTimer}s
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className=" flex items-center mt-2">
                  <div className="input-group m-0 p-0 row ">
                    <input
                      value={phone}
                      onChange={handlephone}
                      className="px-2 block col-12 rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6"
                      type="text"
                      placeholder="Enter phone number"
                      id="phone"
                      name="phone"
                      required
                      disabled={generatedPhoneOtp !== ""}
                    />
                  </div>
                </div>
                {generatedPhoneOtp === "success" && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="col-10 ">
                        <input
                          value={phoneOtp}
                          onChange={(e) => setPhoneOtp(e.target.value)}
                          type="text"
                          className={`px-2 block w-full rounded-md py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 ${
                            phoneVerified === 2
                              ? "border-danger border-2"
                              : "border-dark"
                          }`}
                          placeholder="Enter OTP"
                          id="phoneOtp"
                          name="phoneOtp"
                          required
                          disabled={phoneVerified === 1}
                        />
                      </div>
                      <div className=" ">
                        {phoneVerified === 0 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyPhoneOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                        {phoneVerified === 1 && (
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                        )}
                        {phoneVerified === 2 && (
                          <>
                            <button
                              className="text-sm font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={verifyPhoneOtp}
                            >
                              Verify
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-3">
                <div className="">
                  <label className="newpassword block text-sm font-semibold leading-4 text-[#212121]">
                    Enter your New Password:
                  </label>
                </div>

                <div className="mt-2 ">
                  <div className="flex">
                    <input
                      value={pass}
                      onChange={(e) => validate(e.target.value)}
                      type={`${showPassword === false ? "password" : "text"}`}
                      placeholder="New Password"
                      id="password"
                      name="password"
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                      required
                    />
                    <div
                      className="px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="">
                  <label className="confirmpassword block text-sm font-semibold leading-4 text-[#212121]">
                    Confirm your New Password:
                  </label>
                </div>

                <div className="mt-2 ">
                  <div className="flex">
                    <input
                      type={`${showPassword1 === false ? "password" : "text"}`}
                      value={confirmpass}
                      onChange={(e) => setConfirmpass(e.target.value)}
                      placeholder="Confirm password"
                      id="confirmpassword"
                      name="confirmpassword"
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] text-sm sm:leading-6"
                      required
                    />
                    <div
                      className="px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 my-1">
              {errorMessage === "" ? null : <>{errorMessage}</>}
            </div>

            <div className="mb-4 d-flex justify-content-center">
              <button
                className="flex w-full justify-center rounded-md bg-[#5ab2ff] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="submit"
              >
                Register
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-[#5ab2ff] hover:text-[#3aa0ff]"
            >
              Login Here
            </a>
          </p>
          <p className="mt-1 text-center text-sm font-semibold text-gray-500 flex-column">
            Or Sign-up with:{" "}
            <div className="signup-icons mt-2">
              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaFacebookF />
              </button>

              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaGoogle />
              </button>

              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaLinkedin />
              </button>
            </div>
          </p>
        </div>
      </div>
    </>
  );
}

export default Register;

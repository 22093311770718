import React, { useState } from "react";
import PopUp1 from "../../components/PopUp1/PopUp1";
import { FaFilePdf } from "react-icons/fa6";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import Header from "../../components/Header/Header";
import { IoIosArrowDown } from "react-icons/io";

function FinancialStatements() {
  const { isAuthenticated } = CheckAuth();

  const [openSection, setOpenSection] = useState("2023");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div>
      <Header />
      {!isAuthenticated ? (
        <PopUp1 />
      ) : (
        <>
          <div className="container-fluid after-header m-0 px-0 max-w-[1800px] mx-auto">
            <div className="flex w-[90%] mx-auto">
              <h6 className="pt-4 w-full text-sm md:text-base">
                <a href="/">
                  <span className="header-link">Home</span>
                  <span className="text-secondary"> &gt; </span>
                </a>
                <a href="#">
                  <span className="text-dark text-decoration-underline">
                    Financial Statements
                  </span>
                </a>
                <hr className="text-dark mb-0" />
              </h6>
            </div>

            <div className="flex flex-wrap  justify-between items-start mt-4 w-[90%] mx-auto">
              {/* Left Section */}
              <div className="w-full md:w-[48%]">
                <img
                  src="/images/Financial-data.gif"
                  alt="Financial Data"
                  className="w-full md:w-[650px]"
                />
              </div>
              {/* Right Section */}
              <div className="w-full md:w-[48%]">
                <div className="text-black border rounded-lg shadow-md min-h-[450px] lg:min-h-[520px] 2xl:min-h-[630px]">
                  <h2 className="font-bold text-lg lg:text-xl 2xl:text-2xl bg-[#3B71CA] text-white px-4 py-2 rounded-t-lg">
                    Financial Statements
                  </h2>
                  <hr />
                  <div className="px-4 py-2 overflow-y-scroll">
                    <div className="mt-4 flex flex-col gap-2.5 max-h-[350px] lg:max-h-[400px] 2xl:max-h-[550px]">

                      {/* Cart 0 */}
                      <div
                        onClick={() => toggleSection("2023")}
                        className="flex w-full cursor-pointer items-center justify-between gap-2 p-2 border bg-zinc-100 rounded-lg font-semibold"
                      >
                        <h2 className="text-base lg:text-lg font-bold">
                          Statements of FY 2023-24
                        </h2>
                        <span
                          className={`text-base lg:text-lg  ${
                            openSection === "2023" ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      </div>

                      {openSection === "2023" && (
                        <div className={`flex p-3 rounded-b-lg flex-col gap-1.5 w-full border-b border-x shadow-sm transition-all ease-out duration-300 ${openSection ? "is-expanded animateIn" : "animateOut"}`}>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Balance Sheet</h2>
                            <a
                              href="./PDFs/Financials Statements/BS_FY2023-24.pdf"
                              download="Balance_Sheet_2024.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Profit & Loss</h2>
                            <a
                              href="./PDFs/Financials Statements/P&L_FY2023-24.pdf"
                              download="P&L_FY2023-24.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Related Party</h2>
                            <a
                              href="./PDFs/Financials Statements/RP_FY2023-24.pdf"
                              download="RP_FY2023-24.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Change on Assets</h2>
                            <a
                              href="#"
                              download="Change on Assets 2024.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                        </div>
                      )}

                      {/* Cart 1 */}
                      <div
                        onClick={() => toggleSection("2022")}
                        className="flex w-full cursor-pointer items-center justify-between gap-2 p-2 border bg-zinc-100 rounded-lg font-semibold"
                      >
                        <h2 className="text-base lg:text-lg font-bold">
                          Statements of FY 2022-23
                        </h2>
                        <span
                          className={`text-base lg:text-lg  ${
                            openSection === "2022" ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      </div>

                      {openSection === "2022" && (
                        <div className={`flex p-3 rounded-b-lg flex-col gap-1.5 w-full border-b border-x shadow-sm transition-all ease-out duration-300 ${openSection ? "is-expanded animateIn" : "animateOut"}`}>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Balance Sheet</h2>
                            <a
                              href="./PDFs/Financials Statements/BS_FY2022-23.pdf"
                              download="BS_FY2022-23.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Profit & Loss</h2>
                            <a
                              href="./PDFs/Financials Statements/P&L_FY2022-23.pdf"
                              download="P&L_FY2022-23.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Related Party</h2>
                            <a
                              href="./PDFs/Financials Statements/RP_FY2022-23.pdf"
                              download="RP_FY2022-23.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Change on Assets</h2>
                            <a
                              href="#"
                              download="Change on Assets.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                        </div>
                      )}

                      {/* Cart 2 */}
                      <div
                        onClick={() => toggleSection("2021")}
                        className="flex w-full cursor-pointer items-center justify-between gap-2 p-2 border bg-zinc-100 rounded-lg font-semibold"
                      >
                        <h2 className="text-base lg:text-lg font-bold">
                          Statements of FY 2021-22
                        </h2>
                        <span
                          className={`text-base md:text-lg transform transition-transform duration-200 ${
                            openSection === "2021" ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      </div>

                      {openSection === "2021" && (
                        <div className={`flex p-3 rounded-b-lg flex-col gap-1.5 w-full border-b border-x shadow-sm transition-all ease-out duration-300 ${openSection ? "is-expanded animateIn" : "animateOut"}`}>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Balance Sheet</h2>
                            <a
                              href="./PDFs/Financials Statements/BS_FY2021-22.pdf"
                              download="BS_FY2021-22.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Profit & Loss</h2>
                            <a
                              href="./PDFs/Financials Statements/P&L_FY2021-22.pdf"
                              download="P&L_FY2021-22.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Related Party</h2>
                            <a
                              href="./PDFs/Financials Statements/RP_FY2021-22.pdf"
                              download="RP_FY2021-22.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Change on Assets</h2>
                            <a
                              href="#"
                              download="Change on Assets_2022.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                        </div>
                      )}

                      {/* Cart 3 */}
                      <div
                        onClick={() => toggleSection("2020")}
                        className="flex w-full cursor-pointer items-center justify-between gap-2 p-2 border bg-zinc-100 rounded-lg font-semibold"
                      >
                        <h2 className="text-base lg:text-lg font-bold">
                          Statements of FY 2020-21
                        </h2>
                        <span
                          className={`text-base md:text-lg transform transition-transform duration-200 ${
                            openSection === "2020" ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      </div>

                      {openSection === "2020" && (
                        <div className={`flex p-3 rounded-b-lg flex-col gap-1.5 w-full border-b border-x shadow-sm transition-all ease-out duration-300 ${openSection ? "is-expanded animateIn" : "animateOut"}`}>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Balance Sheet</h2>
                            <a
                              href="./PDFs/Financials Statements/BS_FY2020-21.pdf"
                              download="BS_FY2020-21.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Profit & Loss</h2>
                            <a
                              href="./PDFs/Financials Statements/P&L_FY2020-21.pdf"
                              download="P&L_FY2020-21.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Related Party</h2>
                            <a
                              href="./PDFs/Financials Statements/RP_FY2020-21.pdf"
                              download="RP_FY2020-21.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Change on Assets</h2>
                            <a
                              href="#"
                              download="Change on Assets 2021.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                        </div>
                      )}

                      {/* Cart 4 */}
                      <div
                        onClick={() => toggleSection("2019")}
                        className="flex w-full cursor-pointer items-center justify-between gap-2 p-2 border bg-zinc-100 rounded-lg font-semibold"
                      >
                        <h2 className="text-base lg:text-lg font-bold">
                          Statements of FY 2019-20
                        </h2>
                        <span
                          className={`text-base md:text-lg transform transition-transform duration-200 ${
                            openSection === "2019" ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      </div>

                      {openSection === "2019" && (
                        <div className={`flex p-3 rounded-b-lg flex-col gap-1.5 w-full border-b border-x shadow-sm transition-all ease-out duration-300 ${openSection ? "is-expanded animateIn" : "animateOut"}`}>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Balance Sheet</h2>
                            <a
                              href="./PDFs/Financials Statements/BS_FY2019-20.pdf"
                              download="BS_FY2019-20.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Profit & Loss</h2>
                            <a
                              href="./PDFs/Financials Statements/P&L_FY2019-20.pdf"
                              download="P&L_FY2019-20.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Related Party</h2>
                            <a
                              href="./PDFs/Financials Statements/RP_FY2019-20.pdf"
                              download="RP_FY2019-20.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                          <div className="flex items-center justify-between p-2 w-full rounded-lg font-semibold border hover:bg-[#DDEFFD] duration-200">
                            <h2 className="text-sm lg:text-base">Change on Assets</h2>
                            <a
                              href="#"
                              download="Change on Assets 2020.pdf"
                              target="_blank"
                              className="cursor-pointer p-2 bg-zinc-100 border rounded-md text-sm lg:text-base text-red-700"
                            >
                              <FaFilePdf />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FinancialStatements;

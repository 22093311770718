import React, { useState } from "react";
import howToUseApp from "../../API/howToUse.js";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import { ImArrowUpRight2 } from "react-icons/im";
import { FaArrowRight } from "react-icons/fa";
import "./AboutUs.css";

function AboutUs() {
  const [selectedCard, setSelectedCard] = useState(howToUseApp[0]);
  const [offsetLeft, setOffsetLeft] = useState(15);

  const handleSelectCard = (card) => {
    setSelectedCard(card);
  };

  const handleNextButtonClick = () => {
    setOffsetLeft(offsetLeft - 164);
  };

  const handlePrevButtonClick = () => {
    setOffsetLeft(offsetLeft + 164);
  };

  return (
    <div
      className="bg-container"
    >
      <section className="promise2">
        <div className="work-container container">
          <div className="text-center">
            <img
              src="/images/shake-hand.png"
              className="container-icons"
              alt="Business Model scdnd estates"
            />
          </div>
          <h2 className="main-heading welcome-text text-dark text-center">
            Our Promises To You
          </h2>
          <div className="container">
            <div className="mx-4">
              <div className="">
                <div className="row m-0 p-0 promise-container overflow-hidden">
                  <div className="col-3 p-0">
                    {howToUseApp.map((card, index) => (
                      <div
                        key={index}
                        className={`px-4 ${
                          selectedCard === card ? "selected" : "promise-heading"
                        }`}
                        onClick={() => handleSelectCard(card)}
                      >
                        {card.name}
                        {selectedCard === card ? (
                          <FaArrowRight />
                        ) : (
                          <ImArrowUpRight2 />
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    className="col-9 p-0"
                    style={{ backgroundColor: "#f2f2f2" }}
                  >
                    {selectedCard && (
                      <>
                        <div className="row m-0 p-0">
                          <div className="col-9">
                            <h3 className="p-4 pb-0 fw-bold text-dark heading2">
                              <span className="border-start border-3 border-dark ps-2">
                                Where Commitment Meets Quality.
                              </span>
                            </h3>
                            <div className="p-4 pt-0 promise-text-width">
                              <p className="border-start border-3 border-dark ps-2 text-dark">
                                {selectedCard.content}
                              </p>
                            </div>
                          </div>
                          <div className="col-3 d-flex justify-content-center picture1 py-3">
                            <img src={selectedCard.image} alt="scdnd estates" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="promise1">
        <div className="work-container container">
          <div className="text-center">
            <img
              src="/images/shake-hand.png"
              className="container-icons"
              alt="Business Model scdnd estates"
            />
          </div>
          <h2 className="main-heading welcome-text text-dark text-center">
            Our Promises To You
          </h2>
          <div className="container">
            <div className="mx-4">
              <div className="row mb-3 overflow-hidden">
                {offsetLeft !== 15 && (
                  <GrFormPrevious
                    className="fs-1 p-1 bg-secondary prev-btn text-dark"
                    onClick={() =>handlePrevButtonClick}
                  />
                )}
                <div
                  className="heading-animate p-0"
                  style={{ left: `${offsetLeft}px` }}
                >
                  {howToUseApp.map((card, index) => (
                    <div
                      key={index}
                      className={`promise-heading border border-dark rounded mx-4 ${
                        selectedCard === card ? "selected" : ""
                      }`}
                      onClick={() => handleSelectCard(card)}
                    >
                      {card.name}
                    </div>
                  ))}
                </div>
                {offsetLeft !== -313 && (
                  <GrFormNext
                    className="fs-1 p-1 bg-secondary next-btn text-dark"
                    onClick={() =>handleNextButtonClick}
                  />
                )}
              </div>
              <div className="row">
                <div className="p-0">
                  {selectedCard && (
                    <>
                      <div
                        className="row align-items-center justify-content-center promise-container2 p-3"
                        style={{ backgroundColor: "#f2f2f2" }}
                      >
                        <img
                          src={selectedCard.image}
                          alt="scdnd estates"
                          height={250}
                          style={{ width: "150px" }}
                        />
                        <div className="p-4 promise-text-width">
                          <p className=" text-dark text-center">
                            {selectedCard.content}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;

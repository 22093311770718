import React, { useState, useEffect, useRef } from "react";
import "./ProgressiveDisclosureTable.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const ProgressiveDisclosureTable = () => {
  const [activeRow, setActiveRow] = useState(null);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [activeTable, setActiveTable] = useState(0);
  const [animateLine, setAnimateLine] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const tableOffsetTop = tableRef.current.offsetTop;
        setIsHeaderFixed(window.scrollY > tableOffsetTop);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Function to toggle dropdowns and save scroll position
  const toggleDropdown = (index) => {
    const currentScrollY = window.scrollY;
    setActiveRow(activeRow === index ? null : index);

    // Restore scroll position after the dropdown is toggled
    setTimeout(() => {
      window.scrollTo({ top: currentScrollY });
    }, 0);
  };

  // Function to handle table switching with animation
  const handleTableChange = (index) => {
    if (activeTable === index) return; // Do nothing if the selected table is already active

    const currentTable = document.querySelector(".pd-table-container.active");
    const newTable = document.querySelector(
      `.pd-table-container[data-index="${index}"]`
    );
    currentTable.style.animationName = "tableFoldOut";
    setTimeout(() => {
      currentTable.classList.remove("active");
      currentTable.classList.add("hidden");
      newTable.classList.remove("hidden");
      newTable.style.animationName = "tableFoldIn";
      newTable.classList.add("active");

      setActiveTable(index);
    }, 500); 
  };

  
  gsap.registerPlugin(ScrollTrigger);
  const stepRefs = useRef([]);

  useEffect(() => {

    stepRefs.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 90%",
            end: "top 60%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  // Reset the animation state after switching tables
  useEffect(() => {
    if (animateLine) {
      const timer = setTimeout(() => {
        setAnimateLine(false);
      }, 2000); // Duration of the animation

      return () => clearTimeout(timer);
    }
  }, [activeTable, animateLine]);

  const tables = [
    {
      header: [
        "",
        "WALR BOND",
        "FIXED DEPOSITS",
        "CORPORATE BONDS",
        "DEBT MUTUAL FUNDS",
      ],
      data: [
        {
          title: "Nature of Investment",
          description: [
            "Floating-rate investment with externally set coupon.",
            "Fixed-rate investment with bank-determined interest.",
            "Fixed-rate investment (very rarely floating) set at the time of issue.",
            "Market-linked investment with variable returns.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Principal Redemption",
          description: [
            "100% principal redeemable at any time without penalty.",
            "Premature withdrawal allowed at all times.",
            "Liquidity depends on market conditions and quality of issuer.",
            "Redeemable at at any time, potential exit loads and lock-in periods",
          ],
          checks: ["yes", "yes", "no", "yes"],
        },
        {
          title: "Interest Rate",
          description: [
            "Higher rates, equal to average consumer loan rates given by Banks.",
            "Generally lower than lending rates by about 2- 2.5%.",
            "Rates vary based on issuer’s health, repo rate and liquidity conditions.",
            "Lower than underlying corporate bonds due to cash and liquid NCDs.",
          ],
          checks: ["yes", "no", "yes", "no"],
        },
        {
          title: "Default Risk",
          description: [
            "Zero default risk due to open and segregated collateralization.",
            "Low default risk due to Banks access to RBI’s LAF window and deposit insurance.",
            "Higher risk depending on issuer's creditworthiness and refinancing abilities.",
            "Default risk varies based on fund’s target credit quality and strategies.",
          ],
          checks: ["yes", "yes", "no", "no"],
        },
        {
          title: "Collateral and Security",
          description: [
            "Secured by Gold and Nifty 500 (liquid and internationalized).",
            "Not collateralized but implicitly backstopped by RBI and government.",
            "Collateral varies, some unsecured, others backed by future receivables.",
            "Not secured, subject to liquidity and default risks of underlying portfolio.",
          ],
          checks: ["yes", "yes", "no", "no"],
        },
        {
          title: "Transparency",
          description: [
            "Rates based on publicly available loan data and country-wide surveys.",
            "Rates set by Banks internally, not regulated by RBI.",
            "Rates and repayment terms set at the issuer’s discretion.",
            "NAV dependent on the fund manager’s security-picking and market-timing.",
          ],
          checks: ["yes", "no", "no", "no"],
        },
        {
          title: "Management Fees",
          description: [
            "Lower fees (0.5% of AUM per year).",
            "Banks charge 1% on premature withdrawal.",
            "No management fees but higher brokerage costs of 0.2-0.5% every transaction.",
            "Expense Ratio ranging from 1-1.5% per year (for Regular plans).",
          ],
          checks: ["yes", "no", "yes", "no"],
        },
        {
          title: "Ownership and Transfers",
          description: [
            "Blockchain-based ownership records and Peer-2-Peer trading through Smart Contracts.",
            "Ownership records are maintained centrally by the issuing bank. Not transferrable at all.",
            "Centralized records of ownership and transfers kept with Depositories and RTAs.",
            "Centralized records kept by SEBI regulated Trustees. Active funds are non-transferrable.",
          ],
          checks: ["yes", "no", "no", "no"],
        },
        {
          title: "Rate Adjustments",
          description: [
            "Upward revision of interest on repo-rate hikes, but no reduction during repo rate-cut cycles.",
            "Banks are slow to revise rates up during repo-hiking cycle, but cuts quickly.",
            "Exposed to both interest rate and reinvestment risk. Capital loss if repo rate rises.",
            "NAV can move both up and down, based on repo-rates and fund manager’s expertise.",
          ],
          checks: ["yes", "no", "no", "no"],
        },
        {
          title: "Diversification",
          description: [
            "Issuer holds a diversified portfolio spanning across sectors as well as duration.",
            "No inherent diversification from default, but protected from reinvestment risk.",
            "Risk concentrated on issuer's credit health, as well as exposed to duration risk.",
            "Well diversified across firms and sectors, but not across maturities.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
      ],
    },
    {
      header: [
        "",
        "RESIDEX BOND",
        "IMMOVABLE PROPERTY",
        "REITs and InvITs",
        "NIFTY REALTY",
      ],
      data: [
        {
          title: "Nature of Investment",
          description: [
            "Exposure to property price-variations of a major city as a whole, with rental yields linked to NAV.",
            "Direct exposure to real estate price-movements and rental yields, plus ability to consume/end-use.",
            "Exposure to real estate price-movements and rental yields of underlying properties. But no end-use.",
            "Equity stake of developer firms, not exposure to real estate prices directly.",
          ],
          checks: ["yes", "yes", "yes", "no"],
        },
        {
          title: "Principal Redemption",
          description: [
            "100% principal redeemable at any time without penalty.",
            "Extremely illiquid, and cannot be sold in pieces. Distress sale involves 20-30% haircut.",
            "Direct redemption subject to lock-in. Liquidity varies on market conditions, quality of Sponsor, free-float etc.",
            "Redeemable easily by selling in stock exchanges. Impact cost can vary but buyers available.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Maintenance and Repairs",
          description: [
            "Zero cost and responsibility for day-to-day upkeep and repairs.",
            "About 1-1.5% p.a. of property value spent on regular maintenance and repairs by Owner.",
            "Maintenance carried out by Sponsor but expenses implicitly deducted from dividends.",
            "No responsibility or expense of property maintenance by equity holders.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Stamp Duty & Taxes",
          description: [
            "Zero Stamp Duty, registration charges or Property Taxes.",
            "6-7% of purchase price as stamp duty, plus property taxes to municipality payable every year.",
            "Very small stamp duty, but 0.2-0.5% Brokerage charged on every transaction.",
            "Discount brokers charge almost negligible brokerage on stocks.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Information Asymmetry",
          description: [
            "NAV set externally, linked to city-based housing price index published by NHB.",
            "No centralized transaction-data reporting and broker-builder nexus leads to strongly distorted prices.",
            "No actual transfer of underlying properties occur, so unit prices are speculative of future rentals.",
            "Stock prices internalize all information about the firm. Price manipulation near impossible.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Occupancy & Rental Risk",
          description: [
            "Dividend is fixed at 3% p.a. of NAV, so no risk of non-occupancy or rental yields falling.",
            "Average non-occupancy of two-three months when tenants switch every three-four years.",
            "Non-occupancy or falling rentals yields directly passed on to unit holders by REIT Sponsor.",
            "Negligible rental returns risk as most developers don’t retain properties on their BS after completion.",
          ],
          checks: ["yes", "no", "no", "yes"],
        },
        {
          title: "Management Fees",
          description: [
            "Lower fees (0.5% of AUM per year).",
            "Zero management fees but (variable) brokerage payable on every tenant.",
            "Higher fees or Expense ratio of 2-2.5% p.a. of NAV.",
            "Expense Ratio ranging from 1.5-2% per year (for Regular plans) on thematic funds.",
          ],
          checks: ["yes", "yes", "no", "no"],
        },
        {
          title: "Ownership and Transfers",
          description: [
            "Blockchain-based ownership records and Peer-2-Peer trading through Smart Contracts.",
            "Ownership records are paper-based, legally complex and involves chain deeds, property tax receipts, search reports etc. ",
            "Centralized records of ownership and transfers kept with Depositories and RTAs. Trading through full-service Stock Brokers.",
            "Centralized records of ownership and transfers kept with Depositories and RTAs. Trading through discount brokers.",
          ],
          checks: ["yes", "no", "no", "no"],
        },
        {
          title: "Exogeneous Volatility",
          description: [
            "NAV dependent only on NHB surveyed city-wise property prices. No correlation with financial markets or leverage.",
            "Local demography, land-use restrictions, law and order, infrastructure and amenities etc. can all dramatically affect prices.",
            "Loosely correlated to industry health of tenants (of underlying properties), factors like WFH and the REIT’s leverage.",
            "Extremely volatile as developers take on high leverage on new projects. Nifty PE also influence prices as Market Beta >3.",
          ],
          checks: ["yes", "no", "yes", "no"],
        },
        {
          title: "Diversification",
          description: [
            "Issuer holds a diversified portfolio spanning across mortgage bonds, CMBS, REITs and commodity.",
            "Entire investor’s value concentrated on the one property and neighborhood’s appeal!",
            "Well diversified across tenants, localities, and potentially property types.",
            "No control of diversification (across projects, cities, leverage etc.) in hands of equity holders.",
          ],
          checks: ["yes", "no", "yes", "no"],
        },
      ],
    },
  ];

  return (
    <section
      ref={tableRef}
      className={
        isHeaderFixed
          ? "pd-table-header-is-fixed pt-0 bg-white w-full flex flex-col items-center justify-center"
          : ""
      }
    >
      <div className="w-[90%] mt-10 lg:mt-10 bg-white md:w-[87%] " >
        <div ref={(el) => (stepRefs.current[0] = el)} className="header-title flex flex-col  text-center   xl:text-left leading-tight  font-semibold tracking-wide text-gray-800 p-2 mx-2 md:mx-32 max-sm:mb-1 md:mb-6 xl:mb-12 w-full " >
          <p className="font-bold leading-none lg:leading-snug  mx-0 text-base md:text-xl lg:text-2xl xl:text-3xl 2xl:text-[34px]">
            An <span className="text-sky-400">Evolutionary Change </span>in
            finance
          </p>
        </div>
        <div className="flex  flex-row justify-center items-center md:gap-x-2 " ref={(el) => (stepRefs.current[1] = el)}>
          <div className="button-container  max-md:scale-50 md:scale-[65%] lg:scale-75 xl:scale-[80%]">
            <button
              className={`brutalist-button button-1 ${activeTable === 0 ? "brutalist-button--active" : ""}`}
              onClick={() => handleTableChange(0)}
            >
              <div className="openai-logo">
                <img
                  className="openai-icon"
                  src="/images/discount.gif"
                  alt=""
                />
              </div>
              <div className="button-text">
                {/* <span>Fixed Income</span> */}
                <span>WALR</span>
              </div>
            </button>
          </div>

          <div className="">
            <p
              className={` text-xs md:text-sm lg:text-base font-semibold text-center leading-3 md:leading-5 duration-300 delay-150 ${
                activeTable === 0 ? "text-sky-500" : ""
              }`}
            >
              Fixed <br /> Income
            </p>
          </div>

          <div className="h-20 md:h-24 mx-2 overflow-hidden flex justify-start">
            <svg
              className="relative h-28 md:h-48 w-auto my-0 max-md:-top-2"
              viewBox="0 0 100 100"
            >
              <path
                d="M 50 50 C 65 50, 150 50, 200 50"
                stroke="#e8e8e8"
                strokeWidth="1"
                fill="none"
                transform="rotate(180, 100, 38)"
                stroke-linecap="round"
              />
              <path
                className={`animated-path-upDown mobile-stroke ${
                  activeTable === 1 ? "animate-dashUp" : "animate-dashDown"
                }`}
                d="M 50 50 Q 65 50, 200 50"
                stroke="#5ab2ff"
                fill="none"
                stroke-dasharray="15 85"
                transform="rotate(180, 100, 38)"
                stroke-linecap="round"
              />
            </svg>
          </div>

          <div>
            <p
              className={`text-xs md:text-sm lg:text-base font-semibold text-center leading-3 md:leading-5 duration-300 delay-150 ${
                activeTable === 1 ? "text-sky-500" : ""
              }`}
            >
              Real <br />
              Estate
            </p>
          </div>

          <div className="button-container max-sm:scale-50 md:scale-[65%] lg:scale-75 xl:scale-[80%]">
            <button
              className={`brutalist-button button-1 ${activeTable === 1 ? "brutalist-button--active" : ""}`}
              onClick={() => handleTableChange(1)}
            >
              <div className="openai-logo">
                <img
                  className="openai-icon"
                  src="/images/contract.gif"
                  alt=""
                />
              </div>
              <div className="button-text">
                {/* <span>Real Estate</span> */}
                <span>RESIDEX</span>
              </div>
            </button>
          </div>
        </div>
        <div className="w-full flex  justify-center " ref={(el) => (stepRefs.current[2] = el)}>
          <div className="w-full 2xl:w-[90%] max-sm:scale-100 md:scale-90 overflow-x-scroll rounded-2xl ">
            {tables.map((table, tableIndex) => (
              <div
                key={tableIndex}
                className={`pd-table-container ${activeTable === tableIndex ? "active" : "hidden"}`}
                data-index={tableIndex}
              >
                <div className="pd-table min-w-[450px] shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                  <section className="pd-table__header">
                    <ul className="flex items-center">
                      {table.header.map((headerItem, index) => (
                        <li
                          key={index}
                          className={
                            index !== 0
                              ? "border-r-[1px] border-[#a8a8a8] flex items-center"
                              : ""
                          }
                        >
                          {headerItem}
                        </li>
                      ))}
                    </ul>
                  </section>

                  {table.data.map((item, index) => (
                    <div
                      key={index}
                      className={`pd-table__row pd-table__row--has-dropdown ${activeRow === index ? "active" : ""}`}
                    >
                      <ul
                        className="pd-table__cells"
                        onClick={() => toggleDropdown(index)}
                      >
                        <li className="w-full h-full pd-table__cell pd-table__cell--title">
                          <span
                            className="w-5/6 h-full max-md:text-[8px] md:text-xs lg:text-sm xl:text-lg text-left max-md:ml-1 md:ml-3 lg:ml-6 xl:ml-10 list-title"
                            data-pd-title={item.title
                              .toLowerCase()
                              .replace(" ", "-")}
                          >
                            {item.title}
                          </span>
                          <button className="w-1/6 bg h-full pd-table__cell__button">
                            <img
                              className={`md:p-1 lg:p-2 w-full ${activeRow === index ? "rotate-180" : ""}`}
                              src="/images/down-arrow (1).png"
                              alt=""
                            />
                          </button>
                        </li>
                        {item.checks.map((check, i) => (
                          <li
                            key={i}
                            className="pd-table__cell w-full h-full object-cover flex items-center justify-center"
                            data-checked={check}
                          >
                            <div className="w-full h-full mt-2 flex justify-center items-center">
                              {check === "yes" ? (
                                <img
                                  className="w-3 h-3 md:w-6 md:h-6 lg:w-8 lg:h-8 mt-1"
                                  src="/images/checkmark.png"
                                  alt="Yes"
                                />
                              ) : (
                                <img
                                  className="w-2 h-2 md:w-6 md:h-6 lg:w-8 lg:h-8 md:p-1 lg:p-2 mt-1"
                                  src="/images/close.png"
                                  alt="No"
                                />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>

                      <div
                        className={`pd-table__dropdown transition-all duration-500 ease-in-out overflow-hidden ${
                          activeRow === index ? "max-h-[500px]" : "max-h-0"
                        }`}
                      >
                        <ul
                          className="pd-table__cells overflow-hidden"
                          onClick={() => toggleDropdown(index)}
                        >
                          <li className="w-full pd-table__cell pd-table__cell-drop--title border-r-[1px]">
                            <span
                              className=""
                              data-pd-title={item.title
                                .toLowerCase()
                                .replace(" ", "-")}
                            ></span>
                          </li>
                          {item.checks.map((check, i) => (
                            <li
                              key={i}
                              className="pd-table__cells-drop object-cover flex items-center justify-center h-full overflow-hidden border-r-[1px]"
                              data-checked={check}
                            >
                              <div className="w-full h-full flex justify-center items-center leading-tight md:leading-3 lg:leading-4 text-[6px] md:text-[10px] lg:text-sm text-center px-1 font-semibold overflow-hidden">
                                {item.description[i]}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgressiveDisclosureTable;
